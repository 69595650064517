/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'react-simple-snackbar'
import { Link, useNavigate, useParams } from 'react-router-dom'

import './index.scss'
import { ToastContainer, toast } from 'react-toastify';
import { Input } from 'components/input'
import Constants from '../../../constants'
import { Select } from 'components/select'
import { Button } from 'components/button'
import { Loader } from 'components/loader'
import { Snackbar } from 'components/snackbar'
import UserService from 'services/UserService'
import { PageHeader } from 'components/page-header'
import ClientServiceV2 from 'services/v2/ClientService'
import { FeedbackError } from 'components/feedback/error'
import { ProjectContainer } from 'components/project-container'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import ProviderService from 'services/ProviderService'
import CnpjService from 'services/CnpjService';
import { CheckBoxCustom } from 'components/checkBoxCustom'

const BuyingCompanyAdd = () => {
    const navigate = useNavigate()
    const { register, handleSubmit, errors } = useForm()
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const { id } = useParams<string>()
    const [data, setData] = useState<any>({})
    const [ufs, setUFs] = useState<any>([])
    const [uf, setUF] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [cities, setCities] = useState<any>([])
    const [allCities, setAllCities] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [idVend, SetIdVend] = useState<any>()
    const [hasFieldControl, sethasFieldControl] = useState(0)
    const [shouldSearch, setShouldSearch] = useState(true)
    const [activitesBranch, setActivitesBranch] = useState([])

    // useEffect(() => {
    //     console.log('data=', data)
    // }, [data])

    useEffect(() => {
        const init = async () => {
            loadRamos()
            await loadRegions().then(() => {
                loadClient().then(() => {
                    onChangeUF(null, data.estado)
                    setCity(data.cidade)

                })
            })

        }

        init()
    }, [])

    const loadClient = async () => {
        try {
            setLoading(true)
            const data = await ClientServiceV2.find_register_request_by_id(id);
            SetIdVend(data.vend_id)

            const cnpj_cli = data?.sol_cnpj.match(/\d/g).join(""); // remover pontuaçoes do cnpj para pesquisa
            try {
                var cnpj = await CnpjService.find_cnpj(cnpj_cli);
                const { estados, cidades } = await UserService.regions();



                var cepApi = await CnpjService.find_cep(cnpj.cep);

                const seachEstadoId = await search(cepApi.state, estados, 'estado');
                setUF(seachEstadoId.est_id);

                const seachCity = await search(cepApi.city, cidades, 'cidade');
                const cities = cidades.filter((c) => c.est_id == seachEstadoId.est_id);
                setCities(cities)
                setCity(seachCity.cid_id)




            } catch (e) {
                console.log('erro ao buscar cnpj')
            }
            setData({
                cnpj: data?.sol_cnpj,
                razao_social: cnpj?.razao_social,
                nome_fantasia: cnpj?.nome_fantasia,

                endereco: cepApi?.street,
                estado: data?.est_id,
                cidade: data?.cid_id,
                logradouro: cnpj?.numero,
                bairro: cepApi?.neighborhood,
                complemento: data?.complemento,
                cep: cnpj?.cep ? cnpj?.cep : data.sol_cep,

                email: data?.sol_email,
                senha: data?.sol_senha,
                contato: data?.sol_contato,
                telefone: data?.sol_telefone,
                celular: data?.sol_telefone,
                has_field_control: hasFieldControl
            })
        } catch (e) {
            console.error(e)
        } finally {
            // searchCep(data.sol_cep)
            setLoading(false)
        }
    }
    useEffect(() => {
        if (data.cep && shouldSearch) {
            searchCep(data.cep)
        }
    }, [data])

    const loadRegions = async () => {
        try {
            setLoading(true)
            const { estados, cidades } = await UserService.regions()
            setUFs(
                [{ est_id: undefined, est_nome: 'Selecione um estado' }].concat(
                    estados
                )
            )
            setAllCities(cidades)
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }
    const loadRamos = async () => {
        try {
            const data = await UserService.ramosDeAtividade()
            setActivitesBranch(data)

        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const onChangeUF = (event, value) => {
        const uf_id = value || event?.target?.value
        if (!uf_id) return

        setUF(uf_id)

        const cities = allCities.filter((c) => c.est_id == uf_id)
        setCities(
            [{ cid_id: undefined, cid_nome: 'Selecione uma cidade' }].concat(
                cities
            )
        )
    }
    const onChangeInput = async (event, cep) => {
        const { name, value } = event.target
        setData({ ...data, [name]: value })

        if (name === 'cidade') {
            setCity(value)
        }
        if (name === 'cep') {
            searchCep(value)
        }
    }

    async function searchCep(value) {
        setShouldSearch(false)
        var cep = value.replace(/([^\d])+/gim, ''); //pegar apenas numeros do cep

        if (cep.length == 8) {

            setLoading(true)
            var cepApi = await CnpjService.find_cep(cep).catch(er => {
                setLoading(false),
                    toast.info('CEP não encontrado', {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,

                    })
            })
            if (cepApi) {
                setLoading(false)
                setData({
                    ...data,
                    endereco: cepApi.street,
                    bairro: cepApi.neighborhood,
                    cep: value
                });
                const seachEstadoId = await search(cepApi.state, ufs, 'estado');
                const cepCity = cepApi.city.normalize('NFD').replace(/[\u0300-\u036f]/g, ""); //remover acentos
                const seachCity = await search(cepCity, allCities, 'cidade');

                setUF(seachEstadoId.est_id);
                const cities = allCities.filter((c) => c.est_id == seachEstadoId.est_id);

                setCities(cities)
                setCity(data.cidade ? data.cidade : seachCity.cid_id)
                setLoading(false)

            }


        }
    }

    const search = async (key, inputArray, region) => {
        if (region == 'estado') {
            for (let i = 0; i < inputArray.length; i++) {
                if (inputArray[i].est_sigla === key) {
                    return inputArray[i];
                }
            }
        } else {
            for (let i = 0; i < inputArray.length; i++) {
                if (inputArray[i].cid_nome.toUpperCase() === key.toUpperCase()) {
                    return inputArray[i];
                }
            }

        }
    }

    const save = async (data: any) => {
        // console.log(data)
        let infos = { ...data }
        infos.endereco = infos.endereco + `, ${infos.logradouro}`
        delete infos.logradouro

        try {
            setLoading(true)
            const sol_id = id
            const { provider } = await ProviderService.getLoggedProvider()
            const body = { ...infos, sol_id, for_id: provider.id, vend_id: idVend, field_control: hasFieldControl }
            await ClientServiceV2.create(body)
            // navigate(`${Constants.ROUTE_CLIENTS}#rrm`)

            const cnpj = infos.cnpj;
            navigate(`${Constants.ROUTE_CLIENTS}?cnpj=${cnpj}`);

            openSnackbar(
                <Snackbar icon="sticky_note_2">
                    Cadastro realizado com sucesso!
                </Snackbar>
            )
        } catch (error: any) {
            console.log(error)
            openSnackbar(
                <Snackbar icon="sticky_note_2">
                    {error.response && error.response.data
                        ? error.response.data.mensagem
                        : 'Ocorreu um erro ao salvar o registro!'}
                </Snackbar>
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <ProjectContainer>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable

                pauseOnHover />
            <div className="buying-company-add">
                {loading && <Loader />}

                <header>
                    <PageHeader title="Cadastrar cliente" />
                </header>

                <div className="actions">
                    <Link to={`${Constants.ROUTE_CLIENTS}#rrm`}>
                        <Button>
                            <i className="material-icons">arrow_back</i>Voltar
                        </Button>
                    </Link>
                </div>

                <div className="form">
                    <form onSubmit={handleSubmit(save)}>

                        <div className="row">
                            <div className="form-group col-12 col-md-3">
                                <Input
                                    type="text"
                                    id="cnpj"
                                    name="cnpj"
                                    description="CNPJ"
                                    placeholder="CNPJ"
                                    register={register({ required: true })}
                                    value={data?.cnpj}
                                    mask="99.999.999/9999-99"
                                    onChange={onChangeInput}
                                />
                                {errors.cnpj && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                            <div className="form-group col-12 col-md-3">
                                <Select
                                    type="text"
                                    id="ramo"
                                    name="ramo"
                                    value_key="id"
                                    label_key="name"
                                    description="Ramo de atividade"
                                    placeholder="Selecione"
                                    options={activitesBranch}
                                    onChange={onChangeInput}
                                    register={register({ required: true })}
                                    value={data?.ramo}
                                />
                                {errors.cnpj && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>

                            <div className="form-group col-12"></div>

                            <div className="form-group col-12 col-md-4">
                                <Input
                                    type="text"
                                    id="razao_social"
                                    name="razao_social"
                                    placeholder="Razão Social"
                                    description="Razão Social"
                                    register={register({ required: true })}
                                    defaultValue={data?.razao_social}
                                    onChange={onChangeInput}
                                />
                                {errors.razao_social && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>

                            <div className="form-group col-12 col-md-4">
                                <Input
                                    type="text"
                                    id="nome_fantasia"
                                    name="nome_fantasia"
                                    placeholder="Nome Fantasia"
                                    description="Nome Fantasia"
                                    register={register({ required: true })}
                                    defaultValue={data?.nome_fantasia}
                                    onChange={onChangeInput}
                                />
                                {errors.nome_fantasia && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                            <div className="checkContainer">
                                <CheckBoxCustom
                                    value={hasFieldControl}
                                    // id="has_field_control"
                                    // change={(e, stats) => (e, stats)}
                                    change={(e, stats) => stats ? sethasFieldControl(1) : sethasFieldControl(0)}
                                    checked={hasFieldControl == 1}
                                    description={'Usa Integração FieldControl ?'}
                                ></CheckBoxCustom>

                            </div>
                        </div>

                        <hr className="divider" />

                        <div className="row">
                            <div className="form-group col-12 col-md-2">
                                <Input
                                    type="text"
                                    id="cep"
                                    name="cep"
                                    mask="99.999-999"
                                    description="CEP"
                                    placeholder="CEP"
                                    register={register({ required: true })}
                                    value={data?.cep}
                                    defaultValue={data?.cep}
                                    onChange={onChangeInput}
                                />
                                {errors.cep && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>

                            <div className="form-group col-12 col-md-4">
                                <Input
                                    type="text"
                                    id="endereco"
                                    name="endereco"
                                    placeholder="Endereço"
                                    description="Endereço"
                                    register={register({ required: true })}
                                    defaultValue={data?.endereco}
                                    onChange={onChangeInput}
                                />
                                {errors.endereco && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                            <div className="form-group col-12 col-md-2">
                                <Input
                                    type="number"
                                    id="logradouro"
                                    name="logradouro"
                                    placeholder="logradouro"
                                    description="logradouro"
                                    register={register({ required: true })}
                                    defaultValue={data?.logradouro}
                                    onChange={onChangeInput}
                                />
                                {errors.logradouro && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>

                            <div className="form-group col-md-4"></div>

                            <div className="form-group col-12 col-md-2">
                                <Select
                                    id="estado"
                                    name="estado"
                                    description="UF"
                                    value_key="est_id"
                                    label_key="est_nome"
                                    options={ufs}
                                    onChange={onChangeUF}
                                    defaultValue=""
                                    register={register({ required: true })}
                                    value={uf}
                                />
                                {errors.estado && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>

                            <div className="form-group col-12 col-md-2">
                                <Select
                                    id="ramo"
                                    name="cidade"
                                    description="Cidade"
                                    value_key="cid_id"
                                    label_key="cid_nome"
                                    options={cities}
                                    onChange={onChangeInput}
                                    disabled={uf == null || uf.length === 0}
                                    register={register({ required: true })}
                                    value={city}
                                />
                                {errors.cidade && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>

                            <div className="form-group col-12 col-md-2">
                                <Input
                                    type="text"
                                    id="bairro"
                                    name="bairro"
                                    description="Bairro"
                                    placeholder="Bairro"
                                    className="form-control input-field"
                                    register={register({ required: true })}
                                    defaultValue={data?.bairro}
                                    onChange={onChangeInput}
                                />
                                {errors.bairro && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>

                            <div className="form-group col-12 col-md-2">
                                <Input
                                    type="text"
                                    id="complemento"
                                    name="complemento"
                                    placeholder="Complemento"
                                    description="Complemento"
                                    register={register()}
                                    defaultValue={data?.complemento}
                                    onChange={onChangeInput}
                                />
                                {errors.complemento && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                        </div>

                        <hr className="divider" />

                        <div className="row">
                            <div className="form-group col-12 col-md-4">
                                <Input
                                    type="text"
                                    id="contato"
                                    name="contato"
                                    description="Contato"
                                    placeholder="Contato"
                                    register={register({ required: true })}
                                    defaultValue={data?.contato}
                                    onChange={onChangeInput}
                                />
                                {errors.contato && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>

                            <div className="form-group col-12 col-md-2">
                                <Input
                                    type="text"
                                    id="telefone"
                                    name="telefone"
                                    mask="(99) 99999-9999"
                                    description="Telefone"
                                    placeholder="Telefone"
                                    onChange={onChangeInput}
                                    register={register({ required: false })}
                                    value={data?.telefone}
                                />
                                {errors.telefone && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>

                            <div className="form-group col-12 col-md-2">
                                <Input
                                    type="text"
                                    id="celular"
                                    name="celular"
                                    mask="(99) 99999-9999"
                                    description="Celular"
                                    placeholder="Celular"
                                    onChange={onChangeInput}
                                    register={register({ required: true })}
                                    value={data?.celular}
                                />
                                {errors.celular && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>

                            <div className="form-group col-12"></div>

                            <div className="form-group col-12 col-md-4">
                                <Input
                                    autocomplete="false"
                                    type="text"
                                    id="email"
                                    name="email"
                                    onChange={onChangeInput}
                                    description="Email / Login"
                                    placeholder="Email / Login"
                                    value={data.email}
                                    register={register({
                                        required: true,
                                        pattern:
                                            /^[a-zA-Z0-9\-_.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/g,
                                    })}
                                    defaultValue={data?.email}
                                />
                                {errors.email &&
                                    errors.email.type === 'required' && (
                                        <FeedbackError
                                            message={
                                                Constants.MSG_REQUIRED_FIELD
                                            }
                                        />
                                    )}
                                {errors.email &&
                                    errors.email.type === 'pattern' && (
                                        <FeedbackError
                                            message={
                                                Constants.MSG_PATTERN_FIELD
                                            }
                                        />
                                    )}
                            </div>

                            <div className="form-group col-12 col-md-4">
                                <Input
                                    autocomplete="false"
                                    type="password"
                                    id="senha"
                                    name="senha"
                                    description="Senha"
                                    placeholder="Senha"
                                    onChange={onChangeInput}
                                    register={register({ required: true })}
                                    defaultValue={data?.senha}
                                    value={data.senha}
                                />
                                {errors.senha && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                        </div>

                        <Button type="submit" className="c-btn-success">
                            <i className="material-icons">save_alt</i>Salvar
                        </Button>
                    </form>
                </div>
            </div>
        </ProjectContainer>
    )
}

export default BuyingCompanyAdd
