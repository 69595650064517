import React, { FC, useEffect, useState } from 'react'
import styles from './index.module.scss'
import ModalComponent from 'components/modal-v2'
import { FaStoreAlt } from 'react-icons/fa'
import DateService from 'services/DateService'
import RequestService from 'services/v3/request.service'
import { Status } from 'components/request-status'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import SellerParticipationHighchartModel from 'models/seller-participation-highchart.model'

export const SellerParticipationDetailsModal: FC<any> = ({ request, setShowSellerParticipationDetails }: any) => {

  const [modal, setModal] = useState<any>({})
  const [requests, setRequests] = useState<any>([])
  const [data, setData] = useState<any>({})

  useEffect(() => {
    setModal({
      title: <span style={{ textTransform: "uppercase" }}><b>#{request.seller} - {request.client}</b></span>,
      icon: <FaStoreAlt />
    })
    RequestService.load_seller_participations(request.seller_id, request.client_id).then((data) => {
      setRequests(data)
    })
  }, [request])

  useEffect(() => { setData(new SellerParticipationHighchartModel(requests)) }, [requests])

  const close = () => {
    setShowSellerParticipationDetails(false)
  }

  return (
    <div style={{ 'maxHeight': '85vh' }} className="container-fluid">
      <div style={{ 'overflowY': 'auto' }}>
        <div className="col col-12">
          <HighchartsReact
            highcharts={Highcharts}
            options={data}
          />
        </div>
        <div className={styles.listSellerParticipation}>
          <table className={`table ${styles.newTableSellerParticipation}`}>
            <thead>
              <tr>
                <th className="pl-4"></th>
                <th>Cotação</th>
                <th>Grupo</th>
                <th>Status</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {requests && requests.map((r, k) =>
                <>
                  <tr className={styles.hideOnMobile} key={r.request_id}>
                    <td ><b>#{k + 1}</b></td>
                    <td>{r.request_id}</td>
                    <td>{r.client_name}</td>
                    <td><Status status={r.status} /></td>
                    <td>{DateService.formatToDisplayWithTime(new Date(r.created_at))}</td>
                  </tr>
                  <tr style={{}} className={styles.trMobile} key={r.request_id}>
                    <td style={{}}>
                      <section className={styles.storeNameMobile}>
                        <p>#{k + 1}</p>
                        <p>{r.client_name}</p>
                      </section>
                    </td>
                    <td>
                      <section className={styles.rowIdStatus}>
                          <p># {r.request_id}</p>
                          <span>{DateService.formatToDisplayWithTime(new Date(r.created_at))}</span>
                          <span><Status status={r.status}/></span>
                      </section>
                    </td>
                    {/* <td><b>#{k + 1}</b></td> */}
                    {/* <td>{r.request_id}</td>
                                        <td>{r.client_name}</td>
                                        <td><Status status={r.status} /></td>
                                        <td>{DateService.formatToDisplayWithTime(new Date(r.created_at))}</td> */}
                  </tr>
                </>
              )}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
  //   return <ModalComponent modal={ modal } show={ true } close={ close } className={ styles['seller-participation-modal'] }>
  //       <div className="container-fluid">
  //           <div className="row header">
  //               <div className="col col-12">
  //                   <HighchartsReact
  //                       highcharts={ Highcharts }
  //                       options={ data }
  //                   />
  //               </div>
  //               <div className="col m-4 p-3 shadow">
  //                   <table className={ `table ${styles.table}` }>
  //                       <thead>
  //                           <tr>
  //                               <th className="pl-4"></th>
  //                               <th>Cotação</th>
  //                               <th>Cliente</th>
  //                               <th>Status</th>
  //                               <th>Data</th>
  //                           </tr>
  //                       </thead>
  //                       <tbody>
  //                           { requests && requests.map((r, k) => <tr key={ r.request_id }>
  //                               <td className="pl-4"><b>#{ k+1 }</b></td>
  //                               <td>{ r.request_id }</td>
  //                               <td>{ r.client_name }</td>
  //                               <td><Status status={ r.status } /></td>
  //                               <td>{ DateService.formatToDisplayWithTime(new Date(r.created_at)) }</td>
  //                           </tr>) }
  //
  //                       </tbody>
  //                   </table>
  //               </div>
  //           </div>
  //       </div>
  //   </ModalComponent>
}
