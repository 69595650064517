import ModalComponent from 'components/modal-v2'
import React, { FC, useEffect, useState } from 'react'
import { MdOutlineBlock } from 'react-icons/md'
import ClientService from 'services/v3/clients.service'
import { mask_cnpj } from 'helpers/mask.helper'
import { PhoneWithWhats } from 'components/phoneWithWhats'

export const BlacklistedModal: FC<any> = ({ isVisible, onCloseCallback }: any) => {

    const [ clients, setClients ] = useState<any>([])

    const modal = {
        icon: <MdOutlineBlock />,
        styles: { maxWidth: '80vw' },
        title: `Blacklist`,
        subtitle: 'Você não está recebendo cotações desses clientes'
    }

    useEffect(() => {
        ClientService.blacklist().then(response => {
            setClients(response)
        })
    }, [ ])

    return (
        <ModalComponent modal={ modal } show={ isVisible } close={ onCloseCallback } className={ 'modal-dialog' }>
            <div className="container-fluid">
                { clients && clients.length > 0 ? <table className="table">
                    <thead>
                        <tr>
                            <th className="pr-3">Grupo</th>
                            <th>CNPJ</th>
                            <th>Razão Social</th>
                            <th>Telefone</th>
                            <th>Justificativa</th>
                        </tr>
                    </thead>
                    <tbody>
                        { clients && clients.map(c => <tr key={ c.id }>
                            <td>{ c.group_name }</td>
                            <td>{ mask_cnpj(c.cnpj) }</td>
                            <td>{ c.head_office?.social_reazon }</td>
                            <td><PhoneWithWhats phone={ c.phone }/></td>
                            <td>{ c.blacklist[0]?.obs }</td>
                        </tr>) }
                    </tbody>
                </table> : <div className="row">
                    <div className="col">
                        Nenhum cliente encontrado
                    </div>
                </div> }
            </div >
        </ModalComponent >
    )
}


