import ProviderService from '../../services/ProviderService'

// TYPES
export const LOAD_EDIT_LOGGED_PROVIDER_DATA_SUCCESS   = 'LOAD_EDIT_LOGGED_PROVIDER_DATA_SUCCESS'

// Reducer
const initialState = null

export default function editProvider(state = initialState, action: any) {
    if(action.type === LOAD_EDIT_LOGGED_PROVIDER_DATA_SUCCESS) {
        return action.editLoggedProviderData
    } else {
        return state
    }
}

// ACTIONS
export const loadEditLoggedProviderData = () => {
    return (dispatch: any) => ProviderService.getLoggedProvider()
        .then((response) => {
            dispatch(loadEditLoggedProviderDataSuccess(response))
        })
        .catch(() => {
            dispatch(loadEditLoggedProviderDataSuccess(null))
        })
}

const loadEditLoggedProviderDataSuccess = (editLoggedProviderData) => {
    return { type: LOAD_EDIT_LOGGED_PROVIDER_DATA_SUCCESS, editLoggedProviderData }
}
