import React, { FC } from 'react'
import CurrencyInput from 'react-currency-input'
import { Label } from '../label'
import './index.scss'

export const Currency: FC<any> = ({ mode, className, register, ...props }: any) => (
    <div style={{'display':'flex', 'flexDirection':'column'}}>
        { props.description && mode !== 'label' && <Label className="c-label">{ props.description }</Label> }
        <CurrencyInput
         autocomplete="off"
            className={ `currencyInput form-control ${className} ${mode === 'label' ? 'as-label' : ''}` }
            placeholder="R$ ..."
            decimalSeparator=","
            thousandSeparator="."
            ref={ register }
            prefix="R$ "
            { ...props }
        />
    </div>
)
