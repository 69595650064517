import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;



    @media only screen and (max-width: 740px) {
    flex-direction: column;
    margin-bottom: 10px;
    }
`;

export const BodyContainer = styled.div`
`;

export const FiltersContainer = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
`;

export const DatePickerAndButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    flex-wrap: wrap;
    gap: 15px;

    @media only screen and (max-width: 740px) {

        flex-direction: column;
}
    `;

export const DatePickersContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
    overflow: visible;
    div{
       overflow-y: visible;

      // width: 150px;
    }

    @media only screen and (max-width: 740px) {
        width: 80%;
        flex-wrap: nowrap;
        flex-direction: column;
        div{
        width: 100% !important;
    }
    }
`;
export const FilterButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: flex-end;

`;
export const SelectFiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    align-items: flex-end;


    @media only screen and (max-width: 740px) {

    flex-direction: column;


    div{
        width: 100%;
    }
}
`;
export const TableContainer = styled.div`
`;
