import HttpClient from './http.client'

export default class RequestService {
    //&category=${categories}&name=${textSearch}
    //&digitados=${onlyTyped}&meusProdutos=${myProducts}
    static load_request_products = async (id: number,
        seller_id: number,
        load_derivatives = 1,
        page?,
        category?,
        name?,
        digitados?:number,
        meusProdutos?:number,
        ratio?: number,
        for_report?: number

    ): Promise<any> => {
        const query = { seller_id: seller_id, load_derivatives, paginate: 1, page, category, name, digitados, meusProdutos, ratio, for_report }
        // const query = { seller_id: seller_id, load_derivatives }
        // return HttpClient.get(`/providers/requests/${id}/products`, query)
        return HttpClient.get(`/providers/requests/${id}/products`, query)
    }
    static load_request_products_withoutPaginate = async (id: number,
        seller_id: number,
        load_derivatives = 1,
    ): Promise<any> => {
        const query = { seller_id: seller_id, load_derivatives }
        // const query = { seller_id: seller_id, load_derivatives }
        // return HttpClient.get(`/providers/requests/${id}/products`, query)
        return HttpClient.get(`/providers/requests/${id}/products`, query)
    }

    static load_request_last_offers_by_seller = async (id: number, seller_id: number): Promise<any> => {
        const query = { seller_id: seller_id }
        return HttpClient.get(`/providers/requests/${id}/products/offers/last`, query)
    }

    static load_seller_participations = async (id: number, client_id: number): Promise<any> => {
        const query = { cli_id: client_id }
        return HttpClient.get(`/providers/${id}/reports/requests`, query)
    }
}
