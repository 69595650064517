import { ResponseApi } from '../interfaces/responseApi'
import ProviderService from './ProviderService'
import Constants from '../constants'
import HttpClient from './HttpClient'
import CustomerService from './CustomerService'
import { Order } from '../interfaces/order'
import ProductService from './ProductService'
import { Product } from '../interfaces/product'
import DeliveryService from './DeliveryService'
import axios from 'axios'
import { constants } from 'buffer'
import download from 'downloadjs'

export default class OrderService {

        static parse = (orderFromApi: any) : Order => ({
            id: orderFromApi.id_pedido,
            storeName: orderFromApi.loj_razao,
            date: orderFromApi.data_geracao,
            quotationId: orderFromApi.id_cotacao,
            value: orderFromApi.valor_pedido,
            rate: orderFromApi.nota,
            rating: orderFromApi.avaliacao,
            status: orderFromApi.status,
            salesmanName: orderFromApi.vendedor,
            order_type: orderFromApi.cli_tipoPedido,
            obs: orderFromApi.observacao,
            observations: orderFromApi.observacao ? orderFromApi.observacao: '',
            nomeFantasia:orderFromApi.loj_nomeFantasia ? orderFromApi.loj_nomeFantasia:''

        })

    static getAll = (params: any) : Promise<ResponseApi> => {

        return HttpClient.get('/listarpedidos', params).then(response => {
            const orders: Order[] = []
            response.pedidos.data.forEach((order: any) => orders.push(OrderService.parse(order)))
            response.pedidos.data = orders
            response.pedidos.totalValue = response.total_pedidos[0].total_pedidos
            return response.pedidos
        })
    }
       static getRamos = () : Promise<ResponseApi> => {

        return HttpClient.get('/listarramos').then(response => {
            return response
        })
    }

    static getById = (id: string) : Promise<Order> =>
        HttpClient.get(`${Constants.URL_API_ORDER_DETAILS}${id}`).then(response => {

            // this code is to make sure these fields will be in the city, state and customer final object
            response.cliente.cid_nome = response.cliente.cidade
            response.cliente.cli_bairro = response.cliente.bairro
            response.cliente.est_nome = response.cliente.estado

            const order = OrderService.parse(response.pedido)
            order.customer = CustomerService.parse(response.cliente)
            order.provider = ProviderService.parse(response.fornecedor)
            order.delivery = DeliveryService.parse(response.entrega)
            const products: Product[] = []
            response.produtos.forEach((productFromApi: any) => {
                products.push(ProductService.parse(productFromApi))
            })
            order.products = products
            return order
        })

  static confirm = (orderId: string) : Promise<any> =>
      HttpClient.post(Constants.URL_API_ORDER_CONFIRM, { pedido: orderId })

  static pdf =  async (params: any) : Promise<any> => {
      params.print = 'pdf';
       await axios.get(`${Constants.URL_API_DIRECT_SALE_ORDER}`,{
          params: params,
          headers: HttpClient.getHeaders(),
          responseType: 'blob',
      }).then (response => {
          const content = response.headers['content-type']
          download(response.data, 'relatorio', content);


      })
          .catch(error => console.log(error))
          return true;
  }

}
