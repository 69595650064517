import { State } from '../interfaces/state'

export default class StateService {

    static parse = (stateFromApi: any) : State => ({
        id: stateFromApi?.est_id,
        acronym: stateFromApi?.est_sigla,
        name: stateFromApi?.est_nome
    })
}
