import React from 'react';


import {
    StatusBox
} from './styles'

interface props{
    value:any
}

export function StatusBoxDeliryOrder({value}:props) {

    var statusColor
    if(value =='AGUARDANDO_CONFIRMACAO'){
        statusColor= '#ff7110'
    }else if (value == 'CONFIRMADO'){
        statusColor= '#20ce88'
    }
    else if (value == 'CANCELADO'){
        statusColor= '#f5222d'
    }else{
        statusColor= '#ababab'
    }


    return (
      
            <StatusBox  status={statusColor}>{value}</StatusBox>
        
    )
}