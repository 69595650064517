/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react'
import styles from './index.module.scss'
import ModalComponent from 'components/modal-v2'
import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import SnackBarErrorStyle from 'styles/snackbar-error.style'
import { useSnackbar } from 'react-simple-snackbar'
import { Snackbar } from 'components/snackbar'
import { Select } from 'components/select'
import SalesmanService from 'services/SalesmanService'
import RequestService from 'services/RequestService'
import ButtonV3 from 'components/Buttonv3'

export const AddSellerToRequestModal: FC<any> = ({
    reload, request, is_add_seller_modal_open,
    open_add_seller_modal
}: any) => {

    const [modal, setModal] = useState<any>({})
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const [openSnackbarError] = useSnackbar(SnackBarErrorStyle)
    const [salesmen, setSalesmen] = useState<any>([])
    const [seller, setSeller] = useState<any>('')

    useEffect(() => {
        setModal({
            title: <span>Adicionar Vendedor</span>,
            icon: <AiOutlineUsergroupAdd />,
            submit: () => send_add_seller_to_request(seller),
            styles: {
                maxWidth: '40vw'
            }
        })
        SalesmanService.getAll().then(data => setSalesmen(data))
    }, [seller])


    const send_add_seller_to_request = (seller_id) => {
        RequestService.add_seller_to_request(request.id, seller_id).then(() => {
            openSnackbar(
                <Snackbar icon={"person_add"}>
                    {'Vendedor adicionado com sucesso!'}
                </Snackbar>
            )
            close()
            reload()
        }).catch(() => {
            openSnackbarError(
                <Snackbar icon={"error_outline"}>
                    {'Ocorreu um erro, tente novamente mais tarde!'}
                </Snackbar>
            )
        })
    }

    const close = () => {
        open_add_seller_modal(false)
    }

    {
        // <ModalComponent modal={ modal } show={ is_add_seller_modal_open } close={ close } className={ styles['modal-dialog'] }>
    }

    return (
        <div style={{ padding: '25px 60px' }}>
            <div>
                <div >
                    <Select
                        id="saler"
                        value_key="id"
                        description="Vendedor"
                        label_key="name"
                        blank_label="Selecione um vendedor"
                        options={salesmen}
                        value={seller}
                        onChange={(e: any) => setSeller(parseInt(e.target.value))}>
                    </Select>
                </div>
                <div style={{'display':'flex', 'flexDirection':'row', 'justifyContent':'flex-end','gap':15, 'marginTop': '25px' }}>
                    <div>
                        <ButtonV3
                            style={{'borderColor':'#ff7110', 'height':45}}
                            textStyle={{'color':'#ff7110'}}
                            title='Voltar'
                            onClick={()=>close()}
                            Icon={<></>}
                        >
                        </ButtonV3>
                    </div>
                    <div>
                        <ButtonV3
                        filled
                        style={{'height':45}}
                            title='salvar'
                            onClick={()=>send_add_seller_to_request(seller)}
                            Icon={<></>}
                        >
                        </ButtonV3>
                    </div>
                </div>
            </div>
        </div >
    )
}
