import React, { useState, useEffect } from 'react'
import { ProjectContainer } from '../../../../components/project-container'
import { connect } from 'react-redux'
import { loadEditLoggedProviderData } from '../../../../store/providers'
import './index.module.scss'
import { PageHeader } from '../../../../components/page-header'
import Information from './components/information'
import Details from './components/details'
import Confirmation from './components/confirmation'
import { useNavigate, useParams } from 'react-router-dom'
import { CampaignService } from 'services/v2/campaigns.service'
import ButtonV3 from 'components/Buttonv3'
import { Link } from 'react-router-dom'
import Constants from '../../../../constants'
import IconReact from 'components/icons'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { RoadMap } from 'components/roadmap'
import { ProductsToCampaign } from './components/products'

const CampaignAdd = ({ loadEditLoggedProviderData }: any) => {

    const { id } = useParams<any>()
    const [loading, setLoading] = useState<boolean>(true)
    const [nav, setNav] = useState<string>("I")
    const [campaign, setCampaign] = useState<any>()
    const [edit, setEdit] = useState<boolean>(false)
    // const [edit, setEdit] = useState<boolean>(false)


    useEffect(() => {
        loadEditLoggedProviderData().then(() => setLoading(false))
        if (id) {
            setLoading(true)
            setEdit(true)
            setNav("C")
            CampaignService.show(id)
                .then(async (response: any) => {
                    setCampaign(response.data)
                }).then(() => setLoading(false))
        }
    }, [id, loadEditLoggedProviderData, setLoading])

    const setShowNav = (nav) => {
        setNav(nav)
    }
    const navigate = useNavigate()

    return (

        <ProjectContainer loading={loading}>
            <header>
                <RoadMap
                    pageLinks={[
                        {
                            title: 'Minhas Campanhas',
                            onclick: () => { navigate(Constants.ROUTE_CAMPAIGNS) }
                        },
                        {
                            title: 'Nova Campanha',
                            onclick: () => { }
                        },
                    ]}
                ></RoadMap>
                {edit ? (
                    <PageHeaderV2 title="Informações Gerais " />
                ) : (
                    <PageHeaderV2 title="Nova Campanha" />
                )}

            </header>
            <div className="provider-edit">
                <nav>
                    {edit ? (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist" style={{ 'width': '100%' }}>
                                <a id="nav-profile-tab"
                                    className={nav === "C" ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                    data-toggle="tab" href="#nav-contact" role="tab"
                                    aria-controls="nav-contact" aria-selected="false" onClick={() => setShowNav("C")}>Itens da campanha</a>
                                <a id="nav-add-tab"
                                    className={nav === "P" ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                    data-toggle="tab" href="#nav-add" role="tab"
                                    aria-controls="nav-add" aria-selected="false" onClick={() => setShowNav("P")}>Meus produtos</a>
                            </div>
                            <Link to={`${Constants.ROUTE_CAMPAIGNS}`}>
                                <ButtonV3
                                    Icon={<IconReact type='arrowLeft' className='iconButtonV3' />}
                                    title='Voltar'
                                />
                            </Link>
                        </div>
                    ) : (
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a id="nav-home-tab"
                                className={nav === "I" ? 'nav-item nav-link active' : 'nav-item nav-link hidden'}
                                data-toggle="tab" href="#nav-home" role="tab"
                                aria-controls="nav-home" aria-selected="true"
                                onClick={() => setShowNav("I")} >INFORMAÇÕES GERAIS</a>
                            <a id="nav-profile-tab"
                                  className={campaign === undefined ? 'nav-item nav-link disabled' : nav === "D" ? 'nav-item nav-link active' : 'nav-item nav-link'}

                                // className={nav === "D" ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                data-toggle="tab" href="#nav-profile" role="tab"
                                aria-controls="nav-profile" aria-selected="false" onClick={(e) => campaign == undefined ? e.preventDefault() : setShowNav("D")}>DETALHES</a>
                            <a id="nav-profile-tab"
                                className={campaign === undefined ? 'nav-item nav-link disabled' : nav === "C" ? 'nav-item nav-link active' : 'nav-item nav-link'}

                                // className={nav === "C" ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                data-toggle="tab" href="#nav-contact" role="tab"
                                aria-controls="nav-contact" aria-selected="false" onClick={() => campaign != undefined ? setShowNav("C") : {}}>CONFIRMAÇÃO</a>
                        </div>
                    )}
                    {/* {edit ? (
                        <div style={{ display: 'flex', justifyContent:'space-between'}}>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist" style={{'width':'100%'}}>
                                <a id="nav-profile-tab"
                                    className={nav === "C" ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                    data-toggle="tab" href="#nav-contact" role="tab"
                                    aria-controls="nav-contact" aria-selected="false" onClick={() => setShowNav("C")}>Itens</a>
                            </div>
                            <Link to={`${Constants.ROUTE_CAMPAIGNS}`}>
                                <ButtonV3
                                    Icon={<IconReact type='arrowLeft' className='iconButtonV3'/>}
                                    title='Voltar'
                                />
                            </Link>
                        </div>
                    ) : (
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a id="nav-home-tab"
                                className={nav === "I" ? 'nav-item nav-link active' : 'nav-item nav-link hidden'}
                                data-toggle="tab" href="#nav-home" role="tab"
                                aria-controls="nav-home" aria-selected="true" onClick={() => setShowNav("I")} >INFORMAÇÕES GERAIS</a>
                            <a id="nav-profile-tab"
                                className={nav === "D" ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                data-toggle="tab" href="#nav-profile" role="tab"
                                aria-controls="nav-profile" aria-selected="false" onClick={() => setShowNav("D")}>DETALHES</a>
                            <a id="nav-profile-tab"
                                className={nav === "C" ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                data-toggle="tab" href="#nav-contact" role="tab"
                                aria-controls="nav-contact" aria-selected="false" onClick={() => setShowNav("C")}>CONFIRMAÇÃO</a>
                        </div>
                    )} */}
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className={nav === "I" ? 'tab-pane fade show active' : 'tab-pane fade show'}
                        id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                        <Information
                            setShowNav={setShowNav}
                            setCampaignLoad={setCampaign}
                            campaignEdit={campaign}
                        />

                    </div>
                    <div className={nav === "D" ? 'tab-pane fade show active' : 'tab-pane fade show'}
                        id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">

                        <Details
                            setShowNav={setShowNav}
                            setCampaign={setCampaign}
                            campaign={campaign}
                            nav={nav}
                        />

                    </div>
                    <div className={nav === "C" ? 'tab-pane fade show active' : 'tab-pane fade show'}
                        id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">

                        <Confirmation
                            setShowNav={setShowNav}
                            campaign={campaign}
                            nav={nav}
                            edit={edit}
                        />

                    </div>
                    <div className={nav === "P" ? 'tab-pane fade show active' : 'tab-pane fade show'}
                        id="nav-add" role="tabpanel" aria-labelledby="nav-add-tab">

                        <ProductsToCampaign
                            setShowNav={setShowNav}
                            campaign={campaign}
                            nav={nav}
                            edit={edit}
                        />

                    </div>
                </div>
            </div >

        </ProjectContainer >
    )
}

const mapStateToProps = (state: any) => ({
    loggedUser: state.loggedUser,
    editLoggedProviderData: state.editProvider,
})

const mapDispatchToProps = (dispatch: any) => ({
    loadEditLoggedProviderData: () => dispatch(loadEditLoggedProviderData()),
})


export default connect(mapStateToProps, mapDispatchToProps)(CampaignAdd)
