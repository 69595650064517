import { ResponseApi } from '../../interfaces/responseApi'
import DirectSaleOrderService from '../../services/DirectSaleOrderService'


// TYPES
export const LOAD_DIRECT_SALE_ORDER_SUCCESS = 'LOAD_DIRECT_SALE_ORDER_SUCCESS'


// Reducer
const initialState = null

export default function directSaleOrders(state = initialState, action: any) {
    if (action.type === LOAD_DIRECT_SALE_ORDER_SUCCESS) {
        return action.directSaleOrders
    }
    return state
}


// ACTIONS
export const loadDirectSaleOrders = (params: any) => (dispatch: any) =>
    DirectSaleOrderService.getAll(params)
        .then((response: ResponseApi) => dispatch(loadDirectSaleOrdersSuccess(response)))
        .catch(() => dispatch(loadDirectSaleOrdersSuccess(null)))


const loadDirectSaleOrdersSuccess = (orders: ResponseApi | null) => {
    return { type: LOAD_DIRECT_SALE_ORDER_SUCCESS, directSaleOrders: orders }
}
