import { Store } from '../interfaces/store'
import CityService from './CityService'
import StateService from './StateService'

export default class StoreService {
  static parse = (storeFromApi: any): Store => ({
      id: storeFromApi.loj_id,
      clientId: storeFromApi.cli_id,
      name: storeFromApi.loj_razao,
      email: storeFromApi.loj_email,
      cnpj: storeFromApi.loj_cnpj,
      phone: storeFromApi.loj_telefone,
      neighborhood: storeFromApi.loj_bairro,
      address: storeFromApi.loj_endereco,
      cep: storeFromApi.loj_cep,
      socialReason: storeFromApi.loj_razao ? storeFromApi.loj_razao : '',
      contactName: storeFromApi.cli_contato ? storeFromApi.cli_contato : '',
      segment: (
          storeFromApi.cli_ramoAtividade
      ) ? storeFromApi.cli_ramoAtividade : '',
      state: StateService.parse(storeFromApi),
      city: CityService.parse(storeFromApi),
      count_condition: storeFromApi.count_condicao_loja
  })
}
