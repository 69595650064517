/* eslint-disable @typescript-eslint/no-empty-function */
import ModalComponent from 'components/modal-v2'
import React, { FC, useEffect, useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md'
import { Rating } from 'react-simple-star-rating'
import OrderService from 'services/OrderService'
import styles from './index.module.scss'

export const RatingCommentsModal: FC<any> = ({ id, isVisible, onCloseCallback }: any) => {

    const [ modal, setModal ] = useState<any>({ })

    const [ order, setOrder ] = useState<any>(null)

    const [ rating, setRating ] = useState<any>(null)

    const [ comments, setComments ] = useState<any>(null)

    const [ options ] = useState<any>([
        "Atraso na Entrega",
        "Dados Comerciais não Respeitados ( Preço / Marca / Quantidade )",
        "Difícil Comunicação com o Vendedor"
    ])

    useEffect(() => {
        OrderService.getById(id).then((response) => setOrder(response))
    }, [ id ])

    useEffect(() => {
        if(order) {
            setRating(order.rating)
            setComments(order.rating?.comments)
            setModal({
                icon: <AiFillStar />,
                styles: { maxWidth: '80vw' },
                title: `Avaliação`,
                subtitle: order.customer.group
            })
        }
    }, [ order ])

    const has_comment = (comment) => {
        return comments && comments.some(c => c.descricao == comment)
    }

    return (
        <ModalComponent
            modal={ modal }
            show={ isVisible }
            close={ onCloseCallback }
            className={ [ styles.modal, 'modal-dialog' ].concat(',') }>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        { rating && <Rating
                            readonly={ true }
                            onClick={ () => {} }
                            ratingValue={ rating?.nota }
                        /> }
                    </div>
                </div>
                <div className="row header">
                    {
                        options.map(o => {
                            return <div className={ `col my-2 py-2 col-12 shadow ${styles.comment}` } key={ o }>
                                { has_comment(o) ? <MdCheckBox /> : <MdCheckBoxOutlineBlank /> }
                                <span>{ o }</span>
                            </div>
                        })
                    }
                </div>
            </div >
        </ModalComponent >
    )
}


