import React, { FunctionComponent } from 'react'

import {IoMdCloseCircle}from 'react-icons/io'
import { Button } from '../button'
import styles from './index.module.scss'

const NewModalComponent: FunctionComponent<any> = ({ children, modal, ...props }: any) => {

    return (
        <div className={`new-custom-modal modal animated fadeIn`} id={modal.id} {...props}>
            <div className="modal-dialog modal-lg" role="document" style={modal.styles}>
                <div className="modal-content">
                    <div className={`modal-header ${styles['modal-header']}`}>
                        <h4 className="modal-title" id="exampleModalLabel">
                            <span className="material-icons">{modal.icon}</span>
                            <span  className={'modalTitle'}>
                                {modal.title}
                                <span className={styles.subtitle}>
                                    {modal.subtitle && <><br />{modal.subtitle}</>}
                                </span>
                            </span>
                        </h4>
                        <button  data-dismiss="modal" onClick={() => modal.onClose && modal.onClose()}>
                        <IoMdCloseCircle className={'closeIcon'} />
                        </button>
                    </div>
                    <div className={`modal-body ${styles['modal-body']}`} style={modal.body_styles}>
                        {children}
                    </div>
                    {!modal.disabledButtons && (

                        <div className={`modal-footer ${styles['modal-footer']}`}>
                            <Button type="button" className="btn btn-secondary"
                                onClick={() => modal.onClose && modal.onClose()}
                                data-dismiss="modal">
                                Voltar
                            </Button>
                            {modal && modal.submit &&
                                <Button type="button" className="c-btn-success"
                                    data-dismiss="modal" onClick={modal.submit}>
                                    {modal.submit_text || 'Salvar'}
                                </Button>
                            }
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default NewModalComponent
