import React, { useContext, useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import './index.scss'
import Constants from '../../../constants'
import { ProjectContainer } from '../../../components/project-container'
import DirectSaleProductService from '../../../services/DirectSaleProductService'
import { DirectSaleProductLevel } from '../../../interfaces/directSaleProductLevel'
import { Loader } from '../../../components/loader'
import { PageHeader } from 'components/page-header'
import { Button } from 'components/button'
import { Snackbar } from 'components/snackbar'
import { Currency } from 'components/currency-input'
import { UserContext } from 'contexts/user'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { Input } from 'components/input'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import ButtonV3 from 'components/Buttonv3'


import { IoIosArrowRoundBack, IoIosAddCircleOutline } from 'react-icons/io'
import { RoadMap } from 'components/roadmap'

export const ProductPriceLevels = ({ forModal }) => {
  const id = checkIdOrigin()
  //const { id } = useParams<any>()
  const { handleSubmit } = useForm()
  const { user, can } = useContext(UserContext)
  const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
  const [loading, setLoading] = useState<boolean>(false)
  const [priceLevels, setPriceLevels] = useState<any>([])
  const [iconColor, setIconColor] = useState<string>('material-icons info')

  function checkIdOrigin() {

    console.log(forModal)
    let { id } = useParams<any>()
    if (forModal && forModal.id) {
      console.log('idformodal', forModal.id)
      return forModal.id
    } else {
      console.log('id', id)

      return id
    }
  }
  useEffect(() => {
    if (user) {
      setLoading(true)

      DirectSaleProductService
        .getProductLevels(id)
        .then((lvls: DirectSaleProductLevel[]) => {
          setLoading(false)
          lvls.sort((a, b) => {
            const pos1 = a.level
            const pos2 = b.level
            if (pos1 > pos2) return 1
            if (pos2 > pos1) return -1
            return 0
          })

          setPriceLevels(lvls.map(l => l))
        })

    }
  }, [user])



  const set_level_value = (amount: number, index: number) => {
    const new_list = priceLevels.slice(0);
    new_list[index].price = amount;
    setPriceLevels(new_list)
  }

  const onChange = (event, index) => {
    const new_list = priceLevels.slice(0);
    new_list[index].desc = event?.target?.value;
    setPriceLevels(new_list)
  }

  const addLevel = (e) => {
    e.preventDefault()
    setPriceLevels([...priceLevels, { code: 0, level: 0, price: 0, provider: 0, desc: "" }])
  }

  const editName = (event, key) => {

    var dom = document.getElementById('edit-' + key)?.outerHTML;
    if (dom?.toString().indexOf('text') != -1) {
      document.getElementById('edit-' + key)?.setAttribute("type", "hidden");
      (document.getElementById('BTedit-' + key) as HTMLElement).textContent = "edit";

      (document.getElementById('BTedit-' + key) as HTMLElement).className = "material-icons info"
      save();

    } else {
      document.getElementById('edit-' + key)?.setAttribute("type", "text");
      (document.getElementById('BTedit-' + key) as HTMLElement).textContent = "save";
      (document.getElementById('BTedit-' + key) as HTMLElement).className = "material-icons save"

    }
  }

  const save = () => {
    setLoading(true)
    DirectSaleProductService.updateLevels({ id } as any, priceLevels, user.providerId)
      .then(() => {
        openSnackbar(
          <Snackbar icon="sticky_note_2">
            Níveis salvos com sucesso!
          </Snackbar>)
        //window.location.reload();
        setLoading(false)
      })
      .catch(() => {
        openSnackbar(
          <Snackbar icon="sticky_note_2">
            Ocorreu um erro ao salvar os níveis!!
          </Snackbar>)
        setLoading(false)
      })
  }

  const submit_form = () => {
    const sub_btn = document.getElementById("save-prices")
    if (sub_btn) {
      sub_btn.click()
    }
  }
  const navigate = useNavigate()

  return (
    <>
      {forModal && forModal.id ? <>
        <div className="form formOnModal">
          <div className="actions">
            <div style={{ 'marginRight': 20 }}>
              {/* <Link to={Constants.ROUTE_PRODUCTS}> */}
                <ButtonV3
                  title='Voltar'
                  onClick={() => forModal && forModal.onCloseCallback ? forModal.onCloseCallback() : navigate(Constants.ROUTE_PRODUCTS)}
                  Icon={<IoIosArrowRoundBack className='iconButtonV3' />}
                >
                </ButtonV3>
              {/* </Link> */}
            </div>
            {can('ADD_PRICE', 'PRODUCT') &&
              <div style={{ 'marginRight': 20 }}>

                <ButtonV3
                  title='Adicionar nível'
                  onClick={addLevel}
                  Icon={<IoIosAddCircleOutline className='iconButtonV3' />}
                >
                </ButtonV3>
                {
                  //   <Button className="c-btn-secondary" onClick={addLevel}>
                  //       <i className="material-icons">add</i>Adicionar nível
                  //   </Button>
                }

              </div>
            }
            <div>
              <ButtonV3
                filled
                title='Salvar'
                onClick={submit_form}

              >
              </ButtonV3>
            </div>

            {
              //  <Button onClick={submit_form} className="c-btn-save">
              //      <i className="material-icons">save_alt</i>Salvar
              //  </Button>
            }
          </div>
          {priceLevels && (
            <form onSubmit={handleSubmit(save)}>
              <div className="row">
                {priceLevels.map((all: any, key: number) => (
                  <div key={key} className="form-group col-md-3 " style={!can('PRICING', 'PRODUCT', key + 1) ? { display: 'none' } : {}}>

                    <div style={{ 'width': '100%' }} className='edit-container'>
                      <i
                        color={'#FF7110'}
                        className='material-icons info' id={'BTedit-' + key}
                        onClick={(e) => {
                          editName(e, key)
                        }}>edit</i>
                      <input
                        style={{ 'width': '100%', 'border': 'none' }}
                        className="editInput"
                        id={'edit-' + key}
                        value={all.desc}
                        type='hidden'
                        onChange={(e) =>
                          onChange(e, key)
                        } />
                      <div>{all.desc ? all.desc : `Nível ${key + 1}`} </div>
                    </div>


                    <Currency
                      className="form-control input-field newCurrencyStyle"
                      placeholder={`Nível ${key + 1}`}
                      value={all.price}
                      onChange={(value: string, floatValue: number) => set_level_value(floatValue, key)}
                    />

                  </div>
                ))}
              </div>
              <button id="save-prices" type="submit" style={{ visibility: 'hidden' }}></button>
            </form>
          )}
        </div>
      </> :

        <ProjectContainer>

          <div className="products-edit" >
            {loading && <Loader />}
            <div style={{
              'display': 'flex',
              'flexDirection': 'row',
              'justifyContent': 'space-between',
              'flexWrap': 'wrap'

            }}>

              <header>
                <RoadMap
                  pageLinks={[
                    {
                      title: 'Produtos',
                      onclick: () => { navigate(Constants.ROUTE_PRODUCTS) }
                    },
                    {
                      title: 'Níveis de preço',
                      onclick: () => { }
                    },
                  ]}
                ></RoadMap>
                <PageHeaderV2 title="Níveis de preço" />
              </header>
              <div className="actions">
                <div style={{ 'marginRight': 20 }}>
                  <Link to={Constants.ROUTE_PRODUCTS}>
                    <ButtonV3
                      title='Voltar'
                      // onClick={() => { }}
                      Icon={<IoIosArrowRoundBack className='iconButtonV3' />}
                    >
                    </ButtonV3>
                  </Link>
                </div>
                {can('ADD_PRICE', 'PRODUCT') &&
                  <div style={{ 'marginRight': 20 }}>

                    <ButtonV3
                      title='Adicionar nível'
                      onClick={addLevel}
                      Icon={<IoIosAddCircleOutline className='iconButtonV3' />}
                    >
                    </ButtonV3>
                    {
                      //   <Button className="c-btn-secondary" onClick={addLevel}>
                      //       <i className="material-icons">add</i>Adicionar nível
                      //   </Button>
                    }

                  </div>
                }
                <div>
                  <ButtonV3
                    filled
                    title='Salvar'
                    onClick={submit_form}

                  >
                  </ButtonV3>
                </div>

                {
                  //  <Button onClick={submit_form} className="c-btn-save">
                  //      <i className="material-icons">save_alt</i>Salvar
                  //  </Button>
                }
              </div>
            </div>
            <div className="form">
              {priceLevels && (
                <form onSubmit={handleSubmit(save)}>
                  <div className="row">
                    {priceLevels.map((all: any, key: number) => (
                      <div key={key} className="form-group col-md-3 " style={!can('PRICING', 'PRODUCT', key + 1) ? { display: 'none' } : {}}>

                        <div style={{ 'width': '100%' }} className='edit-container'>
                          <i
                            color={'#FF7110'}
                            className='material-icons info' id={'BTedit-' + key}
                            onClick={(e) => {
                              editName(e, key)
                            }}>edit</i>
                          <input
                            style={{ 'width': '100%', 'border': 'none' }}
                            className="editInput"
                            id={'edit-' + key}
                            value={all.desc}
                            type='hidden'
                            onChange={(e) =>
                              onChange(e, key)
                            } />
                          <div>{all.desc ? all.desc : `Nível ${key + 1}`} </div>
                        </div>


                        <Currency
                          className="form-control input-field newCurrencyStyle"
                          placeholder={`Nível ${key + 1}`}
                          value={all.price}
                          onChange={(value: string, floatValue: number) => set_level_value(floatValue, key)}
                        />

                      </div>
                    ))}
                  </div>
                  <button id="save-prices" type="submit" style={{ visibility: 'hidden' }}></button>
                </form>
              )}
            </div>
          </div>
        </ProjectContainer>
      }
    </>
  )
}
