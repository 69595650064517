/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-spacing */
import React, { FC, useState, useEffect } from 'react'
import Pagination from 'react-responsive-pagination'
import './index.scss'

export const PaginationCustom: FC<any> = ({ total, currentPage, handlePageChange, totalItems, itemsName, ItemsPorPage, ...rest }: any) => {
  const [from, setFrom] = useState(0)
  const [to, seTo] = useState(0)
  const [fix, setFix] = useState(0)





  useEffect(() => {

    if (ItemsPorPage < totalItems) {
      setFix(1)
    } else {
      setFix(0)
    }

    if (totalItems <= ItemsPorPage) {
      seTo(totalItems)
      setFrom(0)
    }
    else {

      setFrom(ItemsPorPage * currentPage - ItemsPorPage)
      if (total == currentPage) {

        // seTo(totalItems)
        seTo(ItemsPorPage)

      }
      else {

        seTo(ItemsPorPage * currentPage)
      }
    }
  }, [currentPage])

  return (
    < >

      <Pagination
        // maxWidth={containerWidth}
        total={total + fix}
        current={currentPage}
        onPageChange={handlePageChange}
        {...rest}
      />
      <div className="pagination-desc">
        <p>Exibindo: {from} até {to} de {totalItems} registros de {itemsName}</p>
      </div>
    </>
  )
}
