import ButtonV3 from "components/Buttonv3";
import styles from './index.module.scss'
import { IoMdTrash } from 'react-icons/io'
import { MdOutlineEdit, MdPersonOutline } from 'react-icons/md'
import { BsTruck } from 'react-icons/bs'
import { BaseModal } from "components/BaseModal";
import { useEffect, useLayoutEffect, useState } from "react";
import { Input } from "components/input";
import FieldControlRoutesService from "services/v3/fieldControlRoutes.service";
import PaginationCustomV2 from "components/PaginationCustomV2";
import { ToastContainer, toast } from "react-toastify";
import ProviderService from 'services/ProviderService'
import { CheckBoxCustom } from "components/checkBoxCustom";

export const RoutesListComponent = ({ }: any) => {

    const dafaultValuesModal = {
        id: null,
        for_id: null,
        descricao: "",
        field_control_employee_id: "",
        Controle_entrega: false,
        Volume_entrega: 0
    }
    const [modalData, setModalData] = useState<any>({})
    const [routes, setRoutes] = useState<any>()
    const [modalVisible, setModalVisible] = useState(false)
    const [modalEditData, setModalEditData] = useState(dafaultValuesModal)
    const [reload, setReload] = useState(false)
    const [provider, setProvider] = useState()



    useEffect(() => {
        getRoutes()

    }, [reload])

    useEffect(() => {
        getRoutes()
        getProviderID()
    }, [])

    async function getRoutes(page = 1) {

        await FieldControlRoutesService.getRoutes(page).then(res => {

            setRoutes(res)
        })
    }

    async function handleDelete(itemId) {
        await FieldControlRoutesService.deleteRoute(itemId).then(res => {
            toast.info('Rota Deletada !', {
                position: "top-right",
                autoClose: 2000,
            })
            setModalEditData(dafaultValuesModal)
            setModalVisible(false)
            setReload(!reload)
        }
        ).catch(e => {
            toast.error('Erro ao deletar rota!', {
                position: "top-right",
                autoClose: 2000,
            })
            setModalEditData(dafaultValuesModal)
            setModalVisible(false)
        }
        )
    }
    async function getProviderID() {
        const { provider } = await ProviderService.getLoggedProvider()

        setProvider(provider.id)

    }

    function handleOpenModal() {
        setModalData(modalEditData)
        setModalVisible(true)
    }
    function handleOpenModalEdit(item) {
        setModalData(item)
        setModalVisible(true)
        setModalEditData(item)
    }
    function handleCloseMOdal() {

        setModalVisible(false)
        setModalData({})
        setModalEditData(dafaultValuesModal)

    }


    async function handleSave() {
        const data = { ...modalEditData, for_id: modalEditData.for_id == null ? provider : modalEditData.for_id }


        if (!modalData.id) {

            await FieldControlRoutesService.createRoute(data).then(res => {
                toast.success('Rota salva com sucesso', {
                    position: "top-right",
                    autoClose: 2000,
                })
                setModalEditData(dafaultValuesModal)
                setModalVisible(false)
                setReload(!reload)
            }
            ).catch(e => {
                toast.error('Erro ao salvar', {
                    position: "top-right",
                    autoClose: 2000,
                })
                setModalEditData(dafaultValuesModal)
                setModalVisible(false)
            }
            )

        } else {

            await FieldControlRoutesService.editRoute(data).then(res => {
                toast.success('Rota salva com sucesso', {
                    position: "top-right",
                    autoClose: 2000,


                })
                setModalEditData(dafaultValuesModal)
                setModalVisible(false)
                setReload(!reload)
            }
            ).catch(e => {
                toast.error('Erro ao salvar', {
                    position: "top-right",
                    autoClose: 2000,
                })
                setModalEditData(dafaultValuesModal)
                setModalVisible(false)
            }
            )
        }
    }

    return (
        <div className={`${styles.profiles} text-center col col-12 rounded`}>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <div >
                <div style={{ 'marginBottom': 15 }} className="d-flex flex-start">
                    {
                        //   <Button className="d-flex flex-start" onClick={add}>
                        //       Novo Perfil
                        //   </Button>
                    }
                    <ButtonV3
                        title='Nova Rota'
                        Icon={<BsTruck className='iconButtonV3' />}
                        onClick={() => handleOpenModal()}
                    />

                </div>

                {
                    routes && routes.data && routes.data.length > 0 ?
                        <div className={`${styles.listRepresentatives}`}>
                            <table className={`${styles.newTableRepresentatives}`}>
                                <thead>
                                    <tr>
                                        <th className="text-left">id</th>
                                        <th className="text-left">Descrição</th>
                                        <th className="text-left">Integração field control</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {routes.data.map(
                                        (item: any) => (
                                            <tr key={item.id}>
                                                <td data-label='id'>{item.id}</td>
                                                <td data-label='Descrição'>{item.descricao}</td>
                                                <td data-label='Integração field control'>{item.field_control_employee_id}</td>
                                                <td data-label='Ações' className="text-center">
                                                    <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'center', 'gap': '20px' }}>

                                                        <div >
                                                            <IoMdTrash onClick={() => handleDelete(item.id)}
                                                                title="Excluir rota"
                                                                style={{ cursor: 'pointer' }}
                                                                color='#CD2A2A' size={22} className={`${styles.svgactionsProfiles}`} />
                                                        </div>
                                                        <div className={styles.tooltip}>
                                                            <MdOutlineEdit title="Editar" onClick={() => handleOpenModalEdit(item)}
                                                                color='#606060'
                                                                style={{ cursor: 'pointer' }} size={22} className={`${styles.svgactionsProfiles}`} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                            {routes.last_page > 1 ?
                                <PaginationCustomV2
                                    handleSelectPage={(page) => getRoutes(page)}
                                    paginationData={routes}
                                    paginationDataDescription={'de representantes.'}

                                />
                                : null}
                        </div> : <div>Nenhuma rota encontrada!</div>
                }
            </div>

            <BaseModal
                title={modalData.descricao ? modalData.descricao : 'Criar nova rota'}
                modalBodyStyle={{ minHeight: 230, height: 'unset' }}
                isVisible={modalVisible}
                onCloseCallback={() => handleCloseMOdal()}
                children={
                    <div className={`${styles.ContainerModalRoutes}`}>
                        <div className={`${styles.ContainerInputsModalRoutes}`}>
                            <div style={{ minWidth: 200, width: "100%" }}>
                                <Input
                                    type="text"
                                    name="description"
                                    description="descrição"
                                    placeholder="Descrição..."
                                    onChange={e => setModalEditData({ ...modalEditData, descricao: e.target.value })}
                                    value={modalEditData.descricao}
                                />
                            </div>
                            <div style={{ minWidth: 200, width: "100%" }}>
                                <Input
                                    type="text"
                                    name="description"
                                    description="Integração field control"
                                    placeholder=""
                                    onChange={e => setModalEditData({ ...modalEditData, field_control_employee_id: e.target.value })}
                                    value={modalEditData.field_control_employee_id}
                                ></Input>
                            </div>
                            <div style={{ minWidth: 20, width: "100%" }}>
                                <CheckBoxCustom
                                    change={(e, stats) => setModalEditData({ ...modalEditData, Controle_entrega: stats })}
                                    checked={ !!modalEditData.Controle_entrega }
                                    description={'Controlar volume entrega'}
                                ></CheckBoxCustom>
                            </div>
                            <div style={{ minWidth: 50, width: "100%" }}>
                                <Input
                                    type="text"
                                    name="description"
                                    description="Volume Entrega"
                                    placeholder=""
                                    onChange={e => setModalEditData({ ...modalEditData, Volume_entrega: e.target.value })}
                                    value={modalEditData.Volume_entrega}
                                ></Input>
                            </div>
                            <div className={`${styles.ContainerModalButtonsRoutes}`}>

                                <div >
                                    <ButtonV3
                                        style={{ height: 45 }}
                                        title="Cancelar"
                                        onClick={() => handleCloseMOdal()}
                                    ></ButtonV3>

                                </div>
                                <div >
                                    <ButtonV3
                                        filled
                                        style={{ height: 45 }}
                                        onClick={() => handleSave()}
                                        title="Salvar"
                                    ></ButtonV3>

                                </div>
                            </div>
                        </div>

                    </div>
                }
            />
        </div>
    );
}
