export const STATUS_ORDERED = 'ORDERED'
export const STATUS_NOT_ORDERED = 'NOT_ORDERED'
export const STATUS_TYPED = 'TYPED'
export const STATUS_NOT_TYPED = 'NOT_TYPED'
export const STATUS_FROZEN = 'FROZEN'
export const STATUS_NOT_OFFERED = 'NOT_OFFERED'
export const STATUS_WINNER = 'WINNER'
export const STATUS_LOOSER = 'LOOSER'

export default class SellerParticipationHighchartModel {

    chart = {
        plotBackgroundColor: null,
        type: "column",
        height: "250px"
    }
    colors: string[] = [ "#20ce88", "#ff7110", "#BBBBBB" ]
    title: any
    tooltip = {
        crosshairs: true,
        shared: true,
        points: [],
        x: null
    }
    series: any
    xAxis: any = { }
    yAxis = {
        title: {
            text: 'Quantidade'
        },
        value: null,
    }

    constructor(data : any) {
        this.title = { text: "Participação" }
        this.xAxis.categories = this.map_status(data)
        this.series = [ {
            type: 'column',
            colorByPoint: true,
            data: this.map_by_status(data),
            showInLegend: false
        } ]
    }

    map_status(data: any) {
        const map = new Map()
        const original_map = new Map()
        data.forEach((r: any) => {
            map.set(this.translate_status(r.status), 1)
            original_map.set(r.status, 1)
        })
        this.map_colors(Array.from(original_map, ([ k, v ]) => (k)))
        return Array.from(map, ([ k ]) => (k))
    }

    map_colors(statuses: any) {
        const result: any = []
        statuses.forEach(r => {
            result.push(this.translate_status_color(r))
        })
        this.colors = result
    }

    map_by_status(data: any) {
        const result = new Map()

        data.forEach((r: any) => {
            const current = result.get(this.translate_status(r.status)) || 0
            result.set(this.translate_status(r.status), current+1)
        })

        return Array.from(result, ([ k, v ]) => (v))
    }

    translate_status(status) {
        switch( status ) {
        case STATUS_LOOSER:
            return 'Não Vencedor'
        case STATUS_WINNER:
            return 'Vencedor'
        case STATUS_ORDERED:
            return 'Pedido Gerado'
        case STATUS_TYPED:
            return 'Digitado'
        case STATUS_NOT_ORDERED:
            return 'Sem Pedido'
        case STATUS_NOT_TYPED:
            return 'Não Digitado'
        case STATUS_NOT_OFFERED:
            return 'Sem oferta'
        case STATUS_FROZEN:
            return 'Congelado'
        default:
            return 'Cancelado'
        }
    }

    translate_status_color(status) {
        switch( status ) {
        case STATUS_ORDERED:
            return '#4267b2'
        case STATUS_TYPED:
            return '#00a65a'
        case STATUS_NOT_ORDERED:
            return 'black'
        case STATUS_NOT_TYPED:
            return 'purple'
        case STATUS_NOT_OFFERED:
            return 'black'
        case STATUS_FROZEN:
            return '#707070'
        default:
            return '#f5222d'
        }
    }

}
