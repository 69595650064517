import React, { ReactNode, useEffect, useState } from "react";
import './index.scss'
import ButtonV3 from "components/Buttonv3";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

interface props {
    onShowFilters: (value: boolean) => void

}

export default function ButtonShowHideMobileFilters({ onShowFilters }: props) {

    const [isMobileView, setIsMobileView] = useState<any>(false)
    const [showFilters, setShowFilters] = useState<any>(false)

    const togleFilters = () => setShowFilters(!showFilters)

    useEffect(() => {
        onShowFilters(showFilters)
    }, [showFilters])

    useEffect(() => {
        function resize() {
            if (window.innerWidth < 900) {
                setIsMobileView(true)
                setShowFilters(false)
            } else {
                setIsMobileView(false)
                setShowFilters(true)
            }
        }
        resize()
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])


    function handleShowFilters() {

    }

    return (

        !isMobileView ?
            null :

            <div style={{ display:'flex', justifyContent:'flex-end'}}>
                <ButtonV3
                    style={{
                        justifyContent: 'space-between',
                        height: 49,
                    }}
                    title={
                        showFilters
                            ? 'Ocultar filtros'
                            : 'Exibir filtros'
                    }
                    textStyle={{ fontSize: 15 }}
                    onClick={togleFilters}
                    Icon={
                        showFilters ? (
                            <FaChevronUp
                                style={{ marginRight: 10 }}
                                color="#ff7110"
                            />
                        ) : (
                            <FaChevronDown
                                style={{ marginRight: 10 }}
                                color="#ff7110"
                            />
                        )
                    }
                ></ButtonV3>
            </div>


    )


}
