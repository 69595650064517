import Constants from '../constants'
import HttpClient from './HttpClient'
import { User } from '../interfaces/user'

export default class UserService {

    static me = (): Promise<User> =>
        HttpClient.get(Constants.URL_API_USER).then(response => ({
            id: response.usuario.usu_id,
            name: response.usuario.usu_nome,
            email: response.usuario.usu_email,
            providerId: response.usuario.for_id,
            groupId: response.usuario.group_id,
            store: response.usuario.loja,
            profile_name: response.usuario.perfil_usuario_nome,
            profile_id: response.usuario.pfl_id,
            settings: response.usuario.settings,
            //added to get cli_id for edit modal
            cli_id:response.usuario.cli_id,
            for_plus:response.usuario.for_plus
        }))

    static regions = (): Promise<any> =>
        HttpClient.get(Constants.URL_API_USER_REGIONS)
    static ramosDeAtividade = (): Promise<any> =>
        HttpClient.get(Constants.URL_API_BRANCH_ACTIVITIES)
}
