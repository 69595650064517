import HttpClient from './http.client'

export default class FieldControlRoutesService {

    static getRoutes = (page) => HttpClient.get(`/providers/routes?page=${page}`)

    static getDeliveries = (params) => HttpClient.get(`/providers/routes/deliveries`, params)

    static putNewCapacity = (id, capacity) =>
        HttpClient.put(`/providers/routes/deliveries/${id}`, { capacity: capacity })

    static editRoute = (data) => HttpClient
        .put(`/providers/routes`, data)

    static createRoute = (data) => HttpClient
        .post(`/providers/routes`, data)

    static deleteRoute = (data) => HttpClient
        .delete(`/providers/routes/${data}`)



}
