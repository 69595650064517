import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
padding: 0.2rem;



`;
export const SelectMenuContainer = styled.div`
display: flex;
flex-direction: row;
padding: 0.2rem;
width: 100%;
gap: 1rem;
align-items: center;
    justify-content: space-evenly;
    margin-top: 2rem;
    @media  only screen and (max-width: 600px) {
        flex-direction: column;

}
`;
export const SelectMenuButton = styled.div`
    /* width: 100%; */
    border: 2px solid #ff7110;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
    /* background-color: #F6F6F6; */
    min-height: 240px;
    min-width: 300px;
    max-width: 40%;
    transition: 150ms;
    padding: 1rem;
    cursor: pointer;
    p{
        font-family: 'Ubuntu-Regular', sans-serif;
        font-size: 18px;
        text-align: center;
    }
    :hover{
        translate: 0 -3px;
        transition: 150ms;
        /* background-color: #ff711030; */
    }
    section{
        display: flex;
        gap: 1rem;
        margin-bottom: 0.8rem;
        align-items: center;
        h4{
            font-family: 'Ubuntu-Medium', sans-serif;
            font-size: 22px;
        }
        svg{
            font-size: 2rem;
            color: #ff7110;
        }
    }

`;


