export default {
    position: 'top-right',
    style: {
        backgroundColor: '#ff7110',
        color: 'white',
        fontSize: '15px',
        textAlign: 'center',
        aligmItems:  'center',
        zIndex: 1000
    },
    closeStyle: {
        color: 'white',
        fontSize: '16px',
    },
}
