import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import './index.scss'
import { Button } from 'components/button'
import Constants from '../../../../../../constants'
import { Link } from 'react-router-dom'
import { CampaignService } from 'services/v2/campaigns.service'
import CampSvc from 'services/v3/campaign.service'
import DateService from 'services/DateService'
import { Loader } from 'components/loader'
import ToggleButton from 'react-toggle-button'
import { Currency } from 'components/currency-input'
import { Snackbar } from 'components/snackbar'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { useSnackbar } from 'react-simple-snackbar'
import { getKey } from 'pages/request/components/requested-products/requested-products.helper'
import ButtonV3 from 'components/Buttonv3'
import IconReact from 'components/icons'
import { MdOutlineAddCircleOutline } from 'react-icons/md'

export const Confirmation = ({ setShowNav, campaign, nav, edit }: any) => {

    const initialCampaign = {
        data_final: new Date()
    }

    const [loading, setLoading] = useState<boolean>(true)
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const [campaingConfirmation, setCampaingConfirmation] = useState<any>(initialCampaign)

    const show = useCallback(async (c: any) => {
        setLoading(true)
        CampaignService.show(c.id)
            .then(async (response: any) => {
                response.data.itens.forEach(i => i.current_price = i.valor_embalagem)
                setCampaingConfirmation(response.data)
            }).then(() => setLoading(false))

    }, [])

    useEffect(
        () => {
            if (campaign && nav == "C") {
                show(campaign)
            }
        }, [campaign, show, nav]
    )

    const setItemPrice = (prod: any, val: number) => {
        const itens = [...campaingConfirmation.itens]
        const i = itens.find(i => i.id == prod.id)
        i.valor_embalagem = val
        i.saved = false
        setCampaingConfirmation({ ...campaingConfirmation, itens })
    }

    const toggleCampaignProductStatus = (prod: any) => {
        CampSvc.update_product_status({ status: !prod.is_enabled }, prod.camp_id, prod.id)
            .then(async () => {
                const itens = [...campaingConfirmation.itens]
                const i = itens.find(i => i.id == prod.id)
                i.is_enabled = !i.is_enabled
                setCampaingConfirmation({ ...campaingConfirmation, itens })
                openSnackbar(
                    <Snackbar icon="sticky_note_2">
                        Status atualizado com sucesso!
                    </Snackbar>
                )
            })
    }

    const savePrice = (prod: any, evt: any = null) => {
        const key = getKey(evt)
        if (prod.current_price != prod.valor_embalagem && (key === "Enter" || evt == null)) {
            CampSvc.update_product_price({ price: prod.valor_embalagem }, prod.camp_id, prod.id)
                .then(async () => {
                    const itens = [...campaingConfirmation.itens]
                    const i = itens.find(i => i.id == prod.id)
                    i.current_price = prod.valor_embalagem
                    i.saved = true
                    setCampaingConfirmation({ ...campaingConfirmation, itens })
                    openSnackbar(
                        <Snackbar icon="sticky_note_2">
                            Preço atualizado com sucesso!
                        </Snackbar>
                    )
                })
        }
    }

    const getPriceUnit = (item: any) => {
        return (item.valor_embalagem / item.quantidade_embalagem).toFixed(2)
    }

    return (
        <>
            {!loading ? (
                <div className="form">
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div className="conpaing-info">
                            <p className="title-campaing">Campanha #{campaingConfirmation.id} - {DateService.formatToDisplay(new Date(campaingConfirmation.data_final.replace(/\s/, 'T')))}</p>
                            <p className="info-campaing"><strong>Data:</strong>{DateService.formatToDisplay(new Date(campaingConfirmation.data_final.replace(/\s/, 'T')))}</p>
                            <p className="info-campaing"><strong>Condição de Entrega:</strong> CIF</p>
                            <p className="info-campaing"><strong>Observações:</strong> {campaingConfirmation.obs}</p>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: !edit ? 'space-between' : 'flex-end', alignItems: 'flex-end', gap: 10 }}>
                            {!edit &&

                                <div style={{ display: 'flex', gap: 20 }}>
                                    <ButtonV3
                                        style={{ height: 45 }}
                                        Icon={<IconReact className={'iconButtonV3'} size={19} type='arrowLeft' />}
                                        title='Voltar'
                                        onClick={() => setShowNav("D")}
                                    />

                                    <Link to={`${Constants.ROUTE_CAMPAIGNS}`}>
                                        <ButtonV3
                                            filled
                                            style={{ height: 45 }}
                                            title='Finalizar Campanha'
                                            textStyle={{ marginLeft: 5 }}
                                            Icon={<IconReact color={'#fff'} size={19} type='check' />} />

                                    </Link>
                                </div>
                            }
                            {edit ?
                                <div >
                                    <ButtonV3
                                        title='Adicionar produto'
                                        onClick={() => setShowNav('P')}
                                        Icon={<MdOutlineAddCircleOutline className='iconButtonV3'></MdOutlineAddCircleOutline>}
                                    />

                                </div>
                                : null}

                        </div>
                    </div>

                    <div className="NewlistEditCampaign">

                        <table className="newTableEditCampaign">
                            <thead>
                                <tr>
                                    <th className="text-center">DATA FINAL</th>
                                    <th>PRODUTOS</th>
                                    <th>EMBALAGEM</th>
                                    <th className="text-center">PREÇO</th>
                                    <th className="text-center">PREÇO UNIT</th>
                                    <th>OBSERVAÇÕES</th>
                                    {edit && <th>Ativo?</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {campaingConfirmation.itens.sort((a,b)=>a.product.pro_descricao.localeCompare(b.product.pro_descricao)).map((i) => (
                                    <tr key={i.id}>
                                        <td data-label="DATA FINAL" className="text-center">{DateService.formatToDisplay(new Date(campaingConfirmation.data_final.replace(/\s/, 'T')))}</td>
                                        <td data-label="PRODUTOS" className="produto-cadastrado">{i.product.pro_descricao}</td>
                                        <td data-label="EMBALAGEM">{i.embalagem + " " + i.quantidade_embalagem}</td>
                                        <td data-label="PREÇO" className="text-center preco">
                                            <div>
                                                <Currency value={i.valor_embalagem}
                                                    disabled={!edit}
                                                    onKeyDown={(e: any) => savePrice(i, e)}
                                                    onChange={(maskedValue: string, floatValue: number) => setItemPrice(i, floatValue)}
                                                    className={`text-center currency ${i.saved ? 'saved' : ''}`}
                                                    onBlur={() => savePrice(i)} />
                                            </div>
                                        </td>
                                        <td data-label="PREÇO UNIT" className={`text-center unit ${i.saved ? 'saved' : ''} `}>R$ {getPriceUnit(i)}</td>
                                        <td data-label="OBSERVAÇÕES" className="text-center">{i.observacao ? i.observacao : '- - -'}</td>
                                        {edit && <td data-label="Ativo?" className="tdTogleActive">
                                            <div>
                                                <ToggleButton
                                                    inactiveLabel={''}
                                                    activeLabel={null}
                                                    colors={Constants.DEFAULT_TOGGLE_BUTTON_COLLORS}
                                                    value={i.is_enabled || false}
                                                    onToggle={() => toggleCampaignProductStatus(i)} />
                                            </div>
                                        </td>}
                                    </tr>
                                ))}

                            </tbody>
                        </table>

                    </div>
                    {
                        //    <div className="btn-right">
                        //
                        //        {edit ? (
                        //            <>
                        //                {
                        //                    //    <Link to={`${Constants.ROUTE_CAMPAIGNS}`}>
                        //                    //        <Button type="submit" className="btn-large btn-laranja">
                        //                    //            Voltar
                        //                    //        </Button>
                        //                    //    </Link>
                        //                }
                        //            </>
                        //        ) : (
                        //
                        //            <>
                        //                {
                        //                    //  <Button className="btn-large btn-laranja" onClick={() => setShowNav("D")}>
                        //                    //      Voltar
                        //                    //  </Button>
                        //                    //  <Link to={`${Constants.ROUTE_CAMPAIGNS}`}>
                        //                    //      <Button type="submit" className="btn-large btn-verde">
                        //                    //          <i className="material-icons">done</i> Finalizar Campanha
                        //                    //      </Button>
                        //                    //  </Link>
                        //                }
                        //            </>
                        //        )}
                        //    </div>
                    }
                </div>
            ) : <Loader type="inline" />}
        </>
    )
}

export default connect()(Confirmation)
