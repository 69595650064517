import React, { useCallback, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Link, useParams } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import { mask_cnpj } from 'helpers/mask.helper'
import ToggleButton from 'react-toggle-button'
import Pagination from 'pagination-front-end'

import './index.scss'

import { Button } from 'components/button'
import Constants from '../../../../../../constants'
import { PageHeader } from 'components/page-header'
import { ProjectContainer } from 'components/project-container'
import { PaginationCustom } from '../../../../../../components/pagination-custom'

import { Customer } from 'interfaces/customer'
import CustomerService from 'services/CustomerService'
import { Representative } from 'interfaces/representative'
import RepresentativesService from 'services/RepresentativesService'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { BiLeftArrowAlt } from 'react-icons/bi'
import ButtonV3 from 'components/Buttonv3'
import { PhoneWithWhats } from 'components/phoneWithWhats'

export const RepresentativeClients = () => {

    const MySwal = withReactContent(Swal)

    const { id } = useParams<string>()
    const [loading, setLoading] = useState<boolean>(true)
    const [clients, setClients] = useState<Customer[]>()
    const [seller, setSeller] = useState<Representative>()

    const [currentPage, setCurrentPage] = useState(1)
    const [allPages, setAllPages] = useState(1)
    const [clientsItems, setClientsItems] = useState([])

    const pageSize = 15

    useEffect(() => {
        const pagination = Pagination.pagination(clients, 1, pageSize)
        setLoading(!clients)
        setCurrentPage(pagination.currentPage)
        setCurrentPage(pagination.currentPage)
        if (pagination.allPages == 0) {
            setAllPages(1)
        }
        else {
            setAllPages(pagination.allPages)
        }
        setClientsItems(pagination.items)
    }, [clients])

    function handlePageChange(page) {
        const pagination = Pagination.pagination(clients, page, pageSize)
        setCurrentPage(page)
        setCurrentPage(pagination.currentPage)
        setCurrentPage(pagination.currentPage)
        if (pagination.allPages == 0) {
            setAllPages(1)
        }
        else {
            setAllPages(pagination.allPages)
        }
        setClientsItems(pagination.items)
    }

    const getAll = useCallback(() =>
        CustomerService
            .getAssociateds(1, parseInt(id as string))
            .then((response: any) => {
                setClients(response?.data.filter(c => c.associado))
            })

        , [id])

    const get_seller = useCallback(() =>
        RepresentativesService.getAll({ vendedor: id })
            .then((response: any) => response.list.data[0])
            .then((response: Representative) => setSeller(response))
        , [id])

    useEffect(() => { getAll() }, [getAll])

    useEffect(() => { get_seller() }, [get_seller])

    const changeStatus = (customer: Customer) => {
        MySwal.fire({
            title: (
                <div>
                    <p>
                        Você irá desassociar este cliente. Deseja continuar?
                    </p>
                    <hr />
                </div>
            ),
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            cancelButtonColor: "red"
        }).then((res) => {
            if (res.isConfirmed) {
                CustomerService.associate(parseInt(id as string), customer.id, customer.associado).then(getAll)
                return MySwal.fire(<p>Cliente desassociado com sucesso!</p>)
            }
        })
    }

    return (
        <ProjectContainer loading={loading}>
            <div className="representative-clients">
                <header style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    'justifyContent': 'space-between',
                    'marginBottom': 40
                }}>

                    <div>
                        <PageHeaderV2 title="Clientes" />
                    </div>
                    <div style={{ 'marginRight': 20 }}>
                        <Link to={`${Constants.ROUTE_PROVIDER_EDIT}?r=1`}>

                            <ButtonV3
                                title='Voltar'
                               // onClick={() => { }}
                                Icon={<BiLeftArrowAlt className='iconButtonV3'></BiLeftArrowAlt>}
                            >
                            </ButtonV3>
                        </Link>
                    </div>
                </header>
                <div className='col'>
                    {seller &&

                        <div className='rowRepresentante'>
                            {
                                clients &&
                                <div className='repInfoDiv'>
                                    <div><h6 >Quantidade Clientes:</h6></div>
                                    <div><p>{clients.length}</p></div>
                                </div>
                            }
                            <div className='repInfoDiv'>
                                <div><h6>Nome:</h6></div>
                                <div><p> {seller.name}</p></div>
                            </div>
                            <div className='repInfoDiv'>
                                <div><h6>Login:</h6></div>
                                <div><p> {seller.login}</p></div>
                            </div>
                            <div className='repInfoDiv'>
                                <div><h6>Email:</h6></div>
                                <div><p> {seller.email}</p></div>
                            </div>
                            <div className='repInfoDiv'>
                                <div><h6>Telefonee:</h6></div>
                                <div><PhoneWithWhats phone={seller.phone}/></div>
                            </div>
                        </div>


                    }

                    <div className="newListCLientsRepresentatives">
                        <table style={{ 'marginBottom': 30 }} className="newTable">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>CNPJ</th>
                                    <th>Estado</th>
                                    <th>Cidade</th>
                                    <th>Segmento</th>
                                    <th>Nome Contato</th>
                                    <th>Telefone</th>
                                    <th className="text-center">Ativar / Desativar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clients && clientsItems.map((item: Customer) => (
                                    <tr key={item.id}>
                                        <td data-label='Cliente'>{item.name}</td>
                                        <td data-label='CNPJ'>{item.cnpj ? mask_cnpj(item.cnpj) : ''}</td>
                                        <td data-label='Estado'>{item.state.name}</td>
                                        <td data-label='Cidade'>{item.city.name}</td>
                                        <td data-label='Segmento'>{item.segment}</td>
                                        <td data-label='Nome Contato'>{item.contactName}</td>
                                        <td data-label='Telefone'>{item.phone ? <PhoneWithWhats phone={item.phone}/> : '- -'} </td>
                                        <td data-label='Ativar / Desativar' className="text-center">
                                            <div title='Desassociar este cliente' className={'tooltip-icon'}>
                                                <ToggleButton

                                                    inactiveLabel={''}
                                                    activeLabel={null}
                                                    colors={{
                                                        activeThumb: {
                                                            base: 'white',
                                                        },
                                                        inactiveThumb: {
                                                            base: 'white',
                                                        },
                                                        active: {
                                                            base: '#FF7110',
                                                            hover: '#FF7110',
                                                        },
                                                        inactive: {
                                                            base: '#e2e2e2',
                                                            hover: '#d9d9d9',
                                                        }
                                                    }}
                                                    value={true}
                                                    onToggle={() => {
                                                        changeStatus(item)
                                                    }} />


                                                {
                                                    //   <span
                                                    //       className={'tooltiptext'}>
                                                    //       {'Desassociar este cliente'}
                                                    //   </span>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <PaginationCustom
                            total={allPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            totalItems={clients && clients.length != 0 ? clients.length : 0}
                            ItemsPorPage={pageSize}
                            itemsName={'clientes'}
                        />
                    </div>
                </div>
            </div>
        </ProjectContainer>
    )
}
