import Constants from "../constants"
import { Delivery } from "../interfaces/delivery"
import HttpClient from "./HttpClient"

export default class DeliveryService {

    static parse = (deliveryFromApi: any) : Delivery => {

        if(!deliveryFromApi)return deliveryFromApi

        return { id: deliveryFromApi.id_entrega,
            date: `${deliveryFromApi.data_entrega}T00:00:00`,
            minimumBilling: deliveryFromApi.faturamento_minimo,
            providerId: deliveryFromApi.for_id,
            paymentWay: deliveryFromApi.forma,
            quotationId: deliveryFromApi.id_cotacao,
            observations: deliveryFromApi.observacao,
        }
    }

    static save_delivery = async (data: any) : Promise<Request> => {
        return HttpClient.post(Constants.URL_SAVE_DELIVERY, data)
    }

    static request_seller_delivery = async (request_id: any, seller_id: number) : Promise<Delivery> => {
        return HttpClient.get(`/v2/requests/${request_id}/sellers/${seller_id}/delivery`).then((del: any) => {
            return DeliveryService.parse(del)
        })
    }

    static last_request_seller_delivery = async (request_id: any, seller_id: number) : Promise<Delivery> => {
        return HttpClient.get(`/v2/sellers/${seller_id}/delivery/last`).then((del: any) => {
            return DeliveryService.parse(del)
        })
    }
}
