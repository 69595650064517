import React, { FC } from 'react'
import { Label } from '../label'
import './index.scss'

export const Textarea: FC<any> = ({ register, ...props }: any) => (
    <div>
        { props.description && <Label className="c-label">{ props.description }</Label> }
        <textarea ref={ register } { ...props } className={ `c-input form-control ${props.className}` } />
    </div>
)
