import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Constants from '../../../constants'
import RepresentativeService from '../../../services/RepresentativesService'
import RepresentativeForm from '../components/form'
import { Representative } from '../../../interfaces/representative'
import { ProjectContainer } from '../../../components/project-container'
import { Loader } from '../../../components/loader'
import { Button } from 'components/button'
import { PageHeader } from '../../../components/page-header'
import './index.scss'
import { useSnackbar } from 'react-simple-snackbar'
import { Snackbar } from 'components/snackbar'
import SnackBarErrortyle from 'styles/snackbar-error.style'
import { PageHeaderV2 } from 'components/pageHeaderV2'

const RepresentativeAdd = () => {

    const [openSnackbar] = useSnackbar(SnackBarErrortyle)

    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(false)

    const [representative, setRepresentative] = useState<boolean>(false)

    const save = (representativeData) => {
        setLoading(true)
        RepresentativeService.add(representativeData)
            .then(() => {
                setLoading(false)
                navigate(Constants.ROUTE_REPRESENTATIVES)
            })
            .catch(error => {
                openSnackbar(
                    <Snackbar icon="error_outline">
                        {error.response?.data?.mensagem}
                    </Snackbar>
                )
                setLoading(false)
            })
    }

    return (
        <ProjectContainer>
            <div className="representative-add">
                {loading && <Loader />}
                <header>
                    <PageHeaderV2 title="Adicionar Representante" />
                </header>
                {
                 //   <div className="actions">
                 //       <Link to={`${Constants.ROUTE_PROVIDER_EDIT}?r=1`} className="btn btn__primary btn-icon">
                 //           <Button>
                 //               <i className="material-icons">arrow_back</i>Voltar
                 //           </Button>
                 //       </Link>
                 //   </div>
                }
                <div className="form">
                    <RepresentativeForm representative={{}}
                        saveCallback={(representative: Representative) => save(representative)} />
                    { /* <ProductForm
            saveCallback={(product: DirectSaleProduct, levels: number[]) => save(product, levels)} */ }
                    { /* /> */}
                </div>
            </div>
        </ProjectContainer>
    )
}

export default RepresentativeAdd
