import React, { useState } from 'react'
import { BlockColor, Container, PickerContainer } from './styles';
import {
    AlphaPicker,
    BlockPicker,
    ChromePicker,
    CirclePicker,
    CompactPicker,
    GithubPicker,
    HuePicker,
    MaterialPicker,
    PhotoshopPicker,
    SketchPicker,
    SliderPicker,
    SwatchesPicker,
    TwitterPicker,

} from 'react-color'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
interface props {
    title?: string
    color: any,
    setColor: (data: any) => void
    visible: boolean
    onSelectPicker: () => void,
    tooltip?: string,
    leftPosition?: number
}

export default function ColorPickerInput({ title, color, setColor, visible, onSelectPicker, tooltip, leftPosition }: props) {

    return (
        <OverlayTrigger placement="top" overlay={<Tooltip placement="top" className="in" id="tooltip-top">{tooltip}</Tooltip>}>
            <Container >
                {title ?
                    <h6 >{title}</h6>
                    : null}
                {/* <div>
                    <AiOutlineQuestionCircle size={22} color='' />
                </div> */}

                <BlockColor onClick={() => onSelectPicker()} color={color} ></BlockColor>
                <PickerContainer left={leftPosition} >
                    {visible ?
                        <ChromePicker

                            color={color}
                            onChange={(color) => {
                                setColor(color.hex);
                            }}
                        />
                        : null}
                </PickerContainer>
            </Container>
        </OverlayTrigger>
    )
}
