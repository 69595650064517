import React, { useContext, useEffect, useState } from 'react'
import Constants from '../../constants'
import AuthService from '../../services/AuthService'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loadLoggedUser } from '../../store/user'
import { Link, NavLink } from 'react-router-dom'
import { FiShoppingCart, FiSettings, FiLogOut, FiTrendingUp } from 'react-icons/fi'
import { FaUsersCog, FaBoxes, FaStoreAlt } from 'react-icons/fa'
import { RiExchangeDollarLine } from 'react-icons/ri'
import { FaUserCircle } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import { GiHamburgerMenu } from 'react-icons/gi'
import './index.scss'
import { MdOutlineAnalytics } from 'react-icons/md'
//import Logo from '../../assets/logo-mini.svg'
import { AiOutlineDollarCircle } from 'react-icons/ai'
import { UserContext } from 'contexts/user'
import { BsGraphUp } from 'react-icons/bs'
import Logo from '../../assets/Logo-BRANCA.svg'
import { TbTruckDelivery } from 'react-icons/tb'
import { FaAngleRight } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";

const Sidebar = () => {

    const [sidebarIsActive, setSidebarIsActive] = useState(false)
    const [expandedItem, setExpandedItem] = useState(false)

    const navigate = useNavigate()

    const signOut = () => {
        AuthService.signOut()
        if (Constants.URL_RETURN_ADDRESS.includes('http')) {
            window.location.href = Constants.URL_RETURN_ADDRESS
        } else {
            navigate(Constants.URL_RETURN_ADDRESS)
        }
    }

    const { can, user } = useContext(UserContext)

    useEffect(() => {
        sidebarIsActive && (document.documentElement.style.overflow = 'hidden')
        !sidebarIsActive && (document.documentElement.style.overflow = 'unset')
        !sidebarIsActive && setExpandedItem(false)
    }, [sidebarIsActive])

    function handleClickBackground(e) {
        document.documentElement.style.overflow = 'hidden'
        setSidebarIsActive(false)
    }
    function verifyPathnameToExpand() {
        if (window.location.pathname == Constants.ROUTE_SALES_REPORT ||
            window.location.pathname == Constants.ROUTE_NOT_WINNER_REPORT && !expandedItem) {
                setExpandedItem(true)
        }
    }
    return (
        <nav
            onMouseLeave={() => setExpandedItem(false)}
            onMouseEnter={() => verifyPathnameToExpand()}
            draggable={false}
            className='sidebar'>
            {!sidebarIsActive && (
                <div className='HeaderContainerMobile'>
                    <GiHamburgerMenu className='burgerButton' onClick={() => setSidebarIsActive(!sidebarIsActive)} />
                    <img src={Logo} alt="logo do club da cotação" className='clubLogo' />
                </div>
            )
            }
            {
                sidebarIsActive && (

                    <div draggable={false} className="expandedHeaderBackground"  >
                        <div draggable={false} className="expandedHeader" >

                            <div draggable={false}>
                                <div draggable={false} className="userDiv">
                                    <div draggable={false} className="userContainer">
                                        <FaUserCircle className='userAvatar' />
                                        <div className="userDescription">
                                            <p className='userNameText'>{user && user.name ? user.name : ''}</p>
                                            <p className='userCompanyDescription'>{user && user.profile_name ? user.profile_name : ''}</p>
                                        </div>
                                    </div>
                                    <IoMdClose className='closeButton' onClick={() => setSidebarIsActive(false)} />
                                </div>
                                <ul draggable={false} className='linksList' >
                                    {can('READ', 'REPORT') && <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_DASHBOARD ? 'LinkContainerSelected' : 'LinkContainer'}  >
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_DASHBOARD}`}
                                        >
                                            <BsGraphUp className='LinkIcon' />
                                            <span className='linkText'>Dashboard</span>
                                        </NavLink>
                                    </li>}
                                    {can('UPDATE', 'PROVIDER') ? <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_PROVIDER_EDIT ? 'LinkContainerSelected' : 'LinkContainer'} >
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_PROVIDER_EDIT}`}
                                        >
                                            <FaUsersCog className='LinkIcon' />
                                            <span className='linkText'>Minhas Configurações</span>
                                        </NavLink>
                                    </li> : <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_REPRESENTATIVES_EDIT ? 'LinkContainerSelected' : 'LinkContainer'}  >
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_REPRESENTATIVES_EDIT}/${user?.id}`}
                                        >
                                            <FaUsersCog className='LinkIcon' />
                                            <span className='linkText'>Representantes Comerciais</span>
                                        </NavLink>
                                    </li>}
                                    {can('READ', 'CLIENT_COMPANY') && <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_CLIENTS ? 'LinkContainerSelected' : 'LinkContainer'}  >
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_CLIENTS}`}
                                        >
                                            <FaStoreAlt className='LinkIcon' />
                                            <span className='linkText'>Empresas Compradoras</span>
                                        </NavLink>
                                    </li>}
                                    {can('READ', 'PRODUCT') && <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_PRODUCTS ? 'LinkContainerSelected' : 'LinkContainer'} >
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_PRODUCTS}?fresh=1`}
                                        >
                                            <FaBoxes className='LinkIcon' />
                                            <span className='linkText'>Produtos</span>
                                        </NavLink>
                                    </li>}
                                    {can('READ', 'REQUEST_ORDER') && <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_ORDERS ? 'LinkContainerSelected' : 'LinkContainer'}  >
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_ORDERS}`}
                                        >
                                            <AiOutlineDollarCircle className='LinkIcon' />
                                            <span className='linkText'>Pedidos</span>
                                        </NavLink>
                                    </li>}
                                    {can('READ', 'DIRECT_PURCHASE') && <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_DIRECT_SALE_ORDER ? 'LinkContainerSelected' : 'LinkContainer'}>
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_DIRECT_SALE_ORDER}`}
                                        >
                                            <RiExchangeDollarLine className='LinkIcon' />
                                            <span className='linkText'>Pedidos de Venda Direta</span>
                                        </NavLink>
                                    </li>}
                                    {can('READ', 'DIRECT_PURCHASE') && <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_SALES_REPORT ? 'LinkContainerSelected' : 'LinkContainer'}>
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_SALES_REPORT}`}
                                        >
                                            <MdOutlineAnalytics size={35} className='LinkIcon' />
                                            <span className='linkText'>Relatorios de vendas de produtos.</span>
                                        </NavLink>
                                    </li>}
                                    {can('READ', 'DIRECT_PURCHASE') && <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_NOT_WINNER_REPORT ? 'LinkContainerSelected' : 'LinkContainer'}>
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_NOT_WINNER_REPORT}`}
                                        >
                                            <MdOutlineAnalytics size={35} className='LinkIcon' />
                                            <span className='linkText'>Relatorios de produtos não vencedores.</span>
                                        </NavLink>
                                    </li>}
                                    {can('READ', 'DIRECT_PURCHASE') && <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_MONITORING ? 'LinkContainerSelected' : 'LinkContainer'}>
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_MONITORING}`}
                                        >
                                            <TbTruckDelivery size={35} className='LinkIcon' />
                                            <span className='linkText'>Monitoramento de Entregas</span>
                                        </NavLink>
                                    </li>}

                                    {can('READ', 'REQUEST') && <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_REQUESTS ? 'LinkContainerSelected' : 'LinkContainer'} >
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_REQUESTS}`}
                                        >
                                            <FiShoppingCart className='LinkIcon' />
                                            <span className='linkText'>Cotações</span>
                                        </NavLink>
                                    </li>}
                                    {can('READ', 'CAMPAIGN') && <li
                                        onClick={() => { setSidebarIsActive(false) }}
                                        className={window.location.pathname == Constants.ROUTE_CAMPAIGNS ? 'LinkContainerSelected' : 'LinkContainer'}  >
                                        <NavLink className='navlinkContainer' to={`${Constants.ROUTE_CAMPAIGNS}`}
                                        >
                                            <FiTrendingUp className='LinkIcon' />
                                            <span className='linkText'>Campanhas</span>
                                        </NavLink>
                                    </li>}
                                </ul>
                            </div>
                            <div className='signoutContainer'>
                                <div className='LinkContainer'
                                    onClick={() => signOut()}>

                                    <FiLogOut className='LinkIcon' />
                                    <span className='linkText'> Sair</span>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleClickBackground(Event)} className='bgGrayDivSideBar'></div>

                    </div>
                )
            }

            <div className='sideBarDesktop'>
                {
                    //    <div className="logo flex items-center justify-center">
                    //        <NavLink to="/">
                    //            <img src={Logo} alt="logo clube da cotação" />
                    //        </NavLink>
                    //    </div>
                }
                <ul className="menu">
                    {can('READ', 'REPORT') && <li>
                        <NavLink to={`${Constants.ROUTE_DASHBOARD}`}
                            className="">
                            <div className={window.location.pathname == Constants.ROUTE_DASHBOARD ? 'itemActive' : 'item'}>
                                <div>
                                    <BsGraphUp />
                                </div>
                                <span className="">Dashboard</span>
                            </div>
                        </NavLink>
                    </li>}
                    {can('UPDATE', 'PROVIDER') ? <li >
                        <NavLink to={`${Constants.ROUTE_PROVIDER_EDIT}`}
                            className="">
                            <div className={window.location.pathname == Constants.ROUTE_PROVIDER_EDIT ? 'itemActive' : 'item'}>
                                <div>
                                    <FaUsersCog />
                                </div>
                                <span className="">Minhas Configurações</span>
                            </div>
                        </NavLink>
                    </li> : <li>
                        <NavLink to={`${Constants.ROUTE_REPRESENTATIVES_EDIT}/${user?.id}`}
                            className="">
                            <div className={window.location.pathname == Constants.ROUTE_REPRESENTATIVES_EDIT ? 'itemActive' : 'item'}>
                                <div>
                                    <FaUsersCog />
                                </div>
                                <span className="">Representantes Comerciais</span>
                            </div>
                        </NavLink>
                    </li>}
                    {can('READ', 'CLIENT_COMPANY') && <li>
                        <NavLink to={`${Constants.ROUTE_CLIENTS}`}
                            className="">
                            <div className={window.location.pathname == Constants.ROUTE_CLIENTS ? 'itemActive' : 'item'}>
                                <div>
                                    <FaStoreAlt />
                                </div>
                                <span className="">Empresas Compradoras</span>
                            </div>
                        </NavLink>
                    </li>}
                    {can('READ', 'PRODUCT') && <li>
                        <NavLink to={`${Constants.ROUTE_PRODUCTS}?fresh=1`}
                            className="">
                            <div className={window.location.pathname == Constants.ROUTE_PRODUCTS ? 'itemActive' : 'item'}>
                                <div>
                                    <FaBoxes />
                                </div>
                                <span className="">Produtos</span>
                            </div>
                        </NavLink>
                    </li>}
                    {can('READ', 'REQUEST_ORDER') && <li>
                        <NavLink to={`${Constants.ROUTE_ORDERS}`}
                            className=" ">
                            <div className={window.location.pathname == Constants.ROUTE_ORDERS ? 'itemActive' : 'item'}>
                                <div>
                                    <AiOutlineDollarCircle />
                                </div>
                                <span className="">Pedidos</span>
                            </div>
                        </NavLink>
                    </li>}
                    {can('READ', 'DIRECT_PURCHASE') && <li>
                        <NavLink to={`${Constants.ROUTE_DIRECT_SALE_ORDER}`}
                            className="">
                            <div className={window.location.pathname == Constants.ROUTE_DIRECT_SALE_ORDER ? 'itemActive' : 'item'}>
                                <div>
                                    <RiExchangeDollarLine />
                                </div>
                                <span className="">Pedidos de Venda Direta</span>
                            </div>
                        </NavLink>
                    </li>}

                    {/* //? ---------- */}
                    {can('READ', 'DIRECT_PURCHASE') &&
                        <div className='itemRelatorio'>
                            <div onClick={() => setExpandedItem(!expandedItem)} className={`${window.location.pathname == Constants.ROUTE_SALES_REPORT || window.location.pathname == Constants.ROUTE_NOT_WINNER_REPORT ? 'itemActive' : 'item'}`}>
                                <div>
                                    <MdOutlineAnalytics />
                                </div>
                                <span className="">Relatorios </span>
                                <span className='relatorioICon'>
                                    <div>
                                        <FaMinus className={`${expandedItem ? 'firstMinusRotate' : 'firstMinus'}`} />
                                        <FaMinus className={`${expandedItem ? 'secondMinusRotate' : 'secondMinus'}`} />
                                    </div>
                                </span>
                            </div>
                            <div className={`expandableItem ${expandedItem ? 'expanded' : 'notExpanded'}`}>
                                <NavLink to={`${Constants.ROUTE_SALES_REPORT}`}>

                                    <div
                                        className={window.location.pathname == Constants.ROUTE_SALES_REPORT ? 'itemActive' : 'item'}
                                    >
                                        <div>
                                            <FaAngleRight />
                                        </div>
                                        <span className="">Venda de produtos (Pedido direto)</span>
                                    </div>
                                </NavLink>
                                <NavLink to={`${Constants.ROUTE_NOT_WINNER_REPORT}`}>
                                    <div
                                        className={window.location.pathname == Constants.ROUTE_NOT_WINNER_REPORT ? 'itemActive' : 'item'}
                                    >
                                        <div>
                                            <FaAngleRight />
                                        </div>
                                        <span className="">Itens nao vencedores (Cotação)</span>
                                    </div>
                                </NavLink>
                                <NavLink to={`${Constants.ROUTE_WINNER_REPORT}`}>
                                    <div
                                        className={window.location.pathname == Constants.ROUTE_WINNER_REPORT ? 'itemActive' : 'item'}
                                    >
                                        <div>
                                            <FaAngleRight />
                                        </div>
                                        <span className="">Itens vencedores (Cotação)</span>
                                    </div>
                                </NavLink>
                            </div>

                        </div>}
                    {/* //? ---------- */}
                    {/* {can('READ', 'DIRECT_PURCHASE') && <li>
                        <NavLink to={`${Constants.ROUTE_SALES_REPORT}`}
                            className="">
                            <div className={window.location.pathname == Constants.ROUTE_SALES_REPORT ? 'itemActive' : 'item'}>
                                <div>
                                    <MdOutlineAnalytics />
                                </div>
                                <span className="">Relatorios de vendas de produtos</span>
                            </div>
                        </NavLink>
                    </li>} */}
                    {can('READ', 'DIRECT_PURCHASE') && <li>
                        <NavLink to={`${Constants.ROUTE_MONITORING}`}
                            className="">
                            <div className={window.location.pathname == Constants.ROUTE_MONITORING ? 'itemActive' : 'item'}>
                                <div>
                                    <TbTruckDelivery />
                                </div>
                                <span className="">Monitoramento de Entregas</span>
                            </div>
                        </NavLink>
                    </li>}
                    {can('READ', 'REQUEST') && <li>
                        <NavLink to={`${Constants.ROUTE_REQUESTS}`}
                            className="">
                            <div className={window.location.pathname == Constants.ROUTE_REQUESTS ? 'itemActive' : 'item'}>
                                <div>
                                    <FiShoppingCart />
                                </div>
                                <span className="">Cotações</span>
                            </div>
                        </NavLink>
                    </li>}
                    {can('READ', 'CAMPAIGN') && <li >
                        <NavLink to={`${Constants.ROUTE_CAMPAIGNS}`}
                            className="">
                            <div className={window.location.pathname == Constants.ROUTE_CAMPAIGNS ? 'itemActive' : 'item'}>
                                <div>
                                    <FiTrendingUp />
                                </div>
                                <span className="">Campanhas</span>
                            </div>
                        </NavLink>
                    </li>}

                    {user && user.name && (
                        <li className="logoutContainer">
                            <div className="item " onClick={() => signOut()}>
                                <div>
                                    <FiLogOut />
                                </div>
                                <span className="">Sair</span>
                            </div>
                            {
                                // <button className="avatar avatar-48 cursor-pointer">{user.name.split("")[0]}</button>
                                // <div className="user-menu">
                                //     <header className="user-menu-header">
                                //         <h4>Olá, <span className="bold">{user.name}!</span></h4>
                                //     </header>
                                //     <ul className="user-menu-content">
                                //         <li>
                                //             {can('UPDATE', 'PROVIDER') ? <Link to={Constants.ROUTE_PROVIDER_EDIT}
                                //                 className="user-menu-content-item color-gray-2 flex items-center">
                                //                 <FiSettings size="16px" className="mr-2 color-gray-2" />
                                //                 Configurações
                                //             </Link> : <Link to={`${Constants.ROUTE_REPRESENTATIVES_EDIT}/${user?.id}`}
                                //                 className="user-menu-content-item color-gray-2 flex items-center">
                                //                 <FiSettings size="16px" className="mr-2 color-gray-2" />
                                //                 Configurações
                                //             </Link>}
                                //         </li>
                                //         <li>
                                //             <button className="user-menu-content-item color-red flex items-center logout"
                                //                 onClick={() => signOut()}>
                                //                 <FiLogOut size="16px" className="mr-2 color-red" />
                                //                 Sair
                                //             </button>
                                //         </li>
                                //     </ul>
                                // </div>
                            }
                        </li>
                    )}
                </ul>
            </div >





















        </nav >
    )
}


const mapStateToProps = (state: any) => ({
    loggedUser: state.loggedUser,
})

const mapDispatchToProps = (dispatch: any) => ({
    loadLoggedUser: () => dispatch(loadLoggedUser())
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)












//    const navigate = useNavigate()
//
//    const signOut = () => {
//        AuthService.signOut()
//        if(Constants.URL_RETURN_ADDRESS.includes('http')) {
//            window.location.href = Constants.URL_RETURN_ADDRESS
//        } else {
//            navigate(Constants.URL_RETURN_ADDRESS)
//        }
//    }
//
//    const { can, user } = useContext(UserContext)
//
//    return (
//        <nav className="col sidebar">
//            <div>
//                <div className="logo flex items-center justify-center">
//                    <NavLink to="/">
//                        <img src={ Logo } alt="logo clube da cotação" />
//                    </NavLink>
//                </div>
//                <ul className="menu flex mx-auto flex-grow mt-4 flex-col">
//                    { can('READ', 'REPORT') && <li className="item mb-1">
//                        <NavLink to={ `${Constants.ROUTE_DASHBOARD}` }
//                            className="button flex items-center justify-center p-relative">
//                            <BsGraphUp />
//                            <span className="animated">Dashboard</span>
//                        </NavLink>
//                    </li> }
//                    { can('UPDATE', 'PROVIDER') ? <li className="item mb-1">
//                        <NavLink to={ `${Constants.ROUTE_PROVIDER_EDIT}` }
//                            className="button flex items-center justify-center p-relative">
//                            <FaUsersCog />
//                            <span className="animated">Minhas Configurações</span>
//                        </NavLink>
//                    </li> : <li className="item mb-1">
//                        <NavLink to={ `${Constants.ROUTE_REPRESENTATIVES_EDIT}/${user?.id}` }
//                            className="button flex items-center justify-center p-relative">
//                            <FaUsersCog />
//                            <span className="animated">Representantes Comerciais</span>
//                        </NavLink>
//                    </li> }
//                    { can('READ', 'CLIENT_COMPANY') && <li className="item mb-1">
//                        <NavLink to={ `${Constants.ROUTE_CLIENTS}` }
//                            className="button flex items-center justify-center p-relative">
//                            <FaStoreAlt />
//                            <span className="animated">Empresas Compradoras</span>
//                        </NavLink>
//                    </li> }
//                    { can('READ', 'PRODUCT') && <li className="item mb-1">
//                        <NavLink to={ `${Constants.ROUTE_PRODUCTS}` }
//                            className="button flex items-center justify-center p-relative">
//                            <FaBoxes />
//                            <span className="animated">Produtos</span>
//                        </NavLink>
//                    </li> }
//                    { can('READ', 'REQUEST_ORDER') && <li className="item mb-1">
//                        <NavLink to={ `${Constants.ROUTE_ORDERS}` }
//                            className="button flex items-center justify-center p-relative">
//                            <AiOutlineDollarCircle />
//                            <span className="animated">Pedidos</span>
//                        </NavLink>
//                    </li> }
//                    { can('READ', 'DIRECT_PURCHASE') && <li className="item mb-1">
//                        <NavLink to={ `${Constants.ROUTE_DIRECT_SALE_ORDER}` }
//                            className="button flex items-center justify-center p-relative">
//                            <RiExchangeDollarLine />
//                            <span className="animated">Pedidos de Venda Direta</span>
//                        </NavLink>
//                    </li> }
//                    { can('READ', 'REQUEST') && <li className="item mb-1">
//                        <NavLink to={ `${Constants.ROUTE_REQUESTS}` }
//                            className="button flex items-center justify-center p-relative">
//                            <FiShoppingCart />
//                            <span className="animated">Cotações</span>
//                        </NavLink>
//                    </li> }
//                    { can('READ', 'CAMPAIGN') && <li className="item mb-1">
//                        <NavLink to={ `${Constants.ROUTE_CAMPAIGNS}` }
//                            className="button flex items-center justify-center p-relative">
//                            <FiTrendingUp />
//                            <span className="animated">Campanhas</span>
//                        </NavLink>
//                    </li> }
//                </ul>
//                { user && user.name && (
//                    <div className="user flex items-center justify-center">
//                        <button className="avatar avatar-48 cursor-pointer">{ user.name.split("")[0] }</button>
//                        <div className="user-menu">
//                            <header className="user-menu-header">
//                                <h4>Olá, <span className="bold">{ user.name }!</span></h4>
//                            </header>
//                            <ul className="user-menu-content">
//                                <li>
//                                    { can('UPDATE', 'PROVIDER') ? <Link to={ Constants.ROUTE_PROVIDER_EDIT }
//                                        className="user-menu-content-item color-gray-2 flex items-center">
//                                        <FiSettings size="16px" className="mr-2 color-gray-2" />
//                                        Configurações
//                                    </Link> : <Link to={ `${Constants.ROUTE_REPRESENTATIVES_EDIT}/${user?.id}` }
//                                        className="user-menu-content-item color-gray-2 flex items-center">
//                                        <FiSettings size="16px" className="mr-2 color-gray-2" />
//                                        Configurações
//                                    </Link> }
//                                </li>
//                                <li>
//                                    <button className="user-menu-content-item color-red flex items-center logout"
//                                        onClick={ () => signOut() }>
//                                        <FiLogOut size="16px" className="mr-2 color-red" />
//                                        Sair
//                                    </button>
//                                </li>
//                            </ul>
//                        </div>
//                    </div>
//                ) }
//            </div>
//        </nav>)
//}
//
//
//const mapStateToProps = (state: any) => ({
//    loggedUser: state.loggedUser,
//})
//
//const mapDispatchToProps = (dispatch: any) => ({
//    loadLoggedUser: () => dispatch(loadLoggedUser())
//})
//
//export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
//
