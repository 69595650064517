/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable react/prop-types */
import { Button } from 'components/button'
import { FeedbackError } from 'components/feedback/error'
import { Input } from 'components/input'
import { Loader } from 'components/loader'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiTrash } from 'react-icons/bi'
import ProviderService from 'services/v3/ProviderService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { v4 as generateUUID } from 'uuid'
import './index.scss'

export const BlacklistDatesForm = ({ user }) => {
    const MySwal = withReactContent(Swal)

    const [blacklist, setBlacklist] = useState<any[]>([])
    const [isLoading, setLoading] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        loadRegisters()
    }, [])

    const loadRegisters = async () => {
        try {
            setLoading(true)

            const data = await ProviderService.deliveryDatesBlacklist(
                user.providerId
            )
            setBlacklist(data)
        } catch (e) {
            console.log('Error to load register', e)
        } finally {
            setLoading(false)
        }
    }

    const onSubmit = async (data: any) => {
        try {
            setLoading(true)

            const dates: string[] = Object.values(data)

            await ProviderService.saveDeliveryDatesBlacklist(
                user.providerId,
                dates
            )

            MySwal.fire({
                title: <p>Datas salvas com sucesso!</p>,
            })
        } catch (e) {
            MySwal.fire({
                title: (
                    <p>
                        Algo inesperado aconteceu, tente novamente em alguns
                        instantes!
                    </p>
                ),
                confirmButtonColor: 'red',
            })
            console.log('Error to submit dates', e)
        } finally {
            setLoading(false)
        }
    }

    const onAddDate = (e) => {
        e.preventDefault()
        setBlacklist((bl) => [...bl, { id: generateUUID(), date: '' }])
    }

    const onRemoveDate = (id: string) => {
        setBlacklist((bl) => bl.filter((b) => b.id !== id))
    }

    const onUpdateDate = (date: string, id: string) => {
        setBlacklist(
            blacklist.map((b) => {
                if (b.id === id) {
                    b.date = date
                }

                return b
            })
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`table`}>
                <div>
                    <Button variant="secondary" onClick={onAddDate}>
                        +
                    </Button>{' '}
                    Lista negras de datas
                </div>
                <div className={`flex-box`}>
                    {isLoading && (
                        <p className={`placeholder`}>
                            <Loader type="inline" size={50} />
                        </p>
                    )}

                    {!isLoading && blacklist.length == 0 && (
                        <p className={`placeholder`}>
                            Não há datas registradas na lista negra
                        </p>
                    )}

                    {!isLoading &&
                        blacklist.map((date) => (
                            <div key={date.id} style={{ margin: '0 10px' }}>
                                <div className={`flex-content`}>
                                    <Input
                                        type="date"
                                        name={date.id}
                                        defaultValue={date.date}
                                        register={register({ required: true })}
                                        onChange={(e) =>
                                            onUpdateDate(
                                                e.target.value,
                                                date.id
                                            )
                                        }
                                    />
                                    <Button
                                        onClick={() => onRemoveDate(date.id)}
                                    >
                                        <BiTrash size={20} />
                                    </Button>
                                </div>
                                {errors[date.id] && (
                                    <FeedbackError message={'Data inválida'} />
                                )}
                            </div>
                        ))}
                </div>
            </div>

            {blacklist.length > 0 && (
                <Button variant="primary" type="submit">
                    Salvar
                </Button>
            )}
        </form>
    )
}
