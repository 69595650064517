
import styled from 'styled-components'

interface props{
    greenValue?:boolean
}


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 10px;
    

`;
export const Title = styled.p`
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #707070;
    margin-bottom: 12px;
`;
export const Description = styled.p<props>`
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: ${({ greenValue}) =>greenValue? '#2ACD72': '#a1a1a1'};;
    text-shadow: ${({ greenValue}) =>greenValue? '0px 4px 4px rgba(0, 0, 0, 0.25)': 'none'};;
   

`;