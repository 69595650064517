export default [
    {
        name: "Relatórios",
        resource: "REPORT",
        action: "READ",
        description: "acessar menu de relatórios",
        permissions: [
            {
                name: "Leitura",
                resource: "REPORT",
                action: "READ",
                description: "acessar menu de relatórios",
            },
            {
                name: "Acesso amplo",
                resource: "REPORT",
                action: "READ_ALL",
                description: "acessar relatório de todos os vendedores"
            }
        ]
    },
    {
        name: "Configurações",
        resource: "PROVIDER",
        action: "UPDATE",
        description: "editar configurações do fornecedor",
        permissions: [
            {
                name: "Editar",
                resource: "PROVIDER",
                action: "UPDATE",
                description: "editar configurações do fornecedor",
            },
        ]
    },
    {
        name: "Empresas Compradoras",
        action: "READ",
        resource: "CLIENT_COMPANY",
        description: "acessar menu de empresas compradoras",
        permissions: [
            {
                name: "Leitura",
                action: "READ",
                resource: "CLIENT_COMPANY",
                description: "acessar menu de empresas compradoras",
            },
            {
                name: "Cadastro",
                action: "CREATE",
                resource: "CLIENT_COMPANY",
                description: "cadastrar novas empresas compradoras",
            },
            {
                name: "Acesso amplo",
                resource: "CLIENT_COMPANY",
                action: "READ_ALL",
                description:
                    "consultar todas as empresas compradoras do fornecedor"
            },
            {
                name: "Acesso completo",
                resource: "CLIENT_COMPANY",
                action: "READ_OTHERS",
                description:
                    "consultar todas as empresas compradoras do sistema"
            }
        ]
    },
    {
        name: "Usuário",
        action: "READ",
        resource: "USER",
        permissions: [
            {
                name: "Atualizar perfil",
                action: "UPDATE_PROFILE",
                resource: "USER",
                description: "atualizar perfil",
            },
            {
                name: "Atualizar grupo",
                resource: "USER",
                action: "UPDATE_GROUP",
                description: "atualizar grupo"
            }
        ]
    },
    {
        name: "Produtos",
        action: "READ",
        resource: "PRODUCT",
        description: "acessar menu de produtos da venda direta",
        permissions: [
            {
                name: "Leitura",
                action: "READ",
                resource: "PRODUCT",
                description: "acessar menu de produtos da venda direta",
            },
            {
                name: "Atualizar",
                action: "UPDATE",
                resource: "PRODUCT",
                description: "atualizar produtos da venda direta",
            },
            {
                name: "Criar",
                action: "CREATE",
                resource: "PRODUCT",
                description: "criar produtos da venda direta",
            },
            {
                name: "Níveis de preço",
                action: "PRICING",
                resource: "PRODUCT",
                description: "editar niveis de preço de produtos da venda direta",
            },
            {
                name: "Adicionar preço",
                action: "ADD_PRICE",
                resource: "PRODUCT",
                description: "adicionar nivel de preço em produtos da venda direta",
            },
            {
                name: "Gerenciar Grupos",
                action: "MANAGE_GROUPS",
                resource: "PRODUCT",
                description: "gerenciar grupos de produtos da venda direta",
            },
            {
                name: "Remover",
                action: "DELETE",
                resource: "PRODUCT",
                description: "remover produtos da venda direta",
            },
            {
                name: "Ativar/Desativar",
                action: "ACTIVATE_DEACTIVATE",
                resource: "PRODUCT",
                description: "ativar/desativar produtos da venda direta",
            }
        ]
    },
    {
        name: "Pedidos",
        action: "READ",
        resource: "REQUEST_ORDER",
        description: "acessar menu de produtos",
        permissions: [
            {
                name: "Pedidos",
                action: "READ",
                resource: "REQUEST_ORDER",
                description: "acessar menu de produtos",
            },
            {
                name: "Acesso amplo",
                resource: "REQUEST_ORDER",
                action: "READ_ALL",
                description: "acessar pedidos de todos os vendedores"
            }
        ]
    },
    {
        name: "Venda direta",
        action: "READ",
        resource: "DIRECT_PURCHASE",
        description: "acessar menu de venda direta",
        permissions: [
            {
                name: "Leitura",
                action: "READ",
                resource: "DIRECT_PURCHASE",
                description: "acessar menu de venda direta",
            },
            {
                name: "Acesso amplo",
                resource: "DIRECT_PURCHASE",
                action: "READ_ALL",
                description: "acessar pedidos de todos os vendedores"
            },
            {
                name: "Atualizar",
                resource: "DIRECT_PURCHASE",
                action: "UPDATE",
                description: "atualizar dados da compra direta"
            }
        ]
    },
    {
        name: "Cotações",
        action: "READ",
        resource: "REQUEST",
        description: "acessar menu de cotações",
        permissions: [
            {
                name: "Leitura",
                action: "READ",
                resource: "REQUEST",
                description: "acessar menu de cotações",
            },
            {
                name: "Acesso amplo",
                resource: "REQUEST",
                action: "READ_ALL",
                description:
                    "acessar cotações de todos os vendedores do fornecedor"
            },
            {
                name: "Acesso completo",
                resource: "REQUEST",
                action: "READ_OTHERS",
                description:
                    "acessar cotações de todos os clientes do sistema"
            },
            {
                name: "Adicionar vendedor",
                resource: "REQUEST",
                action: "ADD_SELLER",
                description: "adicionar vendedores a cotação"
            },
            {
                name: "Histórico de Ofertas",
                resource: "REQUEST_PRODUCT",
                action: "READ_HISTORY",
                description:
                    "visualizar histórico de offertas de itens da cotação"
            }
        ]
    },
    {
        name: "Campanhas",
        action: "READ",
        resource: "CAMPAIGN",
        description: "acessar menu de campanhas",
        permissions: [
            {
                name: "Leitura",
                action: "READ",
                resource: "CAMPAIGN",
                description: "acessar menu de campanhas"
            }
        ]
    }
]
