import { Button } from 'components/button'
import ButtonV3 from 'components/Buttonv3'
import { PageHeader } from 'components/page-header'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { ProjectContainer } from 'components/project-container'
import { RoadMap } from 'components/roadmap'
import { UserContext } from 'contexts/user'
import React, { useContext, useEffect, useState } from 'react'
import { BsClipboardData, BsShieldLock, BsShieldCheck } from 'react-icons/bs'
import { IoIosPeople } from 'react-icons/io'
import { MdOutlineAssignment, MdOutlineGroup } from 'react-icons/md'
import { BsTruck } from 'react-icons/bs'
import { FiMapPin } from "react-icons/fi";
import { IoFilterSharp } from "react-icons/io5";
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { loadEditLoggedProviderData } from 'store/providers'
import Fornecedor from './components/fornecedor/form/'
import { ProfileListComponent } from './components/profiles'
import Representatives from './components/representatives/index'
import { RoutesListComponent } from './components/routesListComponent/index'

import { WeekdayModalV2 } from './components/weekday-modal-v2'
import './index.scss'
import WhitelabelTheme from './components/whitelabelTheme'
import Whitelabel from './components/whitelabel'
import { DeliveryRoutesComponent } from './components/deliveryRoutesComponent'
import { ClassificationListComponent } from './components/ClassificationListComponent'

const ProviderEdit = ({ loadEditLoggedProviderData }: any) => {
    const { user, can } = useContext(UserContext)
    const [weekdayModal, setWeekdayModal] = useState<boolean>()
    const [loading, setLoading] = useState<boolean>(true)
    const [menu, setMenu] = useState('SETTINGS')
    const [WhitelabelMenuVisible, setWhitelabelMenuVisible] = useState(false)
    const [WhitelabelDestaques, setWhitelabelDestaques] = useState(false)


    const [representativesItemsCount, setRepresentativesCount] =
        useState('SETTINGS')
    const useQuery = () => new URLSearchParams(useLocation().search)
    const r = useQuery().get('r')

    useEffect(() => {
        loadEditLoggedProviderData().then(() => setLoading(false))
    }, [loadEditLoggedProviderData, setLoading])

    useEffect(() => {
        if (r && r === '1') {
            setMenu('REPRESENTATIVES')
        }
    }, [r])

    useEffect(() => {
        const getItemsCount = window.sessionStorage.getItem(
            'representativesItemsCount'
        )
        setRepresentativesCount(getItemsCount || '')
    }, [])

    return (
        <ProjectContainer loading={loading}>
            <RoadMap
                pageLinks={[
                    {
                        title: 'Minhas informações',
                        onclick: () => { }
                    },
                ]}
            ></RoadMap>
            <header style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <PageHeaderV2 title="Minhas informações" />
                {menu === 'SETTINGS' &&
                    <div style={{}}>
                        <ButtonV3

                            title='Parâmetros da venda direta'
                            onClick={() => setWeekdayModal(true)}
                        />
                    </div>
                }
            </header>
            <div className="provider-edit" style={{ flex: '100%' }}>
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a
                            id="nav-home-tab"
                            className={
                                menu === 'SETTINGS'
                                    ? 'nav-item nav-link active'
                                    : 'nav-item nav-link'
                            }
                            data-toggle="tab"
                            href="#nav-home"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                        >
                            <MdOutlineAssignment />
                            Dados do fornecedor
                        </a>
                        <a
                            id="nav-representative-tab"
                            className={
                                menu === 'REPRESENTATIVES'
                                    ? 'nav-item nav-link active'
                                    : 'nav-item nav-link'
                            }
                            data-toggle="tab"
                            href="#nav-representative"
                            role="tab"
                            aria-controls="nav-representative"
                            aria-selected="false"
                        >
                            <MdOutlineGroup />
                            Representantes
                        </a>
                        {
                            can('MANAGE', 'PROFILE') &&

                            (
                                <a
                                    id="nav-profile-tab"
                                    className={
                                        menu === 'PROFILES'
                                            ? 'nav-item nav-link active'
                                            : 'nav-item nav-link'
                                    }
                                    data-toggle="tab"
                                    href="#nav-profile"
                                    role="tab"
                                    aria-controls="nav-profile"
                                    aria-selected="false"
                                >
                                    <BsShieldCheck />
                                    Perfis
                                </a>
                            )}
                        <a
                            onClick={() => setWhitelabelMenuVisible(false)}
                            id="nav-routes-tab"
                            className={
                                menu === 'ROUTES'
                                    ? 'nav-item nav-link active'
                                    : 'nav-item nav-link'
                            }
                            data-toggle="tab"
                            href="#nav-routes"
                            role="tab"
                            aria-controls="nav-routes"
                            aria-selected="false"
                        >
                            <BsTruck />
                            Rotas
                        </a>
                        <a
                            onClick={() => setWhitelabelMenuVisible(false)}
                            id="nav-deliveryroutes-tab"
                            className={
                                menu === 'DELIVERYROUTES'
                                    ? 'nav-item nav-link active'
                                    : 'nav-item nav-link'
                            }
                            data-toggle="tab"
                            href="#nav-deliveryroutes"
                            role="tab"
                            aria-controls="nav-deliveryroutes"
                            aria-selected="false"
                        >
                            <FiMapPin />
                            Locais de retirada
                        </a>

                        <a
                            onClick={() => setWhitelabelMenuVisible(true)}
                            id="nav-whitelabel-tab"
                            className={
                                menu === 'WHITELABEL'
                                    ? 'nav-item nav-link active'
                                    : 'nav-item nav-link'
                            }
                            data-toggle="tab"
                            href="#nav-whitelabel"
                            role="tab"
                            aria-controls="nav-whitelabel"
                            aria-selected="false"
                        >
                            <BsShieldCheck />
                            WhiteLabel
                        </a>


                        <a
                            onClick={() => setWhitelabelMenuVisible(true)}
                            id="nav-whitelabel-tab"
                            className={
                                menu === 'CLASSIFICATION'
                                    ? 'nav-item nav-link active'
                                    : 'nav-item nav-link'
                            }
                            data-toggle="tab"
                            href="#nav-classification"
                            role="tab"
                            aria-controls="nav-classification"
                            aria-selected="false"
                        >
                            <IoFilterSharp />
                            Classificações
                        </a>


                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">

                    <div
                        className={`tab-pane fade show ${menu === 'SETTINGS' ? 'active' : ''
                            }`}
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                    >
                        {
                            //   <Button
                            //       className={'c-btn-outline'}
                            //       onClick={() => setWeekdayModal(true)}
                            //   >
                            //       Parametros da venda direta
                            //   </Button>
                        }
                        {/* <ButtonV3

                            title='Parâmetros da venda direta'
                            onClick={() => setWeekdayModal(true)}
                        /> */}

                        {
                            // <div className="col-lg-12">
                            //     {
                            //         /* <WeekdayModal
                            //         user={ user }
                            //         isVisible={ weekdayModal }
                            //         onCloseCallback={ () => setWeekdayModal(false) }
                            //     /> */
                            //     }
                            // </div>
                        }
                        <WeekdayModalV2
                            user={user}
                            isVisible={weekdayModal}
                            onCloseCallback={() =>
                                setWeekdayModal(false)
                            }
                        />

                        <Fornecedor setLoading={setLoading} />
                    </div>
                    <div
                        className={`tab-pane fade show ${menu === 'REPRESENTATIVES' ? 'active' : ''
                            }`}
                        id="nav-representative"
                        role="tabpanel"
                        aria-labelledby="nav-representative-tab"
                    >
                        <Representatives />
                    </div>
                    <div
                        className={`tab-pane fade show ${menu === 'PROFILES' ? 'active' : ''
                            }`}
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                    >
                        <ProfileListComponent />
                    </div>
                    <div
                        className={`tab-pane fade show ${menu === 'ROUTES' ? 'active' : ''
                            }`}
                        id="nav-routes"
                        role="tabpanel"
                        aria-labelledby="nav-routes-tab"
                    >

                        <RoutesListComponent />
                    </div>
                    <div
                        className={`tab-pane fade show ${menu === 'DELIVERYROUTES' ? 'active' : ''
                            }`}
                        id="nav-deliveryroutes"
                        role="tabpanel"
                        aria-labelledby="nav-deliveryroutes-tab"
                    >

                        <DeliveryRoutesComponent />
                    </div>
                    <div

                        className={`tab-pane fade show ${menu === 'WHITELABEL' ? 'active' : ''
                            }`}
                        id="nav-whitelabel"
                        role="tabpanel"
                        aria-labelledby="nav-whitelabel-tab"
                    >

                        <Whitelabel></Whitelabel>

                    </div>
                    <div

                        className={`tab-pane fade show ${menu === 'CLASSIFICATION' ? 'active' : ''
                            }`}
                        id="nav-classification"
                        role="tabpanel"
                        aria-labelledby="nav-classification-tab"
                    >

                        <ClassificationListComponent/>

                    </div>
                    <div
                        className="tab-pane fade"
                        id="nav-contact"
                        role="tabpanel"
                        aria-labelledby="nav-contact-tab"
                    >
                        ...
                    </div>
                </div>
            </div>
        </ProjectContainer>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch: any) => ({
    loadEditLoggedProviderData: () => dispatch(loadEditLoggedProviderData()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProviderEdit)
