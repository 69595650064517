import React, { FC } from 'react'
import DateService from 'services/DateService'
import './index.scss'

export const RequestClientComercialInfo: FC<any> = ({ className, request }: any) => (

    <section >
        <p  className='CondicoesComerciaisTitle'>Condições Comerciais do Cliente</p>
        <div className='divInfosCondicoesComerciais' >
            <div style={{display:'flex',flexWrap:'wrap'}}>
                <div className='divInfosCondicoesComerciaisBlock' >
                    <label htmlFor="del-type">Número da Cotação:</label>
                    <span id="req-id">#{request.id}</span>
                </div>
                <div className='divInfosCondicoesComerciaisBlock'>
                    <label htmlFor="del-type">Tipo Entrega:</label>
                    <span id="del-type">{request.delivery_method}</span>
                </div>
                <div className='divInfosCondicoesComerciaisBlock'>
                    <label htmlFor="del-type">Condição de Pagamento:</label>
                    <span id="pay-condition">-</span>
                </div>
            </div>
            <div style={{display:'flex',flexWrap:'wrap'}}>
            <div className='divInfosCondicoesComerciaisBlock'>
                    <label htmlFor="del-type">Vendedor:</label>
                    <span id="seller">{request.seller}</span>
                </div>
                <div className='divInfosCondicoesComerciaisBlock'>
                    <label htmlFor="del-type">Data Fim:</label>
                    <span id="exp-date">{DateService.formatToDisplayWithTime(new Date(request.date))}</span>
                </div>
                <div className='divInfosCondicoesComerciaisBlock'>
                    <label htmlFor="del-type">Observações:</label>
                    <span id="obs">{request.client_obs}</span>
                </div>
            </div>
        </div>
    </section>
)
{
    //  <section className={`client-comercial-conditions ${className}`}>
    //      <p className="title">Condições Comerciais do Cliente</p>
    //      <div className="container-fluid">
    //          <div className="row">
    //              <div className="col">
    //                  <label htmlFor="del-type">Número da Cotação:</label>
    //                  <span id="req-id">#{request.id}</span>
    //              </div>
    //              <div className="col">
    //                  <label htmlFor="del-type">Tipo Entrega:</label>
    //                  <span id="del-type">{request.delivery_method}</span>
    //              </div>
    //              <div className="col">
    //                  <label htmlFor="del-type">Condição de Pagamento:</label>
    //                  <span id="pay-condition">-</span>
    //              </div>
    //          </div>
    //          <div className="row mt-3">
    //              <div className="col">
    //                  <label htmlFor="del-type">Vendedor:</label>
    //                  <span id="seller">{request.seller}</span>
    //              </div>
    //              <div className="col">
    //                  <label htmlFor="del-type">Data Fim:</label>
    //                  <span id="exp-date">{DateService.formatToDisplayWithTime(new Date(request.date))}</span>
    //              </div>
    //              <div className="col">
    //                  <label htmlFor="del-type">Observações:</label>
    //                  <span id="obs">{request.client_obs}</span>
    //              </div>
    //          </div>
    //      </div>
    //  </section>
}
