import './index.scss'

import * as $ from 'jquery'
import { GrGroup } from 'react-icons/gr'
import React, { useEffect, useState } from 'react'

import { Checkbox } from 'components/checkbox'
import ModalComponent from 'components/modal-v2'
import ProductService from 'services/ProductService'
import { DirectSaleProduct } from 'interfaces/directSaleProduct'
import { Loader } from 'components/loader'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import { BaseModal } from 'components/BaseModal'
import ButtonV3 from 'components/Buttonv3'

type Props = {
    isVisible: boolean;
    onClose: () => void;
    product?: DirectSaleProduct;
    user?: any;
    groups: any[];
}

export const AddProductToGroupModal = ({ isVisible, onClose, product, user, groups }: Props) => {
    const modal = {
        icon: <GrGroup color='white' />,
        styles: { maxWidth: '80vw' },
        id: 'add-product-to-group-modal-mine',
        title: `Escolha de grupo de produtos`,
    }

    const [loading, setLoading] = useState(false)
    const [productGroups, setProductGroups] = useState<any>([])

    useEffect(() => {
        if (isVisible) {
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
            $('#' + modal.id).modal('show')

            loadProductGroups()
        } else {
            $('#' + modal.id).removeClass('fadeIn')
            $('#' + modal.id).addClass('fadeOutUp')
            $('#' + modal.id).modal('hide')

            setProductGroups([])
        }
    }, [isVisible, user, product])

    const loadProductGroups = () => {
        if (!user || !product) return

        setLoading(true)
        ProductService.groups_by_product(user.providerId, product.id).then((result) => {
            setProductGroups(result.groups)
            setLoading(false)
        })
    }

    const hasProduct = (groupId: string) => {
        return productGroups.some(pg => pg.id == groupId)
    }

    const onToggleProduct = async (groupId: any) => {
        
        if (product && product.id) {
            await ProductService.toggle_product_in_group(user.providerId, groupId, product.id)

            loadProductGroups()
        }
    }

    // const onToggleProduct = async ({ target: { value: groupId } }: any) => {
    //     console.log(groupId)
    //     if (product && product.id) {
    //         await ProductService.toggle_product_in_group(user.providerId, groupId, product.id)
    //
    //         loadProductGroups()
    //     }
    // }

    return (


        <div style={{
            'display': 'flex',
            'padding': '10px 30px  30px',
            'flexDirection': 'column',
            'minHeight': '40vh',
            'justifyContent': 'space-between',
            'margin':'0 auto'
        }}>
            <div style={{
                'display': 'flex',
                'flexDirection': 'row',
                'alignItems': 'center',
              
            }}>

                <p className={'titleProductOnGroupsChoose'}>
                    #{product?.ean} - {product?.description}
                </p>
            </div>

            <div style={{
                'display': 'grid',
                'marginLeft':'1%',
                'marginTop':20,
                'gridTemplateColumns': 'repeat(auto-fit, minmax(33%, 200px))',
                'rowGap':20,
                'justifyItems': 'flex-start',
                
                

            }}>

                {loading && (<Loader type="inline" />)}

                {!loading && groups?.map(group => (
                    <div key={group.id}>
                        {
                            //  <Checkbox description={group.name} value={group.id} checked={hasProduct(group.id)} onChange={onToggleProduct} />
                        }
                        {
                            <CheckBoxCustom description={group.name} value={group.id} checked={hasProduct(group.id)} change={onToggleProduct} />
                        }

                    </div>
                ))}
            </div>
            <div style={{
        
                'display': 'flex',
                'flexDirection': 'row',
                'justifyContent': 'flex-end',
                'width':'85%' 
                
            }}>
                <ButtonV3
                    
                    filled
                    title='Voltar'
                    onClick={() => {onClose() }}

                />
            </div>

        </div>


    )
    //return (
    //    <ModalComponent
    //    style={{'backgroundColor':'red'}}
    //        modal={modal}
    //        close={onClose}
    //        show={isVisible}
    //        className={'modal-dialog'}
    //    >
    //        <div className="container-fluid">
    //            <div className="row header">
    //                <div className="col m-4 p-3 shadow">

    //                    <p className={`title-weekday`}>
    //                        #{product?.ean} - {product?.description}
    //                    </p>

    //                    <div style={{
    //                        'display': 'grid',
    //                        'gridTemplateColumns': 'repeat(auto-fit, minmax(30%, 200px))',
    //                        'justifyItems':'center',
    //                        
    //                    }}>

    //                        {loading && (<Loader type="inline" />)}

    //                        {!loading && groups?.map(group => (
    //                            <div key={group.id}>
    //                                {
    //                                    //  <Checkbox description={group.name} value={group.id} checked={hasProduct(group.id)} onChange={onToggleProduct} />
    //                                }
    //                                {
    //                                  <CheckBoxCustom description={group.name} value={group.id} checked={hasProduct(group.id)} change={onToggleProduct} />
    //                                }

    //                            </div>
    //                        ))}
    //                    </div>

    //                </div>
    //            </div>
    //        </div>
    //    </ModalComponent>
    //)
}
