
import React, { useEffect, useRef, useState } from 'react';
import ProductService from 'services/ProductService';
import { TbCategory2 } from "react-icons/tb";
import { CiBoxList } from "react-icons/ci";
import { HiMiniViewColumns } from "react-icons/hi2";
import { PiTextColumns } from "react-icons/pi";
import {
    ListCategoriesContainer,

    CategoriesContainer,
    SubcategoriesContainer,
    HeaderListButtonsContainer,

    CategoryNameButton,
    AddCategoryContainer,
    AddSubcategoryContainer
} from './styles';
import { BiChevronRight, BiLoaderAlt } from 'react-icons/bi';
import ButtonV3 from 'components/Buttonv3';
import { MdAdd, MdCancel, MdEdit, MdFrontLoader, MdOutlineCancel, MdSave } from 'react-icons/md';
import { Input } from 'components/input';
import { FaPlus } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { FaCheck } from "react-icons/fa";
import { IoMdTrash } from 'react-icons/io';
import { BiExpand } from "react-icons/bi";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

interface props {

}

export function CategoriesModal() {

    const [prodCategories, setProdCategories] = useState<any>([])
    const [selectedCategory, setSelectedCategory] = useState<any>({})
    const [listMode, setListMode] = useState(false)
    const [subloading, setSubloading] = useState<any>(null)
    const [addContainerVisible, setAddContainerVisible] = useState(false)
    const [newCategoryName, setNewCategoryName] = useState('')
    const [expandAll, setExpandAll] = useState(false)

    const MySwal = withReactContent(Swal)

    const inputRefs = useRef<any>([]);

    useEffect(() => {
        handleGetAllProdCategories()
    }, [])

    async function handleGetAllProdCategories() {
        await ProductService.getAllProductCategories().then(res => {
            setProdCategories(res.data?.sort((a, b) => a.name.localeCompare(b.name)))
        })
    }

    function handleSetCategory(item) {
        item == selectedCategory ? setSelectedCategory({}) :
            setSelectedCategory(item)
    }
    function handleShowAddContainer(bol: boolean) {
        if (!bol) {
            setNewCategoryName('')
            setAddContainerVisible(bol)
        } else {

            setAddContainerVisible(bol)
        }
    }
    useEffect(() => {
        setExpandAll(false)
        setSelectedCategory({})
    }, [listMode])

    function handleSaveNewSubCategory(index) {
        const submittedData = inputRefs.current[index].value
        if (submittedData != '' && submittedData.length > 1) {
            setSubloading(index)

            createNewSubCategory(index, submittedData)
        }
    }
    async function createNewSubCategory(index, data) {
        let category = prodCategories[index]
        // console.log(category)
        await ProductService.createProductSubCategorie({ cat_id: category?.id, descricao: data }).then(res => {
            toast.success('Subcategoria adicionada!');
            inputRefs.current[index].value = ''
            handleGetAllProdCategories()
        }
        ).catch(er => { toast.error('Erro ao criar subcategoria') }).finally(
            () =>
                setSubloading(null)
        )
    }

    async function handleCreateCategory() {
        setSubloading('new')
        await ProductService.createProductCategorie({ cat_descricao: newCategoryName }).then(res => {
            toast.success('Categoria criada !');
            setAddContainerVisible(false);
            setNewCategoryName('')
            handleGetAllProdCategories()
        }
        ).catch(er => toast.error('Erro ao ciar categoria')).finally(
            () =>
                setSubloading(null)
        )
    }
    // function handleSubmit(event) {
    //     event.preventDefault()
    //     const formData = new FormData(event.target);
    //     const formObject = {};
    //     formData.forEach((value, key) => {
    //         formObject[key] = value;
    //     });
    //     console.log(formObject)
    // }


    async function handleDeleteCategory(id, name) {
        MySwal.fire({
            title: (
                <div>
                    <p>
                        Deseja excluir a categoria {name} ?
                    </p>

                </div>
            ),
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: 'Excluir',
            cancelButtonColor: "red"
        }).then((res) => {
            if (res.isConfirmed) {
                ProductService.deleteCategory(id)
                    .then(async () => {
                        toast.info('Categoria excluida !')
                        handleGetAllProdCategories()
                    }).then(() => { })
                    .catch(() => {
                        toast.error('erro ao excluir categoria')
                    })
            }
        })
    }

    async function handleDeleteSubCategory(id, name) {
        MySwal.fire({
            title: (
                <div>
                    <p>
                        Deseja excluir a subCategoria {name} ?
                    </p>

                </div>
            ),
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: 'Excluir',
            cancelButtonColor: "red"
        }).then((res) => {
            if (res.isConfirmed) {
                ProductService.deleteSubCategory(id)
                    .then(async () => {
                        toast.info('Categoria excluida !')
                        handleGetAllProdCategories()
                    }).then(() => { })
                    .catch(() => {
                        toast.error('erro ao excluir categoria')
                    })
            }
        })
    }
    return (
        <div style={{ padding: '30px 40px' }}>


            <HeaderListButtonsContainer  >
                <AddCategoryContainer visible={addContainerVisible} >
                    <section className=' inputsCategory'>
                        <Input
                            className={'inputNovaCategoria c-input'}
                            placeholder='Nome da categoria'
                            value={newCategoryName}
                            onChange={(e) => setNewCategoryName(e.target.value)}
                        ></Input>
                        <div className='buttonCancelCategory'
                            onClick={() => handleShowAddContainer(false)}>
                            < MdOutlineCancel className='iconButtonClose' />
                        </div>

                    </section>
                    <ButtonV3
                        onClick={() => addContainerVisible ? handleCreateCategory() : handleShowAddContainer(true)}
                        title={addContainerVisible ? 'Salvar' : 'Nova categoria'}
                        Icon={subloading == 'new' ? <BiLoaderAlt className='subLoader iconButtonV3'></BiLoaderAlt> : addContainerVisible ? < MdSave className='iconButtonV3' /> : <MdAdd className='iconButtonV3'></MdAdd>}
                    />
                </AddCategoryContainer>

                <div className='listModeContainer'>
                    {listMode ?
                        <section onClick={() => setExpandAll(!expandAll)} className={expandAll ? 'selected' : 'unselected'}>
                            <BiExpand />
                        </section>
                        : null}
                    <section onClick={() => setListMode(true)} className={listMode ? 'selected' : 'unselected'}>
                        <CiBoxList />
                    </section>
                    <section onClick={() => setListMode(false)} className={!listMode ? 'selected' : 'unselected'}>
                        <HiMiniViewColumns />
                    </section>
                </div>

            </HeaderListButtonsContainer>
            <ListCategoriesContainer>

                <CategoriesContainer listMode={listMode}>

                    {prodCategories?.map((item, index) =>
                        <CategoryNameButton
                            key={index + 'cat'}
                            isActive={selectedCategory == item}

                        >
                            <section >
                                <div onClick={() => handleDeleteCategory(item.id, item.name)} className='removeCategory'>
                                    <IoMdTrash></IoMdTrash>
                                </div>

                                <p onClick={() => handleSetCategory(item)}>
                                    {item.name}
                                </p>
                                {/* <div className=' editCategory'>
                                    <MdEdit ></MdEdit>
                                </div> */}
                            </section>
                            <SubcategoriesContainer
                                className={!listMode ? 'blockmode' : 'listmode'}
                                isActive={selectedCategory == item || expandAll}
                            // isActive={true}
                            >
                                {item.subCategories?.length > 0 ?
                                    item.subCategories.map(sub =>

                                        <>
                                            <section>
                                                <div style={{ cursor: 'pointer' }} onClick={() => handleDeleteSubCategory(sub.id, sub.name)} className='removeCategory'>
                                                    <IoMdTrash></IoMdTrash>
                                                </div>
                                                <p>{sub.name}</p></section>

                                        </>
                                    )
                                    : null}
                                <AddSubcategoryContainer>
                                    <input
                                        className='c-input'
                                        type="text"
                                        placeholder={`Nova subcategoria`}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                    />

                                    <div className='buttonActionMobileTable AddSubcategoryIcon'
                                        onClick={() => handleSaveNewSubCategory(index)} >

                                        {subloading == index ? <BiLoaderAlt className='subLoader'></BiLoaderAlt> :
                                            <>
                                                <FaPlus className='savesubicon'></FaPlus>
                                                <FaCheck className='checksubicon' />
                                            </>
                                        }
                                    </div>

                                </AddSubcategoryContainer>

                            </SubcategoriesContainer>
                        </CategoryNameButton>
                    )}
                </CategoriesContainer>



            </ListCategoriesContainer>


        </div >
    );
}
