import React, { FC } from 'react'
import { InputInterface } from '../../interfaces/input-props.interface'
import './index.scss'

export const ErrorText: FC<InputInterface> = ({ children, ...props } : any) => (
    <>
        <small { ...props } className="form-text text-muted error">
            { children }
        </small>
    </>
)
