import React from 'react'
import { DirectSaleOrder } from 'interfaces/directSaleOrder'
import { DirectSaleOrderDetails } from '../direct-sale-order-details/direct-sale-order-details'

interface Props {
    order: DirectSaleOrder
}

export class DirectSaleOrderPrint extends React.PureComponent<Props> {

    render() {
        return this.props.order && <DirectSaleOrderDetails order={ this.props.order } />
    }
}
