import './index.scss'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import NewModalComponent from 'components/new-modal-edit'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { Textarea } from 'components/textarea'
import { Currency } from 'components/currency-input'
import { CampaignService } from 'services/v2/campaigns.service'


export const EditModal = ({ campaign, productModal, onCloseCallback }: any) => {

    const initialProduct = {
        pro_id:"",
        pro_descricao: "",
        observacao: "",
        pro_embalagem: "",
        valor_unitario: 0,
        valor_embalagem: 0
    }

    const { register } = useForm()
    const [ modal, setModal ] = useState<any>({})
    const [ product, setProduct ] = useState<any>(initialProduct)

    useEffect(() => {
        setModal({
            title: 'Crie ou altere os dados do produto',
            id: 'edit-product-modal',
            icon: 'edit',
            styles: {
                maxWidth: '60vw'
            },
            onClose: onCloseCallback
        })
        if(productModal){
            update_product()
        }


    }, [ productModal, onCloseCallback ])

    const update_product = () => {
        if(productModal.pro_descricao) product.pro_descricao = productModal.pro_descricao
        if(productModal.pro_embalagem) product.pro_embalagem = productModal.pro_embalagem
        if(productModal.observacao) product.observacao = productModal.observacao
        if(productModal.valor_embalagem) product.valor_embalagem = productModal.valor_embalagem
        if(productModal.valor_embalagem) product.valor_unitario = productModal.valor_embalagem / productModal.pro_embalagem.split(" ")[1]
    }

    const set_packing_value = (value: number) => {
        product.packing_value = value
        const valor = value / product.pro_embalagem.split(" ")[1]
        setProduct({ ...product, valor_embalagem: value, valor_unitario: valor })
    }

    const save = () => {

        const data = {
            for_prod_id: productModal.pro_id,
            quantidade_embalagem: productModal.pro_embalagem.split(" ")[1],
            embalagem: productModal.pro_embalagem.split(" ")[0],
            valor_embalagem: product.packing_value,
            observacao: product.observacao
        }

        if(productModal.id){
            CampaignService.update_item(campaign.id, productModal.pro_id, data)
                .then(async (response: any) => {
                    onCloseCallback(response.data)
                }).then()
        }else{
            CampaignService.add_item(campaign.id, data)
                .then(async (response: any) => {
                    onCloseCallback(response.data)
                }).then()
        }
    }

    return (

        <NewModalComponent modal={ modal } onCloseCallback={ onCloseCallback } >

            <div className="condition-body">
                <div className="row">
                    <div className="form-group col-12 col-md-11">
                        <Input id="descricao"
                            name="descricao"
                            description="Descrição"
                            placeholder="DESCRIÇÃO DO PRODUTO"
                            value={ product ? product.pro_descricao : '' }
                            disabled
                        />
                    </div>

                    <div className="form-group col-12 col-md-4">
                        <Input id="embalagem"
                            name="embalagem"
                            description="Embalagem"
                            placeholder=""
                            value={ product? product.pro_embalagem : '' }
                            disabled
                        />
                    </div>

                    <div className="form-group col-12 col-md-3">
                        <Currency id="valor_embalagem"
                            name="valor_embalagem"
                            description="Valor Embalagem"
                            placeholder=""
                            value={ product ? product.valor_embalagem : 0 }
                            onChange={ (value: string, floatValue: number) => set_packing_value(floatValue) }
                        />
                    </div>

                    <div className="form-group col-12 col-md-3">
                        <Currency id="valor_unitario"
                            name="valor_unitario"
                            description="Valor Unitário"
                            placeholder=""
                            value={ product ? product.valor_unitario : 0 }
                            disabled
                        />
                    </div>

                    <div className="form-group col-12 col-md-11">

                        <label className="c-label" htmlFor="additionalInformations">
                            Observações
                        </label>
                        <Textarea id="additionalInformations" name="additionalInformations" rows={ 3 }
                            register={ register() }
                            value={ product.observacao }
                            onChange={ (e: any) => setProduct({ ...product, observacao: e.target.value }) }
                        />

                        <div className="btn-modal">
                            <br/>
                            <Button type="button" data-dismiss="modal" className="btn-verde" onClick={ () => save() }>
                                <i className="material-icons">done </i>Salvar e Continuar
                            </Button>
                        </div>
                    </div>

                </div>
            </div>

        </NewModalComponent>
    )
}
