import React, { FC } from 'react'
import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs'
import { MdOutlineStarOutline,MdOutlineStarHalf,MdStar } from 'react-icons/md'
import styles from './index.module.scss'

export const Rating: FC<any> = ({ rate, size, ...props }: any) => {
    return <>
        <span className={ styles.wrapper } { ...props }>
            { rate >= 4 && <MdStar size={size ? size: 18} className={ styles.icon } /> }
            { rate >= 2.5 && rate < 4 && <MdOutlineStarHalf size={size ? size:18} className={ styles.icon } /> }
            { rate >= 0 && rate < 2.5 && <MdOutlineStarOutline size={size ? size:18} className={ styles.icon } /> }
            ({ rate || 0 })
        </span>
    </>
}
