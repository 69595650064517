import HttpClient from './httpClientv2'

export class SalesTaxesService {

    static getSalesTaxes = async (): Promise<any> => {
        return HttpClient.get('/taxes')
    }
    static createSalesTaxes = async (params: any): Promise<any> => {
        return HttpClient.post('/taxes', params)
    }
    static deleteSalesTaxes = async (id: any): Promise<any> => {
        return HttpClient.delete(`/taxes/${id}`)
    }
    static editSalesTaxes = async (id: any,params): Promise<any> => {
        return HttpClient.put(`/taxes/${id}`,params)
    }

    // static show = async (id: any) : Promise<any> => {
    //     return HttpClient.get(`v2/providers/campaigns/${id}`)
    // }

    // static update = async (params: any, id: any) : Promise<any> => {
    //     return HttpClient.put(`v2/providers/campaigns/${id}`, params)
    // }

    // static save = async (params: any) : Promise<any> => {
    //     return HttpClient.post(`v2/providers/campaigns`, params)
    // }

    // static delete = async (id: any) : Promise<any> => {
    //     return HttpClient.delete(`v2/providers/campaigns/${id}`)
    // }

    // static add_item = async (id: any, params: any) : Promise<any> => {
    //     return HttpClient.post(`v2/providers/campaigns/${id}/itens/add`, params)
    // }

    // static update_item = async (id: any,id_item: any, params: any) : Promise<any> => {
    //     return HttpClient.put(`v2/providers/campaigns/${id}/itens/${id_item}`, params)
    // }

    // static delete_item = async (id: any,id_item: any) : Promise<any> => {
    //     return HttpClient.delete(`v2/providers/campaigns/${id}/itens/${id_item}`)
    // }

    // static show_itens = async (id: any,filters: any = {}) : Promise<any> => {
    //     return HttpClient.get(`v2/providers/campaigns/${id}/itens/grid?product_name_filter=${filters.product_name || ''}`)
    // }

}
