import HttpClient from './http.client'

export default class highlightsService {

    // static getProviderTheme = async (): Promise<any> => {
    //     return HttpClient.get(`/providers/label/themes/fornecedor`,)
    // }

    // static postProviderTheme = async (data: any): Promise<any> => {
    //     return HttpClient.post(`/providers/label/themes`, data)
    // }

    // static putProviderTheme = async (id: any, data: any): Promise<any> => {
    //     return HttpClient.post(`/providers/label/themes/${id}`,data)
    // }

    static getProviderHighlights = async (): Promise<any> => {
        return HttpClient.get(`/providers/label/highlights`,)
    }
    static getProviderHighlightsCarousels = async (): Promise<any> => {
        return HttpClient.get(`/providers/label/highlights/carousel`,)
    }
    static createHighlightBlock = async (data): Promise<any> => {
        return HttpClient.post(`/providers/label/highlights/carousel`, data)
    }
    static createHighlight = async (blockid, data): Promise<any> => {
        return HttpClient.post(`/providers/label/highlights/carousel/${blockid}/content`, data)
    }
    static updateHighlight = async (blockid, data): Promise<any> => {
        return HttpClient.post(`/providers/label/highlights/carousel/${blockid}/update`, data)
    }
    static removeCardFromBLock = async (id): Promise<any> => {
        return HttpClient.post(`/providers/label/highlights/carousel/content/${id}/update`, { status: 0 })
    }
    static editCard = async (id, params): Promise<any> => {
        return HttpClient.post(`/providers/label/highlights/carousel/content/${id}/update`, params)
    }
    static removeBLock = async (id): Promise<any> => {
        return HttpClient.post(`/providers/label/highlights/carousel/${id}/update`, { status: 0 })
    }
    static reorderBlock = async (id, row): Promise<any> => {
        return HttpClient.post(`/providers/label/highlights/carousel/${id}/update`, { row: row })
    }
    // static deactivate = async (id: number, seller_id: number) : Promise<any> => {
    //     return HttpClient.delete(`/providers/clients/${id}/sellers/${seller_id}/deactivate`)
    // }

    // static discontinue_product = async (client_id: number, product_id: number, seller_id: number) : Promise<any> => {
    //     return HttpClient.patch(`/providers/clients/${client_id}/products/${product_id}/discontinue?seller_id=${seller_id}`, {})
    // }
    // static getRequestedProducts = async (client_id: number, page?:'' ) : Promise<any> => {
    //     return HttpClient.get(`/providers/products/cotados?cli_id=${client_id}&page=${page}`)
    // }
    // static getComercialsReferences = async (client_id: number ) : Promise<any> => {
    //     return HttpClient.get(`/providers/products/referencias/comerciais?cli_id=${client_id}`)
    // }
}
