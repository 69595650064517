import UserService from "../../services/UserService"
import { User } from "../../interfaces/user"


// TYPES
export const LOAD_LOGGED_USER_SUCCESS   = 'LOAD_LOGGED_USER_SUCCESS'


// Reducer
const initialState = null

export default function loggedUser(state = initialState, action: any) {
    if ( action.type === LOAD_LOGGED_USER_SUCCESS ) {
        return action.loggedUser
    }
    return state
}


// ACTIONS
export const loadLoggedUser = () => (dispatch: any) =>
    UserService.me()
        .then((user: User) => {
            dispatch(loadLoggedUserSuccess(user))
        })
        .catch(() => {
            dispatch(loadLoggedUserSuccess(null))
        })


const loadLoggedUserSuccess = (loggedUser: User | null) =>
    ({ type: LOAD_LOGGED_USER_SUCCESS, loggedUser })
