import React, { useEffect, useState } from 'react'
import { Container, SelectMenuButton, SelectMenuContainer } from './styles'
import WhitelabelTheme from '../whitelabelTheme'
import { FaPaintRoller } from 'react-icons/fa'
import { AiFillStar } from 'react-icons/ai'
import WhitelabelHighlights from '../whitelabelHighlights'




export default function Whitelabel() {

    const [visibleContent, setVisibleContent] = useState('')

    function renderContent() {
        switch (visibleContent) {
            case 'theme':
                return <WhitelabelTheme closeVisibleContent={() => setVisibleContent('')}></WhitelabelTheme>
                break;
            case 'highlights':
                return <WhitelabelHighlights closeVisibleContent={() => setVisibleContent('')}></WhitelabelHighlights>
                break;

            default:
                return <></>
                break;
        }
    }

    return (
        <Container>
            {visibleContent == '' ?
                <SelectMenuContainer>
                    <SelectMenuButton onClick={() => setVisibleContent('theme')}>
                        <section>
                            <FaPaintRoller></FaPaintRoller>
                            <h4>Tema</h4>
                        </section>
                        <p>Aqui você define a identidade visual do seu App!</p>


                    </SelectMenuButton>
                    <SelectMenuButton onClick={() => setVisibleContent('highlights')}>
                        <section>
                            <AiFillStar></AiFillStar>
                            <h4>Destaques</h4>
                        </section>
                        <p>Aqui você define os destaques que aparecerão na Home<br></br> do seu App!</p>
                    </SelectMenuButton>
                </SelectMenuContainer>
                : null}
            {renderContent()}
        </Container>
    )
}
