import React, { FunctionComponent } from 'react'
import Utils from '../../utils'
import { Modal } from '../../interfaces/modal'
import './index.scss'

interface Props {
    onCloseCallback: any,
    modal: Modal,
}

export const ModalComponent: FunctionComponent<Props> = ({ children, onCloseCallback, modal } : any) => {

    Utils.lockBody()

    const close = () => {
        Utils.unlockBody()
        onCloseCallback()
    }

    return (
        <div className="custom-modal">
            <div className="content">
                <div className="content-header">
                    { modal.title }
                    <button onClick={ () => close() } className="close"><i className="material-icons">close</i></button>
                </div>
                <div className="content-body">
                    { children }
                </div>
            </div>
        </div>
    )
}
