import { Datepicker } from 'components/datepicker'
import { FilterComponent } from 'components/filter'
import React, { FC, useContext, useEffect, useState } from 'react'
import { Input } from 'components/input'
import { Select } from 'components/select'
import CustomerService from 'services/CustomerService'
import DateService from 'services/DateService'
import SalesmanService from 'services/SalesmanService'
import { useLocation } from 'react-router-dom'
import { UserContext } from 'contexts/user'
import ButtonV3 from 'components/Buttonv3'
import './styles.scss'

import { AiOutlineCloseCircle, AiOutlineSearch } from 'react-icons/ai'
import InputV2 from 'components/InputV2'
import { SiMicrosoftexcel } from 'react-icons/si'
import { BaseModal } from 'components/BaseModal'
import DownloadExcelModal from '../downloadExcelModal'

interface Props {
    onChange?: any
    customerId?: number
    status_list?: any[]
    status?: string
    salesmanId?: number
    states?: any[]
    user?: any
}

export const OrdersFilterComponent: FC<Props> = ({ onChange }: Props) => {

    const endDate: Date = new Date()
    const initialDate: Date = DateService.removeDaysToDate(new Date(endDate), 10)
    const statuses = [{ label: 'Confirmado', value: 'C' }, { value: 'P', label: 'Não confirmado' }]
    const useQuery = () => new URLSearchParams(useLocation().search)
    const request_id = useQuery().get('request_id')
    const { user, can } = useContext(UserContext)
    const initialQueryParams = {
        page: 1,
        datainicial: request_id ? '' : DateService.formatToRequest(initialDate),
        datafinal: request_id ? '' : null,
        status: '',
        cliente: '',
        vendedor: '',
        numerocotacao: request_id || ''
    }

    const [params, setParams] = useState<any>(initialQueryParams)
    const [customers, setCustomers] = useState<any>([])
    const [salesmen, setSalesmen] = useState<any>([])
    const [isExcelModalVisible, setIsExcelModalVisible] = useState(false)

    const update = (params: any) => {
        setParams(params)
        onChange(params)
    }

    const clearFilters = () => {
        onChange(initialQueryParams)
    }

    const get_salesman_id = (val: any) => {
        return can('READ_ALL', 'REQUEST_ORDER') ? val : user?.id
    }

    useEffect(() => {
        if (user) {
            CustomerService.getAll(params.page).then(data => setCustomers(data.data))
            SalesmanService.getAll().then(data => setSalesmen(data))
        }
    }, [user, params])

    function handleOpenExcelDownloadModal() {

        setIsExcelModalVisible(true)
    }

    return (
        <div style={{ 'marginTop': 15 }}>
            {
                // < FilterComponent title = "Filtrar Solicitações"
                //  filter={() => update(params)}
                //  clear={clearFilters}
                //>
            }
            <div style={{
                'display': 'flex',
                'flexDirection': 'row',
                'flexWrap': 'wrap',
                'justifyContent': 'space-between',
                'alignItems': 'flex-end',
                'marginBottom': 10,
                gap: 15


            }}>
                <div className='containerDatePickerOrders'>
                    <div className={'datePickerContainerOrdersPage'} >
                        <Datepicker
                            callendarIcon
                            description="Data inicial"
                            value={params.datainicial}
                            onChange={(date: string) => setParams({ ...params, datainicial: date })}
                        />
                    </div>
                    <div className={'datePickerContainerOrdersPage'} >
                        <Datepicker
                            callendarIcon
                            value={params.datafinal}
                            description="Data final"
                            onChange={(date: string) => setParams({ ...params, datafinal: date })}
                        />
                    </div>
                </div>
                <div style={{
                    'height': 41,
                    'display': 'flex',
                    'flexDirection': 'row',
                    'marginBottom': 10,
                    'minWidth': 200,

                }} >

                    <div style={{
                        'height': 41,
                        'marginRight': 10

                    }} >
                        <ButtonV3
                            title='Baixar planilha'
                            Icon={<SiMicrosoftexcel className='iconButtonV3' />}
                            onClick={() => handleOpenExcelDownloadModal()}
                        />

                    </div>
                    <div style={{
                        'height': 41,
                        'marginRight': 10

                    }} >
                        <ButtonV3
                            Icon={<AiOutlineCloseCircle className='iconButtonV3' />}
                            title='Limpar filtros'
                            onClick={() => clearFilters()}
                        />

                    </div>
                    <div style={{
                        'height': 41,

                    }}>
                        <ButtonV3
                            filled
                            title='Filtrar'
                            onClick={() => update(params)}
                        />

                    </div>
                </div>

            </div>

            <div className='mainConteinerorderFilters' >
                <div className='ContaienrFilterOrdersPage'>
                    {
                        //   <Input
                        //       description="Buscar por Solicitação"
                        //       placeholder="Solicitação..."
                        //       value={params.numerocotacao}
                        //       onChange={(e: any) => setParams({ ...params, numerocotacao: e.target.value })}
                        //       mode="search"
                        //   />
                    }

                    <InputV2
                        infoIconNull
                        icon={<AiOutlineSearch color='#898989'></AiOutlineSearch>}
                        mask={undefined}

                        id={'Buscar por Solicitação'}
                        label={'Buscar por Cotação'}
                        value={params.numerocotacao}
                        type={'text'}
                        placeholder={'Número da cotação'}
                        onChange={(e: any) => setParams({ ...params, numerocotacao: e.target.value })}

                    />
                </div>

                <div className='ContaienrFilterOrdersPage'>
                    <Select
                        description="Cliente"
                        onChange={(e: any) => setParams({ ...params, cliente: e.target.value })}
                        options={customers}
                        blank_label="Selecione um cliente"
                        value_key="id"
                        label_key="name"
                    />
                </div>
                <div className='ContaienrFilterOrdersPage'>
                    <Select
                        description="Status"
                        value_key="value"
                        label_key="label"
                        blank_label="Selecione um status"
                        options={statuses}
                        onChange={(e: any) => setParams({ ...params, status: e.target.value })}>
                    </Select>
                </div>
                <div className='ContaienrFilterOrdersPage'>
                    <Select
                        value_key="id"
                        description="Vendedor"
                        label_key="name"
                        blank_label="Selecione um vendedor"
                        value={get_salesman_id(params.vendedor)}
                        disabled={!can('READ_ALL', 'REQUEST_ORDER')}
                        options={salesmen}
                        onChange={(e: any) => setParams({ ...params, vendedor: e.target.value })}>
                    </Select>
                </div>
            </div>

            {
                //  </FilterComponent >
            }
            <BaseModal
                // data={detailsOrder}
                title={`Gerar Planilha`}
                isVisible={isExcelModalVisible}
                onCloseCallback={() => { setIsExcelModalVisible(false) }}
                modalBodyStyle={{ 'height': 'unset', 'overflowY': 'visible' }}
                children={<DownloadExcelModal
                    // routes={routes}
                    data={{}}
                    // onUpdate={() => search()}
                    onCloseCallback={() => setIsExcelModalVisible(false)}
                />
                }
            />
        </div>

    )
}
