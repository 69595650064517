import ButtonV3 from 'components/Buttonv3';
import { Datepicker } from 'components/datepicker';
import IconReact from 'components/icons';
import InputV2 from 'components/InputV2';
import { Loader } from 'components/loader';
import NewPagination from 'components/newPagination';
import { PageHeaderV2 } from 'components/pageHeaderV2';
import { Pagination } from 'components/pagination';
import PaginationCustomV2 from 'components/PaginationCustomV2';
import { ProjectContainer } from 'components/project-container';
import { RoadMap } from 'components/roadmap';
import { Select } from 'components/select';
import { SelectV2 } from 'components/selectV2';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DateService from 'services/DateService';
import SalesmanService from 'services/SalesmanService';
import SalesReportService from 'services/salesReportService';
import { useReactToPrint } from 'react-to-print'
import Constants from "../../constants";

import {
    Header,
    BodyContainer,
    FiltersContainer,
    DatePickerAndButtonsContainer,
    SelectFiltersContainer,
    TableContainer,
    FilterButtonsContainer,
    DatePickersContainer
} from './styles';
import { PrintSalesReport } from './print';




export function SalesReport() {
    const initialQueryParams = {
        paginate: 'S',
        group: 'pf.pro_id',
        dataIni: null,
        dataFim: new Date(),
        dataPedido: null,
        dataEntrega: null,
        status: null,
        vendedor: null,
        cliente: null,
        tipoEntrega: null,

    }

    const [contentTableToPrint, setContentTableToPrint] = useState(initialQueryParams)
    const [queryParams, setQueryParams] = useState(initialQueryParams)
    const [params, setParams] = useState<any>(initialQueryParams)
    const [contentTable, setContentTable] = useState<any>([])
    const [paginationInfo, setPaginationInfo] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)
    const [salesmen, setSalesmen] = useState<any>([])

    const navigate = useNavigate()

    const componentRef = useRef(null)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const print = async () => {
        let formatedParams = {
            ...params,
            paginate: 'N',
            dataIni: params.dataIni ? DateService.formatToRequest(new Date(params.dataIni)) : null,
            dataFim: params.dataFim ? DateService.formatToRequest(new Date(params.dataFim)) : null,
            dataPedido: params.dataPedido ? DateService.formatToRequest(new Date(params.dataPedido)) : null,
            dataEntrega: params.dataEntrega ? DateService.formatToRequest(new Date(params.dataEntrega)) : null,
        }
        SalesReportService.getSalesReport(formatedParams).then(res => {
            setContentTableToPrint(res)
        }).then(res=>
            handlePrint && handlePrint()
        )

    }



    function RenderTable() {
        setIsLoading(true)
        let formatedParams = {
            ...params,
            dataIni: params.dataIni ? DateService.formatToRequest(new Date(params.dataIni)) : null,
            dataFim: params.dataFim ? DateService.formatToRequest(new Date(params.dataFim)) : null,
            dataPedido: params.dataPedido ? DateService.formatToRequest(new Date(params.dataPedido)) : null,
            dataEntrega: params.dataEntrega ? DateService.formatToRequest(new Date(params.dataEntrega)) : null,
        }

        SalesReportService.getSalesReport(formatedParams).then(res => {
            res.responseText ? (
                toast.info(res.responseText, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,

                }), setIsLoading(false)) : (
                setContentTable(res.data),
                setPaginationInfo({ ...res }),
                setIsLoading(false)
            )
        })


    }
    //const goToPage = (nextPage: number) =>
    //setQueryParams({ ...queryParams, page: nextPage })
    //
    useLayoutEffect(() => {
        SalesmanService.getAll().then((data) => setSalesmen(data))

    }, [])


    function handleChangePage(page) {
        setIsLoading(true)

        let formatedParams = {
            ...params,
            dataIni: DateService.formatToRequest(new Date(params.dataIni)),
            dataFim: DateService.formatToRequest(new Date(params.dataFim)),
            dataPedido: params.dataPedido ? DateService.formatToRequest(new Date(params.dataPedido)) : null,
            dataEntrega: params.dataEntrega ? DateService.formatToRequest(new Date(params.dataEntrega)) : null,
        }
        SalesReportService.getSalesReport(formatedParams, page)
            .then(res => {
                setPaginationInfo({ ...res })
                setContentTable(res.data)
                setIsLoading(false)
            })

    }




    return (

        <ProjectContainer>
            <RoadMap pageLinks={[
                {
                    title: 'Relatórios de vendas de produtos',
                    onclick: () => { }
                },
            ]}></RoadMap>
            <Header>
                <PageHeaderV2 title='Relatório de Vendas de Produto'></PageHeaderV2>

                {contentTable.length > 0 &&
                    <div>
                        <ButtonV3
                            style={{ height: '47px' }}
                            Icon={<IconReact type='printer' className='iconButtonV3' />}
                            title="Imprimir"
                            onClick={() => print()}
                        />

                    </div>
                }
            </Header>

            <BodyContainer>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable

                    pauseOnHover />
                <FiltersContainer>
                    <DatePickerAndButtonsContainer>
                        <DatePickersContainer>

                            <div style={{ width: 150 }}>
                                <Datepicker
                                    callendarIcon
                                    value={params.dataIni}
                                    description="Data Início"
                                    onChange={(date: string) => setParams({ ...params, dataIni: new Date(date) })}
                                />
                            </div>
                            <div style={{ width: 150 }}>
                                <Datepicker
                                    callendarIcon
                                    value={params.dataFim}
                                    description="Data Fim"
                                    // onChange={(date: any) => setParams({ ...params, dataFim:DateService.formatToRequest(new Date(date))})}
                                    onChange={(date: any) => setParams({ ...params, dataFim: new Date(date) })}

                                />

                            </div>
                            <div style={{ width: 150 }} >
                                <Datepicker
                                    callendarIcon
                                    value={params.dataEntrega}
                                    description="Data de Entrega"
                                    onChange={(date: string) => setParams({ ...params, dataEntrega: new Date(date) })}
                                />
                            </div>
                        </DatePickersContainer>
                        <FilterButtonsContainer>


                            <ButtonV3
                                style={{ height: '47px' }}
                                Icon={<IconReact type='closeCircle' className='iconButtonV3' />}
                                title="Limpar Filtro"
                                onClick={() => (setParams(initialQueryParams), setContentTable([]))}
                            />


                            <ButtonV3
                                style={{ height: '47px' }}
                                filled
                                Icon={<></>}
                                title="Visualizar"
                                onClick={() => RenderTable()}
                            />
                        </FilterButtonsContainer>
                    </DatePickerAndButtonsContainer>

                    <SelectFiltersContainer>
                        <div style={{ minWidth: 230 }}>
                            <InputV2
                                infoIconNull
                                icon={<IconReact type='search'></IconReact>}
                                mask={undefined}
                                id={'Buscar'}
                                label={'Cliente'}
                                value={params.cliente}
                                type={'text'}
                                placeholder={'Digite o nome do cliente'}
                                onChange={(e: any) => setParams({ ...params, cliente: e.target.value })}
                            />
                        </div>

                        <div style={{ minWidth: 180 }}>
                            <Select
                                value={params.vendedor}
                                description="Vendedor"
                                blank_label='Selecione um vendedor'
                                label_key="name"
                                value_key="id"
                                name="vendedor"
                                //register={}
                                onChange={(e: any) => setParams({ ...params, vendedor: e.target.value })}
                                options={salesmen}
                            />

                        </div >
                        <div style={{ minWidth: 180 }}>
                            <Select
                                value={params.tipoEntrega}
                                description="Tipo de Entrega"
                                blank_label='Selecione o tipo de entrega'
                                label_key="name"
                                value_key="value"
                                name="entrega"
                                //register={}
                                onChange={(e: any) => setParams({ ...params, tipoEntrega: e.target.value })}
                                options={[{ name: 'Entrega', value: 'ENTREGA' }, { name: 'Retirada', value: 'RETIRADA' }]}
                            />

                        </div>
                        <div style={{ minWidth: 150 }}>
                            <Select
                                value={params.status}
                                description="Status"
                                blank_label="Selecione o status"
                                label_key="name"
                                value_key="value"
                                name="status"
                                //register={}
                                onChange={(e: any) => setParams({ ...params, status: e.target.value })}
                                options={[
                                    { name: 'Confirmado', value: 'CONFIRMADO' },
                                    { name: 'Cancelado', value: 'CANCELADO' },
                                    { name: 'Aguardando Confirmação', value: 'AGUARDANDO_CONFIRMACAO' },
                                    { name: 'Iniciado', value: 'INICIADO' },
                                ]}
                            />

                        </div>
                        <div style={{ minWidth: 200 }}>
                            <Select
                                value={params.group}
                                description="Tipo de agrupamento"
                                blank_label='Selecione o agrupamento'
                                label_key="name"
                                value_key="value"
                                name="agrupamento"

                                //register={}
                                onChange={(e: any) => setParams({ ...params, group: e.target.value })}
                                options={[{ name: 'produto', value: 'pf.pro_id' }]}
                            />
                        </div>
                    </SelectFiltersContainer>
                </FiltersContainer>
                <TableContainer>

                    <div className="NewlistEditCampaign">

                        <table className="newTableEditCampaign">
                            <thead>
                                <tr>
                                    <th className="">Código</th>
                                    <th>Nome</th>
                                    <th className="text-center">Quantidade</th>
                                    <th className="text-center">Valor Total</th>
                                </tr>
                            </thead>
                            {isLoading ? <Loader /> :
                                <tbody>
                                    {
                                        contentTable.length > 0 && contentTable.map((i, index) => (
                                            <tr key={index}>
                                                <td style={{paddingLeft:8}} data-label="Código" className="">{i.pro_ean}</td>
                                                <td data-label="Nome" className="">{i.pro_descricao}</td>
                                                <td data-label="Quantidade" className="text-center">{i.qtd_prod}</td>
                                                <td data-label="Valor Total" className="text-center">{`${i.valor_total.toLocaleString('pt-BR', {
                                                    currency: 'BRL',
                                                    style: 'currency',
                                                    minimumFractionDigits: 2
                                                })}`}</td>


                                            </tr>
                                        ))
                                        // ))
                                    }
                                </tbody>}
                        </table>
                    </div>
                    {
                        contentTable.length > 0 && !isLoading &&
                        <div style={{ marginTop: 15 }}>
                            <NewPagination
                                handleSelectPage={(page) => { handleChangePage(page) }}
                                paginationData={paginationInfo}
                                paginationDataDescription={''}
                            />
                        </div>

                    }






                </TableContainer>
            </BodyContainer>
            {
                <div style={{ display: 'none' }} >
                    <PrintSalesReport salesmen={salesmen} params={params} contentTable={contentTableToPrint} ref={componentRef} />
                </div>

            }


        </ProjectContainer >
    );
}
