import permission_modules from './permission-modules'
export const LOGIN_RESULT = "LOGIN_RESULT"
export const USER_PERMS = "USER_PERMS"
export const LOGOUT = "LOGOUT"

export const initalState : any = {
    user: null,
    modules: permission_modules
}

export default function AuthReducer(state = initalState, action) {
    switch (action.type) {
    case "LOGIN_RESULT":
        return { ...state, user: action.user, token: action.auth_token }
    case "LOGOUT":
        return initalState
    case "USER_PERMS":
        return { ...state, loaded: true, perms: action.perms }
    default:
        return state
    }
}
