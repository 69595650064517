import AuthService from './AuthService'
import Constants from '../constants'

export default class ErrorHandleService {

  static handle = (error : any) => {
      if ( error.response && error.response.status === 401 && window.location.pathname !== Constants.ROUTE_SIGNIN) {
          AuthService.signOut()
          window.location.href = Constants.ROUTE_SIGNIN
      } else {
          throw error
      }
  }

}
