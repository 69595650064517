import './index.scss'

import * as $ from 'jquery'
import Swal from 'sweetalert2'

import { Link } from 'react-router-dom'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { RiAddCircleFill } from 'react-icons/ri'
import withReactContent from 'sweetalert2-react-content'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'
import Constants from '../../../../constants'
import { Button } from 'components/button'
import ModalComponent from 'components/modal-v2'
import ClientServiceV2 from 'services/v2/ClientService'
import ClientService from 'services/v3/clients.service'
import ProviderService from 'services/ProviderService'
import { mask_cnpj, mask_datetime } from 'helpers/mask.helper'
import RepresentativeService from 'services/RepresentativesService'
import { Select } from 'components/select'

import { AiFillCheckCircle, AiFillInfoCircle } from 'react-icons/ai'
import { BaseModal } from 'components/BaseModal'
import EditUserInfoForm from 'components/EditUserInfoForm'
import { Loader } from 'components/loader'

export const RegisterRequestsModal = ({
    isVisible,
    onCloseCallback,
    searchParams,
    onRegisterAmount,
    openEditUserInfoModal,
}: any) => {
    const MySwal = withReactContent(Swal)

    const navigate = useNavigate()

    const modal = {
        icon: <RiAddCircleFill />,
        styles: { maxWidth: '80vw' },
        id: 'register-requests-modal-mine',
        title: `Solicitações de cadastro`,
    }

    const [registers, setRegisters] = useState<any[]>([])
    const [isQuantumPao, setIsQuantumPao] = useState(false)
    const [representatives, setRepresentatives] = useState<any[]>([])
    const [cliId, setCliId] = useState<any>()
    const [loadingButton, setLoadingButton] = useState(Boolean)
    const [modalTitle, setModalTitle] = useState('Boolean')
    const [modalData, setModalData] = useState<any>()
    const [modalVisible, setModalVisible] = useState(Boolean)
    const [isSolicitation, setIsSolicitation] = useState(Boolean)

    const handleOpenModal = (data, isSolicitation) => {
        ClientService.saveCondition({
            store_id: data.lojas.split(',')[0],
            sol_id: data.sol_id,
            is_loja: data.tem_loja,
            vendedor_id: data.vend_id,
        }).then((resp) => {
            data.id = resp.loj_id

            searchParams(data)
            onCloseCallback()
            loadRegisters()
        })

        // setModalTitle('Usuário da venda direta');
        // setModalData(data);
        // setIsSolicitation(isSolicitation);
        // setModalVisible(true);
    }

    useEffect(() => {
        loadRegisters()
    }, [])

    useEffect(() => {
        if (isVisible) {
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
            $('#' + modal.id).modal('show')
        } else {
            $('#' + modal.id).removeClass('fadeIn')
            $('#' + modal.id).addClass('fadeOutUp')
            $('#' + modal.id).modal('hide')
        }
    }, [isVisible])

    function notify(text) {
        toast.info(text, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
        })
    }

    const loadRegisters = () => {
        ProviderService.getLoggedProvider().then((data) => {
            const { revendaId, login } = data.provider

            setCliId(data.provider.id)

            const isQuantumPao = login === 'QUANTUMPAO'
            setIsQuantumPao(isQuantumPao)

            RepresentativeService.getAll({ page: 1, activeFilter: true }).then(
                (response) => {
                    const representatives = response.list.data
                    setRepresentatives(representatives)

                    ClientServiceV2.pendent_registers(revendaId)
                        .then((response) => {
                            const result = response

                            setRegisters(result)
                            onRegisterAmount(result.length)
                        })
                        .then((res) => setLoadingButton(false))
                }
            )
        })
    }

    const onRemove = (register: any) => {
        MySwal.fire({
            title: (
                <div>
                    <p>Você irá excluir este registro. Deseja continuar?</p>
                    <hr />
                </div>
            ),
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: 'red',
        }).then((res) => {
            if (res.isConfirmed) {
                ClientServiceV2.deleteRegisterRequest(register.sol_id).then(
                    loadRegisters
                )

                return MySwal.fire(<p>Registro excluído com sucesso!</p>)
            }
        })
    }

    const onChooseSeller = async (sol_id: string, vend_id: string) => {
        setLoadingButton(true)
        await ClientServiceV2.associate_solicitation({ sol_id, vend_id }).then(
            loadRegisters
        )
    }

    function handleGoToRegister(id) {
        navigate(`${Constants.ROUTE_CLIENTS}/register/${id}`)
    }

    return (
        <ModalComponent
            modal={modal}
            show={isVisible}
            close={onCloseCallback}
            className={'modal-dialog'}
        >
            {loadingButton ? <Loader /> : null}
            <div className="container-fluid">
                {
                    // <div className="col-lg-12" >
                    //    <Link to={ `${Constants.ROUTE_CLIENTS}/register` }>
                    //    <Button
                    //            type="button"
                    //            className="c-btn-save" >
                    //                Cadastrar
                    //    </Button>
                    //    </Link>
                    // </div>
                }
                <div className="row header">
                    <div className="col m-4 p-3">
                        <table className={`table`}>
                            <thead>
                                <tr>
                                    <th>Data de solicitação</th>
                                    <th>CNPJ</th>
                                    <th></th>
                                    <th>Email</th>
                                    <th>Telefone</th>
                                    <th>Contato</th>
                                    <th>Vendedor</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {registers.map((r) => (
                                    <tr key={r.sol_id}>
                                        <td>{mask_datetime(r.sol_criacao)}</td>
                                        <td>
                                            {r.sol_cnpj
                                                ? mask_cnpj(r.sol_cnpj)
                                                : ''}
                                        </td>
                                        <td className="tdIconCNPJ">
                                            {r.tem_loja > 0 ? (
                                                <AiFillCheckCircle
                                                    title="CNPJ cadastrado."
                                                    size={15}
                                                    style={{
                                                        marginRight: 3,
                                                        marginBottom: 2,
                                                        color: '#00A65A',
                                                    }}
                                                />
                                            ) : (
                                                <AiFillInfoCircle
                                                    title="Cadastro pendente."
                                                    size={15}
                                                    style={{
                                                        marginRight: 3,
                                                        marginBottom: 2,
                                                        color: '#F5222D',
                                                        display: 'inline',
                                                    }}
                                                />
                                            )}
                                        </td>
                                        <td>{r.sol_email}</td>
                                        <td>{r.sol_telefone}</td>
                                        <td>{r.sol_contato}</td>
                                        <td>
                                            <Select
                                                value_key="id"
                                                label_key="name"
                                                options={representatives}
                                                defaultValue={r.vend_id}
                                                blank_label="Selecione um vendedor"
                                                onChange={(e: any) =>
                                                    onChooseSeller(
                                                        r.sol_id,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </td>
                                        <td className="actions">
                                            {r.sol_excluido ? (
                                                <div className="status inactive">
                                                    Excluído
                                                </div>
                                            ) : (
                                                <React.Fragment>
                                                    <ToastContainer
                                                        position="top-right"
                                                        autoClose={3000}
                                                        hideProgressBar={false}
                                                        newestOnTop={false}
                                                        closeOnClick
                                                        rtl={false}
                                                        pauseOnFocusLoss
                                                        draggable
                                                        pauseOnHover
                                                    />
                                                    {
                                                        <Button
                                                            type="button"
                                                            disabled={
                                                                loadingButton
                                                            }
                                                            className={
                                                                r.vend_id ==
                                                                null
                                                                    ? 'disabledButtonSave'
                                                                    : 'c-btn-save'
                                                            }
                                                            //onClick={()=>notify('selecione um vendedor')}
                                                            onClick={
                                                                //() => <Link to={`${Constants.ROUTE_CLIENTS}/register/${r.sol_id}`} />
                                                                () =>
                                                                    r.vend_id ==
                                                                    null
                                                                        ? notify(
                                                                              'selecione um vendedor'
                                                                          )
                                                                        : r.tem_loja >
                                                                          0
                                                                        ? handleOpenModal(
                                                                              r,
                                                                              true

                                                                              // handleOpenModal(
                                                                              //     {
                                                                              //         id: cliId,
                                                                              //         cnpj: r.sol_cnpj,
                                                                              //         rep: representatives,
                                                                              //         vendId: r.vend_id,
                                                                              //         solID: r.sol_id
                                                                              //     }, true,
                                                                          )
                                                                        : handleGoToRegister(
                                                                              r.sol_id
                                                                          )
                                                            }
                                                        >
                                                            Cadastrar
                                                        </Button>
                                                    }
                                                    {
                                                        //       <Link
                                                        //           to={`${Constants.ROUTE_CLIENTS}/register/${r.sol_id}`}
                                                        //       >
                                                        //           <Button
                                                        //
                                                        //               type="button"
                                                        //               className="c-btn-save"
                                                        //           >
                                                        //               Cadastrar
                                                        //           </Button>
                                                        //       </Link>
                                                    }
                                                    <Button
                                                        type="button"
                                                        className="c-btn-remove"
                                                        onClick={() =>
                                                            onRemove(r)
                                                        }
                                                    >
                                                        Excluir
                                                    </Button>
                                                </React.Fragment>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <BaseModal
                    data={modalData}
                    title={modalTitle}
                    isVisible={modalVisible}
                    onCloseCallback={() => {
                        setModalVisible(false), loadRegisters()
                    }}
                    //children={<EditUserInfoForm data={modalData} isSolocitation={true} />}
                    children={
                        <EditUserInfoForm
                            data={modalData}
                            isSolocitation={isSolicitation}
                        />
                    }
                />
            </div>
        </ModalComponent>
    )
}
