
function isPrecision(numero) {
    var stringfied = numero.toString();
    if (stringfied.indexOf('.') !== -1) {
        var partes = stringfied.split('.');
        if (partes[1].length > 2) {
            return true;
        }
    }
    return false;
}

const f = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
})
const f2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 4
})

export default (val: any) => {

    return val ? isPrecision(val) ? f2.format(val) : f.format(val) : f.format(0)
}

