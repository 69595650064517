import { combineReducers } from 'redux'
import loggedUser from './user'
import directSaleOrders from './directSaleOrder'
import orders from './orders'
import requests from './requests'
import customers from './customer'
import products from './products'
import salesmen from './salesman'
import representatives from './representatives'
import editProvider from './providers'
import payments from './payments'
import routes from './routes'
import filters from './filters'
import { mobileReducer } from './Utility';
import { AuthReducer } from '../contexts/user'


const rootReducer = combineReducers({
    mobileReducer,
    loggedUser,
    directSaleOrders,
    orders,
    requests,
    products,
    customers,
    salesmen,
    editProvider,
    representatives,
    payments,
    routes,
    filters,
    AuthReducer,


})

export default rootReducer
