import React from 'react';
import { Label } from '../label';
import './index.scss';

export function CurrencySafari({ mode, className, register, ...props }: any) {


    function handleChange(value) {
        // console.table({
        //     value: props.value,
        //     valueInput: value
        // })
        let number = value.replace(/[^\d,\.]/g, '');
        number = number.replace(/(,.*?),/g, '$1');

        if (number.charAt(0) === ',' || number.charAt(0) === '.') {
            number = '0' + number.slice(1);
        }

        if (number.length === 2 && number.charAt(0) == '0') {
            if (number.charAt(1) != ',' && number.charAt(1) != '.') {

                number = number.slice(1);

            }

        }

        let floatValue = number.replace(',', '.');
        let splited = floatValue.split('.')[1]

        // console.log('splited .....', splited)
        if (splited && splited.length >= 3) {
            // console.log('splited if--------------')
            floatValue = floatValue.slice(0, -1)
            // console.log('floatValue --------------', floatValue)
        }
        // if ((floatValue.charAt(number.length - 1) != '.' && floatValue.charAt(number.length - 1) != '0') || splited && splited.charAt(1)) {
        //     if(splited && splited.charAt(1) =='0'){
        //         console.log('splt if')
        //         console.log('splt if', floatValue)
        //         // floatValue = Number(parseFloat(floatValue).toFixed(2));
        //         console.log('spltfinal', floatValue)
        //     }else{

        //         // floatValue = parseFloat(floatValue);
        //     }
        //     // floatValue = parseFloat(floatValue);
        //     console.log('parsefloat --->', floatValue)
        // }

        if (isNaN(floatValue)) {
            floatValue = 0;
        }
        // console.table({
        //     float: floatValue
        // })
        // console.log('para on change ---->', floatValue)
        props.onChange(value, floatValue);
    }
    // function handleChange(value) {
    //     console.table({
    //         value: props.value,
    //         valueInput: value
    //     })
    //     let number = value.replace(/[^\d,\.]/g, '');
    //     number = number.replace(/(,.*?),/g, '$1');

    //     if (number.charAt(0) === ',' || number.charAt(0) === '.') {
    //         number = '0' + number.slice(1);
    //     }

    //     if (number.length === 2 && number.charAt(0) == '0') {
    //         if (number.charAt(1) != ',' && number.charAt(1) != '.') {

    //             number = number.slice(1);

    //         }

    //     }

    //     let floatValue = number.replace(',', '.');
    //     let splited = floatValue.split('.')[1]

    //     console.log('splited .....', splited)
    //     if ((floatValue.charAt(number.length - 1) != '.' && floatValue.charAt(number.length - 1) != '0') || splited && splited.charAt(1)) {
    //         if(splited && splited.charAt(1) =='0'){
    //             console.log('splt if')
    //             console.log('splt if', floatValue)
    //             floatValue = Number(parseFloat(floatValue).toFixed(2));
    //             console.log('spltfinal', floatValue)
    //         }else{

    //             floatValue = parseFloat(floatValue);
    //         }
    //         // floatValue = parseFloat(floatValue);
    //         console.log('parsefloat --->', floatValue)
    //     }

    //     if (isNaN(floatValue)) {
    //         floatValue = 0;
    //     }
    //     console.table({
    //         float: floatValue
    //     })
    //     console.log('para on change ---->', floatValue)
    //     props.onChange(value, floatValue);
    // }

    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
            {props.description && mode !== 'label' && <Label className="c-label">{props.description}</Label>}
            <input
                style={{ paddingLeft: 10 }}
                value={props.value ? 'R$ ' + props.value : 'R$ ' + 0}
                name={props.name}
                id={props.id}
                onFocus={props.onFocus}
                disabled={props.disabled}
                type='text'
                onKeyDown={props.onKeyDown}
                className={`currencyInput removeChevron form-control ${className} ${mode === 'label' ? 'as-label' : ''}`}
                // placeholder="R$"

                onBlur={props.onBlur}

                onChange={(e) => handleChange(e.target.value)}
                ref={register ?? null}
                prefix="R$ "
            />
            {/* <p className='currencyInputSymbol'>R$</p> */}
        </div>
    );
}

// import React, { FC } from 'react'
// import CurrencyInput from 'react-currency-input-field'
// import { Label } from '../label'
// import './index.scss'

// export function CurrencySafari({ mode, className, register, ...props }: any) {
//     const intl = { "locale": "pt-BR", "currency": "BRL" }

//     function handleChange(value, name, values) {
//         console.log('1', value)
//         console.log(values)

//         props.onChange(values.formated, values.float)
//     }

//     return (
//         <div style={{ 'display': 'flex', 'flexDirection': 'column', 'border': '1px solid red' }}>
//             {props.description && mode !== 'label' && <Label className="c-label">{props.description}</Label>}
//             <CurrencyInput
//                 value={props.value}
//                 name='currency'
//                 intlConfig={intl}
//                 className={`currencyInput form-control ${className} ${mode === 'label' ? 'as-label' : ''}`}
//                 placeholder="R$ ..."
//                 // decimalSeparator="."
//                 decimalScale={2}
//                 onValueChange={handleChange}
//                 ref={register}
//                 prefix="R$ "

//             />
//         </div>
//     )
// }
