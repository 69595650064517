import React, { useContext, useEffect, useState } from 'react'
import { Container } from './styles'
import ButtonV3 from 'components/Buttonv3'
import { AiOutlineAlert, AiOutlineCiCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import InputV2 from 'components/InputV2'
import { MdEdit } from 'react-icons/md'
import { DirectSaleProduct } from 'interfaces/directSaleProduct'
import ModalImage from 'react-modal-image'
import { connect, useSelector } from 'react-redux'
import { loadProducts } from 'store/products'
import ProductService from 'services/ProductService'
import styles from '../../../../../products/index.module.scss'
import { UserContext } from 'contexts/user'
import { PaginationCustom } from 'components/pagination-custom'

const SelectProductModal = ({ products, findProducts, handleSelectProduct, closeModal, SelectedProduct }) => {
    // export default function SelectProductModal() {
    const [group, setGroup] = useState<any>()
    const [error, setError] = useState('')
    const [filter, setFilter] = useState('')
    const [allPages, setAllPages] = useState(1)
    const [loading, setLoading] = useState(true)
    const [loadingTable, setLoadingTable] = useState(false)
    const [isGroupFilterDisabled, setDisableGroupFilter] = useState(true)
    const [groups, setGroups] = useState<any[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const [productsItems, setProductsItems] = useState([])
    const [product, setProduct] = useState<DirectSaleProduct>()
    const [loadClear, setLoadClear] = useState(false)
    const [brandsModalVisible, setBrandsModalVisible] = useState(false)
    const [selectedItem, setSelectedItem] = useState<any>()
    const [idFilter, setIdFilter] = useState('')
    const { user, can } = useContext(UserContext)


    useEffect(() => {

        setLoading(false)

        products && products.produtos.length > 0 &&
            setProductsItems(products.produtos)

    }, [products])

    useEffect(() => {
        if (products) {

            products && products.produtos.length > 0 &&
                setProductsItems(products.produtos)


        }
    }, [])
    useEffect(() => {

        if (user) {
            setLoading(true)
            setGroup(user.groupId)
            setDisableGroupFilter(!!user.groupId)

            findProducts(user.providerId, { group: user.groupId })
            ProductService.groups(user.providerId).then((result) => setGroups(result.groups))

        }
    }, [user, findProducts])

    useEffect(() => {

        if (user) {
            setLoading(true)
            setGroup(user.groupId)
            setDisableGroupFilter(!!user.groupId)

            findProducts(user.providerId, { group: user.groupId })
            ProductService.groups(user.providerId).then((result) => setGroups(result.groups))

        }
    }, [])
    function handlePageChange(page) {
        setLoading(true)

        checkFilters(page)

    }
    function cleanFilters() {
        setFilter('')
        setIdFilter('')
        setGroup(1)
        setLoadClear(!loadClear)
    }
    useEffect(() => {
        checkFilters()
    }, [loadClear])
    function checkFilters(page?) {
        let grupo = group == 1 ? undefined : group
        if (user) {
            findProducts(user.providerId, { product_name: filter, group: grupo, pro_ean: idFilter, page: page })
        }
    }

    function selectProd(item) {

        handleSelectProduct(item)
        closeModal()
    }

    return (
        <Container>

            <section className='sectionProductsFiltes'>

                <div style={{

                    'display': 'flex',
                    'flexDirection': 'row',
                    'alignItems': 'flex-end',
                    'justifyContent': 'space-between',
                    'width': '100%',
                    'flexWrap': 'wrap',
                    gap: 20

                }} >
                    <div style={{
                        'display': 'flex',
                        'flexDirection': 'row',
                        'alignItems': 'flex-end',
                        'flexWrap': 'wrap',
                        gap: 15
                    }} >


                        <div className='inputItemProductPage' >
                            <InputV2
                                infoIconNull
                                icon={<MdEdit />}
                                mask={undefined}

                                id={'Filtrar por nome'}
                                label={'Filtrar por nome'}
                                // value={filter}
                                value={undefined}
                                type={'text'}
                                placeholder={'Nome do produto'}
                                onChange={(e) => { setFilter(e.target.value) }}

                            />
                        </div>
                        <div className='inputItemIdFilter'  >
                            <InputV2
                                infoIconNull
                                icon={<MdEdit />}
                                mask={undefined}

                                id={'Filtrar por ID'}
                                label={'Filtrar por ID'}
                                // value={idFilter}
                                value={undefined}
                                type={'text'}
                                placeholder={'Código'}
                                onChange={(e) => { setIdFilter(e.target.value) }}

                            />
                        </div>

                        <div className="containerSelectProductPage">
                            <div className={'labelContainer'}>
                                <label style={{ marginBottom: 4 }} id={'123'} className="label" >Filtrar por grupo </label>
                            </div>
                            <div className="selectContainer">
                                <select
                                    name="select"
                                    className="inputSelect"
                                    onChange={(e: any) => setGroup(e?.target?.value)}
                                >
                                    <option value={1} >Selecione um grupo</option>
                                    {
                                        groups && groups.map(item => {
                                            return (
                                                <option selected={group == item.id} value={item.id}>{item.name}</option>
                                            )
                                        }
                                        )
                                    }
                                </select>
                            </div>
                        </div>

                    </div>
                    <div style={{
                        'display': 'flex',
                        'flexDirection': 'row',
                        'minWidth': '25%',
                        //'marginTop': 15,
                        'justifyContent': 'flex-end',
                        gap: 20



                    }}>
                        <div style={{
                            //'marginRight': 20
                        }}>
                            <ButtonV3
                                title='Limpar Filtro'
                                Icon={<AiOutlineCloseCircle className='iconButtonV3' />}
                                onClick={() => { cleanFilters() }}
                            />
                        </div>
                        <div >
                            <ButtonV3
                                title='Filtrar'
                                filled
                                onClick={() => { checkFilters() }}
                            />
                        </div>
                    </div>
                </div>

            </section>
            <div className="NewlistProducts">
                <table className={'newTableProducts'}>
                    <thead>
                        <tr>
                            <th>Imagem</th>
                            <th>Código</th>
                            <th>Descrição</th>
                            <th>Embalagem</th>
                            <th>Qtd. Embalagem</th>
                            <th>Limite diário</th>
                            <th>Estoque</th>
                            <th className="actions">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productsItems?.length > 0 && productsItems.map((item: DirectSaleProduct) => (
                            <tr key={item.id}>
                                <td data-label='Imagem'>
                                    <ModalImage
                                        small={item.picture || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                                        large={item.picture || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                                        className={`${styles.imageProduct}`}

                                        alt={`Produto: ${item.description}`}
                                        hideZoom={true}
                                        showRotate={true}
                                    />
                                </td>
                                <td data-label='Código'>{item.ean}</td>
                                <td data-label='Descrição'>{item.description}</td>
                                <td data-label='Embalagem'>{item.packing.split(' ')[0]}</td>
                                <td data-label='Qtd. Embalagem'>{item.packingQuantity}</td>
                                <td data-label='Limite diário'>{item.dailyLimit ? item.dailyLimit : '--'}</td>
                                <td data-label='Estoque'>{item.stock ? item.stock : '--'}</td>
                                <td data-label='Ações'>
                                    <ButtonV3
                                    style={{height:30}}
                                        filled={SelectedProduct && SelectedProduct.ean == item.ean ? false : true}
                                        title={SelectedProduct && SelectedProduct.ean == item.ean ? 'Selecionado' : 'Selecionar'}
                                        onClick={() => selectProd(item)}
                                    ></ButtonV3>

                                    {/* <div
                                        className={'listActionsProducts'}
                                    >
                                        {can('UPDATE', 'PRODUCT') &&
                                            <div
                                            //className={styles.tooltip}
                                            >
                                                <Link to={`${Constants.ROUTE_PRODUCTS_EDIT}${item.id}`}>
                                                    <MdOutlineEdit
                                                        title='Editar Produto'
                                                        style={{
                                                            'color': '#606060',
                                                            'fontSize': 24
                                                        }} />

                                                </Link>
                                                {
                                                    //  <span className={styles.tooltiptext}>Editar Produto</span>
                                                }
                                            </div>}
                                        {can('PRICING', 'PRODUCT') && <div className={styles.tooltip}>
                                            <Link to={`${Constants.ROUTE_PRODUCTS_EDIT_PRICE_LEVEL}${item.id}`}>
                                                <AiOutlineDollar
                                                    title='Níveis de preço'
                                                    style={{
                                                        'color': '#30AA4C',
                                                        'fontSize': 24
                                                    }} />
                                            </Link>
                                            {
                                                //  <span className={styles.tooltiptext}>Níveis de preço</span>
                                            }
                                        </div>}
                                        {can('MANAGE_GROUPS', 'PRODUCT') && <div className={styles.tooltip}>
                                            <AiOutlineUnorderedList
                                                title={'Grupo de produtos'}
                                                onClick={() => openAddProductToGroupModal(item)}
                                                style={{
                                                    'color': '#17A2B8',
                                                    'fontSize': 24,
                                                    'cursor': 'pointer'
                                                }} />
                                            {
                                                //   <span className={styles.tooltiptext}>Grupo de produtos</span>
                                            }
                                        </div>}
                                        {can('DELETE', 'PRODUCT') && <div className={styles.tooltip}>
                                            <VscTrash
                                                title='Excluir Produto'
                                                onClick={() => confirm(item)}
                                                style={{
                                                    'cursor': 'pointer',
                                                    'color': '#CD2A2A',
                                                    'fontSize': 24
                                                }} />

                                            {
                                                //  <span className={styles.tooltiptext}>Excluir Produto</span>
                                            }
                                        </div>}
                                        {can('ACTIVATE_DEACTIVATE', 'PRODUCT') && <div className={styles.tooltip}>
                                            <ToggleButton

                                                trackStyle={{}}
                                                inactiveLabel={<p className='switchButtonLabel'>Inativo</p>}

                                                activeLabel={<p className='switchButtonLabel'>Ativo</p>}
                                                colors={{
                                                    activeThumb: {
                                                        base: 'white',
                                                    },
                                                    inactiveThumb: {
                                                        base: 'white',
                                                    },
                                                    active: {
                                                        base: '#FF7110',
                                                        hover: '#FF7110',
                                                    },
                                                    inactive: {
                                                        base: '#e2e2e2',
                                                        hover: '#d9d9d9',
                                                    }
                                                }}
                                                value={item.status}
                                                onToggle={() => {
                                                    update_status(item)
                                                }} />
                                            {

                                                //    <span
                                                //        className={styles.tooltiptext}>
                                                //        {item.status ? 'Desativar Produto' : 'Ativar Produto'}
                                                //    </span>
                                            }
                                        </div>}
                                    </div> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {products ?

                    <PaginationCustom
                        total={products.last_page}
                        itemsName={'produtos'}
                        ItemsPorPage={products.per_page}
                        currentPage={products.current_page}
                        onPageChange={handlePageChange}
                        totalItems={products.total}
                    />
                    : null}
            </div>



        </Container>
    )
}
const mapStateToProps = (state: any) => ({
    products: state.products,
})

const mapDispatchToProps = (dispatch: any) => ({
    findProducts: (providerId: string, filters) => dispatch(loadProducts(providerId, filters))
})



export default connect(mapStateToProps, mapDispatchToProps)(SelectProductModal)
