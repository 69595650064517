import { City } from '../interfaces/city'

export default class CityService {

  static parse = (cityFromApi: any) : City => ({
      id: cityFromApi?.cid_id,
      name: cityFromApi?.cid_nome,
      stateId: cityFromApi?.est_id ? cityFromApi?.est_id : '',
  })
}
