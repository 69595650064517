import React, { FC } from 'react'
import styles from './index.module.scss'
import { mask_currency } from 'helpers/mask.helper'

export const CardComponent: FC<any> = ({ data, icon, color }: any) => {

    return (
        <div className={ `${styles.card} shadow row` } style={ { background: color } }>
            <div className={ `${styles.content} col` }>
                { data.map((d: any, idx: number) => {
                    return <div key={ idx } className={ styles.info }>
                        <span>{ d.title }</span>
                        <span className={ styles.value }>{ d.int ? d.value : mask_currency(d.value) }</span>
                    </div> })
                }
            </div>
            <div className={ `${styles.icon}` }>
                { icon }
            </div>
        </div>
    )
}
