import React, { FC } from 'react'
import './index.scss'

export const SelectV2: FC<any> = ({ blank_label, register, options, description, ...props }: any) => (
    <div
    style={{'display':'flex', 'flexDirection':'column'}}

    >
        { description && <label className="selectLabel">{ description }</label> }
        <select className="selectV2" ref={ register } { ...props } >
            { blank_label && <option value="">{ blank_label }</option> }
            { options && options.map((opt : any) => (
                opt[props.value_key] == props.defaultValue
                    ? <option key={ opt[props.value_key] } value={ opt[props.value_key] || '' } selected>
                        { opt[props.label_key]}
                    </option>
                    : <option key={ opt[props.value_key] } value={ opt[props.value_key] || '' }>
                        { opt[props.label_key]}
                    </option>
            )) }
        </select>
    </div>
)

