import React, { useContext, useEffect, useState } from 'react'
import { ProjectContainer } from '../../components/project-container'
import './index.scss'
import { PageHeader } from '../../components/page-header'
import { ReportService } from 'services/v2/reports.service'
import SalesHighchartModel from 'models/sales-highchart.model'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { mask_currency } from 'helpers/mask.helper'
import { CardComponent } from './components/card'
import { FaFileInvoiceDollar, FaStoreAlt } from 'react-icons/fa'
import { GiShoppingCart } from 'react-icons/gi'
import { UserContext } from 'contexts/user'
import { RoadMap } from 'components/roadmap'
import { PageHeaderV2 } from 'components/pageHeaderV2'

const DashboardPage = () => {

    const [ loading, setLoading ] = useState<boolean>(true)
    const [ sales_graph, setSalesGraph ] = useState<SalesHighchartModel>()
    const [ report_data, setReportData ] = useState<any>([])
    const [ top_sellers, setTopSellers ] = useState<any>([])
    const [ top_clients, setTopClients ] = useState<any>([])
    const [ current_month_sales_sum, setCurrentMonthSalesSum ] = useState<any>()
    const [ previous_month_sales_sum, setPreviousMonthSalesSum ] = useState<any>()
    const [ today_sales_sum, setTodaySalesSum ] = useState<any>()
    const [ clients_data, setClientsData ] = useState<any>()
    const [ yesterday_sales_sum, setYesterdaySalesSum ] = useState<any>()
    const { user, can } = useContext(UserContext)
    const months = [
        "Janeiro", "Fevereiro", "Março", "Abril",
        "Maio", "Junho", "Julho", "Agosto",
        "Setembro", "Outubro", "Novembro", "Dezembro"
    ]

    useEffect(() => {
        if(user) {
            const params = can('READ_ALL', 'REPORT') ? null : { seller_id: user.id }
            ReportService.sales(params).then(response => {
                setSalesGraph(new SalesHighchartModel(response.data))
                setLoading(false)
                setReportData(response.data)
            })
            ReportService.client_companies(user.store?.loj_estado).then(response => {

                setClientsData(response.data)
            })
        }
    }, [ user, can ])

    useEffect(() => {
        const map = new Map()
        let current : any = null
        report_data
            .filter((sell: any) => new Date(sell.data_geracao).getFullYear() == new Date().getFullYear())
            .filter((sell: any) => new Date(sell.data_geracao).getMonth() == new Date().getMonth())
            .forEach((sell: any) => {
                current = map.get(sell.id_vendedor)
                if(!current) {
                    current = { ...sell }
                    current.total = sell.valor_pedido
                    map.set(sell.id_vendedor, current)
                } else {
                    current.total += current.valor_pedido || 0
                    map.set(sell.id_vendedor, current)
                }
            })
        const result : any[] = []
        map.forEach((v) => result.push(v))
        setTopSellers(sort(result).slice(0, 10))
    }, [ report_data ])

    useEffect(() => {
        const map = new Map()
        let current : any = null
        report_data
            .filter((sell: any) => new Date(sell.data_geracao).getFullYear() == new Date().getFullYear())
            .filter((sell: any) => new Date(sell.data_geracao).getMonth() == new Date().getMonth())
            .forEach((sell: any) => {
                current = map.get(sell.cli_id)
                if(!current) {
                    current = { ...sell }
                    current.total = sell.valor_pedido
                    map.set(sell.cli_id, current)
                } else {
                    current.total += sell.valor_pedido
                    map.set(sell.cli_id, current)
                }
            })
        const result : any[] = []
        map.forEach((v) => result.push(v))
        setTopClients(sort(result).slice(0, 10))
    }, [ report_data ])

    useEffect(() => {
        const result = report_data
            .filter((sell: any) => new Date(sell.data_geracao).getFullYear() == new Date().getFullYear())
            .filter((sell: any) => new Date(sell.data_geracao).getMonth() == new Date().getMonth())
            .reduce((accumulator: number, { valor_pedido }) => (accumulator + valor_pedido), 0)

        setCurrentMonthSalesSum(result)
    }, [ report_data ])


    useEffect(() => {
        const result = report_data
            .filter((sell: any) => new Date(sell.data_geracao).getFullYear() == new Date().getFullYear())
            .filter((sell: any) => new Date(sell.data_geracao).getMonth() == new Date().getMonth()-1)
            .reduce((accumulator: number, { valor_pedido }) => (accumulator + valor_pedido), 0)
        setPreviousMonthSalesSum(result)
    }, [ report_data ])


    useEffect(() => {
        const result = report_data
            .filter((sell: any) => new Date(sell.data_geracao).getFullYear() == new Date().getFullYear())
            .filter((sell: any) => new Date(sell.data_geracao).getMonth() == new Date().getMonth())
        const todays = result
            .filter((sell: any) => new Date(sell.data_geracao).getDate() == new Date().getDate())
            .reduce((accumulator: number, { valor_pedido }) => (accumulator + valor_pedido), 0)
        const yesterdays = result
            .filter((sell: any) => new Date(sell.data_geracao).getDate() == new Date().getDate()-1)
            .reduce((accumulator: number, { valor_pedido }) => (accumulator + valor_pedido), 0)

        setTodaySalesSum(todays)
        setYesterdaySalesSum(yesterdays)
    }, [ report_data ])


    const sort = (collection: any) => {
        return collection.sort((a: any, b: any) => {
            const total1 = a.total
            const total2 = b.total
            return total2-total1
        })
    }

    return (

        <ProjectContainer loading={ loading } className="dashboad-container">
               <RoadMap
                pageLinks={[
                    {
                        title: 'Dashboard',
                        onclick: () => { }
                    },
                ]}
            ></RoadMap>
            <header>
                <PageHeaderV2 title="Dashboard" />
            </header>
            <div className="row">
                <div className="col">
                    <CardComponent
                        color={ '#0070a6' }
                        icon={ <FaStoreAlt /> }
                        data={ [
                            { title: `Empresas`, value: clients_data?.available_stores, int: true },
                            {
                                title: `Em Atendimento`,
                                value: `${clients_data?.atending_stores}(${((clients_data?.atending_stores/clients_data?.available_stores)*100).toFixed(2)}%)`,
                                int: true
                            }
                        ] } />
                </div>
                <div className="col">
                    <CardComponent
                        color={ '#00a65a' }
                        icon={ <FaFileInvoiceDollar /> }
                        data={ [
                            { title: `Faturamento ${months[new Date().getMonth()]}`, value: current_month_sales_sum },
                            { title: `Faturamento ${months[new Date().getMonth()-1]}`, value: previous_month_sales_sum }
                        ] } />
                </div>
                <div className="col">
                    <CardComponent
                        color={ '#ff7110' }
                        icon={ <GiShoppingCart /> }
                        data={ [
                            { title: `Total Pedidos Hoje`, value: today_sales_sum },
                            { title: `Total Pedidos Ontem`, value: yesterday_sales_sum }
                        ] } />
                </div>
            </div>
            <div className="row mt-4">
                <HighchartsReact
                    highcharts={ Highcharts }
                    options={ sales_graph }
                />
            </div>
            <div className="row dashboad-container">
                <div className="col">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Ranking Vendedores { months[new Date().getMonth()] }</th>
                            </tr>
                        </thead>
                        <tbody>
                            { top_sellers && top_sellers.map((sell: any) => <tr key={ sell.id_vendedor }>
                                <td className="pl-2">
                                    <div className="ranking-user">
                                        <span>{ sell.usu_nome }</span>
                                        <span>{ mask_currency(sell.total) }</span>
                                    </div>
                                </td>
                            </tr>) }
                        </tbody>
                    </table>
                </div>
                <div className="col">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Ranking Clientes { months[new Date().getMonth()] }</th>
                            </tr>
                        </thead>
                        <tbody>
                            { top_clients && top_clients.map((sell: any) => <tr key={ sell.cli_id }>
                                <td className="pl-2">
                                    <div className="ranking-user">
                                        <span>{ sell.cli_nome }</span>
                                        <span>{ mask_currency(sell.total) }</span>
                                    </div>
                                </td>
                            </tr>) }
                        </tbody>
                    </table>
                </div>
            </div>
        </ProjectContainer>
    )
}

export default DashboardPage
