/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import './index-provider.scss'
import { useForm } from 'react-hook-form'
import Constants from '../../constants'
import { FeedbackError } from '../../components/feedback/error'
import { FeedbackSuccess } from '../../components/feedback/success'
import { AuthProvider } from 'contexts/user'
import InputMask from 'react-input-mask'
import ProviderService from 'services/ProviderService'

export const ProviderRequestAccess = () => {

    const [error, setError] = useState<string>('')
    const [sucess, setSucess] = useState<string>('')
    const [provider, setProvider] = useState<any>()
    const [submited, setSubmited] = useState<boolean>(false)
    const { register, handleSubmit, errors } = useForm()

    const onSubmit = async (data: any) => {
        setError('')
        check_cnpj(data.cnpj)
        try {
            let phone_exists = await check_phone(data.phone)
            phone_exists = phone_exists.response.status == 302
            if (phone_exists) {
                setError("Telefone já está em uso!")
                return
            }
        } catch (error: any) {
            console.log(error.response.status, error.status)
        }

        const request = {
            cnpj: data.cnpj,
            telefone: data.phone,
            email: data.mail,
            nome: data.name,
            responsavel: data.name,
            senha: data.password,
            for_id: provider?.for_id
        }
        if (provider) {
            request['vendedorPadrao'] = 1;

            ProviderService.register(request).then(resp => {
                if (resp.status == "500") {
                    setError(resp.mensagem)
                    setSubmited(false)
                } else {
                    setSucess('Solicitação efetuada com sucesso')
                    setSubmited(true)
                }
            }).catch(err => setError(Constants.MSG_REQUEST_FAIL))
        } else {
            ProviderService.requestAccess(request)
                .then(response => {
                    if (response) {
                        setSucess('Solicitação efetuada com sucesso')
                        setSubmited(true)
                    }
                })
                .catch(() => setError(Constants.MSG_REQUEST_FAIL))
        }
    }

    const check_cnpj = (cnpj: string) => {

        return ProviderService.getProviders({ cnpj: cnpj.replace(/[^0-9]/g, '') }).then(resp => {

            if (resp.total > 0) {
                setProvider(resp.data[0])
            } else {
                setProvider(null)
            }
        })
    }

    const check_phone = (phone: string) => {
        const url = `/v2/external/users/exists?phone=${phone}`;
        return ProviderService.phoneExists({ phone }).then(resp => {

            return resp
        }).catch(e => e)
    }

    return (
        <AuthProvider>
            <div className="request" style={{ backgroundImage: "url('/images/request-fornecedor.png')" }}>
                <div className="row request-box-container">
                    <div className="col-lg-6 col-md-8 col-sm-8 request-box">
                        <div className="request-box-header">
                            <div className="request-box-logo">
                                <img src="/images/logo-sign-in.jpg" alt="Logo" className="logo" />
                            </div>
                            <h1 className="title-center">Solicite seu acesso e venda ainda mais</h1>
                            <h1 className="title-center-color">Cadastro de fornecedores</h1>
                        </div>

                        <div className="request-box-body">
                            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                {true ?
                                    <>
                                        <InputMask mask="99.999.999/9999-99" onBlur={(evt) => check_cnpj(evt.target.value)}>
                                            {(inputProps) =>
                                                <input
                                                    className="field"
                                                    placeholder="Cnpj"
                                                    name="cnpj"
                                                    {...inputProps}
                                                    ref={register({
                                                        required: true,
                                                        minLength: 18,
                                                        pattern: /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/
                                                    })}
                                                    autoComplete="off" />}
                                        </InputMask>
                                        {errors.cnpj?.type === "required" && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                                        {errors.cnpj?.type === "minLength" && <FeedbackError message={Constants.MSG_LENGTH_FIELD} />}
                                        {errors.cnpj?.type === "pattern" && <FeedbackError message={Constants.MSG_INVALID_FIELD} />}

                                        <input
                                            className="field"
                                            placeholder="Nome"
                                            name="name"
                                            ref={register({ required: true })}
                                            autoComplete="off"
                                        />
                                        {errors.name && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                                    </>
                                    : null}

                                <InputMask mask="(99) 99999-9999" >
                                    {(inputProps) =>
                                        <input
                                            className="field"
                                            placeholder="Telefone"
                                            name="phone"
                                            {...inputProps}
                                            ref={register({
                                                required: true,
                                                pattern: /^(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/

                                            })}
                                            autoComplete="off" />}
                                </InputMask>
                                {errors.phone?.type === "required" && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                                {errors.phone?.type === "pattern" && <FeedbackError message={Constants.MSG_INVALID_FIELD} />}
                                {true ?
                                    <>
                                        <input
                                            className="field"
                                            placeholder="Email"
                                            name="mail"
                                            type="email"
                                            ref={register({
                                                required: true,
                                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            })}
                                            autoComplete="off"
                                        />
                                        {errors.mail?.type === "required" && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                                        {errors.mail?.type === "pattern" && <FeedbackError message={Constants.MSG_INVALID_FIELD} />}

                                        <input
                                            className="field"
                                            placeholder="Senha"
                                            name="password"
                                            type="password"
                                            ref={register({ required: true })}
                                            autoComplete="off"
                                        />
                                        {errors.password && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}

                                        {error && <FeedbackError message={error} />}
                                        {sucess && <FeedbackSuccess message={sucess} />}
                                    </>
                                    : null}

                                <button className={`send ${submited ? "disabled" : ""}`} type="submit" disabled={submited}>Solicitar</button>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </AuthProvider>
    )
}
