import styled from 'styled-components';

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #c7c7c7;
    border-radius: 8px;
    gap: 4px;
    padding: 4px 6px;
    font-family:  'Rubik, sans-serif';
`;
export const FilterTitle = styled.p`
padding:0px 4px 4px 4px;
border-bottom: 1px solid #ff7110 ;
font-size: 18px;
font-weight: 600 ;

`;
export const FilterValue = styled.p`
font-size: 16px;
`;
