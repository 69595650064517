import styled from 'styled-components';

export const Container = styled.div`
padding: 15px 20px;
`;
export const ListContainer = styled.div`
padding: 15px 20px;
display: grid;
grid-template-columns: 1fr 1fr;
gap: 15px;
@media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
}

`;
export const Item = styled.div`
p{
    font-weight: 700;
}
display: flex;
flex-direction: row;
gap: 15px;
padding: 8px 15px;
background-color: rgba(0,0,0,0.03);
border-radius: 6px 6px 6px 0;
border-bottom: 1px solid;
border-image: linear-gradient(to right, #ff7110 -30%, transparent 95%) 100% 0%;

`;
export const SubItem = styled.div`
padding: 5px 20px;
display: flex;
flex-direction: row;
gap: 6px;
align-items: center;

`;
