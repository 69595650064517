import React from "react";


import{
    Container,
    Title,
    Description,

} from './styles'


interface props {
    title:string,
    description:string,
    greenValue?:boolean

}

export function DescriptionCollumn({title, description,greenValue}:props){

return(

    <Container >
        <Title >{title}</Title>
        <Description greenValue={greenValue}>{description}</Description>
    </Container>
)


}