import React, { useState, useContext, useEffect } from 'react'
import Swal from 'sweetalert2'
import { useSnackbar } from 'react-simple-snackbar'
import { Link, useNavigate } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'

import './index.scss'

import Constants from '../../../constants'
import { Button } from 'components/button'
import { UserContext } from 'contexts/user'
import { Snackbar } from 'components/snackbar'
import { Loader } from '../../../components/loader'
import ProductService from 'services/ProductService'
import { PageHeader } from '../../../components/page-header'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { ProductGroupFormModal } from '../components/group-form-modal'
import { ProjectContainer } from '../../../components/project-container'
import { MdOutlineEdit } from 'react-icons/md'
import { BiTrash } from 'react-icons/bi'
import { BsArrowLeftShort } from 'react-icons/bs'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import ButtonV3 from 'components/Buttonv3'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { BaseModal } from 'components/BaseModal'

const GroupsProduct = () => {

    const MySwal = withReactContent(Swal)
    const { user } = useContext(UserContext)

    const [group, setGroup] = useState<any>()
    const [groups, setGroups] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [isModalVisible, setModalVisible] = useState<boolean>(false)

    useEffect(() => {
        if (user) {
            loadGroups()
        }
    }, [user])

    const loadGroups = async () => {
        setLoading(true)
        ProductService.groups(user.providerId).then((result) => {
            setLoading(false)
            setGroups(result.groups)
        })
    }

    const openModal = (group?: any) => {
        setGroup(group)
        setModalVisible(true)
    }

    const onRemove = (group_id: string) => {
        MySwal.fire({
            title: (
                <div>
                    <p>Você irá excluir este grupo. Deseja continuar?</p>
                    <hr />
                </div>
            ),
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: 'red',
        }).then((res) => {
            if (res.isConfirmed) {
                ProductService.delete_group(user.providerId, group_id).then(loadGroups)
                return MySwal.fire(<p>Grupo excluído com sucesso!</p>)
            }
        })
    }

    return (
        <ProjectContainer loading={loading}>
            <div className="products-add">
                {loading && <Loader />}

                <header>
                    <PageHeaderV2 title="Grupo de Produtos" />
                </header>
                <div style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    'justifyContent': 'flex-end',
                    'flexWrap': 'wrap',
                    'gap': 20
                }}>
                    <Link to={Constants.ROUTE_PRODUCTS}>

                        <ButtonV3
                            title='Voltar'
                            onClick={() => { }}
                            Icon={<BsArrowLeftShort className='iconButtonV3' />}
                        />
                        {
                            //    <Button>
                            //        <i className="material-icons">arrow_back</i>Voltar
                            //    </Button>
                        }
                    </Link>
                    <ButtonV3
                        title='Adicionar Grupo'
                        onClick={() => openModal()}
                        Icon={<AiOutlinePlusCircle className='iconButtonV3' />}
                    />
                    {
                        //   <Button className={'c-btn-ternary'} onClick={() => openModal()}>
                        //       <i className="material-icons">add</i>Novo grupo
                        //   </Button>
                    }
                </div>
                <div className="form">
                    <div className="listProdusctsGroup">
                        <table className={`newTableProdusctsGroup`}>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Quantidade de produtos</th>
                                    <th className="tbl-actions">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {groups?.map((group) => (
                                    <tr key={group.id}>
                                        <td data-label='ID'>
                                            {group.id}
                                        </td>
                                        <td data-label='Nome'>
                                            {group.name}
                                        </td>
                                        <td data-label='Quantidade de produtos'>
                                            {group.products_amount}
                                        </td>
                                        <td data-label='Ações'>
                                            <div className='list-actionsGrupoP'>
                                                <div >
                                                    {
                                                        //   <i
                                                        //       data-placement="top"
                                                        //       data-toggle="tooltip"
                                                        //       style={{ color: '#ff7110' }}
                                                        //       onClick={() => openModal(group)}
                                                        //       className={`material-icons iconsActionsEdit`}>
                                                        //       edit
                                                        //   </i>
                                                    }
                                                    <MdOutlineEdit style={{

                                                        'color': '#606060',
                                                        'cursor':'pointer'
                                                    }} title='Editar grupo' onClick={() => openModal(group)} />


                                                </div>
                                                <div >
                                                    {
                                                        //    <i
                                                        //        data-placement="top"
                                                        //        data-toggle="tooltip"
                                                        //        style={{ color: '#ff7110' }}
                                                        //        onClick={() => onRemove(group.id)}
                                                        //        className={`material-icons iconsActionsExclude`}>
                                                        //        delete_forever
                                                        //    </i>
                                                    }
                                                    <BiTrash style={{

                                                        'color': '#CD2A2A',
                                                        'cursor':'pointer'
                                                    }} title=' Deletar grupo' onClick={() => onRemove(group.id)} />

                                                    {
                                                        //    <span className={`tooltiptext`} style={{ color: '#ff1010' }}>
                                                        //        Deletar grupo
                                                        //    </span>
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                   <ProductGroupFormModal
                       isVisible={isModalVisible}
                       user={user}
                       group={group}
                       onClose={(is_submitted) => {
                           setModalVisible(false)
                           if (is_submitted) {
                               loadGroups()
                           }
                       }} />
            }
            {
           //  <BaseModal
           //      title='Grupo de Produtos'
           //      data={group}
           //      onCloseCallback={setModalVisible(false)}
           //      isVisible={isModalVisible}
           //      children={<ProductGroupFormModal
           //          //isVisible={isModalVisible}
           //          user={user}
           //          group={group}
           //          onClose={(is_submitted) => {
           //              setModalVisible(false)
           //              if (is_submitted) {
           //                  loadGroups()
           //              }
           //          }} />}
           //  />

            }
        </ProjectContainer>
    )
}

export default GroupsProduct
