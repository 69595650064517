import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './index.module.scss'
import ModalComponent from 'components/modal-v2'
import OfferService from 'services/v2/offer.service'
import { RiHistoryFill } from 'react-icons/ri'
import DateService from 'services/DateService'
import format_currency from 'services/formatter.service'
import { BsFillExclamationTriangleFill } from 'react-icons/bs'
import { Loader } from 'components/loader'
export const ProductPreviousOffersModal: FC<any> = ({
    request, seller_id, product, setProductShowPrevious
}: any) => {

    const [modal, setModal] = useState<any>({})
    const [offers, setOffers] = useState<any>(null)
    const [loading, setLoading] = useState<any>(true)
    const [my_offers, setMyOffers] = useState<any>(null)

    useEffect(() => {
        setModal({
            title: <span>Digitações Anteriores</span>,
            subtitle: product.name,
            icon: <RiHistoryFill />
        })
    }, [product])

    const get_status_class = useCallback((offers: any, offer: any): string => {
        const winner = offers.find((o: any) => o.vencedor && o.cot_id === offer.cot_id)
        const preco_vencedor = winner?.valor
        const has_orders = offers.filter((o: any) => o.vencedor && o.cot_id === offer.cot_id).some(w => w.has_orders)
        const count = offer.valor && preco_vencedor ?
            parseFloat((((offer.valor - preco_vencedor) / preco_vencedor) * 100).toFixed(2)) : 100

        let filter = false
        offer.preco_vencedor = preco_vencedor
        offer.has_orders = has_orders

        if (offer.valor !== 0 && offer.valor !== null && (offer.vencedor === 0 || offer.vencedor === null)) {
            filter = true
        }
        if (offer.vencedor) {
            return ''
        }
        if (filter && count <= 5 && preco_vencedor / offer.valor < 1) {
            return 'competitive'
        } else if (filter && count >= 5.01 && count <= 15 && preco_vencedor / offer.valor < 1) {
            return 'intermediary'
        } else if (count > 15 && (preco_vencedor / offer.valor) < 1) {
            return 'non-competitive'
        } else if (filter && preco_vencedor / offer.valor > 1 || (preco_vencedor / offer.valor) == 1) {
            return 'others'
        }
        return ''
    }, [])

    useEffect(() => {
        setLoading(true)
        OfferService.previous(request.id, { seller_id, product_id: product.id }).then(response => {
            setOffers(response)
            setLoading(false)
        })
    }, [seller_id, request, product, get_status_class])

    useEffect(() => {
        if (offers && offers.length > 0) {
            const my = offers.filter((o: any) => o.vendedor_id === parseInt(seller_id) && o.prod_id == product.id)
            my.forEach((d: any) => d.status = get_status_class(offers, d))
            setMyOffers(my)
        }
    }, [offers, seller_id, product])

    const close = () => {
        setProductShowPrevious(null)
    }

    return (
        <div>
            <div style={{ padding: 25 }}>
                <div className="row">
                    <div className={styles.legend}>
                        <div className={styles.squareComp}></div>
                        <span>Competitivo</span>
                        <div className={styles.squareInter}></div>
                        <span>Intermediário</span>
                        <div className={styles.squareNonComp}></div>
                        <span>Não Competitivo</span>
                        <div className={styles.squareOthers}></div>
                        <span>Outros</span>
                    </div>
                </div>
                <div >
                    {!loading ?
                        <div className={styles.newListPreviousProduct}>
                            {my_offers
                                ? <table className={styles.newTablePreviousProduct}>
                                    <thead>
                                        <tr>
                                            <th>Cotação</th>
                                            <th className="text-center">Valor</th>
                                            <th>Data</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {my_offers && my_offers.map((o: any) =>
                                            <tr key={`${o.cot_id}-${o.prod_id}-${o.vendedor_id}`}>
                                                <td data-label='Cotação'>{o.cot_id}</td>
                                                <td data-label='Valor' className={styles.status}>
                                                    <div style={{ display:'flex', justifyContent:'flex-end'}}>
                                                        <div style={{}} className={styles[o.status]}>
                                                            {format_currency(o.valor)}
                                                        </div>

                                                    </div>
                                                </td>
                                                <td data-label='Data'>{DateService.formatToDisplayWithTime(new Date(o.data_digitacao))}</td>
                                                {o.preco_vencedor ?
                                                    <td data-label='Status' className={o.vencedor ? styles.winner : styles.looser}>
                                                        <BsFillExclamationTriangleFill title="Sem pedidos para este item"
                                                            style={{ visibility: !o.has_orders ? 'visible' : 'hidden' }} />
                                                    </td> :
                                                    <td data-label='Status' className={styles['no-winners']}>
                                                        {!o.has_orders && <BsFillExclamationTriangleFill title="Sem pedidos para este item" />}
                                                    </td>}
                                            </tr>)}
                                    </tbody>
                                </table>
                                : <>
                                    <p>Produto sem movimentação nos últimos 6 meses</p>
                                </>
                            }
                        </div>
                        : <div className="col">
                            <Loader type="inline" className="prod-list-loader" />
                        </div>}
                </div>
            </div>
            {
                //   <ModalComponent modal={modal} show={true} close={close} className={styles['modal-dialog']}>
                //       <div className="container-fluid">
                //           <div className="row">
                //               <div className={styles.legend}>
                //                   <div className="square comp"></div>
                //                   <span>Competitivo</span>
                //                   <div className="square inter"></div>
                //                   <span>Intermediário</span>
                //                   <div className="square non-comp"></div>
                //                   <span>Não Competitivo</span>
                //                   <div className="square others"></div>
                //                   <span>Outros</span>
                //               </div>
                //           </div>
                //           <div className="row header">
                //               {!loading ?
                //                   <div className="col m-4 p-3 shadow">
                //                       {my_offers
                //                           ? <table className={`table ${styles.table}`}>
                //                               <thead>
                //                                   <tr>
                //                                       <th>Cotação</th>
                //                                       <th className="text-center">Valor</th>
                //                                       <th>Data</th>
                //                                       <th></th>
                //                                   </tr>
                //                               </thead>
                //                               <tbody>
                //                                   {my_offers && my_offers.map((o: any) =>
                //                                       <tr key={`${o.cot_id}-${o.prod_id}-${o.vendedor_id}`}>
                //                                           <td>{o.cot_id}</td>
                //                                           <td className={styles.status}>
                //                                               <div className={styles[o.status]}>
                //                                                   {format_currency(o.valor)}
                //                                               </div>
                //                                           </td>
                //                                           <td>{DateService.formatToDisplayWithTime(new Date(o.data_digitacao))}</td>
                //                                           {o.preco_vencedor ?
                //                                               <td className={o.vencedor ? styles.winner : styles.looser}>
                //                                                   <BsFillExclamationTriangleFill title="Sem pedidos para este item"
                //                                                       style={{ visibility: !o.has_orders ? 'visible' : 'hidden' }} />
                //                                               </td> :
                //                                               <td className={styles['no-winners']}>
                //                                                   {!o.has_orders && <BsFillExclamationTriangleFill title="Sem pedidos para este item" />}
                //                                               </td> }
                //                                       </tr>) }
                //                               </tbody>
                //                           </table>
                //                           : <>
                //                               <p>Produto sem movimentação nos últimos 6 meses</p>
                //                           </>
                //                       }
                //                   </div>
                //                   : <div className="col">
                //                       <Loader type="inline" className="prod-list-loader" />
                //                   </div> }
                //           </div>
                //       </div>
                //   </ModalComponent>
            }
        </ div>


    )
}
