import ButtonV3 from 'components/Buttonv3'
import { Loader } from 'components/loader'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { RoadMap } from 'components/roadmap'
import { RequestContext } from 'contexts/request'
import { mask_currency } from "helpers/mask.helper"
import ProductModel from 'models/product.model'
import TotalItensHighchartModel from 'models/total-itens-highchart.model'
import TypedItensHighchartModel from 'models/typed-itens-highchart.model'
import { useContext, useEffect, useRef, useState } from 'react'
import { AiOutlinePrinter } from 'react-icons/ai'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import DateService from 'services/DateService'
import HttpClient from 'services/HttpClient'
import { ProjectContainer } from '../../components/project-container/index'
import Constants from '../../constants'
import Chart from './components/chart'
import { DescriptionCollumn } from './components/descriptionCollumn'
import { DescriptionRow } from './components/descriptionRow'
import { RequestedProductsComponent } from './components/requested-products'
import styles from './index.module.scss'

export default function Manager() {
    const [isPrinting, setIsPrinting] = useState(false);
    const [loading, setLoading] = useState<boolean>(true)
    const [page, setPage] = useState(1)
    const { id, seller_id } = useParams<{ id: any, seller_id: any }>()
    const [client, setClient] = useState<any>({})
    const [cotacao, setCotacao] = useState<any>({})
    const [products, setProducts] = useState<any>()
    const [data, setData] = useState<any>({})
    const [all_itens_chart_data, setAllItensChartData] = useState<any>()
    const [typed_itens_chart_data, setTypedItensChartData] = useState<any>()
    const { load_request_products, load_request, request_products, request, request_products_pagination, load_others_requests } = useContext(RequestContext)
    const useQuery = () => new URLSearchParams(useLocation().search)
    const others = useQuery().get('o')
    const componentRef = useRef(null)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    async function print() {
        setIsPrinting(true)
        await Promise.resolve()
            .then(() => handlePrint()).then(res =>
                setIsPrinting(false)
            )
    }

    useEffect(() => {
        async function loadCotacao() {
            const response = await HttpClient.get(`${Constants.URL_API_MANAGER}`, {
                numerocotacao: id,
                vendedor: seller_id
            })
            setCotacao(response.cotacao)
            setClient(response.cliente)
            setLoading(false)
            setAllItensChartData(new TotalItensHighchartModel(response))
            setTypedItensChartData(new TypedItensHighchartModel(response))
            setData(response)

        }

        load_products()
        loadCotacao()
    }, [id, seller_id])

    const load_products = async (page = 1) => {
        !loading && load_request_products(id, seller_id, page, undefined, undefined, undefined, undefined, undefined, 1).then(load_request(id)
            .then(() => setLoading(false))
        )
    }

    useEffect(() => {
        load_request_products(id, seller_id, page, undefined, undefined, undefined, undefined, undefined, 1).then(load_others_requests(id)
            .then(() => setLoading(false))
        )
        setProducts(request_products?.map((p: ProductModel) => { return { ...p, visible: true } }))
    }, [])

    useEffect(() => {
        setProducts(request_products?.map((p: ProductModel) => { return { ...p, visible: true } }))
    }, [ request_products])


    const navigate = useNavigate()


    return (
        <ProjectContainer loading={loading} id={'documentToPrintAG'}>
            <div ref={componentRef}>
                <RoadMap
                    pageLinks={[
                        {
                            title: 'Minhas Cotações',
                            onclick: () => {navigate(Constants.ROUTE_REQUESTS)}
                        },
                        {
                            title: 'Análise Gerencial',
                            onclick: () => {}
                        }

                    ]}
                ></RoadMap>
                {!isPrinting &&

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>

                        <PageHeaderV2 title="ANÁLISE GERENCIAL" />
                        <div style={{ display: 'flex', alignItems: 'flex-end', gap: 15 }}>
                            <div >
                                <ButtonV3
                                    style={{ 'height': 45 }}
                                    title='imprimir'
                                    onClick={() => print()}
                                    Icon={<AiOutlinePrinter className='iconButtonV3' />}
                                >
                                </ButtonV3>
                            </div>
                            <div style={{}}>
                                <Link to={Constants.ROUTE_REQUESTS}>
                                    <ButtonV3
                                        style={{ 'height': 45 }}
                                        title='Voltar'
                                    >
                                    </ButtonV3>
                                </Link>
                            </div>
                        </div>

                    </div>
                }


                <div className={styles.descriptionTableContainerAG}>
                    <div style={{ width: '100%' }}>
                        <p style={{
                            fontFamily: 'Rubik, sans-serif',
                            fontWeight: 500,
                            fontSize: 20,
                            marginBottom: 24,
                            color: '#606060',
                        }}>
                            Dados do Cliente:
                        </p>
                        <DescriptionRow hasBorderBottom={true} title='NOME:' description={client.cli_nome} />
                        <DescriptionRow hasBorderBottom={true} title='CNPJ:' description={client.cli_cnpj} />
                        <DescriptionRow hasBorderBottom={true} title='TELEFONE:' description={client.cli_telefone} />
                        <DescriptionRow title='EMAIL:' description={client.cli_email} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <p style={{
                            fontFamily: 'Rubik, sans-serif',
                            fontWeight: 500,
                            fontSize: 20,
                            color: '#606060',
                            marginBottom: 24
                        }}>
                            Dados do Vendedor:
                        </p>
                        <DescriptionRow hasBorderBottom={true} title='NOME:' description={cotacao.nome_vendedor} />

                        <DescriptionRow hasBorderBottom={true} title='TELEFONE:' description={client.cli_telefone} />
                        <DescriptionRow title='EMAIL:' description={cotacao.email_vendedor} />
                    </div>
                </div>

                <div style={{ display: 'flex', margin: '30px 0px', flexWrap: 'wrap', justifyContent: 'space-between', gap: 15 }}>
                    <DescriptionCollumn title='COD SOLICITAÇÃO:' description={`#${cotacao.id_cotacao}`} />
                    <DescriptionCollumn title='DATA SOLICITAÇÃO:' description={DateService.formatToDisplayWithTime(new Date(cotacao.data_cotacao))} />
                    <DescriptionCollumn title='INÍCIO DA DIGITAÇÃO:' description={DateService.formatToDisplayWithTime(new Date(cotacao.inicio_digitacao))} />
                    <DescriptionCollumn title='DATA FIM:' description={DateService.formatToDisplayWithTime(new Date(cotacao.data_expiracao))} />
                    <DescriptionCollumn title='N.EMPRESAS PARTICIPANTES:' description={cotacao.total_concorrentes} />
                    <DescriptionCollumn greenValue title='VALOR DOS PEDIDOS::' description={data && data.summary && mask_currency(data.summary.orders_count)} />

                </div>
                {
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap', overflow: 'auto' }}>
                        <div style={{ width: 480 }}>
                            <Chart options={all_itens_chart_data} />
                        </div>
                        <div style={{ width: 480 }}>
                            <Chart options={typed_itens_chart_data} />
                        </div>
                    </div>
                }
                <div>
                    {products && !loading ?
                        <RequestedProductsComponent
                            visible={true}
                            showFilters={true}
                            others={false}
                            reloadProducts={load_request_products}
                            onChange={(products: any) => setProducts(products)}
                            data={products}
                            paginationData={request_products_pagination}
                            seller_id={seller_id}
                            request={request} />
                        : <Loader />
                    }
                    {/* {produtos && <> */}
                        {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 15 }}>
                            <div style={{ width: '60%' }}>
                                <PaginationCustom
                                    total={produtos.last_page}
                                    itemsName={'produtos'}
                                    ItemsPorPage={20}
                                    currentPage={page}
                                    request={ cotacao }
                                    onPageChange={load_products}
                                    totalItems={30}
                                />
                            </div>
                        </div> */}
                    {/* </> */}
                    {/* } */}
                </div>
            </div>

        </ProjectContainer>
    )
}


