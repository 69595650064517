import ButtonV3 from "components/Buttonv3";
import styles from './index.module.scss'
import { IoMdCloseCircleOutline, IoMdSave, IoMdTrash } from 'react-icons/io'
import { MdFormatListBulletedAdd, MdOutlineEdit } from 'react-icons/md'
import { useEffect, useState } from "react";
import { Input } from "components/input";
import { ToastContainer, toast } from "react-toastify";
import ClassificationService from "services/v3/classification.service";
import { IoAdd, IoFilterSharp } from "react-icons/io5";
import InputV2 from "components/InputV2";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { BsArrowReturnRight } from "react-icons/bs";
export const ClassificationListComponent = ({ }: any) => {

  const MySwal = withReactContent(Swal)
  const dafaultValuesModal = {
    id: null,
    for_id: null,
    descricao: "",
    field_control_employee_id: "",
    Controle_entrega: false,
    Volume_entrega: 0
  }

  const [classifications, setClassifications] = useState<any>([])



  const [newClassificationTitle, setNewClassificationTitle] = useState('')
  const [newSubClassificationTitle, setNewSubClassificationTitle] = useState('')
  const [editingitem, setEditingitem] = useState<any>({})
  const [editingSub, setEditingSub] = useState<any>({})
  const [showAddNewSub, setShowAddNewSub] = useState<any>({})
  const [shake, setShake] = useState(false);

  useEffect(() => {
    getClassification()

  }, [])

  async function getClassification(page = 1) {
    await ClassificationService.getclassification(page).then(res => {
      setClassifications(res)
    })
  }

  async function handleDelete(itemId, title) {

    MySwal.fire({
      title: `Excluir a classificação ${title}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {

        ClassificationService.editClassification(itemId, { status: 0 }).then(res => {
          toast.info('Classificação Deletada !', {
            position: "top-right",
            autoClose: 2000,
          })
          // setModalEditData(dafaultValuesModal)
          // setModalVisible(false)
          // setReload(!reload)
          getClassification()
        }
        ).catch(e => {
          toast.error('Erro ao deletar classificação!', {
            position: "top-right",
            autoClose: 2000,
          })
          // setModalEditData(dafaultValuesModal)
          // setModalVisible(false)
        }
        )
      }
    })
  }
  async function handleDeleteSub(itemId, title) {

    MySwal.fire({
      title: `Excluir a classificação ${title}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {

        ClassificationService.editSubClassification(itemId, { status: 0 }).then(res => {
          toast.info('Classificação Deletada !', {
            position: "top-right",
            autoClose: 2000,
          })
          // setModalEditData(dafaultValuesModal)
          // setModalVisible(false)
          // setReload(!reload)
          getClassification()
        }
        ).catch(e => {
          toast.error('Erro ao deletar classificação!', {
            position: "top-right",
            autoClose: 2000,
          })
          // setModalEditData(dafaultValuesModal)
          // setModalVisible(false)
        }
        )
      }
    })


  }


  const handleSaveNewCLassification = () => {

    if (newClassificationTitle === '') {

      setShake(true);
      setTimeout(() => setShake(false), 400);  // Remova o shake após 500ms
    } else {
      handleCreateClassification(newClassificationTitle)
    }
  };
  const handleSaveNewSubCLassification = (id) => {

    if (newSubClassificationTitle !== '') {
      // setShake(true);
      // setTimeout(() => setShake(false), 400);  // Remova o shake após 500ms
      handleCreateSubClassification(id, newSubClassificationTitle)
    }
  };

  async function handleCreateClassification(title) {
    await ClassificationService.createClassification({ descricao: title }).then(res => {
      getClassification();
      toast.success('Classificação Criada !');
      setNewClassificationTitle('')
    }

    ).catch(
      err => toast.error('Erro ao criar classificaco')
    )
  }
  async function handleCreateSubClassification(id, title) {
    await ClassificationService.createSubClassification({ id_class: id, nome: title }).then(res => {
      getClassification();
      toast.success('subclassificação Criada !');
      setNewSubClassificationTitle('')
      setShowAddNewSub(null)
    }

    ).catch(
      err => toast.error('Erro ao criar subclassificaco')
    )
  }

  async function handleEditCLassification() {
    await ClassificationService.editClassification(editingitem.id, editingitem).then(res => {
      toast.success('Alterações salvas')
      setEditingitem(null)
      getClassification();
    }).catch(err => toast.error('Erro ao efetuar alterações'))
  }
  async function handleEditSubCLassification() {
    await ClassificationService.editSubClassification(editingSub.id, editingSub).then(res => {
      toast.success('Alterações salvas')
      setEditingSub(null)
      getClassification();
    }).catch(err => toast.error('Erro ao efetuar alterações'))
  }

  return (
    <div className={`${styles.profiles} text-center col col-12 rounded`}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <div >
        <div style={{ 'marginBottom': 15, gap: 12 }} className="d-flex flex-start ">

          <div >
            <Input
              // className={`input-container ${shake ? 'shake' : ''}`}
              className={`${styles.inputContainertoShake}, ${shake ? styles.shake : ''}`}

              placeholder={'Nova Classificação'}
              style={{ height: 47 }}
              onChange={e => setNewClassificationTitle(e.target.value)}
              value={newClassificationTitle}
            />
          </div>
          <ButtonV3
            title='Criar Classificação'
            Icon={<IoAdd className='iconButtonV3' />}
            onClick={() => handleSaveNewCLassification()}
          />

        </div>

        {
          classifications?.length > 0 ?
            <div className={`${styles.listRepresentatives}`}>
              <table className={`${styles.newTableRepresentatives}`}>
                <thead>
                  <tr>
                    <th colSpan={5} className="text-left">Descrição</th>
                    <th style={{ paddingRight: 20 }} className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {classifications.map(
                    (item: any, index) => (
                      <>

                        <tr style={index % 2 == 0 ? { backgroundColor: '#fff' } : { backgroundColor: 'rgba(0,0,0,0.1) ' }} className="desktopTr" key={item.id + 'desktop'}>
                          <td colSpan={5} data-label={editingitem?.id == item.id ? '' : 'Descrição'}>
                            {editingitem?.id == item.id ?
                              <div style={{ gap: 5 }} className="d-flex flex-start ">

                                <div >
                                  <Input

                                    className={`${styles.inputContainertoShake}, ${shake ? styles.shake : ''}`}

                                    placeholder={item?.descricao}
                                    style={{ height: 40 }}
                                    onChange={e => setEditingitem({ ...editingitem, descricao: e.target.value })}
                                    value={editingitem.descricao}
                                  />
                                </div>
                                <div
                                  className="buttonActionMobileTable"
                                  style={{ boxShadow: 'unset ', height: 40, cursor: 'pointer', borderColor: 'rgba(0,0,0,0.15)' }}
                                  onClick={() => setEditingitem(null)}>
                                  <IoMdCloseCircleOutline size={24} color="#CD2A2A"
                                    style={{ margin: '0 5px' }}

                                  />
                                </div>
                                <ButtonV3
                                  style={{ height: 40 }}
                                  title='Salvar'
                                  Icon={<IoMdSave className='iconButtonV3' />}
                                  onClick={() => handleEditCLassification()}
                                />

                              </div> :
                              <>
                                <p className={`${styles.classificationDescricao}`}>
                                  {item.descricao}
                                </p>
                              </>
                            }
                          </td>
                          <td data-label='Ações' className="text-right">
                            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'flex-end', 'gap': '20px' }}>

                              <div >
                                <MdFormatListBulletedAdd onClick={() => showAddNewSub?.id == item.id ? setShowAddNewSub(null) : setShowAddNewSub(item)}
                                  title="Excluir rota"
                                  style={{ cursor: 'pointer' }}
                                  color='#1ece3b' size={22} className={`${styles.svgactionsProfiles}`} />
                              </div>
                              <div >
                                <IoMdTrash onClick={() => handleDelete(item.id, item.descricao)}
                                  title="Excluir rota"
                                  style={{ cursor: 'pointer' }}
                                  color='#CD2A2A' size={22} className={`${styles.svgactionsProfiles}`} />
                              </div>
                              <div className={styles.tooltip}>
                                <MdOutlineEdit title="Editar" onClick={() => setEditingitem(item)}
                                  color='#606060'
                                  style={{ cursor: 'pointer' }} size={22} className={`${styles.svgactionsProfiles}`} />
                              </div>
                            </div>
                          </td>
                        </tr>
                        {showAddNewSub?.id == item.id ?
                          <tr
                            style={index % 2 == 0 ? { backgroundColor: '#fff' } : { backgroundColor: 'rgba(0,0,0,0.1) ' }}
                            className="desktopTr"
                          >
                            <td colSpan={6}>
                              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                {/* <div>

                                <BsArrowReturnRight />
                              </div> */}
                                <div style={{ gap: 12 }} className="d-flex flex-start ">

                                  <div >
                                    <Input
                                      // className={`input-container ${shake ? 'shake' : ''}`}
                                      className={`${styles.inputContainertoShake}, ${shake ? styles.shake : ''}`}

                                      placeholder={'Nova Classificação'}
                                      style={{ height: 47 }}
                                      onChange={e => setNewSubClassificationTitle(e.target.value)}
                                      value={newSubClassificationTitle}
                                    />
                                  </div>
                                  <ButtonV3
                                    title='Criar subclassificação'
                                    Icon={<IoAdd className='iconButtonV3' />}
                                    onClick={() => handleSaveNewSubCLassification(item.id)}
                                  />

                                </div>
                              </div>
                            </td>
                          </tr>
                          : null}
                        {item.sub_classificacao.length > 0 && item.sub_classificacao?.map(
                          (subitem: any, idx) => (
                            <tr
                              style={index % 2 == 0 ? { backgroundColor: '#fff' } : { backgroundColor: 'rgba(0,0,0,0.1) ' }}
                              className="desktopTr"
                            >
                              <td colSpan={5}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 15, marginLeft: 15 }}>

                                  {editingSub?.id == subitem.id ?
                                    <div style={{ gap: 5 }} className="d-flex flex-start ">

                                      <div >
                                        <Input

                                          className={`${styles.inputContainertoShake}, ${shake ? styles.shake : ''}`}

                                          placeholder={item?.descricao}
                                          style={{ height: 40 }}
                                          onChange={e => setEditingSub({ ...editingSub, nome: e.target.value })}
                                          value={editingSub.nome}
                                        />
                                      </div>
                                      <div
                                        className="buttonActionMobileTable"
                                        style={{ boxShadow: 'unset ', height: 40, cursor: 'pointer', borderColor: 'rgba(0,0,0,0.15)' }}
                                        onClick={() => setEditingSub(null)}>
                                        <IoMdCloseCircleOutline size={24} color="#CD2A2A"
                                          style={{ margin: '0 5px' }}

                                        />
                                      </div>
                                      <ButtonV3
                                        style={{ height: 40 }}
                                        title='Salvar'
                                        Icon={<IoMdSave className='iconButtonV3' />}
                                        onClick={() => handleEditSubCLassification()}
                                      />

                                    </div> :
                                    <>
                                      <BsArrowReturnRight size={14} />
                                      <p className={`${styles.classificationDescricao}`}>
                                        {subitem.nome}
                                      </p>
                                    </>
                                  }
                                </div>
                              </td>
                              <td data-label='Ações' className="text-right">
                                <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'flex-end', 'gap': '20px' }}>

                                  <div >
                                    <IoMdTrash onClick={() => handleDeleteSub(subitem.id, subitem.descricao)}
                                      title="Excluir rota"
                                      style={{ cursor: 'pointer' }}
                                      color='#CD2A2A' size={22} className={`${styles.svgactionsProfiles}`} />
                                  </div>
                                  <div className={styles.tooltip}>
                                    <MdOutlineEdit title="Editar" onClick={() => setEditingSub(subitem)}
                                      color='#606060'
                                      style={{ cursor: 'pointer' }} size={22} className={`${styles.svgactionsProfiles}`} />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}






                        <tr style={{ padding: 0, overflow: 'hidden' }} className="mobileTr" key={item.id + 'mobile'}>
                          <td className="firstTdMobile">
                            <div style={{ padding: '8px 10px' }} className="headerMobileTable">
                              <p className={`${styles.classificationDescricao}`}>
                                {item.descricao}
                              </p> </div>
                            {editingitem?.id == item.id ?
                              <div style={{ gap: 5, padding: '10px 10px' }} className="d-flex flex-start ">

                                <div >
                                  <Input

                                    className={`${styles.inputContainertoShake}, ${shake ? styles.shake : ''}`}

                                    placeholder={item?.descricao}
                                    style={{ height: 40 }}
                                    onChange={e => setEditingitem({ ...editingitem, descricao: e.target.value })}
                                    value={editingitem.descricao}
                                  />
                                </div>
                                <div
                                  className="buttonActionMobileTable"
                                  style={{ boxShadow: 'unset ', height: 40, cursor: 'pointer', borderColor: 'rgba(0,0,0,0.15)' }}
                                  onClick={() => setEditingitem(null)}>
                                  <IoMdCloseCircleOutline size={24} color="red"
                                    style={{ margin: '0 5px' }}

                                  />
                                </div>
                                <ButtonV3
                                  style={{ height: 40 }}
                                  title='Salvar'
                                  Icon={<IoMdSave className='iconButtonV3' />}
                                  onClick={() => handleEditCLassification()}
                                />

                              </div> :
                              null
                            }
                          </td>
                          {
                            //??
                          }


                          {showAddNewSub?.id == item.id ?

                              <td colSpan={6}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                  {/* <div>

                                <BsArrowReturnRight />
                              </div> */}
                                  <div style={{ gap: 12 }} className="d-flex flex-start ">

                                    <div >
                                      <Input
                                        // className={`input-container ${shake ? 'shake' : ''}`}
                                        className={`${styles.inputContainertoShake}, ${shake ? styles.shake : ''}`}

                                        placeholder={'Nova Classificação'}
                                        style={{ height: 47 }}
                                        onChange={e => setNewSubClassificationTitle(e.target.value)}
                                        value={newSubClassificationTitle}
                                      />
                                    </div>
                                    <ButtonV3
                                      title='Criar subclassificação'
                                      Icon={<IoAdd className='iconButtonV3' />}
                                      onClick={() => handleSaveNewSubCLassification(item.id)}
                                    />

                                  </div>
                                </div>
                              </td>

                            : null}
                          {item.sub_classificacao.length > 0 && item.sub_classificacao?.map(
                            (subitem: any, idx) => (
                              <>
                                <td colSpan={5}>
                                  <div style={{ display: 'flex', alignItems: 'center', gap: 15, marginLeft: 15 }}>

                                    {editingSub?.id == subitem.id ?
                                      <div style={{ gap: 5 }} className="d-flex flex-start ">

                                        <div >
                                          <Input

                                            className={`${styles.inputContainertoShake}, ${shake ? styles.shake : ''}`}

                                            placeholder={item?.descricao}
                                            style={{ height: 40 }}
                                            onChange={e => setEditingSub({ ...editingSub, nome: e.target.value })}
                                            value={editingSub.nome}
                                          />
                                        </div>
                                        <div
                                          className="buttonActionMobileTable"
                                          style={{ boxShadow: 'unset ', height: 40, cursor: 'pointer', borderColor: 'rgba(0,0,0,0.15)' }}
                                          onClick={() => setEditingSub(null)}>
                                          <IoMdCloseCircleOutline size={24} color="#CD2A2A"
                                            style={{ margin: '0 5px' }}

                                          />
                                        </div>
                                        <ButtonV3
                                          style={{ height: 40 }}
                                          title='Salvar'
                                          Icon={<IoMdSave className='iconButtonV3' />}
                                          onClick={() => handleEditSubCLassification()}
                                        />

                                      </div> :
                                      <>
                                        <BsArrowReturnRight size={14} />
                                        <p className={`${styles.classificationDescricao}`}>
                                          {subitem.nome}
                                        </p>
                                      </>
                                    }
                                  </div>
                                </td>
                                <td className="text-right">
                                  <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'flex-end', 'gap': '20px' }}>

                                    <div >
                                      <IoMdTrash onClick={() => handleDeleteSub(subitem.id, subitem.descricao)}
                                        title="Excluir rota"
                                        style={{ cursor: 'pointer' }}
                                        color='#CD2A2A' size={22} className={`${styles.svgactionsProfiles}`} />
                                    </div>
                                    <div className={styles.tooltip}>
                                      <MdOutlineEdit title="Editar" onClick={() => setEditingSub(subitem)}
                                        color='#606060'
                                        style={{ cursor: 'pointer' }} size={22} className={`${styles.svgactionsProfiles}`} />
                                    </div>
                                  </div>
                                </td>
                              </>
                            ))}








                          {
                            //??
                          }
                          <td className="text-right">
                            <div className="divActionsMobileTable"  >
                            <div className="buttonActionMobileTable" >
                                <MdFormatListBulletedAdd onClick={() => showAddNewSub?.id == item.id ? setShowAddNewSub(null) : setShowAddNewSub(item)}
                                  title="Excluir rota"
                                  style={{ cursor: 'pointer' }}
                                  color='#1ece3b' size={22} className={`${styles.svgactionsProfiles}`} />
                              </div>
                              <div className="buttonActionMobileTable" >
                                <IoMdTrash onClick={() => handleDelete(item.id, item.descricao)}
                                  title="Excluir rota"
                                  style={{ cursor: 'pointer' }}
                                  color='#CD2A2A' size={22} className={`${styles.svgactionsProfiles}`} />
                              </div>
                              <div className="buttonActionMobileTable" >
                                <MdOutlineEdit title="Editar" onClick={() => setEditingitem(item)}
                                  color='#606060'
                                  style={{ cursor: 'pointer' }} size={22} className={`${styles.svgactionsProfiles}`} />
                              </div>
                            </div>
                          </td>
                        </tr >

                      </>
                    )
                  )}
                </tbody>
              </table>
            </div> : <div>Nenhuma classificação encontrada!</div>
        }
      </div>
    </div >
  );
}
