import React from 'react';

import {
    Container,
} from './styles';


interface props {
    title: string,
    icon?: any,
    isActive: boolean,
    onClick: () => void

}

export function ButtonTab({ title, icon, isActive ,onClick}: props) {
    return (
        <Container onClick={onClick} isActive={isActive}>
            {icon && icon}
            <div>
                {title}
            </div>
        </Container>
    );
}
