import HttpClient from './http.client'

export default class clubProductService {

    static loadCategories = async (): Promise<any> => {
        return HttpClient.get(`/providers/categories`)
    }
    static loadClubProducts = async (params: any = null): Promise<any> => {
        return HttpClient.get(`/providers/management/products`, params)
    }

}
