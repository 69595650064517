import OrderService from '../../services/OrderService'
import { ResponseApi } from '../../interfaces/responseApi'


// TYPES
export const LOAD_ORDER_SUCCESS   = 'LOAD_ORDER_SUCCESS'


// Reducer
const initialState = null

export default function orders(state = initialState, action: any) {
    if ( action.type === LOAD_ORDER_SUCCESS ) {
        return action.orders
    }
    return state
}


// ACTIONS
export const loadOrders = (params: any) => (dispatch: any) =>
    OrderService.getAll(params)
        .then((response: ResponseApi) => {
            dispatch(loadOrdersSuccess(response))
        }).catch(() => {
            dispatch(loadOrdersSuccess(null))
        })


const loadOrdersSuccess = (orders: ResponseApi | null) => {
    return { type: LOAD_ORDER_SUCCESS, orders }
}
