import HttpClient from '../HttpClient'

export class ReportService {

    static sales = async (params: any) : Promise<any> => {
        return HttpClient.get('v2/reports/orders', params)
    }

    static client_companies = async (uf: string) : Promise<any> => {
        return HttpClient.get(`v2/reports/clients?est=${uf}`)
    }
}
