import styled from 'styled-components';

interface statusProps{
    status:any
}

export const StatusBox = styled.p<statusProps>`
    font-family: $font-familyV2;
    font-size: 14px;
    font-weight: 500;
    //color: #707070;
    margin-right: 3px;
    border: 1px solid #ABABAB;
    text-align: center;
    padding: 5px;
    width: 70%;
    color:${({ status }) => status };
`