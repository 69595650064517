import { BaseModal } from 'components/BaseModal'
import InputV2 from 'components/InputV2'
import { Button } from 'components/button'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import IconReact from 'components/icons'
import { Loader } from 'components/loader'
import { PaginationCustom } from 'components/pagination-custom'
import { Select } from 'components/select'
import { Snackbar } from 'components/snackbar'
import { RequestContext } from 'contexts/request'
import OfferModel from 'models/offer.model'
import ProductModel from 'models/product.model'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import OfferService from 'services/offer.service'
import ClientService from 'services/v3/clients.service'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { DerivativeProductsModalComponent } from './components/derivative-products-modal'
import { RequestProductsFilterComponent } from './components/filters'
import { OfferItemRowComponent } from './components/offer-item'
import item_styles from './components/offer-item/index.module.scss'
import { OfferItemRowComponentMobileRequestGraphs } from './components/offer-itemMobile'
import { ProductPreviousOffersModal } from './components/product-previous-offers'
import './index.scss'
import {
    is_editable,
    is_visible,
    link_products,
    navigate,
    offer_confirmation
} from './requested-products.helper'
import { ChecksContainer, CustomCheckContainer } from './styles'
export const RequestedProductsComponent: FC<any> = ({ showFilters, others, request, data, onChange, seller_id, paginationData, reloadProducts, visible }: any) => {

    const defaultFilter = {
        name: '',
        category: '',
        digitados: false,
        meusProdutos: false,
        for_report: true
    }

    const [products, setProducts] = useState<ProductModel[]>(data)
    const [filteredProducts, setFilteredProducts] = useState<ProductModel[]>(data)
    const [product_show_previous, setProductShowPrevious] = useState<ProductModel>()
    const [current_product, setCurrentProduct] = useState<ProductModel>()
    const [current_offer, setCurrentOffer] = useState<OfferModel>()
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const MySwal = withReactContent(Swal)
    const [processing, set_processing] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [ratioFilter, setRatioFilter] = useState<any>(null)
    const [page, setPage] = useState(1)
    const list = useRef<any>({})
    const [filters, setFilters] = useState<any>(defaultFilter)
    const [searchText, setSearchText] = useState('')
    const [categories] = useState<any>([
        { label: 'Verde', value: '0' },
        { label: 'Laranja', value: 1 },
        { label: 'Preto', value: 2 },
        { label: 'Vencedores', value: 4 },
        { label: 'Outros', value: 5 }
    ])
    const [derivativeProductsModalVisible, setDerivativeProductsModalVisible] = useState(false)
    const [showPreviousProductModalVisible, setShowPreviousProductModalVisible] = useState(false)


    const containerRef = useRef<any>()
    const { request_products_pagination
    } = useContext(RequestContext)
    useEffect(() => {
        if (data) {

            const prods = data.filter((p: ProductModel) => p.visible)
            prods.filter((p: ProductModel) => p && !p.is_generic)
                .forEach((p: ProductModel) => p.derivatives.forEach((pp: ProductModel) => pp.main_product = p))
            const derivatives = prods.map((p: ProductModel) => p.derivatives).flat().filter(is_visible)
            link_products(derivatives)
            setProducts(data)
            setFilteredProducts(data)
        }
    }, [data])

    const check_offer_pricing = (offer: OfferModel, callback: any) => {
        const perc = 0.3
        if (offer.last_offer_price > 0 && offer.price > 0 &&
            (offer.last_offer_price * (1 - perc) >= offer.price ||
                offer.price >= offer.last_offer_price * (1 + perc))
        ) {
            MySwal.fire({
                title: 'Confirmar digitação?',
                html: offer_confirmation(offer),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.isConfirmed) {
                    callback()
                }
            })
        } else {
            callback()
        }
    }

    useEffect(() => {
        if (current_product == null && products) {
            onChange(products)

        }
    }, [current_product])

    const open_previous_offers_modal = (product: ProductModel) => {
        setProductShowPrevious(product)
    }

    const remove_offer = (item: ProductModel) => {
        MySwal.fire({
            title: 'Confirma remoção da digitação?',
            text: "Esta digitação será removida, mas poderá ser feita novamente!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!'
        }).then((result) => {
            if (result.isConfirmed) {
                OfferService.save_quantity(OfferModel.from_product(item, request).to_request({
                    quantidadeDigitacao: 0,
                    alteraEmbalagem: 0
                })).then(() => {
                    ClientService.discontinue_product(request.client_id, item.id, seller_id).then(() => {
                        item.offer.quantity = undefined
                        item.last_offer_price = 0
                        item.is_discontinued = true
                        item.new_offer_price = 0
                        reprocess()
                        Swal.fire(
                            'Removido!',
                            'Sua digitação foi removida com sucesso.',
                            'success'
                        )
                    })
                })
            }
        })
    }

    const setup_offer = ({ maskedvalue, floatValue, deriv, main }:
        { maskedvalue?, floatValue?, deriv, main?, obs?, new_quantity?}) => {


        const offer = OfferModel.from_product(deriv, request).with_price(floatValue)

        offer.display_price = maskedvalue
        offer.generic = main && main.is_generic
        offer.offer_quantity = offer.generic ? offer.offer_quantity : 1
        offer.purchase_unity = main?.purchase_unity
        offer.seller_id = seller_id
        offer.others = others ? true : false
        offer.changed = true

        setCurrentOffer(offer)
    }



    const reprocess = () => {

        if (list.current && list.current.resetAfterIndex) {
            list.current.resetAfterIndex(0)
        }
    }

    const save = (callback: any = null) => {
        if (current_offer?.changed) {
            check_offer_pricing(current_offer, () => {
                OfferService.save(current_offer?.to_request()).then(() => {

                    setCurrentOffer(undefined)

                    if (callback) callback()

                    link_products(products)

                    openSnackbar(
                        <Snackbar icon="attach_money">
                            Digitação salva com sucesso {current_offer?.display_price}
                        </Snackbar>
                    )
                })
            })
        }
    }

    const save_quantity = (callback: any = null) => {
        if (current_offer?.changed) {
            OfferService.save_quantity(current_offer?.to_request({ alteraEmbalagem: 1 })).then(() => {
                set_processing(false)

                if (callback) callback()
                openSnackbar(
                    <Snackbar icon="tune">
                        Quantidade atualizada com sucesso!
                    </Snackbar>
                )
            }).then(() => set_processing(false))
        }
    }

    const save_obs = (callback: any = null) => {
        if (current_offer?.changed) {
            check_offer_pricing(current_offer, () => {
                OfferService.save_obs(current_offer?.to_request({ observacao: current_offer.obs })).then(() => {

                    setCurrentOffer(undefined)

                    if (callback) callback()

                    openSnackbar(
                        <Snackbar icon="sticky_note_2">
                            Observação salva com sucesso
                        </Snackbar>)
                })
            })
        }
    }

    // useEffect(() => {
    //     if (request.categories) {
    //         setCategories(request.categories.map((cat: any) => { return { label: cat, value: cat } }))
    //     }
    // }, [request])

    const { id } = useParams<{ id: any }>()

    useEffect(() => {

        handleAplyFilters(page, filters, searchText)
    }, [page, filters, ratioFilter])

    function clearFilter() {
        setFilters(defaultFilter)
        setSearchText('')
    }

    function handleSetSearchText() {
        if (filters.name != searchText) {
            setFilters({ ...filters, name: searchText })
        }
    }

    async function handleAplyFilters(page, filters, searchText) {

        setLoading(true)
        let name = searchText

        let digitados = filters.digitados ? 1 : 0
        let meusProdutos = filters.meusProdutos ? 1 : 0

        await reloadProducts(id,
            seller_id,
            page,
            filters.category,
            name,
            digitados,
            meusProdutos,
            ratioFilter,
            1
        )
        //?
        setTimeout(() => {

            scrolltoTOp()
        }, 500);
        setLoading(false)
    }

    function scrolltoTOp() {
        window.scrollTo({
            top: containerRef.current.offsetTop,
            behavior: "smooth",
        });
    }

    function handleSetStatus(data) {

    }
    return (
        <section ref={containerRef} className="products">
            {isLoading ? <Loader /> : null}
            <header >

                {false && <RequestProductsFilterComponent
                    onFilter={(params: any) => setFilters({ ...filters, ...params })}
                    request={request} />}
                {showFilters ?
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="reset" onClick={() => clearFilter()} className="c-btn-outline">Limpar filtros</Button>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-end', gap: 20, flexWrap: 'wrap' }}>

                            <div className='divInputFilter' >
                                <InputV2
                                    infoIconNull
                                    onBlur={() => handleSetSearchText()}
                                    type='text'
                                    id="product-description"
                                    label="Buscar por descrição"
                                    icon={<IconReact type={'search'} />}
                                    placeholder="Descrição..."
                                    value={searchText}
                                    onChange={(e: any) => setSearchText(e.target.value)}

                                />
                            </div>
                            {/* <CheckBoxCustom
                                checked={filters.meusProdutos}
                                value={filters.meusProdutos}
                                change={(value: any) => {
                                    setFilters({ ...filters, meusProdutos: !value })
                                }}

                                description='Apenas meus produtos.'
                            /> */}
                            <div style={{
                                display:'flex',
                                flexDirection:'column',
                                gap:4

                            }}>

                                <CheckBoxCustom
                                    checked={filters.digitados}
                                    value={filters.digitados}
                                    change={(value: any) => {
                                        setFilters({ ...filters, digitados: !value })
                                    }}
                                    description='Apenas digitados'
                                />


                                    <CheckBoxCustom

                                        // checked={filters.digitados}
                                        checked={ratioFilter == 4}
                                        // value={ratioFilter == 4}
                                        change={() => {
                                            setRatioFilter(ratioFilter == 4 ? null : 4)
                                        }}
                                        description='Apenas Vencedores'
                                    />

                            </div>
                            {/* <div>
                                <Select
                                    id="category"
                                    description="Categoria"
                                    defaultValue=''
                                    blank_label="Selecione uma categoria"
                                    onChange={(e: any) => setFilters({ ...filters, ratio: e.target.value })}
                                    options={categories}
                                    value_key="value"
                                    label_key="label"
                                />
                            </div> */}
                            {/* [
        { label: 'Verde', value: '0' },
        { label: 'Laranja', value: 1 },
        { label: 'Preto', value: 2 },
        { label: 'Vencedores', value: 4 },
        { label: 'Outros', value: 5 }
    ] */}
                            <ChecksContainer>
                                <CustomCheckContainer bg={'rgba(48, 170, 76, 0.1)'} color={'#30AA4C'}>
                                    <CheckBoxCustom
                                        checked={ratioFilter == 0}
                                        change={() => setRatioFilter(ratioFilter == 0 ? null : 0)}
                                        description=''></CheckBoxCustom>
                                    <section>
                                        <p>Competitivo</p>
                                    </section>
                                </CustomCheckContainer>
                                <CustomCheckContainer bg={'rgba(255, 113, 16, 0.1)'} color={'#FF7110'}>
                                    <CheckBoxCustom
                                        checked={ratioFilter == 1}
                                        change={() => setRatioFilter(ratioFilter == 1 ? null : 1)}
                                        description=''></CheckBoxCustom>
                                    <section>
                                        <p>Intermediário</p>
                                    </section>
                                </CustomCheckContainer>
                                <CustomCheckContainer bg={'rgba(96, 96, 96, 0.1)'} color={'#505050'}>
                                    <CheckBoxCustom
                                        checked={ratioFilter == 2}
                                        change={() => setRatioFilter(ratioFilter == 2 ? null : 2)}
                                        description=''></CheckBoxCustom>
                                    <section>
                                        <p>Não Competitivo</p>
                                    </section>
                                </CustomCheckContainer>
                                <CustomCheckContainer bg={'rgba(23, 162, 184, 0.1)'} color={'#17A2B8'}>
                                    <CheckBoxCustom
                                        checked={ratioFilter == 5}
                                        change={() => setRatioFilter(ratioFilter == 5 ? null : 5)}
                                        description=''></CheckBoxCustom>
                                    <section>
                                        <p>Outros</p>
                                    </section>
                                </CustomCheckContainer>

                            </ChecksContainer>
                        </div>
                    </div>
                    : null}

            </header>
            {!processing &&
                <>
                    <div style={{ marginTop: 25 }} className={'tableContainerRequested'}>
                        <div style={{ minWidth: '100%' }} className="table">
                            <div className={`row t-head ${item_styles.header}`}>
                                <div className="col">CÓDIGO</div>
                                <div className="col"></div>
                                <div className="col">DESCRIÇÃO/MARCA</div>
                                <div className="col"></div>
                                <div className="col">QTD. NA EMBALAGEM</div>
                                <div className="col"></div>
                                <div className="col ">PREÇO</div>
                                {/* <div className="col text-right">TOTAL</div> */}
                                <div className="col col-1 text-center">STATUS</div>
                            </div>
                            <div className="t-body">
                                {
                                    filteredProducts && filteredProducts.map((item, index) => {
                                        return <OfferItemRowComponent
                                            ref={list}
                                            length={filteredProducts.length}
                                            index={index}
                                            data={{
                                                products: item,
                                                request, setCurrentProduct, setShowPreviousProductModalVisible, seller_id,
                                                remove_offer, setup_offer, save_quantity,
                                                setCurrentOffer, save_obs, save, open_previous_offers_modal, setDerivativeProductsModalVisible

                                            }}
                                        />
                                    })
                                }

                                {request_products_pagination && visible && request_products_pagination.per_page ?

                                    <div style={{ width: 'inherit' }}>
                                        <PaginationCustom
                                            total={Number(request_products_pagination.last_page - 1)}
                                            // total={100}
                                            itemsName={'produtos'}
                                            ItemsPorPage={Number(request_products_pagination.per_page)}
                                            currentPage={Number(request_products_pagination.current_page)}
                                            onPageChange={(page: number) => setPage(page)}
                                            totalItems={request_products_pagination.total}
                                        />
                                    </div>

                                    : null}
                            </div>




                        </div>
                    </div>
                    <div className={'tableContainerRequestedMobile'}>
                        {filteredProducts && filteredProducts.map((item, index) => {
                            return <OfferItemRowComponentMobileRequestGraphs
                                index={index}
                                data={{
                                    products: item,
                                    request, setCurrentProduct, setShowPreviousProductModalVisible, seller_id,
                                    remove_offer, setup_offer, save_quantity,
                                    setCurrentOffer, save_obs, save, open_previous_offers_modal, setDerivativeProductsModalVisible

                                }}
                            />
                        })}
                        {request_products_pagination && visible ?
                            <div style={{ width: '100%' }}>
                                <PaginationCustom
                                    total={Number(request_products_pagination.last_page - 1)}
                                    // maxWidth={250}
                                    // total={100}
                                    itemsName={'produtos'}
                                    ItemsPorPage={Number(request_products_pagination.per_page)}
                                    currentPage={Number(request_products_pagination.current_page)}
                                    onPageChange={(page: number) => setPage(page)}
                                    totalItems={request_products_pagination.total}
                                />

                            </div>

                            : null}
                    </div>
                </>
            }

            {processing && <Loader type="inline" className="prod-list-loader" />}
            {product_show_previous &&
                <BaseModal
                    title={'Digitações Anteriores'}
                    isVisible={showPreviousProductModalVisible}
                    onCloseCallback={() => setShowPreviousProductModalVisible(false)}
                    children={<ProductPreviousOffersModal
                        request={request}
                        seller_id={seller_id}
                        setProductShowPrevious={setProductShowPrevious}
                        product={product_show_previous}
                    />
                    }
                />
            }
            {current_product &&
                <BaseModal
                    title={current_product.name}
                    isVisible={derivativeProductsModalVisible}
                    onCloseCallback={() => setDerivativeProductsModalVisible(false)}
                    children={<DerivativeProductsModalComponent
                        is_editable={is_editable}
                        navigate={navigate}
                        save={save}
                        save_quantity={save_quantity}
                        save_obs={save_obs}
                        setCurrentOffer={setCurrentOffer}
                        setup_offer={setup_offer}
                        request={request}
                        onChange={onChange}
                        products={products}
                        reprocess={reprocess}
                        setCurrentProduct={setCurrentProduct}
                        product={current_product}
                    />}
                />
            }
        </section>
    )
}
