
export const LOAD_REQUESTS_SUCCESS   = 'LOAD_REQUESTS_SUCCESS'
export const LOAD_STATUS_LIST        = 'LOAD_STATUS_LIST'
export const SET_REQUEST_PRODUCTS    = 'SET_REQUEST_PRODUCTS'
export const SET_REQUEST_PRODUCTS_PAGINATION    = 'SET_REQUEST_PRODUCTS_PAGINATION'
export const SET_REQUEST             = 'SET_REQUEST'
export const SET_DELIVERY            = 'SET_DELIVERY'

export const initalState : any = { user: null }

export default function RequestReducer(state = initalState, action) {
    switch (action.type) {
    case LOAD_REQUESTS_SUCCESS:
        return { ...state, requests: action.requests }
    case LOAD_STATUS_LIST:
        return { ...state, status_list: action.status_list }
    case SET_REQUEST_PRODUCTS:
        return { ...state, request_products: action.products }
    case SET_REQUEST_PRODUCTS_PAGINATION:
        return { ...state, request_products_pagination: action.paginationData }
    case SET_REQUEST:
        return { ...state, request: action.request }
    case SET_DELIVERY:
        return { ...state, delivery: action.delivery }
    default:
        return state
    }
}
