import StorageService from "../StorageService"
import Constants from "../../constants"
import ErrorHandleService from "../ErrorHandleService"
import axios from 'axios'

export default class HttpClient {

    static http = axios.create({
        baseURL: Constants.URL_V3_API,
        timeout: 60000
    })

    static getHeaders = () => {
        let headers = { "Content-Type": "application/json" }
        const token = StorageService.get(Constants.STORAGE_TOKEN_KEY)
        if (token) {
            headers = { ...headers, ...{ Authorization: `Bearer ${token}` } }
        }
        return headers
    }

    static get = async (path: string, params: any = null) =>
        HttpClient.http.get(path, { headers: HttpClient.getHeaders(), params })
            .then(response => response.data).catch(err => ErrorHandleService.handle(err))

    static delete = (url: string) =>
        HttpClient.http.delete(url, { headers: HttpClient.getHeaders() })
            .then(response => response.data).catch(err => ErrorHandleService.handle(err))

    static post = (path: string, data: Record<string, unknown>, params: any = null) =>
        HttpClient.http.post(path, data, { headers: HttpClient.getHeaders(), params })
            .then(response => response.data)

    static patch = (path: string, data: Record<string, unknown>, params: any = null) =>
        HttpClient.http.patch(path, data, { headers: HttpClient.getHeaders(), params })
            .then(response => response.data)

    static put = (path: string, data: Record<string, unknown>, params: any = null) =>
        HttpClient.http.put(path, data, { headers: HttpClient.getHeaders(), params })
            .then(response => response.data)
}
