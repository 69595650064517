import { Currency } from 'components/currency-input'
import React, { useContext, useEffect, useState } from 'react'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { FiTrash } from 'react-icons/fi'
import { Button } from '../../../../../../components/button'
import { Input } from '../../../../../../components/input'
import styles from './index.module.scss'
// import { is_visible, is_editable, navigate } from '../../requested-products.helper'
import { is_visible, navigate } from '../../requested-products.helper'
import { filter_number } from 'helpers/string.helper'
import { BsCircleFill } from 'react-icons/bs'
import format_currency from 'services/formatter.service'
import { RiNumbersLine } from 'react-icons/ri'
import { UserContext } from 'contexts/user'
import ProductModel from 'models/product.model'
import { FaRegCopy } from 'react-icons/fa'
import OfferModel from 'models/offer.model'
import OfferService from 'services/offer.service'
import { Snackbar } from 'components/snackbar'
import { useSnackbar } from 'react-simple-snackbar'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { mask_currency } from 'helpers/mask.helper'
import IconReact from 'components/icons'
import { PiCrosshairDuotone } from "react-icons/pi";
import { CurrencySafari } from 'components/currency-input-Safary'
// export const OfferItemRowComponent = ({ length, ref, data, index }: any) => {

export const OfferItemRowComponent = ({ isSafari, length, ref, data, index, is_editable }: any) => {

  const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)

  const {
    request, setup_offer, products, setCurrentOffer, seller_id,
    setCurrentProduct, save, save_obs, remove_offer,
    setShowPreviousProductModalVisible, save_quantity,
    open_previous_offers_modal, setDerivativeProductsModalVisible,
    continue_product
  } = data

  //const [prod, setProd] = useState<ProductModel>(products[index])
  const [prod, setProd] = useState<ProductModel>(products)
  const [visiblePricisionInput, setVisiblePricisionInput] = useState<any>([])
  const { can } = useContext(UserContext)



  //  useEffect(() => {
  //      setProd(products[index])
  //  }, [data, products, index])
  useEffect(() => {
    setProd(products)
  }, [data, products])

  const get_last_price_text_class = (prod: ProductModel) => {
    if (prod.is_previous_offer_winner == null || !prod.last_offer_price) {
      return ''
    } else if (!prod.is_previous_offer_winner) {
      return 'lower'
    } else {
      return 'higher'
    }
  }

  const copy_price = async (deriv, main) => {
    deriv.new_offer_price = deriv.last_offer_price
    const offer = OfferModel.from_product(deriv, request).with_price(deriv.new_offer_price)
    offer.generic = main && main.is_generic
    offer.offer_quantity = offer.generic ? offer.offer_quantity : 1
    offer.purchase_unity = main?.purchase_unity
    offer.seller_id = seller_id
    offer.display_price = mask_currency(deriv.last_offer_price)
    offer.changed = true

    OfferService.save(offer?.to_request()).then(() => {
      deriv.saved = true
      openSnackbar(
        <Snackbar icon="attach_money">
          Ultima oferta copiada com sucesso {offer?.display_price}
        </Snackbar>
      )
    })
  }

  const get_last_price_text = (prod: ProductModel) => {
    if (prod.is_previous_offer_winner == null || !prod.last_offer_price) {
      return ''
    } else if (!prod.is_previous_offer_winner) {
      return 'Preço não vencedor na ultima cotação'
    } else {
      return 'Preço vencedor na ultima cotação'
    }
  }

  const wrap_inputs = (deriv: ProductModel, prod: ProductModel, idx, index) => {
    let el_index = idx
    let row_index = index

    return prod.is_generic ? (<>
      <div style={{ width: 60 }}>

        <Input value={deriv.new_offer_quantity}
          name="offer-quantity"
          id={row_index + 'offer-quantity' + el_index}
          autocomplete="off"
          className={`wrap-amount thin ${styles.itemUnity} ${deriv.qtd_saved && styles.saved}`}
          register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
          disabled={(!is_editable(request) || !prod.is_generic ||
            (request.owner_type && request.owner_type !== 'PROVIDER')) ? 'disabled ' : false}
          onBlur={() => save_quantity(() => {
            deriv.qtd_saved = true
            deriv.offer.quantity = deriv.new_offer_quantity
          })}
          onKeyDown={(e: any) => navigate(e, deriv, { save: null, save_obs: null }, el_index, row_index, length)}
          onChange={(evt: any) => {
            const parsed = parseInt(filter_number(evt.target.value))
            deriv.new_offer_quantity = isNaN(parsed) ? 0 : parsed
            setup_offer({
              floatValue: deriv.new_offer_price,
              deriv, main: prod
            })
          }} />
      </div>
      <div style={{}} >
        <Input defaultValue={prod.purchase_unity} tabIndex="-1" readOnly="readonly"
          register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
          className={`wrap-description thin ${styles.itemUnity} `} />
      </div>
    </>) :
      <>
        <div style={{ width: 60 }}></div>
        <div style={{}}>
          <Input defaultValue={prod.packing} tabIndex="-1" readOnly="readonly"
            register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
            className={`wrap-description thin ${styles.itemUnity}`} />
        </div>

      </>
  }

  function handleOpenModalDerivated() {
    setCurrentProduct(prod)
    setDerivativeProductsModalVisible(true)
  }
  function handleOpenModalPrevious(deriv) {
    open_previous_offers_modal(deriv)
    setShowPreviousProductModalVisible(true)
  }

  function handleSetFourDecimals(identifier) {
    let actualIdentifiers = [...visiblePricisionInput]
    if (actualIdentifiers.includes(identifier)) {
      actualIdentifiers = actualIdentifiers.filter(item => item != identifier)
    } else {
      actualIdentifiers.push(identifier)
    }
    setVisiblePricisionInput(actualIdentifiers)
  }
  function isPrecisionValue(value) {
    if (value) {

      var stringfied = value.toString();

      if (stringfied.indexOf('.') !== -1) {
        var partes = stringfied.split('.');
        if (partes[1].length > 2) {
          return true;
        }
      }
      return false
    } else {
      return false
    }
  }

  return (
    <>
      {prod ? (
        <div key={index} className={styles.wrapper}>
          <div key={prod.id} className={`row product-header bg-light-gray ${styles.main}`}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '85%' }}>
              <div data-label='Código' style={{ display: 'flex', flexDirection: 'row' }}>
                <div className={`${styles.codigoOffer} `}><p>{prod.ean}</p></div>
                <div className={`${styles.circle} `}>
                  <p className={prod.is_generic ? styles.showbrands : styles.showbrandsDisabled}
                    data-toggle="modal"
                    //disabled={!prod.is_generic}
                    data-target="#derivatives-modal"
                    onClick={() => { prod.is_generic ? handleOpenModalDerivated() : {} }
                    }>
                    Ver Marcas
                  </p>
                  {
                    //  <BsCircleFill />
                  }

                </div>

              </div>
              <div className='TdtabelaDigitacaoPreco' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <span className={`${styles.prodNameOffer} `} style={{}}>{prod.name}</span>

                </div>


                <div className={`${styles.infOfferItem}  text-lowercase`}>
                  {prod.is_generic ? <p>Informe o preço do(a): {prod.purchase_unity}</p> : ''}
                  {!prod.is_generic ? <p>Informe apenas seu preço</p> : ''}
                </div>
              </div>
            </div>

            <div style={{ width: '15%' }} className=" requested-quantity"><p className="w-100">Qtd {prod.requested_quantity}</p></div>
          </div>
          {
            prod.derivatives && prod.derivatives.filter(is_visible).map((deriv: any, idx) =>
              <div key={`${deriv.id}-deriv`} className={`row product-deriv ${styles.brand}`}>
                {
                  <div style={{ width: 60 }}>

                  </div>
                }
                <div className="col">
                  {prod.is_generic ?
                    <IconReact type='trash' title="Remover digitação"
                      onClick={() => { is_editable(request) && remove_offer(deriv) }} /> : ''}
                  {prod.is_generic ? <div className={`brand ${styles.hearth}`}>
                    {deriv.starred ? <AiFillHeart title="Marca preferencial" /> : ''}
                    {!deriv.starred ? <div className="on flex"><AiOutlineHeart /></div> : ''}
                    {deriv.brand}
                  </div> : ''}
                </div>
                {/* <div style={{ width: 435 }}> */}
                <div >

                  <div style={{}}>

                    <div style={{ display: 'flex' }} className="wrap">
                      {wrap_inputs(deriv, prod, idx, index)}
                    </div>
                  </div>
                  <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }} >
                    {/* //! teste aqui */}
                    {/* <p onClick={() => console.log(deriv)}>t</p> */}
                    <div className={styles.crosshairContainer} title='Utilizar 4 casas decimais '>
                      <PiCrosshairDuotone
                        size={21}
                        onClick={() => handleSetFourDecimals(index + 'set-currency' + idx)}
                        color={visiblePricisionInput.includes(index + 'set-currency' + idx) ||
                          isPrecisionValue(deriv.new_offer_price)
                          ?
                          '#ff7110' : '#c7c7c7'
                        } />
                    </div>

                    <div style={{ marginLeft: 10, display: 'flex' }} >
                      {isSafari ?
                        <CurrencySafari
                          id={index + 'set-currency' + idx}
                          precision={visiblePricisionInput.includes(index + 'set-currency' + idx) || isPrecisionValue(deriv.new_offer_price) ? 4 : 2}
                          name={'set-currency'}
                          value={deriv.new_offer_price}
                          onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs, continue_product }, idx, index, length)}
                          disabled={(prod.is_generic && deriv.new_offer_quantity < 1) || (!is_editable(request) || (request.owner_type && request.owner_type !== 'PROVIDER')) ?
                            'disabled ' : false}
                          register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
                          onFocus={() => setCurrentOffer(undefined)}
                          // className={`offer-amount thin${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                          className={`${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                          //className={`${styles.currencyOffer} ${true&& styles.saved}`}
                          onChange={(maskedvalue: string, floatValue: number) => {
                            deriv.offer.price = floatValue
                            deriv.new_offer_price = floatValue
                            setup_offer({ maskedvalue, floatValue, deriv, main: prod })
                          }}
                          onBlur={() => {  save(() => deriv.saved = true) }} />
                        :

                        <Currency
                          id={index + 'set-currency' + idx}
                          precision={visiblePricisionInput.includes(index + 'set-currency' + idx) || isPrecisionValue(deriv.new_offer_price) ? 4 : 2}
                          name={'set-currency'}
                          value={deriv.new_offer_price}
                          onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs, continue_product }, idx, index, length)}
                          disabled={(prod.is_generic && deriv.new_offer_quantity < 1) || (!is_editable(request) || (request.owner_type && request.owner_type !== 'PROVIDER')) ?
                            'disabled ' : false}
                          register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
                          onFocus={() => setCurrentOffer(undefined)}
                          // className={`offer-amount thin${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                          className={`${styles.currencyOffer} ${deriv.saved && styles.saved}`}
                          //className={`${styles.currencyOffer} ${true&& styles.saved}`}
                          onChange={(maskedvalue: string, floatValue: number) => {
                            deriv.offer.price = floatValue
                            deriv.new_offer_price = floatValue
                            setup_offer({ maskedvalue, floatValue, deriv, main: prod })
                          }}
                          onBlur={() => save(() => deriv.saved = true)} />
                      }
                      <div className={styles['price-actions']}>
                        {can('READ_HISTORY', 'REQUEST_PRODUCT') && deriv.last_offer_price > 0 &&
                          <IconReact
                            type='graphVertical'
                            title="Histórico de ofertas"
                            className={styles.previous}
                            // onClick={() => open_previous_offers_modal(deriv)} />
                            onClick={() => handleOpenModalPrevious(deriv)} />
                        }
                        {
                          // is_editable(request) && deriv.last_offer_price > 0 &&
                          // (deriv.new_offer_quantity > 0 || !prod.is_generic) &&
                          // (is_editable(request) && deriv.last_offer_price > 0 && !prod.is_generic) ? <IconReact type='fileCopy' className={styles.copy}
                          (is_editable(request) && deriv.last_offer_price > 0) ? <IconReact type='fileCopy' className={styles.copy}
                            // (is_editable(request) && deriv.last_offer_price > 0 ) ? <IconReact type='fileCopy' className={styles.copy}
                            onClick={() => copy_price(deriv, prod)}
                            title="Copiar ultimo preço ofertado" />
                            :
                            <></>

                        }
                      </div>
                    </div>

                  </div>
                  <div style={{ width: 94 }} className=" text-center last-price">
                    <span title={get_last_price_text(deriv)}
                      className={get_last_price_text_class(deriv)}>
                      {format_currency(deriv.last_offer_price)}

                    </span>
                  </div>
                  <div style={{ width: 140 }} className=" text-right">
                    <Input value={deriv.observations || ''}
                      placeholder="..."
                      autocomplete="off"
                      name='offer-obs'
                      id={index + 'offer-obs' + idx}
                      className={` ${styles.offerObs}${deriv.obs_saved && styles.saved}`}
                      disabled={(!is_editable(request) || deriv.new_offer_price == 0) ? 'disabled ' : false}
                      register={(el: any) => deriv.obs_ref ? deriv.obs_ref.current = el : el}
                      onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs }, idx, index, length)}
                      onChange={(evt: any) => {
                        deriv.observations = evt.target.value
                        setup_offer({ deriv, obs: evt.target.value })
                      }}
                      onBlur={() => save_obs(() => deriv.obs_saved = true)} />
                  </div>
                </div>



              </div>
            )}
        </div>
      ) : null}
    </>
  )

  //return prod ? (
  //    <div style={style} className={styles.wrapper}>
  //        <div key={prod.id} className={`row product-header bg-light-gray ${styles.main}`}>
  //            <div className="col">{prod.ean}</div>
  //            <div className={`${styles.circle} col`}>
  //                <p className={styles.showbrands}
  //                    data-toggle="modal"
  //                    //disabled={!prod.is_generic}
  //                    data-target="#derivatives-modal"
  //                    onClick={() => {prod.is_generic ? setCurrentProduct(prod): {}}
  //                }>
  //                    Ver Marcas
  //                </p>
  //                <BsCircleFill />
  //                <span style={{ width: "50px" }}>{prod.name}</span>
  //            </div>
  //            <div className="col">
  //            </div>
  //            <div className="col text-lowercase">
  //                {!prod.is_generic ? 'Informe apenas seu preço' : ''}
  //                {prod.is_generic ? `Informe o preço do(a): ${prod.purchase_unity}` : ''}
  //            </div>
  //            <div className="col requested-quantity"><p className="w-100">Qtd {prod.requested_quantity}</p></div>
  //        </div>
  //        {prod.derivatives && prod.derivatives.filter(is_visible).map((deriv: any) =>
  //            <div key={`${deriv.id}-deriv`} className={`row product-deriv ${styles.brand}`}>
  //                <div className="col">

  //                </div>
  //                <div className="col">
  //                    {prod.is_generic ? <FiTrash title="Remover digitação"
  //                        onClick={() => { is_editable(request) && remove_offer(deriv) }} /> : ''}
  //                    {prod.is_generic ? <div className={`brand ${styles.hearth}`}>
  //                        {deriv.starred ? <AiFillHeart title="Marca preferencial" /> : ''}
  //                        {!deriv.starred ? <div className="on flex"><AiOutlineHeart /></div> : ''}
  //                        {deriv.brand}
  //                    </div> : ''}
  //                </div>
  //                <div className="col">
  //                    <div className="wrap">
  //                        {wrap_inputs(deriv, prod)}
  //                    </div>
  //                </div>
  //                <div className="col">
  //                    <Currency value={deriv.new_offer_price}
  //                        onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
  //                        disabled={(prod.is_generic && deriv.new_offer_quantity < 1) || (!is_editable(request) || (request.owner_type && request.owner_type !== 'PROVIDER')) ?
  //                            'disabled ' : false}
  //                        register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
  //                        onFocus={() => setCurrentOffer(undefined)}
  //                        className={`offer-amount thin ${deriv.saved && styles.saved}`}
  //                        onChange={(maskedvalue: string, floatValue: number) => {
  //                            deriv.offer.price = floatValue
  //                            deriv.new_offer_price = floatValue
  //                            setup_offer({ maskedvalue, floatValue, deriv, main: prod })
  //                        }}
  //                        onBlur={() => save(() => deriv.saved = true)} />
  //                    <div className={styles['price-actions']}>
  //                        {can('READ_HISTORY', 'REQUEST_PRODUCT') && deriv.last_offer_price > 0 && <RiNumbersLine
  //                            title="Histórico de ofertas"
  //                            className={styles.previous} onClick={() => open_previous_offers_modal(deriv)} />}
  //                        {is_editable(request) && deriv.last_offer_price > 0 &&
  //                            (deriv.new_offer_quantity > 0 || !prod.is_generic) &&
  //                            <FaRegCopy className={styles.copy}
  //                                onClick={() => copy_price(deriv, prod)}
  //                                title="Copiar ultimo preço ofertado" />}
  //                    </div>
  //                </div>
  //                <div className="col text-center last-price">
  //                    <span title={get_last_price_text(deriv)}
  //                        className={get_last_price_text_class(deriv)}>
  //                        {format_currency(deriv.last_offer_price)}
  //                    </span>
  //                </div>
  //                <div className="col text-right">
  //                    <Input value={deriv.observations || ''}
  //                        placeholder="..."
  //                        name="offer-obs"
  //                        className={`thin ${deriv.obs_saved && styles.saved}`}
  //                        disabled={(!is_editable(request) || deriv.new_offer_price == 0) ? 'disabled ' : false}
  //                        register={(el: any) => deriv.obs_ref ? deriv.obs_ref.current = el : el}
  //                        onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
  //                        onChange={(evt: any) => {
  //                            deriv.observations = evt.target.value
  //                            setup_offer({ deriv, obs: evt.target.value })
  //                        }}
  //                        onBlur={() => save_obs(() => deriv.obs_saved = true)} />
  //                </div>
  //            </div>
  //        )}
  //    </div>) : ''
}
