import React, { useEffect, useState } from 'react'
import pao from '../../../../../../assets/images/paogergilim.png'
import {

    Container,
    Image,
    FooterContainer,
    AddButton,
    ProdInfosContainer,


} from './styles'
import { useTheme } from 'styled-components';

interface props {
    // scrollRef: any
    theme: any

}


export default function ProductCard({
    theme
}: props) {




    return (
        <Container
            theme={theme}
        >
            <section >
                <Image
                    src={pao}
                    theme={theme}>
                </Image>
            </section>
            <ProdInfosContainer theme={theme} >
                <h4>Pão de hamb. tradicional com gergelim pct c/4</h4>
                <span>Pacote c/ 4un</span>
                {/* <h3> Valor Emb: R$ {numeric.format(price)}</h3> */}
                <h3> Valor Emb: R$ 1,23</h3>
                <h3> Valor Unit: R$ 1,23</h3>
                <h2>Total: R$ 2,34</h2>
            </ProdInfosContainer>
            <FooterContainer theme={theme}>
                <AddButton theme={theme}>
                    <span>Adicionar</span>
                </AddButton>
            </FooterContainer>
        </Container>
    )
}
