
export default class CampaignsModel{
    id: number
    provider: number
    date?: string
    itemQuantity: number
    sumValue: string
    conditionPayment?: string
    sales?: number
    views?: number
    observation?: string
    title?: string
    delivery?: string
    orders_count?: number
    orders_amount?: number

    constructor({
        id, for_id, data_final, qtd_itens, valor_total,
        condicao_pgto, vendas, views, obs, titulo, entrega,
        orders_amount, orders_count
    }: any){
        this.orders_count = orders_count
        this.orders_amount = orders_amount
        this.id = id
        this.provider = for_id
        this.date = data_final
        this.itemQuantity = qtd_itens
        this.sumValue = valor_total
        this.conditionPayment = condicao_pgto
        this.sales = vendas
        this.views = views
        this.observation = obs
        this.title = titulo
        this.delivery = entrega
    }


}
