import styled from 'styled-components';

export const ChecksContainer = styled.div`
display: flex;
gap: 15px;
flex-wrap: wrap;
`;


interface props {
    bg: string,
    color: string
}
export const CustomCheckContainer = styled.div<props>`
border-radius: 5px;
display: flex;
flex-direction: row;
gap: 5px;
align-items: center;
p{
    color: ${props => props.color};;
}

section{
    padding: 6px 8px;
    background-color: ${props => props.bg};
    border-radius: 6px;
    margin-left: -6px;
    /* height: 80%;
    width: 25px;
    display: flex;
    border-radius: 25%;
    align-items: center;
    justify-content: center;
    padding: 4px 2px 4px 7px;
    /* box-shadow: 1px 1px 2px 1px ${props => props.bg};; */
}

`;
