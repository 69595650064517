import HttpClient from './http.client'

export default class DeliveryRoutesService {

    static getDeliveryRoutes = () => HttpClient.get(`/providers/local/retirada`)

    static editDeliveryRoute = (data) =>
        HttpClient.put(`/providers/local/retirada/${data.id}`, data)

    static createDeliveryRoute = (data) =>
        HttpClient.post(`/providers/local/retirada`, data)

    static deleteDeliveryRoute = (id) =>
        HttpClient.put(`/providers/local/retirada/${id}`, { status: 0 })

    static linkAddressToStore = (storeId, localeId) =>
        HttpClient.post(`/providers/local/retirada/link`, { id_local_retirada: localeId, id_loja: storeId })

    static unlinkAddressToStore = (storeId, localeId) =>
        HttpClient.delete(`/providers/local/retirada/${localeId}/loja/${storeId}`)




}
