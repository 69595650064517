import './index.scss'

import Swal from 'sweetalert2'
import Switch from "react-switch"
import React, { useEffect, useState } from 'react'
import withReactContent from 'sweetalert2-react-content'
import { FaUserAlt } from 'react-icons/fa'
import NewModalComponent from 'components/new-modal'
import { Representative } from 'interfaces/representative'
import ClientService from 'services/v3/clients.service'
import RepresentativeService from 'services/RepresentativesService'
import PaginationCustomV2 from 'components/PaginationCustomV2'
import { Loader } from 'components/loader'
import { ToastContainer, toast } from 'react-toastify';
import { PhoneWithWhats } from 'components/phoneWithWhats'

export const RepresentativesModal = ({ company, onCloseCallback }: any) => {

    const MySwal = withReactContent(Swal)

    const [modal, setModal] = useState<any>({})
    const [representatives, setRepresentatives] = useState<Representative[]>([])
    const [representativesTab, setRepresentativesTab] = useState('ACTIVATEDS')

    const [representativesItems, setRepresentativesItems] = useState([])
    const [paginationInfo, setPaginationInfo] = useState<any>()
    const [loading, setLoading] = useState(false)


    const [queryParams, setQueryParams] = useState<any>({
        page: 1,
        activeFilter: true,
        profile: null,
    })

    useEffect(() => {
        setRepresentativesTab('ACTIVATEDS')
        setModal({
            title: `${company?.name}`,
            id: 'representatives-modal',
            icon: 'people',
            styles: {
                maxWidth: '80vw',
                minHeigth: '70vh'
            },
            body_styles: {
                minHeight: '50vh',
                maxHeight: '80vh'
            },
            onClose: onCloseCallback
        })
    }, [company, onCloseCallback])

    useEffect(() => {
        if (company) {
            getAll()
        }
    }, [company])

    useEffect(() => {
        if (company && representatives) {

            RepresentativeService.get(`getvendedores?page=${queryParams.page}`)
                .then(res => {

                    setPaginationInfo({ ...res.list })
                    let availables = res.list.data
                    filterAvailables(representatives, availables)
                }).then(res => {
                    scrollToTop()

                })
        }
    }, [representatives, queryParams])

    function scrollToTop() {
        window.scrollTo({ top: 150, behavior: 'smooth' });
    }
    const getAll = () =>
        ClientService.sellers(company.id)
            .then((response) => setRepresentatives(response.sellers))

    const updateStatus = (id: any) =>
        ClientService
            .deactivate(company.id, id)
            .then(getAll)

    const changeStatus = (representative: Representative) => {
        MySwal.fire({
            title: (
                <div>
                    <p>
                        Você irá desativar este vendedor. Deseja continuar?
                    </p>
                    <hr />
                </div>
            ),
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            cancelButtonColor: "red"
        }).then((res) => {
            if (res.isConfirmed) {
                updateStatus(representative.id)
                return MySwal.fire(<p>Vendedor desativado com sucesso!</p>)
            }
        })
    }
    const profile_description = (rep: Representative) => {
        if (rep.isMaster) {
            return 'Gerente'
        } else if (rep.isSalesman) {
            return 'Vendedor'
        } else if (rep.isSupervisor) {
            return 'Supervisor'
        } else {
            return 'Vendedor'
        }
    }
    function notify(text,type) {
        toast.info(text, {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            type:type
        });

    };
    async function handleActivateSeller(seller) {
        setLoading(true)
        RepresentativeService.addSeller(seller, company.id).then(res =>
            getAll()
        ).then(res => {
            notify('Vendedor Adicionado', 'success')
            setLoading(false)
        }
        )
    }

    function filterAvailables(activateds, availables) {
        let filtrados: any = []

        for (let index = 0; index < availables.length; index++) {
            const element = availables[index];
            let skip = false
            for (let index = 0; index < activateds.length; index++) {
                const element2 = activateds[index];
                if (element2.id == element.id) {
                    skip = true
                }
            }
            !skip && filtrados.push(element)
        }
        setRepresentativesItems(filtrados)
    }

    return (

        <NewModalComponent modal={modal}>
            <div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable

                    pauseOnHover />
                <div className="navContainer">
                    <div onClick={() => setRepresentativesTab('ACTIVATEDS')} className={`navItemModal ${representativesTab == 'ACTIVATEDS' ? 'active' : ''}`}><a>Representantes ativos</a></div>
                    <div onClick={() => setRepresentativesTab('AVAILABLES')} className={`navItemModal ${representativesTab == 'AVAILABLES' ? 'active' : ''}`}><a>Representantes disponíveis</a></div>
                </div>
                {representativesTab == 'ACTIVATEDS' ?
                    <div className="listRepresentatives">
                        <table className="newTableRepresentatives">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Login</th>
                                    <th>Email</th>
                                    <th>Telefone</th>
                                    <th className="text-center">Desativar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {representatives.map((r) => (
                                    <tr key={r.name}>
                                        <td>{r.name}</td>
                                        <td>{r.login}</td>
                                        <td>{r.email}</td>
                                        <td>{r.phone}</td>
                                        <td className="text-center">
                                            <Switch
                                                className="status-switch"
                                                height={23}
                                                width={50}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                onColor="#F29C33"
                                                onChange={() => changeStatus(r)} checked={r.status}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    :
                    <div>
                        {loading ? <Loader></Loader> : null}


                        <div className={'listRepresentatives'}>
                            <table className={'newTableRepresentatives'}>
                                <thead>
                                    <tr>
                                        <th>Perfil</th>
                                        <th>Nome</th>
                                        <th>Login</th>
                                        <th>E-mail</th>
                                        <th>Telefone</th>
                                        <th className="text-center">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {representativesItems &&
                                        representativesItems.length > 0 &&
                                        // representatives.list.data.map(
                                        representativesItems.map(
                                            (item: Representative) => (
                                                <tr key={item.id}>
                                                    <td data-label='Perfil' >
                                                        <div className={'tdPerfilDiv'}>
                                                            <p style={{ marginTop: -4 }}>
                                                                <FaUserAlt
                                                                    className={
                                                                        'seller'
                                                                    }
                                                                />
                                                                {profile_description(item)}
                                                            </p>
                                                        </div>

                                                    </td>
                                                    <td data-label='Nome'>{item.name}</td>
                                                    <td data-label='Login'>{item.login}</td>
                                                    <td data-label='E-mail'>{item.email}</td>
                                                    <td data-label='Telefone'>{item.phone ? item.phone : '- -'}</td>
                                                    <td data-label='Ações'>
                                                        <div className="text-center">
                                                            {company && company.implanted == 1 ?
                                                                <Switch
                                                                    className="status-switch"
                                                                    height={23}
                                                                    width={50}
                                                                    //color='#000'
                                                                    checkedIcon={false}
                                                                    uncheckedIcon={false}
                                                                    onColor="#F29C33"
                                                                    offHandleColor='#c5c5c5'
                                                                    // offColor='#000'
                                                                    onChange={(e) => notify('Cliente não permite ação de novos vendedores em sua cotação','info')}
                                                                    checked={false}
                                                                /> :
                                                                <Switch
                                                                    className="status-switch"
                                                                    height={23}
                                                                    width={50}
                                                                    checkedIcon={false}
                                                                    uncheckedIcon={false}
                                                                    onColor="#F29C33"
                                                                    onChange={(e) => handleActivateSeller(item.id)}
                                                                    // onChange={(e) => notify('Cliente não permite ação de novos vendedores em sua cotação')}
                                                                    checked={false}
                                                                />
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                </tbody>
                            </table>



                            {
                                //    <PaginationCustom
                                //        total={allPages}
                                //        currentPage={currentPage}
                                //        onPageChange={handlePageChange}
                                //        totalItems={
                                //            representatives
                                //                ? representatives.list.data.length
                                //                : 0
                                //        }
                                //        ItemsPorPage={pageSize}
                                //        itemsName={'representantes'}
                                //    />
                            }
                            {paginationInfo.last_page > 0 ?
                                <div style={{ marginTop: 15 }}>

                                    <PaginationCustomV2
                                        // handleSelectPage={(page) => handleSelectPage(page)}
                                        handleSelectPage={(page) => setQueryParams({ ...queryParams, page: page })}
                                        paginationData={paginationInfo}
                                        paginationDataDescription={'de representantes.'}

                                    />
                                </div>
                                : null}
                        </div>

                    </div>
                }
            </div>

        </NewModalComponent>
    )
}
