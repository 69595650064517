import Constants from '../constants'
import HttpClient from './HttpClient'
import StorageService from './StorageService'

export default class AuthService {

    static authorize = (user: any) => HttpClient.post(Constants.URL_API_AUTH, {
        usu_login: user.username,
        senha: user.password,
    })

    static isLogged = () => !!StorageService.get(Constants.STORAGE_TOKEN_KEY)

    static signIn = (token: string) => StorageService.set(Constants.STORAGE_TOKEN_KEY, token)

    static signOut = () => StorageService.clear()

    static send_password = (credential) => {
        return HttpClient.get(`v2/credentials/password`, {
            credential: credential
        });
    }
}
