import Constants from "../../constants";
import React from "react";
import { AiOutlineCaretRight } from 'react-icons/ai'
import {  NavLink } from 'react-router-dom'
import {
    Container,
    LinkContainer,
    Link,
} from './styles'


interface props {
    pageLinks?: any
}


export function RoadMap({pageLinks}:props) {

    const teste = [
        {
            title: 'nomedapagina1',
            onClick: () => { }
        },
        {
            title: 'nomedapagina2',
            onClick: () => { }
        },
        {
            title: 'nomedapagina3',
            onClick: () => { }
        },
        {
            title: 'nomedapagina4',
            onClick: () => { }
        },
    ]



    return (

        <Container>
            <LinkContainer>
                <NavLink className='navlinkContainer' to={`${Constants.ROUTE_REQUESTS}`}>
                    <Link>Home</Link>
                </NavLink>
            </LinkContainer>
            {pageLinks && pageLinks.map(item => {
                return (
                    <LinkContainer key={ item.title }>
                        <AiOutlineCaretRight size={14} color="#ff7110"></AiOutlineCaretRight>
                        <Link onClick={item.onclick}>{item.title}</Link>
                    </LinkContainer>
                )
            })}
        </Container>


    )
}
