// actions.js
export const SET_IS_MOBILE = 'SET_IS_MOBILE';

export const setIsMobile = (isMobile) => ({
  type: SET_IS_MOBILE,
  payload: isMobile,
});

// reducer.js
const initialState = {
  isMobile: false,
};

export const mobileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    default:
      return state;
  }
};
