/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable react-hooks/exhaustive-deps */
import './index.scss'

import * as $ from 'jquery'
import React, { useEffect } from 'react'
import { BsCalendarMinus } from 'react-icons/bs'

import ModalComponent from 'components/modal-v2'
import { WeekdayForm } from './weekday-form'
import { SettingsForm } from './settings-form'
import { BlacklistDatesForm } from './blacklist-form'
import { WhitelistDatesForm } from './whitelist-form'

export const WeekdayModal = ({ isVisible, onCloseCallback, user }: any) => {
    const modal = {
        icon: <BsCalendarMinus />,
        styles: { maxWidth: '80vw' },
        id: 'weekday-modal-mine',
        title: `Parâmetros de venda direta`,
    }

    useEffect(() => {
        if (isVisible) {
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
            $('#' + modal.id).modal('show')
        } else {
            $('#' + modal.id).removeClass('fadeIn')
            $('#' + modal.id).addClass('fadeOutUp')
            $('#' + modal.id).modal('hide')
        }
    }, [isVisible])

    return (
        <ModalComponent
            modal={modal}
            show={isVisible}
            close={onCloseCallback}
            className={'modal-dialog'}
        >
            <div className="container-fluid">
                <div className="row header">
                    <div className="col m-4 p-3 shadow">
                        <WeekdayForm user={user} />
                    </div>
                </div>

                <div className="row header">
                    <div className="col m-4 p-3 shadow">
                        <SettingsForm user={user} />
                    </div>
                </div>

                <div className="row header">
                    <div className="col m-4 p-3 shadow">
                        <BlacklistDatesForm user={user} />
                    </div>
                </div>

                <div className="row header">
                    <div className="col m-4 p-3 shadow">
                        <WhitelistDatesForm user={user} />
                    </div>
                </div>
            </div>
        </ModalComponent>
    )
}
