import { User } from "interfaces/user"
import React, { useCallback, useEffect, useReducer } from "react"
import AuthService from "services/AuthService"
import UserService from "services/UserService"
import { ProfileService } from "services/v2/profile.service"
import AuthReducer, { LOGIN_RESULT, LOGOUT, USER_PERMS } from "./reducer"
import mods from './permission-modules'
const state : any = {
    perms: [ ],
    user: null,
    loaded: false,
    modules: mods
}

const AuthContext = React.createContext(state)

export const AuthProvider = ({ children } : any) => {

    const [ user, dispatch ] = useReducer(AuthReducer, state)

    const me = useCallback(async () => {
        return UserService.me().then((user: User) => {
            load_profiles(user)
            return user
        }).catch((response) => {
            return response.response
        })
    }, [ ])

    const logout = async () => {
        return  dispatch({ type: LOGOUT })
    }

    const load_profiles = async (usr: any) => {
        return ProfileService.my().then(profiles => {
            dispatch({ type: USER_PERMS, perms: profiles })
            if(usr) {
                dispatch({ type: LOGIN_RESULT, user: usr })
            }
        })
    }

    const valid_json = (str) => {
        try {
            JSON.parse(str)
        } catch (e) {
            return false
        }
        return true
    }

    const can = (action: string, resource: string, extra: any) => {
        return ( !user.perms || user.perms.length === 0) ||
            user.perms.some((perm: any) => {
                const sub_ids = valid_json(`[${perm.subject_ids}]`) && perm.subject_ids
                    ? JSON.parse(`[${perm.subject_ids}]`)
                    : null
                const valid_extra = (!sub_ids || !extra || (sub_ids.includes(extra)))
                return perm.resource === resource && perm.action === action && valid_extra
            })
    }

    const setting = (key: string) => {
        return user.user.settings.find((set: any) => set.key === key)
    }

    useEffect(() => {
        if(AuthService.isLogged()) {
            me()
        }
    }, [ me ])

    return (
        <AuthContext.Provider value={ { ...user, can, setting, load_profiles, me, logout } }>
            { children }
        </AuthContext.Provider>)
}

export default AuthContext
