import React, { FC, useState } from 'react'
import { Select } from 'components/select'
import './index.scss'
import { Input } from 'components/input'
import DateService from 'services/DateService'
import { FilterComponent } from 'components/filter'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import Constants from '../../../../constants'
import InputV2 from 'components/InputV2'
import {IoIosAddCircleOutline} from 'react-icons/io'
import { AiOutlineSearch, AiOutlineClear, AiOutlineCloseCircle } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs'
import ButtonV3 from 'components/Buttonv3'

export const CampanhasFilterComponent: FC<any> = ({ onChange }: any) => {

    const months = [{ name: 'Últimos 3 Meses', id: 3 }]

    const initialQueryParams = {
        title: null,
        page: 1,
        from: DateService.formatToRequest(DateService.removeMonthToDate(new Date(), 3)),
    }

    const [params, setParams] = useState<any>(initialQueryParams)

    const update = (params: any) => {
        onChange(params)
    }

    const clearFilters = () => {
        onChange(initialQueryParams)
    }

    return (

        <div>

            {
                //   <FilterComponent title="Filtrar Solicitações" filter={() => update(params)} clear={clearFilters}>
            }
            <div style={{
                'display': 'flex',
                'flexDirection': 'row',
                'alignItems': 'flex-end',
                'justifyContent': 'flex-end',
                'flexWrap': 'wrap'
            }}>

                <Link to={Constants.ROUTE_CAMPAINS_ADD} className="btn__primary btn-icon add-button">
                <ButtonV3
                            Icon={<IoIosAddCircleOutline className='iconButtonV3' />}
                            title='Nova Campanha'
                           // onClick={() =>{}}
                           
                        />
                </Link>

                

            </div>
            <div style={{
                'display': 'flex',
                'flexDirection': 'row',
                'alignItems': 'flex-end',
                'flexWrap': 'wrap',
                'justifyContent':'space-between',
            }}>

                <div style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    'alignItems': 'flex-end',
                    
                    'flexWrap': 'wrap',
                    
                }}>


                    <div style={{ 'width': 250, 'marginRight': 20, 'marginBottom': 10 }}>
                        <InputV2
                            infoIconNull
                            icon={<AiOutlineSearch color='#898989'></AiOutlineSearch>}
                            mask={undefined}

                            id={'Buscar'}
                            label={'Buscar'}
                            value={params.numerocotacao}
                            type={'text'}
                            placeholder={'Pesquisar Campanha'}
                            onChange={(e: any) => { setParams({ ...params, title: e.target.value }) }}

                        />

                        {
                            //   <Input
                            //       description="Buscar"
                            //       placeholder="Pesquisar Campanha"
                            //       mode="search"
                            //       onChange={(e: any) => { setParams({ ...params, title: e.target.value }) }}
                            //   />
                        }
                    </div>

                    <div style={{ 'width': 250, 'marginRight': 20, 'marginBottom': 10 }}>
                        <Select
                            value_key="id"
                            description="Filtrar por"
                            label_key="name"
                            blank_label="Selecione um período"
                            options={months}
                            value="3"
                            onChange={(e: any) => { setParams({ ...params, from: DateService.formatToRequest(DateService.removeMonthToDate(new Date(), e.target.value)) }) }}
                        >
                        </Select>
                    </div>
                </div>

                <div style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    'alignItems': 'flex-end',
                    'flexWrap': 'wrap'
                }}>
                    <div style={{ 'marginRight': 20, 'marginBottom': 10 }}>
                        <ButtonV3
                            Icon={<AiOutlineCloseCircle className='iconButtonV3' />}
                            title='Limpar filtros'
                            onClick={() => clearFilters()}
                        />

                    </div>
                    {
                        //   <Button
                        //       className="c-btn-outline"
                        //       onClick={props.filter}
                        //   >
                        //       <BsSearch />
                        //       Filtrar
                        //   </Button>

                    }
                    {
                        //    <Button
                        //        className="c-btn-outline"
                        //        type="button"
                        //        onClick={(props.clear)}
                        //    >
                        //        <AiOutlineClear />
                        //        Limpar filtros
                        //    </Button>

                    }
                    <div style={{ 'marginRight': 20, 'marginBottom': 10 }}>


                        <ButtonV3
                            filled
                            title='Filtrar'
                            onClick={() => update(params)}
                        />
                    </div>
                </div>

            </div>
            {
                //  </FilterComponent>
            }
        </div>
    )
}
