import React, { useEffect, useState } from 'react'
import './index.scss'
import { BsCheck } from 'react-icons/bs'
import HttpClient from 'services/v3/http.client'

interface props {
    description: string
    checked: boolean
    change: (e, stats) => void
    onDelete?: (id: number) => Promise<void>
    value?: any
    preventUncheck?: boolean
    disabled?: boolean
}

export function CheckBoxCustom({
    description,
    checked,
    change,
    value,
    preventUncheck,
    disabled,
    onDelete,
}: props) {
    const [isChecked, setIsChecked] = useState(Boolean)
    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    // No CheckBoxCustom
    async function handleChecked(data: any) {
        if (isChecked) {
            if (onDelete) {
                await onDelete(value)
            }
            change(data, false)
        } else {
            change(data, true)
        }
        setIsChecked(!isChecked)
    }

    function handleCheck(data) {
        isChecked ? () => {} : change(data, true)
    }

    return (
        <div
            className="customCheckContainer"
            onClick={
                disabled
                    ? () => {}
                    : preventUncheck
                    ? () => handleCheck(value)
                    : () => handleChecked(value)
            }
        >
            <div
                style={{ minWidth: 25 }}
                className={` checkArea  ${disabled ? 'disabledArea' : ''}`}
            >
                {isChecked && <BsCheck size={32} className="CheckBIcon" />}
            </div>
            <p className="checkDescription">{description}</p>
        </div>
    )
}
