import React, { useState, useContext } from 'react'
import { ImLab } from 'react-icons/im'
import Logo from '../../assets/Logo-BRANCA.svg'
import LogoFace from '../../assets/Facebook_black.svg'
import LogoInsta from '../../assets/Instagram_black.svg'
import LogoMail from '../../assets/Mail_black.svg'
import LogoYT from '../../assets/Youtube_black.svg'
import WhatsLogo from '../../assets/WhatsApp_black.svg'
import AnyLogo from '../../assets/Rectangle_32.svg'
import { FaUserCircle } from 'react-icons/fa'
import { FaUserAlt } from 'react-icons/fa'
import { UserContext } from 'contexts/user'

import './index.scss'

import Sidebar from '../sidebar'
import { Loader } from '../loader'
import Constants from '../../constants'

export function ProjectContainer({ loading, children, ...props }: any) {

    const [suportNumbersVisible, setSuportNumbersVisible] = useState(false)

    const { can, user } = useContext(UserContext)
    return (
        <>
            <div>
                <div className='containerHeader'>
                    <div className='headerContainerDesktop'>
                        <div className='logoContainer'>
                            <img src={Logo} alt="logo do club da cotação" className='HeaderLogo' />
                        </div>
                        <div className='iconsContainer'>
                            <div className="socialMediaContainer">
                                <img src={LogoFace} alt="logo do club da cotação" className='headerIcons' onClick={() => window.open('https://www.facebook.com/clubdacotacao')} />
                                <img src={LogoInsta} alt="logo do club da cotação" className='headerIcons' onClick={() => window.open('https://www.instagram.com/clubdacotacao')} />
                                <img src={LogoMail} alt="logo do club da cotação" className='headerIcons' onClick={() => window.open('mailto:contato@clubdacotacao.com.br')} />
                                <img src={LogoYT} alt="logo do club da cotação" className='headerIcons' onClick={() => window.open('https://www.youtube.com/channel/UCiP8ACo7VDoIzlUwVC5m5Xw')} />
                                <div className='iconBadge' onClick={() => setSuportNumbersVisible(!suportNumbersVisible)}>
                                    <img src={WhatsLogo} alt="logo do club da cotação" className='headerIcons avatarOnBadge' />
                                    <p unselectable="on" className='iconBadgeText'>Suporte</p>
                                    {suportNumbersVisible && (
                                        <div className='suportNumbersContainer'>
                                            <div className='SuportNumberDiv'><FaUserAlt /> <a href="https://api.whatsapp.com/send?phone=5581996490176" target="_blank"> +55 (81) 99649-0176 </a></div>
                                            <div className='SuportNumberDiv'><FaUserAlt /> <a href="https://api.whatsapp.com/send?phone=5581997413889" target="_blank"> +55 (81) 99741-3889 </a></div>
                                            <div className='SuportNumberDiv'><FaUserAlt /> <a href="https://api.whatsapp.com/send?phone=5581997410500" target="_blank"> +55 (81) 99741-0500 </a></div>
                                        </div>
                                    )}
                                </div>
                                <div className='iconBadge' onClick={() => window.open('https://anydesk.com/pt/downloads')}>
                                    <img src={AnyLogo} alt="logo do club da cotação" className='headerIcons avatarOnBadge' />
                                    <p className='iconBadgeText'>Anydesk</p>
                                </div>

                            </div>
                            <div className="userInfoContainer">
                                <FaUserCircle className='avatarIcon ' />
                                <div className="userDescriptionContainer">
                                    <p className='userNameHeader'>{user && user.name ? user.name : ''}</p>
                                </div>

                            </div>
                        </div>



                    </div>
                </div>




                <div className="newContainerMainRoot"
                    {...props}>
                    {
                        //     Constants.ENV !== 'prod' && <div className="row env">
                        //         <ImLab />
                        //         <p>
                        //             [ { Constants.ENV } ] { Constants.URL_API }
                        //         </p>
                        //     </div>
                    }
                    <div style={{ display: 'flex' }}>
                        <Sidebar />
                        {loading ? <Loader /> : <main className="col body marginTopSet removepaddinMobile">
                            {children}
                        </main>}
                    </div>
                </div>
            </div >
        </>
    )
}














//export const ProjectContainer = ({ loading, children, ...props }: any) => {
//    return (
//        <div>
//
//            <div className='divTesteHeader'><p className="testeHeader">tesssste</p>
//            </div>
//            <div className="container-fluid" {...props}>
//                {
//                    // Constants.ENV !== 'prod' && <div className="row env">
//                    //     <ImLab />
//                    //     <p>
//                    //         [ { Constants.ENV } ] { Constants.URL_API }
//                    //     </p>
//                    // </div>
//                }
//                <div className="row">
//                    <Sidebar />
//                    {loading ? <Loader /> : <main className="col body">{children}</main>}
//                </div>
//            </div>
//        </div>
//    )
//}
