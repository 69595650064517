import Constants from '../constants'
import { PaymentType } from '../interfaces/payment-type.model'
import HttpClient from './HttpClient'

export default class PaymentTypeService {

    static parse = (salesmanFromApi: any) : PaymentType => ({
        id: salesmanFromApi.ttp_id,
        name: salesmanFromApi.ttp_nome
    })

    static getAll = () : Promise<PaymentType[]> =>
        HttpClient.get(Constants.URL_API_PAYMENT_TYPES)
            .then((response) => {
                const salesmen: PaymentType[] = []
                response.data.forEach((type: any) => salesmen.push(PaymentTypeService.parse(type)))
                return salesmen
            })
}
