import * as React from 'react'
import { RouteProps, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import AuthService from '../services/AuthService'
import Constants from '../constants'
import { Navigate } from 'react-router-dom'
import { UserContext } from 'contexts/user'
import AuthServiceV3 from 'services/v3/auth.service'
import { useContext, useEffect, useState } from 'react'
import { Loader } from 'components/loader'

export interface ProtectedRouteProps extends RouteProps {
  path: string;
}
export const ProtectedRoute = ({ Component } : any ) => {

    const useQuery = () => new URLSearchParams(useLocation().search)
    const tkn = useQuery().get('token')
    const { me, load_profiles } = useContext(UserContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [searchParams, setSearchParams] = useSearchParams()

    const process_token = async (token: string) => {
        try {
            const response = await AuthServiceV3.auth_token(token)
            AuthService.signIn(response.token)
            await me().then((response: any) => {
                if(response && response.status !== 401) {
                    load_profiles()
                }
            })
            return response.token
        } finally {
            searchParams.delete('token')
            setSearchParams(searchParams)
            setLoading(false)
        }
    }

    useEffect(() => {
        tkn ? process_token(tkn) : setLoading(false)
    }, [ tkn ])

    if(!loading) {
        return AuthService.isLogged() ? <Component /> : <Navigate to={ Constants.ROUTE_SIGNIN } />
    }
    return <Loader />
}

export default ProtectedRoute
