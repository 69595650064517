import styled from 'styled-components'

export const Container =styled.div`
    display: flex;
    gap: 15px;
    padding: 10px 0px;

`;
export const LinkContainer =styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
`;
export const Link =styled.p`
    font-size: 14px;
    font-weight: 400;
    font-family: Rubik,sans-serif;
    color: #ABABAB;

    cursor: pointer;
    &:hover{
        color: #ff7110;
    }

`;
