import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;



    @media only screen and (max-width: 740px) {
    flex-direction: column;
    margin-bottom: 10px;
    }
`;

export const BodyContainer = styled.div`
`;

export const FiltersContainer = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
`;

export const DatePickerAndButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    flex-wrap: wrap;
    gap: 15px;

    @media only screen and (max-width: 740px) {

        flex-direction: column;
}
    `;

export const DatePickersContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
    overflow: visible;
    div{
       overflow-y: visible;

      // width: 150px;
    }

    @media only screen and (max-width: 740px) {
        width: 80%;
        flex-wrap: nowrap;
        flex-direction: column;
        div{
        width: 100% !important;
    }
    }
`;
export const FilterButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: flex-end;
    @media only screen and (max-width: 740px) {
        width: 100%;
        justify-content: flex-end;

    }
`;
export const SelectFiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    align-items: flex-end;


    @media only screen and (max-width: 740px) {

    flex-direction: column;


    div{
        width: 100%;
    }
}
`;
export const TableContainer = styled.div`
`;
export const ChecksContainer = styled.div`
display: flex;
gap: 15px;
flex-wrap: wrap;
`;


interface props {
    bg: string,
    color:string
}
export const CustomCheckContainer = styled.div<props>`
border-radius: 5px;
display: flex;
flex-direction: row;
gap: 5px;
align-items: center;
p{
    color: ${props => props.color};;
}

section{
    padding: 6px 8px;
    background-color: ${props => props.bg};
    border-radius: 6px;
    margin-left: -6px;
    /* height: 80%;
    width: 25px;
    display: flex;
    border-radius: 25%;
    align-items: center;
    justify-content: center;
    padding: 4px 2px 4px 7px;
    /* box-shadow: 1px 1px 2px 1px ${props => props.bg};; */
}

`;
export const DivClienteEndereco= styled.div`
    section{
        /* background-color: red; */
        display: flex;
        flex-direction: row;
        /* gap: 10px; */
    }
    p{
        font-weight: 400;
    }
    @media only screen and (max-width: 900px) {
        display: flex;
        /* flex-direction: row; */
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

    }
`;
