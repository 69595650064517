import formatCnpj from '@brazilian-utils/format-cnpj'

import DateService from 'services/DateService'

const f = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
})

export const mask_cnpj = (cnpj : any) => {
    cnpj = `${cnpj}`
    while(cnpj.length < 14) {
        cnpj = '0' + cnpj
    }
    return formatCnpj(cnpj)
}

export const mask_currency = (val : any) => {
    return f.format(val)
}

export const mask_date = (date : any) => {
    if (!date) return date
    return DateService.formatToDisplay(new Date(date))
}

export const mask_datetime = (date : any) => {
    if (!date) return date

    return DateService.formatToDisplayWithTime(new Date(date))
}
export function phoneMask(v) {

   let r = v.replace(/\D/g, "");
   r = r.replace(/^0/, "");
 
   if (r.length > 11) {
     r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
   } else if (r.length > 7) {
     r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
   } else if (r.length > 2) {
     r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
   } else if (v.trim() !== "") {
     r = r.replace(/^(\d*)/, "($1");
   }
   return r;

  }
