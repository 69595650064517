import React, { FC } from 'react'
import styles from './index.module.scss'

export const STATUS_ORDERED = 'ORDERED'
export const STATUS_NOT_ORDERED = 'NOT_ORDERED'
export const STATUS_TYPED = 'TYPED'
export const STATUS_NOT_TYPED = 'NOT_TYPED'
export const STATUS_FROZEN = 'FROZEN'
export const STATUS_NOT_OFFERED = 'NOT_OFFERED'
export const STATUS_WINNER = 'WINNER'
export const STATUS_LOOSER = 'LOOSER'

interface Props {
  status: string
}

export const Status: FC<Props> = ({ status } : any) => {
    switch( status ) {
    case STATUS_LOOSER:
        return <p className={ `${styles.status} ${styles.ordered}` }>Não Vencedor</p>
    case STATUS_WINNER:
        return <p className={ `${styles.status} ${styles.typed}` }>Vencedor</p>
    case STATUS_ORDERED:
        return <p className={ `${styles.status} ${styles.ordered}` }>Pedido Gerado</p>
    case STATUS_TYPED:
        return <p className={ `${styles.status} ${styles.typed}` }>Digitado</p>
    case STATUS_NOT_ORDERED:
        return <p className={ `${styles.status} ${styles['not-ordered']}` }>Sem Pedido</p>
    case STATUS_NOT_TYPED:
        return <p className={ `${styles.status} ${styles['not-typed']}` }>Não Digitado</p>
    case STATUS_NOT_OFFERED:
        return <p className={ `${styles.status} ${styles['not-offered']}` }>Sem oferta</p>
    case STATUS_FROZEN:
        return <p className={ `${styles.status} ${styles.frozen}` }>Congelado</p>
    default:
        return <p className={ `${styles.status} ${styles['waiting-confirmation']}` }>Cancelado</p>
    }
}
