import React, { useEffect, useState } from 'react'
import Constants from '../../../constants'
import ProductForm from '../components/form'
import DirectSaleProductService from '../../../services/DirectSaleProductService'
import { DirectSaleProduct } from '../../../interfaces/directSaleProduct'
import { ProjectContainer } from '../../../components/project-container'
import { Link, useNavigate } from 'react-router-dom'
import { Loader } from '../../../components/loader'
import './index.scss'
import { CiBoxes } from "react-icons/ci";
import { PageHeader } from '../../../components/page-header'
import { Button } from 'components/button'
import { Snackbar } from 'components/snackbar'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { useSnackbar } from 'react-simple-snackbar'
import { useContext } from 'react'
import { UserContext } from 'contexts/user'
import ButtonV3 from 'components/Buttonv3'
import { HiOutlineBuildingStorefront } from "react-icons/hi2";
import { ImArrowLeft2 } from 'react-icons/im'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { AiOutlineDollar, AiOutlineUnorderedList } from 'react-icons/ai';
import { RoadMap } from 'components/roadmap'
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align'
import { BaseModal } from 'components/BaseModal'
import { AddProductToGroupModal } from '../components/add-product-to-group-modal'
import ProductService from 'services/ProductService'
import { ProductPriceLevels } from '../price-level'
import HttpClient from '../../../services/v3/http.client'
import ClassificationService from 'services/v3/classification.service'
import { IoFilterCircleSharp } from 'react-icons/io5'
import ClassificationModal from '../components/classificationsModal'
const ProductAdd = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const [groups, setGroups] = useState<any[]>([])


  const [isAddProductToGroupModalVisible, setAddProductToGroupModalVisible] = useState(false)
  const [isPriceLevelVisible, setIsPriceLevelVisible] = useState(false)
  const [classifications, setClassifications] = useState<any>()
  const [createdProduct, setCreatedProduct] = useState<any>(null)

  const [selectedsClassifications, setSelectedsClassifications] = useState<any>([])
  const [modalClassificationVisible, setModalClassificationVisible] = useState(false)

  async function getClassification(page = 1) {
    await ClassificationService.getclassification(page).then(res => {
      console.log('classifications', res)
      setClassifications(res)
    })
  }


  useEffect(() => {
    if (user) {
      ProductService.groups(user.providerId).then((result) => setGroups(result.groups))
      getClassification()
    }
  }, [user])

  const save = (product: DirectSaleProduct, levels: number[]) => {
    setLoading(true)
    DirectSaleProductService.add(product, levels, user.providerId)
      .then((res) => {
        setLoading(false)
        // HttpClient.post(`/providers/classificacao/sub/items`, product.classificacao)

        setCreatedProduct(DirectSaleProductService.parse(res))
        //?teste retirei o navigate
        // navigate(Constants.ROUTE_PRODUCTS)
        openSnackbar(
          <Snackbar icon="sticky_note_2">
            Produto salvo com sucesso!
          </Snackbar>
        )
      })
      .catch((error) => {
        openSnackbar(
          <Snackbar icon="sticky_note_2">
            {error.response && error.response.data
              ? error.response.data.mensagem
              : 'Ocorreu um erro ao salvar o produto!'}
          </Snackbar>
        )
        setLoading(false)
      })
  }

  const submit_form = () => {
    const sub_btn = document.getElementById('save-product')
    if (sub_btn) {
      sub_btn.click()
    }
  }
  // const product = {
  //   "for_id": "886",
  //   "cat_id": null,
  //   "pro_descricao": "testeCArlosss",
  //   "pro_embalagem": "CX 2",
  //   "pro_caixa": "2",
  //   "pro_ean": "323232",
  //   "pro_dataCadastro": "2024-04-26 18:28:32",
  //   "pro_status": 1,
  //   "pro_excluido": 0,
  //   "pro_codInterno": "323232",
  //   "pro_embalagemQtd": "2",
  //   "pro_sub_cat_id": 22,
  //   "pro_limite_diario": "3",
  //   "pro_estoque": "2",
  //   "pro_qtd_min": "3",
  //   "storage_control": 1,
  //   "pro_informacoes_adicionais": null,
  //   "prod_terceiro": 1,
  //   "pro_imagem": "https://i.ibb.co/S7dYJ9x/244bda21cd11.png",
  //   "pro_id": 2518
  // }
  // const createdProduct = DirectSaleProductService.parse(product)

  function handleNiveisDePreco(proid) {
    setIsPriceLevelVisible(true)
  }

  function handleGrupoDeProdutos(proid) {
    setAddProductToGroupModalVisible(true)

  }

  return (
    <ProjectContainer>
      <RoadMap
        pageLinks={[
          {
            title: 'Produtos',
            onclick: () => navigate(Constants.ROUTE_PRODUCTS)
          },
          {
            title: 'Adicionar Produto',
            onclick: () => { }
          },
        ]}
      ></RoadMap>
      <div className="products-add">
        {loading && <Loader />}
        <header style={{ marginBottom: 25 }}>
          <PageHeaderV2 title="Adicionar Produto" />
        </header>

        {createdProduct ?
          <div className='createdContainer' >
            <div className='createdProductContainer'>

              <img
                src={createdProduct.picture ??
                  'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                alt=""
                className='createdProductImage'
              />
              <div className='InfosContainer'>

                <section className=''>
                  <div className='titleRowNewProd'>

                    <h5>{createdProduct.description ?? ''} </h5>

                  </div>

                </section>
                <section className='sectionInfosCreatedProd'>

                  <div className='infoRowNewProd'>
                    <p>Embalagem:</p>
                    <span>{createdProduct.packing ?? ''}</span>

                  </div>
                  <div className='infoRowNewProd'>
                    <p>Qtd. embalagem:</p>
                    <span>{createdProduct.packingQuantity ?? ''}</span>

                  </div>
                  <div className='infoRowNewProd'>
                    <p>Código:</p>
                    <span>{createdProduct.ean ?? ''}</span>
                  </div>
                </section>
                <section className='sectionInfosCreatedProd'>
                  <div className='infoRowNewProd'>
                    <p>Limite diário</p>
                    <span>{createdProduct.dailyLimit ?? ''}</span>

                  </div>
                  <div className='infoRowNewProd'>
                    <p>Estoque:</p>
                    <span>{createdProduct.stock ?? ''}</span>

                  </div>
                  <div className='infoRowNewProd'>
                    <p>Qtd. Mínima:</p>
                    <span>{createdProduct.pro_qtd_min ?? ''}</span>

                  </div>
                  <div className='infoRowNewProd'>
                    <p>Qtd. na caixa:</p>
                    <span>{createdProduct.pro_caixa ?? ''}</span>
                  </div>
                </section>
                {createdProduct.additionalInformations ?
                  <section className='sectionInfosCreatedProdFlags'>
                    <div className='infoRowNewProd'>
                      <p >Informações Adicionais: </p>
                      <span>{createdProduct.additionalInformations ?? ''}</span>
                    </div>

                  </section>
                  : null}
                <section className='sectionInfosCreatedProdFlags'>
                  {createdProduct.prod_terceiro ?
                    <div className='infoRowNewProd'>
                      <HiOutlineBuildingStorefront color='#ff7110' size={20} />

                      <p>Produto de terceiro</p>
                    </div>
                    : null}
                  {createdProduct.storage_control ?
                    <div className='infoRowNewProd'>
                      <CiBoxes color='#ff7110' size={20}></CiBoxes>
                      <p>Controle de estoque</p>
                    </div>
                    : null}
                </section>
              </div>



            </div>
            <div className="buttonsContainerCreatedProduct">
              <section style={{ minWidth: 140 }}>


                <ButtonV3
                  style={{ width: '100%', justifyContent: 'center' }}
                  filled
                  title='Voltar'
                  onClick={() => navigate(Constants.ROUTE_PRODUCTS)} />

              </section>
              <section>
                {createdProduct?.id ?
                  <ButtonV3
                    style={{ width: '100%', justifyContent: 'center' }}
                    Icon={<IoFilterCircleSharp className='iconButtonV3' />}
                    type='button'
                    onClick={() => setModalClassificationVisible(true)}
                    title='Selecionar Classificação' />
                  : null}
                <ButtonV3
                  style={{ borderColor: '#30AA4C', width: '100%', justifyContent: 'center' }}
                  Icon={<AiOutlineDollar
                    title='Níveis de preço'
                    style={{
                      'color': '#30AA4C',
                      'fontSize': 20,
                      marginRight: 10
                    }} />}
                  title='Níveis de preço'
                  onClick={() => handleNiveisDePreco(createdProduct.id)} />
                <ButtonV3
                  style={{ borderColor: '#17A2B8', minWidth: 195, width: '100%', justifyContent: 'center' }}
                  Icon={
                    <AiOutlineUnorderedList style={{
                      'color': '#17A2B8',
                      'fontSize': 20,
                      'cursor': 'pointer',
                      marginRight: 10
                    }}></AiOutlineUnorderedList>
                  }
                  title='Grupo de Produtos'
                  onClick={() => handleGrupoDeProdutos(createdProduct.id)} />

              </section>
            </div>
          </div>
          :
          <div className="form">
            <ProductForm
              classifications={classifications}
              saveCallback={(
                product: DirectSaleProduct,
                levels: number[],
                // classifications:classifications

              ) => save(product, levels)}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: 20,
                marginTop: 25,
              }}
            >
              <Link to={Constants.ROUTE_PRODUCTS}>
                <ButtonV3
                  Icon={<ImArrowLeft2 className="iconButtonV3" />}
                  style={{ padding: '6px 35px', height: 45 }}
                  title="Voltar"
                  type="button"
                  onClick={submit_form}
                />
                {
                  //   <Button>
                  //       <i className="material-icons">arrow_back</i>Voltar
                  //   </Button>
                }
              </Link>
              {
                //     <Button
                //         type="submit"
                //         className="c-btn-save ml-3"
                //         onClick={submit_form}>
                //         <i className="material-icons">save_alt</i>Salvar
                //     </Button>
              }
              <ButtonV3

                filled
                style={{ padding: '6px 35px', height: 45 }}
                title="Salvar"
                type="submit"
                onClick={submit_form}
              />
            </div>
          </div>
        }
      </div>
      {createdProduct ?
        <>
          <BaseModal
            onCloseCallback={() => setAddProductToGroupModalVisible(false)}
            children={

              <AddProductToGroupModal
                user={user}
                groups={groups}
                product={createdProduct}
                isVisible={isAddProductToGroupModalVisible}
                onClose={() => setAddProductToGroupModalVisible(false)} />
            }
            title={'Escolha de Grupo de produtos'}
            user={user}
            groups={groups}
            product={createdProduct}
            isVisible={isAddProductToGroupModalVisible}
            onClose={() => setAddProductToGroupModalVisible(false)} />




          <BaseModal
            onCloseCallback={() => setIsPriceLevelVisible(false)}
            children={

              <ProductPriceLevels
                forModal={{ id: createdProduct.id, onCloseCallback: () => setIsPriceLevelVisible(false) }} />
            }
            title={'Níveis de preço'}

            isVisible={isPriceLevelVisible}
            onClose={() => setIsPriceLevelVisible(false)} />
        </>
        : null}
      {createdProduct?.id ?
        <BaseModal
          data={null}
          title={`Classificações`}
          isVisible={modalClassificationVisible}
          onCloseCallback={() => setModalClassificationVisible(false)}
          children={<ClassificationModal
          closeCallback={()=>setModalClassificationVisible(false)}
          showSaveButton={true}
            proId={createdProduct?.id}
            selecteds={selectedsClassifications}
            onChangeValue={(data) => setSelectedsClassifications(data)}
            classifications={classifications} />
          }
          modalBodyStyle={{ overflow: 'none' }}
        />
        : null}
    </ProjectContainer>
  )
}

export default ProductAdd
