import React, { useEffect, useState } from 'react'
import { ButtonDownload, ButtonsContainer, Container, ContentContainer, EmptyResponseContainer } from './styles'

import { Datepicker } from 'components/datepicker'
import ButtonV3 from 'components/Buttonv3'
import ProviderService from 'services/v3/ProviderService'
import CsvDownload from 'react-csv-downloader'
import DateService from 'services/DateService'
import { Loader } from 'components/loader'
import { TbLoader2 } from "react-icons/tb";


interface props {
    data: any,
    onCloseCallback: () => void
}

export default function DownloadExcelModal({ data, onCloseCallback }: props) {

    const dataMinima = new Date();
    dataMinima.setMonth(dataMinima.getMonth() - 6);

    const dataInicialDefault = new Date();
    dataInicialDefault.setMonth(dataInicialDefault.getMonth() - 1);


    const [dataInicial, setDataInicial] = useState<any>(dataInicialDefault)
    const [dataFinal, setDataFinal] = useState<any>(new Date())
    const [isLoading, setIsLoading] = useState<any>(false)
    const [emptyResponse, setEmptyResponse] = useState<any>(false)




    async function handleGetRelatorio() {
        setIsLoading(true)
        setEmptyResponse(false)

        return ProviderService.getRelatiorio(DateService.formatToRequest(new Date(dataInicial)), DateService.formatToRequest(new Date(dataFinal)))
    }
    const asyncFnComputeDate = () => {
        return handleGetRelatorio().then((response: any) => {

            if (response.length === 0) {
                setIsLoading(false);
                setEmptyResponse(true)
                throw Error('');
            }
            const dadosFormatados: any = [];

            response.forEach(item => {
                item.item.forEach((subItem: any) => {
                    dadosFormatados.push({
                        pedido: item.id_pedido,
                        cnpj: item.loj_cnpj,
                        descricao: item.loj_descricao,
                        vendedor: item.usu_nome,
                        datadopedido: DateService.formatToDisplay(new Date(item.data_geracao)),
                        // datadopedido:item.data_geracao,
                        produto: subItem.descricao.replace(',', '.'),
                        embalagem: subItem.embalagem,
                        quantidade: subItem.quantidade,
                        valorUnitario: `R$ ${Number(subItem.valorUnitario.toString().replace(',', '.')).toFixed(2)} `,
                        observacao: subItem.observacao ? subItem.observacao.replace(',', ' ') : '',
                    });
                });
            });


            setIsLoading(false)
            return Promise.resolve(dadosFormatados);
        }).catch(errr => {
            setIsLoading(false),
                Promise.reject();
        });
    };
    //         console.log('formatados', dadosFormatados)

    //         setIsLoading(false)
    //         return Promise.resolve(dadosFormatados);
    //     }).catch(errr => { alert('erro ao gerar planilha'), setIsLoading(false), console.log(errr) });
    // };

    const columns = [
        {
            id: 'pedido',
            displayName: 'pedido '
        },
        {
            id: 'cnpj',
            displayName: 'cnpj '
        },
        {
            id: 'descricao',
            displayName: 'Descrição '
        },
        {
            id: 'vendedor',
            displayName: 'Vendedor '
        },
        {
            id: 'datadopedido',
            displayName: 'Data do pedido '
        },
        {
            id: 'produto',
            displayName: 'produto '
        },
        {
            id: 'embalagem',
            displayName: 'Embalagem '
        },
        {
            id: 'quantidade',
            displayName: 'Quantidade '
        },
        {
            id: 'valorUnitario',
            displayName: 'Valor Unitário '
        },
        {
            id: 'observacao',
            displayName: 'Observacao '
        },
    ];


    return (
        <Container>

            <ContentContainer>

                <h4>Selecione o periodo dos dados.</h4>

                <section>
                    <Datepicker
                        callendarIcon
                        value={dataInicial}
                        description="Data Inicial"
                        min={dataMinima}
                        max={new Date()}

                        onChange={(date: any) => setDataInicial(new Date(date))}
                    />
                    <Datepicker
                        callendarIcon
                        value={dataFinal}
                        description="Data Final"
                        min={dataMinima}
                        max={new Date()}

                        onChange={(date: any) => setDataFinal(new Date(date))}
                    />
                </section>
                {emptyResponse ?

                    <EmptyResponseContainer>
                        <p>Nenhum dado encontrado no periodo!</p>
                    </EmptyResponseContainer>
                    : null}
                <ButtonsContainer>
                    <ButtonV3
                        style={{ 'height': 41, 'padding': '14px 53px', 'borderRadius': 4 }}
                        title='Cancelar'
                        onClick={() => onCloseCallback()}

                        type={'button'}
                    />
                    {/* <ButtonV3
                        filled
                        style={{ 'height': 41, 'padding': '14px 53px', 'borderRadius': 4 }}
                        title='getrelatorio'
                        onClick={(e) => handleGetRelatorio()}

                        type={'button'}
                    /> */}
                    <ButtonDownload>
                        <CsvDownload
                            style={{
                                color: '#fff',
                                fontWeight: 500,
                                fontSize: '15px',
                                fontFamily: 'Rubik',
                                height: '100%',
                                width: '100%',
                                padding: '14px 45px'
                            }}
                            datas={asyncFnComputeDate}
                            // datas={asyncFnComputeDate}
                            separator=','
                            // extension='.xls'
                            meta={true}
                            handleError={() => console.log('erro')}
                            columns={columns}
                            text='Fazer download'
                            handleEmpty={() => void 0}
                            filename={`relatorio-${DateService.formatToDisplay(new Date())}`} />
                        {isLoading ?
                            <TbLoader2 size={25} />
                            : null
                        }
                        {/* <CsvDownload
                            style={{
                                color: '#fff',
                                fontWeight: 500,
                                fontSize: '15px',
                                fontFamily: 'Rubik',
                                height: '100%',
                                width: '100%',
                                padding: '14px 45px'
                            }}
                            datas={asyncFnComputeDate}
                            // datas={asyncFnComputeDate}
                            columns={columns}
                            separator=','
                            // extension='.xls'
                            meta={true}
                            text='Fazer download'
                            filename={`relatorio-${DateService.formatToDisplay(new Date())}`} />
                        {isLoading ?
                            <TbLoader2 size={25} />
                            : null
                        } */}
                    </ButtonDownload>

                </ButtonsContainer>
            </ContentContainer>
        </Container>
    )
}
