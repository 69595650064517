import React from "react";

import { BiEditAlt, BiTrendingUp } from 'react-icons/bi'
import { AiFillInfoCircle ,AiOutlinePrinter, AiOutlineArrowLeft, AiOutlineCheck} from 'react-icons/ai'
import { MdCheckCircleOutline ,MdBlockFlipped,MdSearch,MdOutlineStorefront,MdOutlineInventory2,MdAlignVerticalBottom} from 'react-icons/md'
import { FaHandHoldingUsd ,FaRegTrashAlt} from 'react-icons/fa'
import { IoMdTrash,IoMdCloseCircle, IoMdCopy, IoMdSave } from "react-icons/io"
import { BsCheck2Circle } from "react-icons/bs"
import {ReactComponent as FileOpen} from '../../assets/images/fileOpen.svg'


interface props {
    type?: 'edit' | 'check' | 'save' | 'arrowLeft' | 'infoCircle' | 'checkCircle'|'graphVertical'|'fileCopy'|'checkCircle2' |'printer'|'inventory' | 'trendingUp' |'store' | 'handMoney'|'block'|'trash'|'closeCircle'|'fileOpen'|'search',
    style?: any,
    className?: any
    size?: any,
    color?: any,
    onClick?: any,
    title?: any
    showExample?: boolean
}



export default function IconReact({ type, style, size, color, onClick, showExample, title, className }: props) {

    var icon

    switch (type) {
        case 'edit':
            icon = <BiEditAlt style={style} className={className} title={title} size={size} color={color} onClick={onClick} />
            break;
        case 'infoCircle':
            icon = <AiFillInfoCircle style={style} className={className} title={title} size={size} color={color} onClick={onClick} />
            break;
        case 'checkCircle':
            icon = <MdCheckCircleOutline style={style} className={className} title={title} size={size} color={color} onClick={onClick} />
            break;
        case 'trendingUp':
            icon = <BiTrendingUp style={style} className={className} title={title} size={size} color={color} onClick={onClick} />
            break;
        case 'handMoney':
            icon = <FaHandHoldingUsd style={style} className={className} title={title} size={size} color={color} onClick={onClick} />
            break;
        case 'block':
            icon = <MdBlockFlipped style={style} className={className} title={title} size={size} color={color} onClick={onClick} />
            break;
        case 'trash':
            icon = <FaRegTrashAlt style={style} className={className} title={title} size={size} color={color} onClick={onClick} />
            break;
        case 'closeCircle':
            icon = <IoMdCloseCircle style={style} className={className} title={title} size={size} color={color} onClick={onClick} />
            break;
        case 'fileOpen':
            icon = <FileOpen style={style} className={className} title={title} color={color} onClick={onClick} />
            break;
        case 'search':
            icon = <MdSearch style={style} className={className} title={title} color={color} onClick={onClick} />
            break;
        case 'store':
            icon = <MdOutlineStorefront style={style} className={className} title={title}  color={color} onClick={onClick} />
            break;
        case 'inventory':
            icon = <MdOutlineInventory2 style={style} className={className} title={title}  color={color} onClick={onClick} />
            break;
        case 'fileCopy':
            icon = <IoMdCopy style={style} className={className} title={title}  color={color} onClick={onClick} />
            break;
            case 'printer':
                icon = <AiOutlinePrinter style={style} className={className} title={title}  color={color} onClick={onClick} />
                break;
                default:
                   icon = <BiEditAlt style={style} className={className} title={title} size={size} color={color} onClick={onClick} />
                   break;
            case 'checkCircle2':
                icon = <BsCheck2Circle style={style} className={className} title={title}  color={color} onClick={onClick} />
            break;
            case 'graphVertical':
            icon = <MdAlignVerticalBottom style={style} className={className} title={title}  color={color} onClick={onClick} />
            break;
            case 'arrowLeft':
                icon = <AiOutlineArrowLeft style={style} className={className} title={title} color={color} onClick={onClick} />
            break;
            case 'save':
                icon = <IoMdSave style={style} className={className} title={title} color={color} onClick={onClick} />
            break;
            case 'check':
                icon = <AiOutlineCheck style={style} className={className} title={title} color={color} onClick={onClick} />
            break;
    }
    return (
        <>
            {
                !showExample ? icon : <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                    <div>type - 'edit'= <BiEditAlt style={style} size={25} color={color} onClick={onClick} /></div>
                    <div>type - 'infoCircle'= <AiFillInfoCircle style={style} size={25} color={color} onClick={onClick} /></div>
                    <div>type - 'checkCircle' = <MdCheckCircleOutline style={style} size={25} color={color} onClick={onClick} /></div>
                    <div>type -'trendingUp'= <BiTrendingUp style={style} size={25} color={color} onClick={onClick} /></div>
                    <div>type - 'handMoney'= <FaHandHoldingUsd style={style} size={25} color={color} onClick={onClick} /></div>
                    <div>type - 'closeCircle'= <IoMdCloseCircle style={style}  size={25} color={color} onClick={onClick} /></div>
                    <div>type - 'fileOpen'= <FileOpen style={style} className={className} title={title}  color={color} onClick={onClick} /></div>
                    <div>type - 'search'= <MdSearch style={style} className={className} title={title}  color={color} onClick={onClick} /></div>
                    <div>type - 'store'= <MdOutlineStorefront style={style} className={className} title={title}  color={color} onClick={onClick} /></div>
                    <div>type - 'inventory'= <MdOutlineInventory2 style={style} className={className} title={title}  color={color} onClick={onClick} /></div>
                    <div>type - 'fileCopy'= <IoMdCopy style={style} className={className} title={title}  color={color} onClick={onClick} /></div>
                    <div>type - 'graphVertical'= <MdAlignVerticalBottom style={style} className={className} title={title}  color={color} onClick={onClick} /></div>
                </div>
            }

        </>
    )
}
