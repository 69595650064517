import { Weekday } from 'models/weekday.model'
import HttpClient from './http.client'

export default class ProviderService {
    static deliveryWeekdays = (providerId: number, sellerId?: number) =>
        HttpClient.get(
            `/providers/requests/${providerId}/delivery?sellerId=${sellerId}`
        )

    static saveDeliveryWeekdays = (
        providerId: number,
        weekdays: Weekday[],
        sellerId?: number
    ) =>
        HttpClient.post(
            `/providers/requests/${providerId}/delivery/?sellerId=${sellerId}`,
            {
                weekdays,
            }
        )

    static deliveryDatesBlacklist = (providerId: number, sellerId?: number) =>
        HttpClient.get(
            `/providers/requests/${providerId}/delivery/blacklist/raw?sellerId=${sellerId}`
        )

    static saveDeliveryDatesBlacklist = (
        providerId: number,
        dates: string[],
        sellerId?: number
    ) =>
        HttpClient.post(
            `/providers/requests/${providerId}/delivery/blacklist?sellerId=${sellerId}`,
            { dates }
        )

    static deliveryDatesWhitelist = (providerId: number, sellerId?: number) =>
        HttpClient.get(
            `/providers/requests/${providerId}/delivery/whitelist?sellerId=${sellerId}`
        )

    static saveDeliveryDatesWhitelist = (
        providerId: number,
        dates: string[],
        sellerId?: number
    ) =>
        HttpClient.post(
            `/providers/requests/${providerId}/delivery/whitelist?sellerId=${sellerId}`,
            { dates }
        )

    static getRelatiorio = (dateInit: any, dateFinal:any): Promise<any> =>
        HttpClient.get(`/providers/relatorio?data_inicio=${dateInit}&data_fim=${dateFinal}`).then(
            (response) => {
                return response
            }
        )
}
