import { DayValue } from 'react-modern-calendar-datepicker'
import { Weekday } from '../interfaces/weekday'
import Utils from '../utils'

export default class DateService {

    static weekDays: Weekday[] = [
        { key: 'Segunda', value: 1 },
        { key: 'Terça', value: 2 },
        { key: 'Quarta', value: 3 },
        { key: 'Quinta', value: 4 },
        { key: 'Sexta', value: 5 },
        { key: 'Sábado', value: 6 },
        { key: 'Domingo', value: 7 }
    ]

    static newDayValueFromDate = (date: Date): DayValue => (date && {
        day: date.getDate(),
        month: DateService.getDateMonth(date),
        year: date.getFullYear()
    })

    static formatDayValueToRequest = (date: DayValue): string =>
        DateService.formatToRequest(DateService.newDateFromDayValue(date))

    static newDateFromDayValue = (date: DayValue): Date => date
        ? new Date(`${date.year}-${Utils.strpad(date.month)}-${Utils.strpad(date.day)}T00:00:00`)
        : new Date()

    static formatToDisplayInTimezone = (date): string => DateService.formatToDisplay(
        new Date(`${date.year}-${Utils.strpad(date.month)}-${Utils.strpad(date.day)}T00:00:00`)
    )

    static formatToRequest = (date: Date) =>
        `${date.getFullYear()}-` +
        `${Utils.strpad(DateService.getDateMonth(date))}-` +
        `${Utils.strpad(date.getDate())}`

    static formatToRequestWithTimezone = (date: Date) =>
        `${date.getFullYear()}-` +
        `${Utils.strpad(DateService.getDateMonth(date))}-` +
        `${Utils.strpad(date.getDate())}T00:00:00`

    static formatToDisplay = (date: Date) => date ?
        (`${Utils.strpad(date.getDate())}/` +
            `${Utils.strpad(DateService.getDateMonth(date))}/` +
            `${date.getFullYear()}`) : ''

    static formatToDisplayShort = (date: Date) => date ?
        (`${Utils.strpad(date.getDate())}/` +
            `${Utils.strpad(DateService.getDateMonth(date))}/` +
            `${date.getFullYear().toString().slice(-2)}`) : ''

    static formatToDisplayDate = (date: Date) => date ?
        (`${Utils.strpad(date.getDate() + 1)}/` +
            `${Utils.strpad((DateService.getDateMonth(date)))}/` +
            `${date.getFullYear()} `) : ''

    static formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses em JavaScript começam do zero
        const year = String(date.getFullYear()).slice(-2);

        return `${day}/${month}/${year}`;
    };



    static formatToDisplayWithTime = (date: Date) =>
        `${Utils.strpad(date.getDate())}/` +
        `${Utils.strpad((DateService.getDateMonth(date)))}/` +
        `${date.getFullYear()} ` +
        `${Utils.strpad((date.getHours()))}:` +
        `${Utils.strpad((date.getMinutes()))}`

    static formatToDisplayOnlyTime = (date: Date) =>
        `${Utils.strpad((date.getHours()))}:` +
        `${Utils.strpad((date.getMinutes()))}`

    static addDaysToDate = (date: Date, days: number) => {
        date.setDate(date.getDate() + days)
        return date
    }

    static removeDaysToDate = (date: Date, days: number) => {
        date.setDate(date.getDate() - days)
        return date
    }

    static removeMonthToDate = (date: Date, month: number) => {
        date.setMonth(date.getMonth() - month)
        return date
    }

    static getDateMonth = (date: Date) => (date.getMonth() + 1)

}
