import React, { ReactNode } from "react";
import './index.scss'

interface props {
    onClick?: any,
    title: string,
    Icon?: ReactNode
    type?: any,
    children?: ReactNode,
    filled?: boolean,
    style?: any,
    textStyle?: any
    id?: any
    disabled?:boolean

}

export default function ButtonV3({ onClick, title, type, Icon, children, filled, style, textStyle,id,disabled }: props) {

    return (

        <button
            id={id && id}
            style={style}
            className={filled ? 'buttonFilled ' : 'buttonv3'}
            type={type ? type : undefined}
            disabled={disabled}
            onClick={onClick}
        >
            {Icon && <div> {Icon}</div>}
            <p style={textStyle} className={filled ? 'textFilled' : 'nonFilledText'}>{title}</p>
            {children && children}
        </button>

    )
}
