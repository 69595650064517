/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import './index.scss'
import { useForm } from 'react-hook-form'
import Constants from '../../constants'
import { FeedbackError } from '../../components/feedback/error'
import { FeedbackSuccess } from '../../components/feedback/success'
import { AuthProvider } from 'contexts/user'
import InputMask from 'react-input-mask'
import CustomerService from 'services/CustomerService'

export const AbraselBA = () => {

    const [ error, setError ] = useState<string>('')
    const [ sucess, setSucess ] = useState<string>('')
    const [ submited, setSubmited ] = useState<boolean>(false)
    const { register, handleSubmit, errors } = useForm()

    const onSubmit = (data: any) => {
        setError('')
        const request = {
            cnpj: data.cnpj,
            telefone: data.phone,
            email: data.mail,
            contato: data.name,
            usuario: data.mail,
            senha: data.password
        }
        CustomerService.request(request)
            .then(response => {
                if ( response ) {
                    setSucess('Solicitação efetuada com sucesso')
                    setSubmited(true)
                }
            })
            .catch( () => setError(Constants.MSG_REQUEST_FAIL))
    }

    return (
        <AuthProvider>
            <div className="request" style={ { backgroundImage: "url('/images/abraselba.png')" } }>
                <div className="row request-box-container">
                    <div className="col-sm-12 col-md-4 request-box">

                        <div className="request-box-header">
                            <div className="request-box-logo">
                                <img src="/images/logo-sign-in.jpg" alt="Logo" className="logo" />
                                <img src="/images/abraselba2.png" alt="Logo" className="logo" />
                            </div>
                            <h1 className="title">Solicite seu acesso</h1>
                        </div>

                        <div className="request-box-body">
                            <form className="form" onSubmit={ handleSubmit(onSubmit) }>
                                <InputMask mask="99.999.999/9999-99" >
                                    { (inputProps) =>
                                        <input
                                            className="field"
                                            placeholder="Cnpj"
                                            name="cnpj"
                                            { ...inputProps }
                                            ref={ register({
                                                required: true,
                                                minLength: 18,
                                                pattern: /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/

                                            }) }
                                            autoComplete="off"/> }
                                </InputMask>
                                { errors.cnpj?.type === "required" && <FeedbackError message={ Constants.MSG_REQUIRED_FIELD } /> }
                                { errors.cnpj?.type === "minLength" && <FeedbackError message={ Constants.MSG_LENGTH_FIELD } /> }
                                { errors.cnpj?.type === "pattern" && <FeedbackError message={ Constants.MSG_INVALID_FIELD } /> }

                                <input
                                    className="field"
                                    placeholder="Nome"
                                    name="name"
                                    ref={ register({ required: true }) }
                                    autoComplete="off"
                                />
                                { errors.name && <FeedbackError message={ Constants.MSG_REQUIRED_FIELD } /> }

                                <InputMask mask="(99) 99999-9999" >
                                    { (inputProps) =>
                                        <input
                                            className="field"
                                            placeholder="Telefone"
                                            name="phone"
                                            { ...inputProps }
                                            ref={ register({
                                                required: true,
                                                pattern: /^(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/

                                            }) }
                                            autoComplete="off"/> }
                                </InputMask>
                                { errors.phone?.type === "required" && <FeedbackError message={ Constants.MSG_REQUIRED_FIELD } /> }
                                { errors.phone?.type === "pattern" && <FeedbackError message={ Constants.MSG_INVALID_FIELD } /> }

                                <input
                                    className="field"
                                    placeholder="Email"
                                    name="mail"
                                    type="email"
                                    ref={ register({
                                        required: true ,
                                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    }) }
                                    autoComplete="off"
                                />
                                { errors.mail?.type === "required" && <FeedbackError message={ Constants.MSG_REQUIRED_FIELD } /> }
                                { errors.mail?.type === "pattern" && <FeedbackError message={ Constants.MSG_INVALID_FIELD } /> }

                                <input
                                    className="field"
                                    placeholder="Senha"
                                    name="password"
                                    type="password"
                                    ref={ register({ required: true }) }
                                    autoComplete="off"
                                />
                                { errors.password && <FeedbackError message={ Constants.MSG_REQUIRED_FIELD } /> }

                                { error && <FeedbackError message={ error } /> }
                                { sucess && <FeedbackSuccess message={ sucess } /> }

                                <button className="send" type="submit" disabled={ submited }>Solicitar</button>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </AuthProvider>
    )
}
