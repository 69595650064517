import Constants from '../constants'
import { Salesman } from '../interfaces/salesman'
import HttpClient from './HttpClient'

export default class SalesmanService {

    static parse = (salesmanFromApi: any): Salesman => ({
        id: salesmanFromApi.ven_id,
        name: salesmanFromApi.ven_nome,
    })

    static parseSalesman = (salesmanFromApi: any): Salesman => ({
        id: salesmanFromApi.usu_id,
        name: salesmanFromApi.usu_nome,
    })

    static getAll = (): Promise<any> =>
        HttpClient.get(`${Constants.URL_API_SALESMAN}`)
            .then((response) => {
                const salesmen: Salesman[] = []
                response.vendedores.forEach((salesman: any) => salesmen.push(
                    SalesmanService.parseSalesman(salesman))
                )
                return salesmen
            })

    static getSalesManForStore = (provider_id?: number, store_id?: number): Promise<any> =>
        HttpClient.get(`${Constants.URL_API_SALESMAN}?fornecedor_id=${provider_id}&loja_id=${store_id}`)
            .then((response) => {
                const salesmen: Salesman[] = []
                response.vendedores.filter((salesman: any) => salesman.usu_status == "1")
                    .forEach((salesman: any) => salesmen.push(
                        SalesmanService.parseSalesman(salesman))
                    )
                return salesmen
            })

}
