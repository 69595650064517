import { Product } from "../interfaces/product"
import HttpClient from './HttpClient'
import Constants from '../constants'

export default class ProductService {

    static parse = (productFromApi: any): Product => ({
        id: productFromApi.produto_id,
        name: productFromApi.produto,
        internalCode: productFromApi.cod_interno,
        ean: productFromApi.ean,
        packing: productFromApi.embalagem,
        orderId: productFromApi.id_pedido,
        observations: productFromApi.observacao,
        quantity: productFromApi.quantidade,
        typingQuantity: productFromApi.quantidade_digitacao,
        suggestQuantity: productFromApi.quantidade_sugestao,
        packingPrice: productFromApi.valorEmbalagem,
        brand_name: productFromApi.pro_observacao,
        totalPrice: productFromApi.valorTotal,
        starred: productFromApi.pro_preferencial === 1,
        main_product_id: productFromApi.pro_principal,
        pro_unidade_compra: productFromApi.pro_unidade_compra,
        prin_pro_unidade_compra: productFromApi.prin_pro_unidade_compra,
        unitPrice: productFromApi.valorUnitario,
        typingPrice: productFromApi.valor_digitacao,
        category: productFromApi.categoria,
        last_offer_price: productFromApi.ult_preco_cotacao,
        visible: true,
        derivatives: []
    })

    static parse_request_detail_product = (productFromApi: any): Product => ({
        id: productFromApi.produto_id,
        name: productFromApi.descricao,
        internalCode: productFromApi.cod_interno,
        ean: productFromApi.ean,
        packing: productFromApi.embalagem,
        orderId: productFromApi.id_pedido,
        observations: productFromApi.observacao,
        quantity: productFromApi.quantidade,
        starred: productFromApi.pro_preferencial == 1,
        typingQuantity: parseInt(productFromApi.quantidadeDigitacao || 0),
        suggestQuantity: parseInt(productFromApi.quantidadeSugestao),
        packingPrice: productFromApi.valorEmbalagem,
        totalPrice: productFromApi.valorTotal,
        unitPrice: productFromApi.valorUnitario,
        typingPrice: productFromApi.precoDigitacao,
        is_winner: productFromApi.vencedor,
        last_offer_price: productFromApi.ult_preco_cotacao,
        brand: productFromApi.marca,
        visible: true,
        category: productFromApi.categoria,
        is_generic: productFromApi.generico == 1,
        purchase_unity: productFromApi.pro_unidade_compra,
        derivatives: productFromApi.produtosSecundarios ? productFromApi.produtosSecundarios.map((prod: any) => {
            return ProductService.parse_request_detail_product(prod) }) : null
    })

    static removeProduct = (providerId: string, prodId: string): Promise<ProductService[]> =>
        HttpClient.delete(`${Constants.URL_API_PROVIDER}/${providerId}/products/${prodId}`).then(
            (response) => {
                return response
            }
        )

    static groups = (providerId: string): Promise<any> =>
        HttpClient.get(`${Constants.URL_API_PROVIDER}/${providerId}/products/groups/`).then(
            (response) => response
        )

    static groups_by_product = (providerId: string, productId: string): Promise<any> =>
        HttpClient.get(`${Constants.URL_API_PROVIDER}/${providerId}/products/${productId}/groups/`).then(
            (response) => response
        )

    static toggle_product_in_group = (providerId: string, groupId: string, productId: string): Promise<any> =>
        HttpClient.put(`${Constants.URL_API_PROVIDER}/${providerId}/products/${productId}/groups/${groupId}`, {}).then(
            (response) => response
        )

    static create_group = (providerId: string, body: any): Promise<any> =>
        HttpClient.post(`${Constants.URL_API_PROVIDER}/${providerId}/products/groups`, body).then(
            (response) => response
        )

    static update_group = (providerId: string, body: any): Promise<any> =>
        HttpClient.put(`${Constants.URL_API_PROVIDER}/${providerId}/products/groups/${body.id}`, body).then(
            (response) => response
        )

    static delete_group = (providerId: string, groupId: string): Promise<any> =>
        HttpClient.delete(`${Constants.URL_API_PROVIDER}/${providerId}/products/groups/${groupId}`).then(
            (response) => response
        )
    static getgroups = (productID: string): Promise<any> =>
        HttpClient.get(`${Constants.URL_API_PROVIDER_GROUPS}/${productID}/group`).then(
            (response) => response
        )
    static getAllProductCategories = (): Promise<any> =>
        HttpClient.get(`${Constants.URL_API_PROVIDER_CATEGORIES}`).then(
            (response) => response
        )
    static createProductCategorie = ( body: any): Promise<any> =>
        HttpClient.post(`${Constants.URL_API_PROVIDER__CREATE_CATEGORIE}`,body).then(
            (response) => response
        )
    static createProductSubCategorie = ( body: any): Promise<any> =>
        HttpClient.post(`${Constants.URL_API_PROVIDER__CREATE_SUBCATEGORIE}`,body).then(
            (response) => response
        )
    static editCategory = ( id,body: any): Promise<any> =>
        HttpClient.put(`${Constants.URL_API_PROVIDER__CREATE_CATEGORIE}/${id}`,body).then(
            (response) => response
        )
    static deleteCategory = ( id): Promise<any> =>
        HttpClient.delete(`${Constants.URL_API_PROVIDER__CREATE_CATEGORIE}/${id}`).then(
            (response) => response
        )
    static editSubCategory = ( id,body: any): Promise<any> =>
        HttpClient.put(`${Constants.URL_API_PROVIDER__CREATE_SUBCATEGORIE}/${id}`,body).then(
            (response) => response
        )
    static deleteSubCategory = ( id): Promise<any> =>
        HttpClient.delete(`${Constants.URL_API_PROVIDER__CREATE_SUBCATEGORIE}/${id}`).then(
            (response) => response
        )

}
