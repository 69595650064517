import HttpClient from './http.client'

export default class ClassificationService {


    static getclassification = (page) => HttpClient.get(`/providers/classificacao`)

    static getclassificationSelected = (proid) => HttpClient.get(`/providers/classificacao/product/${proid}`)

    static editClassification = (id,data) => HttpClient
        .put(`providers/classificacao/${id}`, data)
    static editSubClassification = (id,data) => HttpClient
        .put(`providers/classificacao/sub/${id}`, data)

    static createClassification = (data) => HttpClient
        .post(`/providers/classificacao`, data)
    static createSubClassification = (data) => HttpClient
        .post(`/providers/classificacao/sub`, data)



}
