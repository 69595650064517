import ProductModel from "models/product.model"
import React, { useReducer } from "react"
import DeliveryService from "services/DeliveryService"
import RequestService from "services/RequestService"
import RequestServiceV3 from "services/v3/request.service"
import RequestReducer, { LOAD_REQUESTS_SUCCESS, SET_DELIVERY, SET_REQUEST, SET_REQUEST_PRODUCTS, SET_REQUEST_PRODUCTS_PAGINATION } from "./reducer"

const state: any = {
    status_list: [
        { status_nome: "Digitada", status_id: "TYPED" },
        { status_nome: "Não Digitada", status_id: "NOT_TYPED" },
        { status_nome: "Sem Pedido", status_id: "NOT_ORDERED" },
        { status_nome: "Pedido Gerado", status_id: "ORDERED" },
        { status_nome: "Congelada", status_id: "FROZEN" },
    ],
    states: [
        { "name": "Acre", "value": "AC" },
        { "name": "Alagoas", "value": "AL" },
        { "name": "Amapá", "value": "AP" },
        { "name": "Amazonas", "value": "AM" },
        { "name": "Bahia", "value": "BA" },
        { "name": "Ceará", "value": "CE" },
        { "name": "Distrito Federal", "value": "DF" },
        { "name": "Espírito Santo", "value": "ES" },
        { "name": "Goiás", "value": "GO" },
        { "name": "Maranhão", "value": "MA" },
        { "name": "Mato Grosso", "value": "MT" },
        { "name": "Mato Grosso do Sul", "value": "MS" },
        { "name": "Minas Gerais", "value": "MG" },
        { "name": "Pará", "value": "PA" },
        { "name": "Paraíba", "value": "PB" },
        { "name": "Paraná", "value": "PR" },
        { "name": "Pernambuco", "value": "PE" },
        { "name": "Piauí", "value": "PI" },
        { "name": "Rio de Janeiro", "value": "RJ" },
        { "name": "Rio Grande do Norte", "value": "RN" },
        { "name": "Rio Grande do Sul", "value": "RS" },
        { "name": "Rondônia", "value": "RO" },
        { "name": "Roraima", "value": "RR" },
        { "name": "Santa Catarina", "value": "SC" },
        { "name": "São Paulo", "value": "SP" },
        { "name": "Sergipe", "value": "SE" },
        { "name": "Tocantins", "value": "TO" }
    ]
}

const RequestContext = React.createContext(state)

export const RequestProvider = ({ children }: any) => {

    const [requests, dispatch] = useReducer(RequestReducer, state)

    const load_requests = async (params: any) =>
        RequestService.getAll(params)
            .then((response) => dispatch({ type: LOAD_REQUESTS_SUCCESS, requests: response }))
            .catch(() => dispatch({ type: LOAD_REQUESTS_SUCCESS, requests: null }))

    const load_others_requests = async (params: any) =>
        RequestService.load_others_request(params)
            .then((response) => dispatch({ type: SET_REQUEST, request: response }))
            .catch(() => dispatch({ type: SET_REQUEST, request: null }))

    const load_request_products = async (id: number, seller_id: number, page, category, name, digitados, meusProdutos, ratio, for_report) => {

        console.log("for-report" ,for_report)
        let paginationData
        return RequestServiceV3.load_request_products(id, seller_id,1,page, category, name, digitados, meusProdutos, ratio, for_report)
            .then(async (products) => {
                paginationData = products.meta ? products.meta : {}
                products.data.forEach((p: any) => {
                    p.derivatives = p.derivatives.map((pp: any) => new ProductModel(pp))
                })

                return products.data.map((p: any) => new ProductModel(p))
            })
            .then((products) => {
                return RequestServiceV3.load_request_last_offers_by_seller(id, seller_id)
                    .then((result: any) => {
                        products.forEach((p: ProductModel) => {
                            p.process_last_offer(result)
                        })
                        return products
                    })
            })
            .then((products) => dispatch({ type: SET_REQUEST_PRODUCTS, products }))
            .then((products) => { dispatch({ type: SET_REQUEST_PRODUCTS_PAGINATION, paginationData })})
            .catch(() => dispatch({ type: SET_REQUEST_PRODUCTS, products: null }))
    }

    const load_request = async (id: number) =>
        RequestService.load_request(id)
            .then((response) => dispatch({ type: SET_REQUEST, request: response }))
            .catch(() => dispatch({ type: SET_REQUEST, request: null }))

    const load_request_seller_delivery = async (request_id: number, seller_id: number) =>
        DeliveryService.request_seller_delivery(request_id, seller_id)
            .then((delivery) => {
                dispatch({ type: SET_DELIVERY, delivery })
                return delivery
            })
            .catch(() => dispatch({ type: SET_DELIVERY, delivery: null }))

    const load_last_request_seller_delivery = async (request_id: number, seller_id: number) =>
        DeliveryService.last_request_seller_delivery(request_id, seller_id)
            .then((delivery) => {
                dispatch({ type: SET_DELIVERY, delivery: { ...delivery, old: true } })
                return delivery
            })
            .catch(() => dispatch({ type: SET_DELIVERY, delivery: null }))

    const set_delivery = (delivery: any) => dispatch({ type: SET_DELIVERY, delivery })

    const ctx = {
        ...requests, load_requests, load_request, load_request_products,
        load_others_requests, load_request_seller_delivery, set_delivery,
        load_last_request_seller_delivery
    }

    return (
        <RequestContext.Provider value={ctx}>
            {children}
        </RequestContext.Provider>)
}

export default RequestContext
