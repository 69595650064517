import { PhoneWithWhats } from 'components/phoneWithWhats'
import { mask_cnpj } from 'helpers/mask.helper'
import React, { FC } from 'react'
import { Store } from '../../../../interfaces/store'
import './index.scss'

export const RequestCompaniesComponent: FC<any> = ({ stores }: any) => (
    <section className="companies-in">
        <p className="title">Empresas Participantes</p>
        <div className="newListCompaniesIn">
            <table className="newTableCompaniesIn">
                <thead>
                    <tr>
                        <th className="pl-2">Razão Social</th>
                        <th>CNPJ</th>
                        <th>Estado</th>
                        <th>Cidade</th>
                        <th>Endereço</th>
                        <th>Telefone</th>
                    </tr>
                </thead>
                <tbody>
                    {stores && stores.map((store: Store) =>
                        <tr key={store.id}>
                            <td data-label='Razão Social' className="pl-2">{store.socialReason}</td>
                            <td data-label='CNPJ'>{mask_cnpj(store.cnpj)}</td>
                            <td data-label='Estado'>{store.state?.name}</td>
                            <td data-label='Cidade'>{store.city?.name}</td>
                            <td data-label='Endereço'>{store.address}</td>
                            <td data-label='Telefone'>{store.phone ?<PhoneWithWhats phone={store.phone}/> :' - '}</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    </section>
)
