export default class TotalItensHighchartModel {

    chart = {
        height: 300,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie"
    }
    colors: string[] = [ "#20ce88", "#ff7110", "#BBBBBB" ]
    title: any
    credits = false
    tooltip = { pointFormat: "({point.y}) - <b>{point.percentage:.1f}%</b>" }
    series: any
    plotOptions = {
        pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
                enabled: true,
                format: "({point.y}) - {point.percentage:.1f} %"
            },
            showInLegend: true
        }
    }

    constructor(data : any) {
        console.log(data.summary)
        this.title = { text: "Todos os Produtos - " + data.cotacao.total_produtos }
        this.series = [
            {
                colorByPoint: true,
                data: [
                    {
                        name: "Itens Vencedores",
                        y: parseInt(data.summary.vencedores),
                        sliced: true,
                        selected: true
                    },
                    {
                        name: "Itens Não Vencedores",
                        y: parseInt(data.summary.nao_vencedores)
                    },
                    {
                        name: "Não Digitados",
                        y: data.cotacao.total_produtos - parseInt(data.summary.digitados)
                    }
                ]
            }
        ]
    }

}
