import styled from 'styled-components';

export const Container = styled.div`
    /* width: 100px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    gap: 5px;
    position: relative;
    transition: margin 100ms ease-in-out;
    animation: 0.5s ease 0s 1 slideInFromRight;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0.4rem 0.5rem;

h6{
    font-size: 1rem;
    font-family: 'Ubuntu';
    font-weight: 400;
    flex: 1;
}
`;
interface colorProps {
    color: string
}
export const BlockColor = styled.div<colorProps>`
width: 60px;
height: 35px;
border-radius: 8px;
background-color: ${props => props.color};;
box-shadow:0 0 2px 1px rgba(0,0,0,0.4);
transition: 100ms;
:hover{
    scale: 1.02;
    transition: 100ms;
}
`;
interface pickerPositionProps {
    left?: '0px'
}
export const PickerContainer = styled.div<pickerPositionProps>`
    position: absolute;
    top:100%;
    left: ${props => props.left}px;;
    z-index: 3;

`
