import { Customer } from 'interfaces/customer'
import CustomerService from 'services/CustomerService'
import Constants from '../../constants'
import HttpClient from '../HttpClient'
import StorageService from '../StorageService'

export default class ClientServiceV2 {

    static saveDeliveries = (storeId: string, deliveries: any) =>
        HttpClient.post(Constants.URL_V2_API_CLIENT_DELIVERIES.replace('{store_id}', storeId), deliveries)

    static deliveries = (storeId: any) => HttpClient.get(Constants.URL_V2_API_CLIENT_DELIVERIES.replace('{store_id}', storeId))

    static isLogged = () => !!StorageService.get(Constants.STORAGE_TOKEN_KEY)

    static signIn = (token: string) => StorageService.set(Constants.STORAGE_TOKEN_KEY, token)

    static signOut = () => StorageService.remove(Constants.STORAGE_TOKEN_KEY)

    static available_by_provider_id = (params: any) =>
        HttpClient.get('/v2/clients/available', params)

    static find_register_request_by_id = (sol_id: any) =>
        HttpClient.get('/v2/external/register/find', { sol_id })

    static associate_solicitation = (params: { sol_id: string, vend_id: string }) =>
        HttpClient.put('/v2/external/solicitation/associate', params)

    static pendent_registers = (revenda_id: any) =>
        HttpClient.get('/v2/external/register/pendents', { revenda_id })

    static deleteRegisterRequest = (sol_id: any) =>
        HttpClient.post('/v2/external/register/delete', { sol_id })

    static create = (body: any) =>
        HttpClient.post('/v2/clients', body)

    static createSigle = (body: any) =>
        HttpClient.post(`${Constants.URL_V3_API}/providers/clients/solicitacao/revenda`, body)

    static list =
        (page: number, customerId: string | null, status: string[] = [],
            stateId: string | null, cnpj: string, provider_id: number): Promise<any> => {
            let queryStringStatus = ''
            if (status.length > 0) {
                status.forEach(
                    (selectedStatus: string) => queryStringStatus += `&status[]=${selectedStatus}`
                )
            }
            const customerIdQueryString = customerId ? `&cliente_id=${customerId}` : ''
            const statesIdQueryString = stateId ? `&estado_sigla=${stateId}` : ''
            const cnpjQueryString = cnpj ? `&cnpj=${cnpj}` : ''
            const providerQueryString = provider_id ? `&provider_id=${provider_id}` : ''

            return HttpClient.get(
                `${Constants.URL_V2_API_CLIENTS}` +
                `?page=${page}${customerIdQueryString}${queryStringStatus}` +
                `${statesIdQueryString}${cnpjQueryString}${providerQueryString}`
            ).then(response => {
                const customers: Customer[] = []
                response.clientes.data.forEach(
                    (customer: any) => customers.push(CustomerService.parse(customer))
                )
                response.clientes.data = customers
                response.clientes.totalActive = response.countAtivos.count_ativos
                response.clientes.totalPending = response.countPendentes.count_pendentes
                response.clientes.totalInactive = response.countInativos.count_inativos
                return response.clientes
            })
        }
}
