/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'react-simple-snackbar'
import { Link, useNavigate, useParams } from 'react-router-dom'

import './index.scss'

import { Input } from 'components/input'
import Constants from '../../../../constants'
import { Select } from 'components/select'
import { Button } from 'components/button'
import { Loader } from 'components/loader'
import { Snackbar } from 'components/snackbar'
import UserService from 'services/UserService'
import { PageHeader } from 'components/page-header'
import ClientServiceV2 from 'services/v2/ClientService'

import { FeedbackError } from 'components/feedback/error'
import { ProjectContainer } from 'components/project-container'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import ProviderService from 'services/ProviderService'

const BuyingCompanyAddSingle = () => {
    const navigate = useNavigate()
    const { register, handleSubmit, errors } = useForm()
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const { id } = useParams<string>()
    const [data, setData] = useState<any>({})
    const [ufs, setUFs] = useState<any>([])
    const [uf, setUF] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [cities, setCities] = useState<any>([])
    const [allCities, setAllCities] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
    }, [])

    const save = async (data: any) => {

        try{
           setLoading(true)
           let resCreate =  await ClientServiceV2.createSigle(data);
           navigate(`${Constants.ROUTE_CLIENTS}#rrm`);
           openSnackbar(
            <Snackbar icon="sticky_note_2">
                Cadastro realizado com sucesso!
            </Snackbar>
        )
        setLoading(false);

        }catch(error: any){
            console.log(error)
            openSnackbar(
                <Snackbar icon="sticky_note_2">
                    {error.response && error.response.data
                        ? error.response.data.mensagem
                        : 'Ocorreu um erro ao salvar o registro!'}
                </Snackbar>
            )

        }
    }


    return (
        <ProjectContainer>
            <div className="buying-company-add">
                {loading && <Loader />}
                <header>
                    <PageHeader title="Cadastrar cliente" />
                </header>

                <div className="actions">
                    <Link to={`${Constants.ROUTE_CLIENTS}#rrm`}>
                        <Button>
                            <i className="material-icons">arrow_back</i>Voltar
                        </Button>
                    </Link>
                </div>

                <div className="form">
                <form id="form" onSubmit={handleSubmit(save)}>
                    <div className="row">

                        <div className="form-group col-12 col-md-3">
                                        <Input
                                            type="text"
                                            id="cnpj"
                                            name="cnpj"
                                            description="CNPJ"
                                            placeholder="CNPJ"
                                            register={register({ required: true })}
                                            mask="99.999.999/9999-99"
                                        />
                                        {errors.cnpj && (
                                            <FeedbackError
                                                message={Constants.MSG_REQUIRED_FIELD}
                                            />
                                        )}
                        </div>
                        <div className="form-group col-12 col-md-4">
                                    <Input
                                        type="text"
                                        id="email"
                                        name="email"
                                        description="Email / Login"
                                        placeholder="Email / Login"
                                        register={register({
                                            required: true,
                                            pattern:
                                                /^[a-zA-Z0-9\-_.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/g,
                                        })}
                                    />
                                    {errors.email &&
                                        errors.email.type === 'required' && (
                                        <FeedbackError
                                            message={
                                                Constants.MSG_REQUIRED_FIELD
                                            }
                                        />
                                    )}
                                    {errors.email &&
                                        errors.email.type === 'pattern' && (
                                        <FeedbackError
                                            message={
                                                Constants.MSG_PATTERN_FIELD
                                            }
                                        />
                                    )}
                            </div>

                                <div className="form-group col-12 col-md-4">
                                <Input
                                            type="password"
                                            id="password"
                                            name="senha"
                                            description="password"
                                            placeholder="password"
                                            register={register({ required: true })}
                                />
                                {errors.senha && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                     </div>

                     <div className="row">

                     <div className="form-group col-12 col-md-4">
                                <Input
                                    type="text"
                                    id="contato"
                                    name="contato"
                                    description="Contato"
                                    placeholder="Contato"
                                    register={register({ required: true })}
                                />
                                {errors.contato && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                         </div>
                         <div className="form-group col-12 col-md-2">
                                <Input
                                    type="text"
                                    id="telefone"
                                    name="telefone"
                                    mask="(99) 99999-9999"
                                    description="Telefone"
                                    placeholder="Telefone"
                                    register={register({ required: true })}
                                />
                                {errors.telefone && (
                                    <FeedbackError
                                        message={Constants.MSG_REQUIRED_FIELD}
                                    />
                                )}
                            </div>
                     </div>

                     <Button type="submit" className="c-btn-success">
                            <i className="material-icons">save_alt</i>Salvar
                        </Button>
                 </form>
                </div>
            </div>
         </ProjectContainer>
    )
}

export default BuyingCompanyAddSingle
