import HttpClient from './HttpClient';
import Constants from '../constants';


export default class CnpjService {

    static find_cnpj = (cnpj: any) =>
      HttpClient.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`)

    static find_cep = (cep: any) =>
      HttpClient.get(`https://brasilapi.com.br/api/cep/v1/${cep}`)


}