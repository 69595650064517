//import '../../styles/variables';
import styled from 'styled-components';


export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;
export const Title = styled.p`
    font-family: rubik;
    margin-right: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #707070;
`;
export const Value = styled.p`
    font-family: rubik;
    font-weight: 300;
    font-size: 14px;
    color: #707070;
    line-height: 1.3;
`;