import React, { FC, useEffect, useState, useContext, useCallback } from 'react'
import Constants from '../../constants'
import { Loader } from '../../components/loader'
import { ProjectContainer } from '../../components/project-container/index'
import { Link } from 'react-router-dom'
import { Datepicker } from 'components/datepicker'
import './index.scss'
import { CampaignService } from 'services/v2/campaigns.service'
import { Button } from '../../components/button'
import { PageHeader } from '../../components/page-header'
import { CampanhasFilterComponent } from './components/filter'
import { AiOutlineClose } from 'react-icons/ai'
import { EditModal } from './components/edit-modal'
import CampaignsModel from 'models/campaigns.model'
import { mask_currency } from 'helpers/mask.helper'
import { UserContext } from 'contexts/user'
import DateService from 'services/DateService'
import { FeedbackError } from '../../components/feedback/error'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Snackbar } from 'components/snackbar'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { useSnackbar } from 'react-simple-snackbar'
import { FaBoxes } from 'react-icons/fa'
import { BiTrash } from 'react-icons/bi'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import IconReact from 'components/icons'
import { ReactComponent as Boxes } from '../../assets/images/boxes.svg'
import { RoadMap } from 'components/roadmap'

export const CampaignsPage: FC<any> = () => {

    const MySwal = withReactContent(Swal)

    const initialQueryParams = {
        page: 1,
        from: DateService.formatToRequest(DateService.removeMonthToDate(new Date(), 3))
    }

    const [loading, setLoading] = useState<boolean>(true)
    const [queryParams, setQueryParams] = useState(initialQueryParams)
    const [showFilters, setShowFilters] = useState<boolean>(true)
    const [campaigns, setCampaigns] = useState<any[]>([])
    const { user } = useContext(UserContext)
    const toogleFilters = () => setShowFilters(!showFilters)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [successMessage, setSuccessMessage] = useState<string>('')
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)


    const filter = useCallback((filterParams) => {
        if (user) {
            setLoading(true)
            CampaignService.load(filterParams)
                .then(async (response: any) => {
                    const camps: any = response.data.data.map((c: any) => new CampaignsModel(c))
                    setCampaigns(camps)
                }).then(() => setLoading(false))
        }
    }, [user])


    useEffect(
        () => {
            if (user) {
                filter(queryParams)
            }
        },
        [user, filter, queryParams]
    )


    const delete_campaign = (id: any) => {
        MySwal.fire({
            title: (
                <div>
                    <p>
                        Deseja excluir a campanha?
                    </p>
                    <hr />
                </div>
            ),
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            cancelButtonColor: "red"
        }).then((res) => {
            if (res.isConfirmed) {
                CampaignService.delete(id)
                    .then(async () => {
                        MySwal.fire(<p>Campanha excluída com sucesso!</p>)
                        filter(queryParams)
                    }).then(() => setLoading(false))
                    .catch(() => {
                        setErrorMessage(Constants.MSG_CAMPAIGN_DELETE_ERROR)
                    })
            }
        })
    }

    const getDateFinal = (date: any) => {
        return new Date(date)
    }

    const updateDate = (campaign: any, date: any) => {
        const data = {
            titulo: campaign.title,
            for_id: campaign.provider,
            obs: campaign.observation,
            entrega: campaign.delivery,
            data_final: DateService.formatToRequest(new Date(date))
        }
        setLoading(true)
        CampaignService.update(data, campaign.id)
            .then((response: any) => {
                filter(queryParams)
                setLoading(false)
                openSnackbar(<Snackbar icon="sticky_note_2">
                    Data final alterada com sucesso!
                </Snackbar>)
            })
            .catch(error => {
                openSnackbar(
                    <Snackbar icon="sticky_note_2">
                        {error.response && error.response.data ?
                            error.response.data.message : 'Ocorreu um erro ao alterar a data da campanha!'}
                    </Snackbar>)
                setLoading(false)
            })
    }

    const filterParams = useCallback((filterParams) => {
        setQueryParams(filterParams)
        filter(filterParams)
    }, [filter])

    const hasCampaings = (campaigns: any) => campaigns && campaigns.length > 0

    return (
        <ProjectContainer >
            <div className="requests color-gray-2">
                <RoadMap
                    pageLinks={[
                        {
                            title: 'Minhas Campanhas',
                            onclick: () => { }
                        },
                    ]}
                ></RoadMap>
                <header className="header flex justify-space-between mb-32">

                    <PageHeaderV2 title="Minhas Campanhas - Listagem" />

                    <div className="header-actions">
                        {
                            //    <Link to={Constants.ROUTE_CAMPAINS_ADD} className="btn__primary btn-icon add-button">
                            //        <Button className={'c-btn-ternary'}>
                            //            <i className="material-icons">add</i>Nova Campanha
                            //        </Button>
                            //    </Link>
                        }
                        {
                            //   <Button onClick={() => toogleFilters()}>
                            //       {showFilters ? (
                            //           <span className="flex items-center justify-center">
                            //               <i className="material-icons">done</i>
                            //               Filtros
                            //           </span>
                            //       ) : (
                            //           <span className="flex items-center justify-center">
                            //               <i className="material-icons">filter_alt</i>
                            //               Filtros
                            //           </span>
                            //       )}
                            //   </Button>
                        }
                    </div>
                </header>

                {true && <CampanhasFilterComponent onChange={filterParams} />}

                <div className="flex justify-content-center">
                    {loading && <Loader type="inline" />}
                </div>

                {!loading && (hasCampaings(campaigns) ? (

                    <div style={{ overflowX: 'visible', overflowY: 'visible' }} className="NewlistCampagne">
                        <table className="newTableCampagne">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">#ID</th>
                                    <th scope="col">Título</th>
                                    <th scope="col">Data Final</th>
                                    <th scope="col" className="text-center">QTD ITENS</th>
                                    <th scope="col" className="text-center">VALOR PEDIDO</th>
                                    <th scope="col" className="text-center">QTD PEDIDA</th>
                                    <th scope="col">OBS</th>
                                    <th scope="col" className="text-center">AÇÕES</th>
                                </tr>
                            </thead>
                            <tbody >
                                {campaigns.map((c) => (

                                    <tr key={c.id} >
                                        <td data-label="#ID" className="text-center id">{c.id}</td>
                                        <td data-label="Título">{c.title}</td>
                                        <td data-label="Data Final" className='TdDatePicker'>
                                            <div className='ContainerDatePicker'>
                                                <Datepicker

                                                    value={getDateFinal(c.date.replace(/\s/, 'T'))}
                                                    // min={new Date()}
                                                    onChange={(date: string) => updateDate(c, date)}
                                                    inputClass={"example-custom-input"}
                                                />

                                            </div>



                                        </td>
                                        <td data-label="QTD ITENS" className="products-count">
                                            <div>
                                                <Link to={`${Constants.ROUTE_CAMPAIGNS_EDIT}${c.id}`}
                                                    title="Gerenciar produtos"
                                                    className="products-link">
                                                    <Boxes />
                                                    <p>
                                                        {c.itemQuantity}

                                                    </p>
                                                </Link>

                                            </div>
                                        </td>
                                        <td data-label="VALOR PEDIDO" className="text-center">{mask_currency(c.orders_amount)}</td>
                                        <td data-label="QTD PEDIDA" className="text-center " style={{ color: '#f77110' }}>{c.orders_count}</td>
                                        <td data-label="OBS" >{c.observation}</td>
                                        <td data-label="AÇÕES" className="text-center">
                                            <button onClick={() => delete_campaign(c.id)} title="deletar" className="btn-acoes-campanha">
                                                <IconReact type='trash' />

                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                            <EditModal />
                        </table>
                    </div>

                ) : <FeedbackError message={Constants.MSG_DATA_NOT_FOUND} />)}

            </div>
        </ProjectContainer>
    )
}
