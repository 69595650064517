import styled from 'styled-components';
import thumb from '../../../../../assets/images/thumbexample.png'
export const Container = styled.div`
display: flex;
flex-direction: column;
/* flex: 1; */
margin-top: 0.8rem;
width: 100%;
`;
export const CreateContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
flex: 1;
`;
export const HomePreviewContainer = styled.div`
display: flex;
flex-direction: column;
/* width: auto; */

width: 90vw;
width: 90dvw;
/* background-color: red; */
/* flex: 1; */


`;
export const HeaderPreview = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
width: 100%;
border-bottom: 1px solid #cecece;
margin-bottom: 10px;
h2{
    font-family: 'Ubuntu-Medium', sans-serif;
    font-size: 22px;
    margin: 1rem 0;
    color: #605F5F;
}
`;
export const CreateHighlightContainer = styled.div`
display: flex;
flex-direction: column;
flex: 1;
`;
export const StepsContainer = styled.div`
display: flex;
flex-direction: column;
flex: 1;
padding: 1rem;
`;
export const StepType = styled.div`
display: flex;
flex-direction: row;
flex: 1;
justify-content:center;
gap:2rem;
flex-wrap: wrap;
`;
export const YoutubeTypeStep = styled.div`

width: 100%;
height: 100%;
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
position: relative;
img{
    max-width: 300px;
    filter: opacity(0.7);
}
svg{
    font-size: 55px;
    color: #c00;
    position: absolute;

}
`;
export const BannerTypeStep = styled.div`

width: 100%;
height: 100%;

display: flex;
align-items: center;
justify-content: center;
position: relative;
img{
    max-width: 300px;
    max-height: 250px;
    filter: opacity(0.7);
    border-radius: 8px;
}

`;
export const StepTypeButton = styled.div`
padding: 1rem 1.2rem;
display: flex;
flex-direction: column;
border-radius: 8px;
border: 1px solid #cecece;
align-items: center;
box-shadow: 2px 2px 2px  rgba(0,0,0,0.2);

width: 330px;
height: 310px;
cursor: pointer;
h6{
    font-family: 'Ubuntu-Medium', sans-serif;
    font-size: 18px;
    margin-bottom: 5px;
    color: #605F5F;
}
:hover{
        translate: -1px -2px;
        transition: 150ms;
        border-color: #ff7110;
        img{
            transition:150ms;
    filter: opacity(1)
}
    }
`;
export const StepSizeButton = styled.div`
padding: 1rem 1.2rem;
display: flex;
flex-direction: column;
border-radius: 8px;
border: 1px solid #cecece;
align-items: center;
box-shadow: 2px 2px 2px  rgba(0,0,0,0.2);
position: relative;
width: 350px;
height: 370px;
cursor: pointer;
img{
    border-radius: 8px;
    filter: opacity(0.7);
    transition:150ms;
}
div{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
h6{
    font-family: 'Ubuntu-Medium', sans-serif;
    font-size: 18px;
    margin-bottom: 5px;
    color: #605F5F;
}
:hover{
        translate: -1px -2px;
        transition: 150ms;
        border-color: #ff7110;
        img{
            transition:150ms;
    filter: opacity(1)
}
    }
`;
export const StepMetadataButton = styled.div`
padding: 2rem 1.2rem;
display: flex;
flex-direction: column;
border-radius: 8px;
border: 1px solid #cecece;

box-shadow: 2px 2px 2px  rgba(0,0,0,0.2);
position: relative;
max-width: 95%;
min-width: 50%;
/* height: 370px; */
border-color: #ff7110;
gap: 1rem;
/* cursor: pointer; */
img{
    border-radius: 8px;
    filter: opacity(0.7);
    transition:150ms;
}
/* div{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */
h4{
    font-family: 'Ubuntu-Medium', sans-serif;
    font-size: 18px;
    margin-bottom: 5px;
    color: #605F5F;
}

`;



export const StepSize = styled.div`
display: flex;
flex-direction: row;
flex: 1;
justify-content: center;
gap :2rem;
flex-wrap: wrap;
`;
export const StepMetadata = styled.div`
display: flex;
flex-direction: row;
flex: 1;
align-items: center;

justify-content: center;
section{
    display: flex;
    align-items: center;
    gap: 1rem;
}
`;
export const PriorityContainer = styled.div`
display: flex;
flex-direction: column;
section{
    margin-left:2px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    margin-top: 10px;
}

`;
export const ActionContainer = styled.div`
display: flex;
flex-direction: row;
gap: 15px;
margin-top: 10px;


`;
export const ShowSelectedProdContainer = styled.div`
display: flex;
gap: 10px;
margin-left: 15px;
align-items: center;
/* border: 1px solid rgba(0,0,0,0.2);
border-radius: 5px;
padding: 3px 10px; */
img{
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

`;
export const FooterButtons = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
gap: 2rem;
margin-top: 1.5rem;

`;
interface sizeProps {
    size?: {}
}
export const VideoContainer = styled.div<sizeProps>`
    /* width: 250px;
    height: 250px; */
    iframe{
        width:${props => props.size.width}px;
        height: ${props => props.size.height}px;
        border-radius: 8px;
    }
`;
export const ImageContainer = styled.div<sizeProps>`

img{
    width:${props => props.size.width}px;
    /* width:500px; */
    height: ${props => props.size.height}px;
    border-radius: 8px;
    object-fit: cover;
    /* margin: 0 100px; */
}

`;
export const PreviewCardsContainer = styled.div`
/* width: 100%;d */
display: flex;
flex-direction: column;
/* align-items: center; */
width: 100%;

`;
export const RowBlock = styled.div`
display: flex;
align-items: center;


`;
export const AddItemToBlockButton = styled.div`
display: flex;
margin: 5px 0;
flex-direction: row;
gap: 0.8rem;
align-items: center;
justify-content: center;
padding: 1rem;
transition: 100ms;
cursor: pointer;
border-radius: 8px;
background-color: #fff;
box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.1);
transition: 150ms;
width: 50%;

:active{
  translate: 0 2px;
  transition: translate 50ms;
}
svg{
    color: #3498db;
    font-size: 35px;
    transition: 250ms;
  }
  h4{
      font-family: 'Ubuntu-Medium', sans-serif;
      font-size: 16px;
      color: #605F5F;
      span{
        color: #ff7110;
      }
    }
:hover{
  translate: 0 -2px;
    svg{
      /* color: #246c9c; */
      rotate: 90deg;
      transition: 320ms;
    }
    h4{
      font-family: 'Ubuntu-Medium', sans-serif;
      font-size: 16px;
      /* color: black; */
    }
    }


`;
export const NewRowBlockContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
`;
export const CreateBlockSettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border: 1px solid #ff711030;
    border-radius: 8px;
    padding: 0.6rem;

    h4{
        font-family: 'Ubuntu-Medium', sans-serif;
        font-size: 20px;
        margin-bottom: 1rem;
        color: #605F5F;
        /* border-bottom: 1px solid #cecece; */
        padding-bottom: 5px;
    }

    ul{
        display: flex;
        flex-direction: row;
        /* align-items: center; */
        flex-wrap: wrap;
        gap: 1rem;
        section{
            display: flex;
            flex-direction: column;
            gap:0.4rem;
        }
    }

    transition:  400ms ease-in-out;
    animation: 0.5s ease 0s 1 slideInFromRight;
`;
export const ItemConfig = styled.li`
    display: flex;
    flex-direction:column;
    gap: 0.5rem;

    padding: 0.4rem 1rem;
    h3{
      font-family: 'Ubuntu-Medium', sans-serif;
      font-size: 20px;
      margin-bottom: 1rem;
      color: #605F5F;
    }
    span{
      margin-left: 5px;
      margin-bottom: 10px;
    }
`;
export const ButtonAdd = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    section{
        /* background-color: #07bc0c;
        border-radius: 50%;
        padding: 5px; */
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        svg{
            transition: 500ms;
            font-size: 35px;
            color: #07bc0c;
        }
    }
    p{

        font-family: 'Ubuntu-Medium', sans-serif;
        font-size: 18px;
        color: #605F5F;
        text-align: center;
        transition: 600ms;
    }
    :hover{
        /* border: 1px solid #ff7110;
         */
        box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.1);
        transition: 600ms;
        svg{
            /* font-size: 40px; */
            rotate: 180deg;
        }
        p{

            transition: 600ms;

        }
    }

`;
export const ButtonCancelSave = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.1);
    :hover{
      translate: 3px 0;
    }
    section{
        /* background-color: #07bc0c;
        border-radius: 50%;
        padding: 5px; */
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        svg{
            transition: 500ms;
            font-size: 35px;
            color: #07bc0c;
        }
    }
    p{

        font-family: 'Ubuntu-Medium', sans-serif;
        font-size: 18px;
        color: #605F5F;
        text-align: center;
        transition: 600ms;
    }


`;

export const EmptyBlock = styled.div`
    height: 350px;
    width: 100%;
    background-color: #cecece40;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const BlockCard = styled.div`
    border: 1px solid #c6c6c6;
    width: 100%;
    padding: 10px 12px;
    border-radius: 12px;
    box-shadow: 1px 1px 5px  rgba(0,0,0,0.1);
    transition: 200ms;
    background-color: #F6F6F6;
    :hover{
        transition: 200ms;
        box-shadow: 1px 1px 7px  rgba(0,0,0,0.2);
    }

`;
export const ContainerUploadImageDescription = styled.div`
    background-color: #ff711010;
    border-radius: 8px;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    padding: 7px 10px;
    border: 1px solid #ff711070;
    :hover{
        box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
    }
    h5{
        font-family: 'Ubuntu-Medium', sans-serif;
        font-size: 20px;
        color: #605F5F;
        margin-bottom: 10px;
        margin-bottom:5px;
    }
    p{
        font-family: 'Ubuntu-Medium', sans-serif;
        font-size: 16px;
        color: #605F5F;
        margin-left:10px;
        margin-bottom:5px;
        strong{
            color: #000;
        }
    }
    section{
        /* margin-left:15px; */
    }
`;
export const TextsConfigsBlockSetup = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
margin-bottom: 15px;
/* border: 1px solid #ff711060; */
border-bottom: 1px solid #605F5F;
/* border-radius: 8px; */
padding: 5px 8px;
section{
    display: flex;
    flex-direction: column;
    gap: 4px;
    h6{
        font-size: 16px;
        color: #605F5F;
    }
    span{
        font-size: 13px;
    color: #605F5F;
    }
}

`;
export const AlignTitleSetupContainer = styled.div`
/* display: flex;
flex-direction: column;
gap: 10px;
margin-bottom: 15px; */
section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

`;

interface alignProps {
    align: 'left' | 'center' | 'right',
    colorTitle: '',
    colorSubtitle: ''
}

export const AlignPreview = styled.div<alignProps>`
text-align: ${props => props.align};;
h2{
    font-size: 36px;
    flex: 1;
    color: ${props => props.colorTitle ? props.colorTitle : '#293134'};
}
h4{
    font-size: 24px;
    flex: 1;
    color: ${props => props.colorSubtitle ? props.colorSubtitle : '#293134'};
}
`;
interface blockTitleProps {
    align?: 'left' | 'center' | 'right'
    titleColor?: string
    subtitleColor?: string
}
export const BlockTitleContainer = styled.div<blockTitleProps>`
  text-align: ${props => props.align};
  margin: 1rem 0;
`;

export const BlockTitle = styled.h2<blockTitleProps>`
  color: ${props => props.titleColor ? props.titleColor : 'unset'};;
  font-family: Ubuntu;
  font-weight: 400;
  font-size: 2.14rem;
  text-transform: capitalize;
`;

export const BlockSubtitle = styled.h4<blockTitleProps>`
text-transform: capitalize;
font-size: 1.5rem;
margin-top: 2px;
font-family: Ubuntu;
  font-weight: 300;
  color: ${props => props.subtitleColor ? props.subtitleColor : 'unset'};;
`;
