import { CheckBoxCustom } from 'components/checkBoxCustom';
import React, { useState, useRef, useEffect } from 'react';
import { HiChevronDown } from 'react-icons/hi'
import {
    Container,
    Select,
    OptionsContainer,
    Option,
    TextLabel,
    TextSelect
} from './styles';

interface props {
    id?: any,
    options: any,
    value_key: string,
    label_key: string,
    blank_label: string,
    onChange: (items) => void,
    label?: string

}

export function MultipleSelect({ id, options, value_key, label_key, blank_label, onChange, label }: props) {


    const [isOpen, setIsOpen] = useState(false)
    const [selectedItems, setSelectItems] = useState<any>([])

    useEffect(() => {
        onChange(selectedItems)
    }, [selectedItems])

    const selectItem = (value: any) => {

        if (selectedItems.includes(value)) {
            let array = selectedItems.filter((el) => el != value)
            setSelectItems(array)
        } else {
            setSelectItems(old => [...old, value])
        }

    }

    return (
        <Container onMouseLeave={() => setIsOpen(false)}>
            {label ?
                <TextLabel >{label}</TextLabel>
                : null}
            <Select onClick={() => setIsOpen(!isOpen)}>
                <TextSelect>{blank_label}</TextSelect>
            </Select>
            <HiChevronDown style={{ position: 'absolute', right: 5, top: '56%', zIndex: -1 }}></HiChevronDown>
            <OptionsContainer isOpen={isOpen}>
                {options ? options.map((item, index) => {
                    return <Option onClick={() => selectItem(item[value_key])} key={index}>
                        <CheckBoxCustom
                            value={item[value_key]}
                            change={(e, stats) => { }}
                            checked={selectedItems.includes(item[value_key])}
                            description={item[label_key]}
                        ></CheckBoxCustom>
                    </Option>
                })
                    : <></>}
            </OptionsContainer>


        </Container>
    );
}

