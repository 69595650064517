import React, { FunctionComponent } from 'react'
import styles from './index.module.scss'

const NewModalComponent: FunctionComponent<any> = ({ children, modal, ...props } : any) => {

    return (
        <div className={ `new-custom-modal modal animated fadeIn` } id={ modal.id } { ...props }>
            <div className="modal-dialog modal-lg" role="document" style={ modal.styles }>
                <div className="modal-content">
                    <div className={ `modal-header ${styles['modal-header']}` }>
                        <h4 className="modal-title" id="exampleModalLabel">
                            <span className="material-icons">{ modal.icon }</span>
                            <span>
                                { modal.title }
                                <span className={ styles.subtitle }>
                                    { modal.subtitle && <><br/>{ modal.subtitle }</> }
                                </span>
                            </span>
                        </h4>
                        <button className="close" data-dismiss="modal">
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    <div className={ `modal-body ${styles['modal-body']}` } style={ modal.body_styles }>
                        { children }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewModalComponent
