import { Request } from "interfaces/request"
import ProductModel from "./product.model"

export default class OfferModel {

    request_id: number
    seller_id: number
    price: number
    product_id: number
    offer_quantity: number
    changed?: boolean
    display_price?: string
    last_offer_price: number
    purchase_unity?: string
    generic?: boolean = true
    others?: boolean = false
    obs?: string

    constructor({ request_id, seller_id, price, product_id, offer_quantity, last_offer_price, generic, obs }) {
        this.request_id = request_id
        this.last_offer_price = last_offer_price
        this.seller_id = seller_id
        this.price = price
        this.generic = generic
        this.product_id = product_id
        this.offer_quantity = offer_quantity
        this.obs = obs
    }

    to_request({ ...props } : any = {}) {
        return {
            numerocotacao: this.request_id,
            vendedor: `${this.seller_id}`,
            preco: this.price*this.offer_quantity,
            produto: this.product_id,
            outrascotacoes: this.others,
            quantidadeDigitacao: this.generic ? this.offer_quantity : null,
            ...props
        }
    }

    with_price(price: number) {
        this.price = price
        return this
    }

    static from_product(product: ProductModel, request: Request) {
        return new OfferModel({
            product_id: product.id,
            generic: product.is_generic,
            price: product.new_offer_price,
            request_id: request.id,
            offer_quantity: product.new_offer_quantity,
            seller_id: request.seller_id,
            obs: product.observations,
            last_offer_price: product.last_offer_price
        })
    }
}
