export default class ProductModel {

    id: number
    name: string
    is_previous_offer_winner: boolean
    is_generic: boolean
    last_offer_price: number
    previous_offer: number
    derivatives: ProductModel[]
    purchase_unity: string
    ean: string
    packing: string
    requested_quantity: number
    offer: { quantity?: number, price?: number }
    category: string
    brand: string
    starred: boolean
    is_discontinued?: boolean
    observations: string
    selected_amount?: number

    flagged?: boolean
    winner_offer_ratio?: number
    new_offer_quantity?: number
    new_offer_price?: number
    main_product?: ProductModel
    visible?: boolean = true
    saved?: boolean = false
    obs_saved?: boolean
    qtd_saved?: boolean
    next?: ProductModel
    prev?: ProductModel
    ref?: any
    obs_ref?: any
    cat_id?: any
    is_winner?: any
    offer_flag?: string

    constructor({
        id, is_generic, last_offer_price, derivatives, purchase_unity, ean, packing, offer_quantity,
        offer_price, name, requested_quantity, category, brand, starred, observations, previous_offer,
        is_previous_offer_winner, flagged, is_discontinued ,cat_id, is_winner, selected_amount, winner_offer_ratio, offer_flag } : any) {
        this.id = id
        this.offer_flag = offer_flag
        this.flagged = flagged
        this.winner_offer_ratio = winner_offer_ratio
        this.selected_amount = selected_amount
        this.is_winner = is_winner
        this.is_discontinued = is_discontinued
        this.previous_offer = previous_offer
        this.observations = observations
        this.is_previous_offer_winner = is_previous_offer_winner
        this.starred = starred
        this.brand = brand
        this.is_generic = is_generic
        this.last_offer_price = last_offer_price
        this.derivatives = derivatives
        this.purchase_unity = purchase_unity
        this.ean = ean
        this.packing = packing
        this.offer = { quantity: offer_quantity, price: offer_price }
        this.name = name
        this.category = category
        this.requested_quantity = requested_quantity
        this.cat_id = cat_id
    }

    process_last_offer(offers: any) {
        offers.forEach((offer: any) => {
            if(this.id == offer.main_product_id) {
                this.last_offer_price = this.previous_offer || offer.last_offer
            }
            this.derivatives.forEach((deriv: ProductModel) => {
                if(deriv.id == offer.product_id && offer.last_offer > 0) {
                    this.last_offer_price = deriv.previous_offer || offer.last_offer
                    deriv.last_offer_price = deriv.previous_offer || offer.last_offer
                    deriv.is_discontinued = offer.is_discontinued == 1
                    if(deriv.offer.quantity === 0) {
                        deriv.offer.quantity = offer.last_offer_quantity ? offer.last_offer_quantity : 1
                    }
                }
            })
        })
    }

}
