import { Loader } from 'components/loader'
import NewModalComponent from 'components/new-modal'
import React, { FC, useEffect, useState } from 'react'
import { OrderDetails } from '../order-details/order-details'
import styles from './index.module.scss'

export const OrderModalDetails: FC<any> = ({ isMobileView, order, order_type }: any) => {

    const [modal, setModal] = useState({})

    useEffect(() => {
        setModal({
            title: `Pedido ${(order) ? order.id : ''}`,
            id: 'order-details',
            icon: 'content_paste',
            styles: {
                maxWidth: '70vw'
            }
        })
    }, [order])

    return (
        <NewModalComponent modal={modal}>
            {order ? <OrderDetails isMobileView={isMobileView} order={order} order_type={order_type} /> : <Loader className={styles.loader} type="inline" />}
        </NewModalComponent>
    )
}


