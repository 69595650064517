
import styled from 'styled-components'

interface props{
    hasBorderBottom?:boolean
}


export const Container = styled.div<props>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 300px;
    padding: 10px 2px;
    border-bottom: ${({ hasBorderBottom}) =>hasBorderBottom? '1px solid #E5E5E5': 'none'};

`;
export const Title = styled.p`
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #707070;
`;
export const Description = styled.p`
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #a1a1a1;
`;