import React, { useContext, useEffect, useState } from 'react'
import Constants from '../../../constants'
import RepresentativeService from 'services/RepresentativesService'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectContainer } from 'components/project-container'
import { Representative } from 'interfaces/representative'
import { Loader } from 'components/loader'
import RepresentativeForm from '../components/form'
import './index.scss'
import { PageHeader } from 'components/page-header'
import { UserContext } from 'contexts/user'
import SnackBarErrortyle from 'styles/snackbar-error.style'
import { Snackbar } from 'components/snackbar'
import { useSnackbar } from 'react-simple-snackbar'
import { PageHeaderV2 } from 'components/pageHeaderV2'

const RepresentativeEdit = () => {

    const { id } = useParams<string>()
    const [ representative, setRepresentative ] = useState<Representative | null>(null)
    const [ loading, setLoading ] = useState<boolean>(false)
    const { can, user } = useContext(UserContext)
    const [ openSnackbar ] = useSnackbar(SnackBarErrortyle)

    useEffect(() => {
        setLoading(true)
        RepresentativeService.getById(id as string)
            .then((currentRepresentative: Representative) => {
                setLoading(false)
                setRepresentative(currentRepresentative)
            })
    }, [ id, setRepresentative ])

    const navigate = useNavigate()

    const save = (representativeData) => {
        setLoading(true)
        RepresentativeService.edit({ ...representativeData, vendedor_id: id })
            .then((response) => {
                setLoading(false)
                if(response.status == "500") {
                    openSnackbar(<Snackbar icon="error_outline">
                        { response.mensagem }
                    </Snackbar>)
                } else {
                    if(can('UPDATE', 'PROVIDER')) {
                        navigate(`${Constants.ROUTE_PROVIDER_EDIT}?r=1`)
                    } else {
                        navigate(Constants.ROUTE_REPRESENTATIVES_EDIT + `/${user.id}`)
                    }
                }
            })
            .catch(error => {
                error.json().then( errorMessage => {
                    alert(errorMessage.mensagem)
                })
                setLoading(false)
            })
    }

    return (
        <ProjectContainer>
            { !loading ? <div className="representative-edit">
                <header>
                    <PageHeaderV2 title="Editar Representante" />
                </header>
                <div className="form">
                    <RepresentativeForm representative={ representative }
                        saveCallback={ (representative: Representative) => save(representative) }/>
                </div>
            </div> : <Loader /> }
        </ProjectContainer>
    )
}

export default RepresentativeEdit
