import RequestService from '../../services/RequestService'
import { ResponseApi } from '../../interfaces/responseApi'
import { RequestDetail } from '../../interfaces/request-detail.model'
import { Request } from '../../interfaces/request'

// TYPES
export const LOAD_REQUESTS_SUCCESS   = 'LOAD_REQUESTS_SUCCESS'
export const LOAD_STATUS_LIST   = 'LOAD_STATUS_LIST'
export const LOAD_REQUEST_DETAIL   = 'LOAD_REQUEST_DETAIL'
export const SET_REQUEST = 'SET_REQUEST'
// Reducer
const initialState = {
    status_list: [
        { status_nome: "Digitada", status_id: "TYPED" },
        { status_nome: "Não Digitada", status_id: "NOT_TYPED" },
        { status_nome: "Sem Pedido", status_id: "NOT_ORDERED" },
        { status_nome: "Pedido Gerado", status_id: "ORDERED" },
        { status_nome: "Congelada", status_id: "FROZEN" },
    ],
    states: [
        { "name": "Acre", "value": "AC" },
        { "name": "Alagoas", "value": "AL" },
        { "name": "Amapá", "value": "AP" },
        { "name": "Amazonas", "value": "AM" },
        { "name": "Bahia", "value": "BA" },
        { "name": "Ceará", "value": "CE" },
        { "name": "Distrito Federal", "value": "DF" },
        { "name": "Espírito Santo", "value": "ES" },
        { "name": "Goiás", "value": "GO" },
        { "name": "Maranhão", "value": "MA" },
        { "name": "Mato Grosso", "value": "MT" },
        { "name": "Mato Grosso do Sul", "value": "MS" },
        { "name": "Minas Gerais", "value": "MG" },
        { "name": "Pará", "value": "PA" },
        { "name": "Paraíba", "value": "PB" },
        { "name": "Paraná", "value": "PR" },
        { "name": "Pernambuco", "value": "PE" },
        { "name": "Piauí", "value": "PI" },
        { "name": "Rio de Janeiro", "value": "RJ" },
        { "name": "Rio Grande do Norte", "value": "RN" },
        { "name": "Rio Grande do Sul", "value": "RS" },
        { "name": "Rondônia", "value": "RO" },
        { "name": "Roraima", "value": "RR" },
        { "name": "Santa Catarina", "value": "SC" },
        { "name": "São Paulo", "value": "SP" },
        { "name": "Sergipe", "value": "SE" },
        { "name": "Tocantins", "value": "TO" }
    ]
}

export default function requests_state(state = initialState, action: any) {
    if ( action.type === LOAD_REQUESTS_SUCCESS ) {
        return { ...state, requests: action.requests }
    }
    if ( action.type === LOAD_STATUS_LIST ) {
        return { ...state, status_list: action.status_list }
    }
    if ( action.type === LOAD_REQUEST_DETAIL ) {
        return { ...state, buyer: action.buyer }
    }
    if ( action.type === SET_REQUEST ) {
        return { ...state, request: action.request }
    }
    return state
}

// ACTIONS
export const load_requests = (params: any) => (dispatch: any) =>
    RequestService.getAll(params)
        .then((response) => dispatch(loadOrdersSuccess(response as ResponseApi)))
        .catch(() => dispatch(loadOrdersSuccess(null)))

export const load_others_requests = (params: any) => (dispatch: any) =>
    RequestService.load_others_request(params)
        .then((response) => dispatch(set_request(response)))
        .catch(() => dispatch(set_request(null)))

export const load_request_products = (id: number, seller_id: number) => (dispatch: any) =>
    RequestService.load_request_products(id, seller_id)
        .then((response) => dispatch(set_buyer(response)))
        .catch(() => dispatch(set_buyer(null)))

export const load_request = (id: number) => (dispatch: any) =>
    RequestService.load_request(id)
        .then((response) => dispatch(set_request(response)))
        .catch(() => dispatch(set_request(null)))

const loadOrdersSuccess = (requests: ResponseApi | null) => {
    return { type: LOAD_REQUESTS_SUCCESS, requests }
}

const set_buyer = (buyer: RequestDetail | null) => {
    return { type: LOAD_REQUEST_DETAIL, buyer }
}

const set_request = (request: Request | null) => {
    return { type: SET_REQUEST, request }
}
