import React, { FC } from 'react'
import './index.scss'

interface Props {
  message: string
}

export const FeedbackSuccess: FC<Props> = ({ message } : any) => (
    <div className="alert alert-success">{ message }</div>
)
