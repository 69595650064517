import Constants from "./constants"

export default class Utils {

    static strpad = (value: string | number) => ('00' + (value)).slice(-2)

    static formatPrice = (price: number) => {
        let isPrecision;
        let formattedPrice;
        if(typeof(price) !='number')return price
        if (price) {
            const stringfied = price.toFixed(4); // Fixa o número de casas decimais em 4

            // Verifica se os dois últimos dígitos são '00'
            if (stringfied.endsWith('00')) {
                formattedPrice = new Intl.NumberFormat(['pt-BR'], {
                    style: 'currency',
                    currency: 'BRL'
                }).format(parseFloat(stringfied)); // Remove os dois últimos zeros se forem '00'
            } else {
                formattedPrice = new Intl.NumberFormat(['pt-BR'], {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4
                }).format(parseFloat(stringfied));
            }
        } else {
            formattedPrice = new Intl.NumberFormat(['pt-BR'], {
                style: 'currency',
                currency: 'BRL'
            }).format(price);
        }

        return formattedPrice;
    };
    // static formatPrice= (price: number) => {

    //        return new Intl.NumberFormat(['pt-BR'], {
    //             style: 'currency', currency: 'BRL',
    //         }).format(price)

    //     }



    static formatPriceToApi = (price: string) =>
        price ? parseFloat(price.replaceAll('.', '').replaceAll(',', '.')) : 0
    //?resolve bug valor
    static formatPriceToApiFix = (price: string) =>
        price ? parseFloat(price.replaceAll(',', '.')) : 0
    //?/
    static formatImageToApi = (imageBase64: string) => imageBase64.split(',')[1]

    static lockBody = () => document.body.classList.add(Constants.BODY_LOCK_CLASSNAME)

    static unlockBody = () => document.body.classList.remove(Constants.BODY_LOCK_CLASSNAME)

    static removeMoneySignal = (data: string): string => data.replace(Constants.CURRENCY_SIGNAL, '')

}
