import { Salesman } from '../../interfaces/salesman'
import SalesmanService from '../../services/SalesmanService'


// TYPES
export const LOAD_SALESMEN_SUCCESS   = 'LOAD_SALESMEN_SUCCESS'


// Reducer
const initialState = null

export default function salesmen(state = initialState, action: any) {
    if (action.type === LOAD_SALESMEN_SUCCESS) {
        return action.salesmen
    } else {
        return state
    }
}


// ACTIONS
export const loadSalesmen = () => {
    return (dispatch: any) => SalesmanService.getAll()
        .then((response) => {
            dispatch(loadSalesmenSuccess(response))
        })
        .catch(() => {
            dispatch(loadSalesmenSuccess(null))
        })
}

const loadSalesmenSuccess = (salesmen: Salesman[] | null) => {
    return { type: LOAD_SALESMEN_SUCCESS, salesmen }
}
