import React, { useEffect, useState } from 'react'
import pao from '../../../../../assets/images/paogergilim.png'
import {
    AlphaPicker,
    BlockPicker,
    ChromePicker,
    CirclePicker,
    CompactPicker,
    GithubPicker,
    HuePicker,
    MaterialPicker,
    PhotoshopPicker,
    SketchPicker,
    SliderPicker,
    SwatchesPicker,
    TwitterPicker,

} from 'react-color'

import {
    Container,

    ConfigsContainer,
    PreviewContainer,
    Preview,
    HeaderPreview,
    TextHeader,
    IconContainer,
    Icon,
    MenuSelectors,
    Menu,
    ButtonSelector,
    BodyPreview,
    TextsContainer,
    SecondRowPreview,
    CartIconComponent,
    CartItemEmoji,
    CartCounter,
    Collumn,
    Input,
    LabelText,
    PrimaryButton,
    ButtonSaveChanges,
    ButtonSaveChangesDefault,
    InputImage,
    UploadImageContainer,
    CloseContainer
} from './styles'
import ColorPickerInput from './ColorPickerInput'
import { GiHamburgerMenu } from 'react-icons/gi'
import ProductCard from './ProductCard'
import { IoMdArrowBack, IoMdClose, IoMdDocument, IoMdHome } from 'react-icons/io'
import { RiFileUserFill } from 'react-icons/ri'
import { MdOutlineShoppingCart } from 'react-icons/md'
import ColorPickerInputGradient from './ColorPickerInputGradient'
import themeService from 'services/v3/ThemeConfig.service'
import ButtonV3 from 'components/Buttonv3'
import { ToastContainer, toast } from 'react-toastify'
import { Loader } from 'components/loader'
import axios from 'axios'


interface props {
    closeVisibleContent: () => void
}

export default function WhitelabelTheme({ closeVisibleContent }: props) {
    const defaultTheme: any = {
        colors: {

            primary: '#FF7010',
            secondary: '#F6F6F6',
            textWhite: '#fff',

            textPrimary: '#2D2A31',
            textSecondary: '#293134',
            textTertiary: '#8D8D8D',

            background: '#F6F6F6',
            backgroundWhite: '#FFF',
            white: '#fff',
            danger: '#C50000',
            'gray-100': '#E1E1E6',
            'gray-300': '#C4C4CC',
            'gray-400': '#8D8D99',
            'gray-500': '#7C7C8A',
            'gray-600': '#323238',
            'gray-700': '#29292E',
            'gray-800': '#202024',
            'gray-900': '#121214',

            'green-300': '#00B37E',
            'green-500': '#00875F',
            'green-700': '#015F43',

            'red-500': '#AB222E',
            'red-700': '#7A1921',

            'yellow-500': '#FBA94C',

            gradient: {
                primary: 'linear-gradient(90.91deg, #DF8F30 -3.32%, #FF9D34 100%);',
                // primary: '#DF8F3',
                // secondary: ' #c3c3',
            }
        },
        header: {
            backgroundColor: '#FF7010',
            iconColor: '#fff',
            iconActiveColor: '#29292E',
            textColor: '#fff',

        },
        imgs: {
            img_window: "",
            img_loading: "",
            img_background: "",
            img_logo: ""
        },
    }
    const [blockPickerColor, setBlockPickerColor] = useState<any>(defaultTheme)
    const [visiblePicker, setVisiblePicker] = useState('')
    const [menuVisible, setMenuVisible] = useState('HEADER')
    const [actualTheme, setActualTheme] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [logo, setLogo] = useState<any>()
    const [background, setBackground] = useState<any>()


    function handleSetVisiblePicker(id: string) {
        visiblePicker == id ? setVisiblePicker('') : setVisiblePicker(id)
    }

    useEffect(() => { fetchTheme() }, [])
    useEffect(() => {
        if (actualTheme) {
            handleSetPickersParams(actualTheme)
        }
    }, [actualTheme])

    async function fetchTheme() {

        await themeService.getProviderTheme().then(res => {
            // console.log('actualthenme ?', res)
            setActualTheme(res)
        }
        )
    }
    async function saveNewTheme(data) {
        await themeService.postProviderTheme(data).then(res =>
            toast.success('Alterações salvas !', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,

            })
        ).catch(err => toast.error('Erro ao salvar !', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,

        }))
        setIsLoading(false)
    }
    async function changeTheme(data) {
        await themeService.putProviderTheme(actualTheme.id, data).then(res =>
            toast.success('Alterações salvas !', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,

            })
        ).catch(err => toast.error('Erro ao salvar !', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,

        }))
        setIsLoading(false)
    }

    function handleSetPickersParams(data) {

        setBlockPickerColor({
            ...blockPickerColor,
            header: {
                ...blockPickerColor.header,
                backgroundColor: data.header_background,
                iconColor: data.header_icon_color,
                iconActiveColor: data.header_icon_active_color,
                textColor: data.header_text_color,
            },
            colors: {
                ...blockPickerColor.colors,
                primary: data.primary_color,
                secondary: data.secondary_color,
                textWhite: data.text_white,
                textPrimary: data.text_primary,
                textSecondary: data.text_secondary,
                textTertiary: data.text_tertiary,
                background: data.background,
                backgroundWhite: data.background_white,
            },
            imgs: {
                ...blockPickerColor.imgs,
                img_window: data.img_window,
                img_loading: data.img_loading,
                img_background: data.img_background,
                img_logo: data.img_logo,
            },

        })
    }

    // function handleUploadImage(event) {
    //     const file = event.target.files[0];
    //     console.log('file ----- ', file)


    //     if (file) {

    //         const formData = new FormData();
    //         formData.append('image', file);

    //         const theme = {
    //             primary_color: blockPickerColor.colors.primary,
    //             secondary_color: blockPickerColor.colors.secondary,
    //             text_white: blockPickerColor.colors.textWhite,
    //             text_primary: blockPickerColor.colors.textPrimary,
    //             text_secondary: blockPickerColor.colors.textSecondary,
    //             text_tertiary: blockPickerColor.colors.textTertiary,
    //             background: blockPickerColor.colors.background,
    //             background_white: blockPickerColor.colors.backgroundWhite,
    //             header_background: blockPickerColor.header.backgroundColor,
    //             header_icon_color: blockPickerColor.header.iconColor,
    //             header_icon_active_color: blockPickerColor.header.iconActiveColor,
    //             header_text_color: blockPickerColor.header.textColor,
    //         };

    //         formData.append('theme', JSON.stringify(theme));


    // const reader = new FileReader();

    // // Carrega o arquivo em um objeto Image
    // reader.onload = function (e: any) {
    //     const image: any = new Image();
    //     image.src = e.target.result;

    //     console.log('result ?', e.target.result)

    // };
    // console.log('?', reader.readAsDataURL(file))
    // Lê o conteúdo do arquivo como uma URL de dados
    // reader.readAsDataURL(file);

    // }


    function handleSaveThemeChanges() {
        setIsLoading(true)
        let theme = {
            primary_color: blockPickerColor.colors.primary,
            secondary_color: blockPickerColor.colors.secondary,
            text_white: blockPickerColor.colors.textWhite,
            text_primary: blockPickerColor.colors.textPrimary,
            text_secondary: blockPickerColor.colors.textSecondary,
            text_tertiary: blockPickerColor.colors.textTertiary,
            background: blockPickerColor.colors.background,
            background_white: blockPickerColor.colors.backgroundWhite,

            header_background: blockPickerColor.header.backgroundColor,
            header_icon_color: blockPickerColor.header.iconColor,
            header_icon_active_color: blockPickerColor.header.iconActiveColor,
            header_text_color: blockPickerColor.header.textColor,
        }
        if (actualTheme && actualTheme.id) {
            const formData = new FormData();


            if (logo) {
                formData.append('img_logo', logo);
            }
            if (background) {
                formData.append('img_background', background);
            }
            for (let key in theme) {
                formData.append(key, theme[key])
            }
            // formData.append('theme', JSON.stringify(theme));
            changeTheme(formData)
        } else {

            const formData = new FormData();

            if (logo) {
                formData.append('img_logo', logo);
            }
            if (background) {
                formData.append('img_background', background);
            }


            for (let key in theme) {
                formData.append(key, theme[key])
            }

            saveNewTheme(formData)
        }
    }



    return (
        <Container>
            {isLoading ?
                <Loader></Loader>
                : null}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable

                pauseOnHover />
            <ConfigsContainer>
            <div style={{ padding: '0 0 15px 0', display: 'flex', gap: 15, flexWrap: 'wrap' ,justifyContent:'space-between' ,marginBottom:10}}>
                    <div style={{  display: 'flex', gap: 15, flexWrap: 'wrap' }} >
                        <ButtonSaveChangesDefault onClick={() => setBlockPickerColor(defaultTheme)} >Definir Cores Padrão do Club</ButtonSaveChangesDefault>
                        <ButtonSaveChanges onClick={() => handleSaveThemeChanges()}>Salvar Mudanças</ButtonSaveChanges>

                    </div>
                    <div>
                        <ButtonV3 Icon={<IoMdArrowBack className='iconButtonV3'></IoMdArrowBack>} title='Voltar' onClick={closeVisibleContent} />
                    </div>

                </div>
                <MenuSelectors>
                    <ButtonSelector onClick={() => setMenuVisible('HEADER')} active={menuVisible == 'HEADER'}>
                        <span>Cabeçalho</span>
                    </ButtonSelector>
                    <ButtonSelector onClick={() => setMenuVisible('COLORS')} active={menuVisible == 'COLORS'}>
                        <span>Cores Principais</span>
                    </ButtonSelector>
                    <ButtonSelector onClick={() => setMenuVisible('TEXT')} active={menuVisible == 'TEXT'}>
                        <span>Textos</span>
                    </ButtonSelector>
                    <ButtonSelector onClick={() => setMenuVisible('BACKGROUND')} active={menuVisible == 'BACKGROUND'}>
                        <span>Plano de fundo</span>
                    </ButtonSelector>
                    <ButtonSelector onClick={() => setMenuVisible('IMAGENS')} active={menuVisible == 'IMAGENS'}>
                        <span>Imagens</span>
                    </ButtonSelector>

                </MenuSelectors>

                <Menu>
                    {menuVisible == 'HEADER' ?
                        <>

                            <ColorPickerInput
                                title='Cor do cabeçalho'
                                onSelectPicker={() => handleSetVisiblePicker('header')}
                                color={blockPickerColor.header.backgroundColor}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    header: {
                                        ...blockPickerColor.header,
                                        backgroundColor: color,
                                    },
                                })}
                                visible={visiblePicker == 'header'}
                                tooltip='Define cor usada no cabeçalho da página'
                            />
                            <ColorPickerInput
                                title='Texto do cabeçalho'
                                onSelectPicker={() => handleSetVisiblePicker('textHeader')}
                                color={blockPickerColor.header.textColor}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    header: {
                                        ...blockPickerColor.header,
                                        textColor: color,
                                    },
                                })}
                                visible={visiblePicker == 'textHeader'}
                                tooltip='Define cor usada em títulos, botôes e ícones'
                            />
                            <ColorPickerInput
                                title='Cor dos ícones'
                                onSelectPicker={() => handleSetVisiblePicker('iconColor')}
                                color={blockPickerColor.header.iconColor}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    header: {
                                        ...blockPickerColor.header,
                                        iconColor: color,
                                    },
                                })}
                                visible={visiblePicker == 'iconColor'}
                                tooltip='Define cor dos ícones do cabeçalho'
                            />
                            <ColorPickerInput
                                title='Cor do ícone ativo'
                                onSelectPicker={() => handleSetVisiblePicker('iconColorActive')}
                                color={blockPickerColor.header.iconActiveColor}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    header: {
                                        ...blockPickerColor.header,
                                        iconActiveColor: color,
                                    },
                                })}
                                visible={visiblePicker == 'iconColorActive'}
                                tooltip='Define cor do ícone ativo no cabeçalho'
                            />
                        </>
                        : null}
                    {menuVisible == 'COLORS' ?
                        <>
                            <ColorPickerInput
                                title='Cor primária'
                                onSelectPicker={() => handleSetVisiblePicker('primary')}
                                color={blockPickerColor.colors.primary}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    colors: {
                                        ...blockPickerColor.colors,
                                        primary: color,
                                    },
                                })}
                                visible={visiblePicker == 'primary'}
                                tooltip='Define cor usada em títulos, botôes e ícones'
                            />
                            <ColorPickerInput
                                title='Cor secundaria'
                                onSelectPicker={() => handleSetVisiblePicker('secondary')}
                                color={blockPickerColor.colors.secondary}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    colors: {
                                        ...blockPickerColor.colors,
                                        secondary: color,
                                    },
                                })}
                                visible={visiblePicker == 'secondary'}
                                tooltip='Define cor secundária, geralmente com uma leve diferença da cor primária'
                            />
                            {/* <ColorPickerInputGradient
                                title='Gradient'
                                onSelectPicker={() => handleSetVisiblePicker('secondary')}
                                color={blockPickerColor.colors.gradient.primary}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    colors: {
                                        ...blockPickerColor.colors,
                                        secondary: color,
                                    },
                                })}
                                visible={visiblePicker == 'secondary'}
                                tooltip='Define cor secundária, geralmente com uma leve diferença da cor primária'
                            /> */}
                        </>
                        : null}
                    {menuVisible == 'TEXT' ?
                        <>
                            <ColorPickerInput
                                title='Text primário'
                                onSelectPicker={() => handleSetVisiblePicker('primaryText')}
                                color={blockPickerColor.colors.textPrimary}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    colors: {
                                        ...blockPickerColor.colors,
                                        textPrimary: color,
                                    },
                                })}
                                visible={visiblePicker == 'primaryText'}
                                tooltip='Define cor do texto principal'
                            />
                            <ColorPickerInput
                                title='Texto secundário'
                                onSelectPicker={() => handleSetVisiblePicker('secondaryText')}
                                color={blockPickerColor.colors.textSecondary}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    colors: {
                                        ...blockPickerColor.colors,
                                        textSecondary: color,
                                    },
                                })}
                                visible={visiblePicker == 'secondaryText'}
                                tooltip='Define cor do texto secundário'
                            />
                            <ColorPickerInput
                                title='Texto terciário'
                                onSelectPicker={() => handleSetVisiblePicker('tertiaryText')}
                                color={blockPickerColor.colors.textTertiary}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    colors: {
                                        ...blockPickerColor.colors,
                                        textTertiary: color,
                                    },
                                })}
                                visible={visiblePicker == 'tertiaryText'}
                                tooltip='Define cor do texto terciário'
                            />
                        </>
                        : null}

                    {menuVisible == 'BACKGROUND' ?
                        <>
                            <ColorPickerInput
                                title='Fundo primário'
                                onSelectPicker={() => handleSetVisiblePicker('primaryBackground')}
                                color={blockPickerColor.colors.backgroundWhite}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    colors: {
                                        ...blockPickerColor.colors,
                                        backgroundWhite: color,
                                    },
                                })}
                                visible={visiblePicker == 'primaryBackground'}
                                tooltip='Define cor do primária do background das telas'
                            />
                            <ColorPickerInput
                                title='Fundo secundário'
                                onSelectPicker={() => handleSetVisiblePicker('secondaryBackground')}
                                color={blockPickerColor.colors.background}
                                setColor={(color) => setBlockPickerColor({
                                    ...blockPickerColor,
                                    colors: {
                                        ...blockPickerColor.colors,
                                        background: color,
                                    },
                                })}
                                visible={visiblePicker == 'secondaryBackground'}
                                tooltip='Define cor do secundária do background das telas'
                            />
                        </>
                        : null}
                    {menuVisible == 'IMAGENS' ?
                        <>
                            <UploadImageContainer>
                                <p>Sua Logomarca</p>
                                <InputImage type='file' accept="image/*" onChange={(e) => { setLogo(e.target.files[0]) }} ></InputImage>
                            </UploadImageContainer>

                            <UploadImageContainer>
                                <p>Plano de fundo do Login</p>
                                <InputImage type='file' accept="image/*" onChange={(e) => { setBackground(e.target.files[0]) }} ></InputImage>
                            </UploadImageContainer>


                        </>
                        : null}
                </Menu>
            </ConfigsContainer>
            <PreviewContainer>
                {/* <div style={{ padding: '0 0 15px 0', display: 'flex', gap: 15, flexWrap: 'wrap' ,justifyContent:'space-between' }}>
                    <div style={{  display: 'flex', gap: 15, flexWrap: 'wrap' }} >
                        <ButtonSaveChangesDefault onClick={() => setBlockPickerColor(defaultTheme)} >Definir Cores Padrão do Club</ButtonSaveChangesDefault>
                        <ButtonSaveChanges onClick={() => handleSaveThemeChanges()}>Salvar Mudanças</ButtonSaveChanges>

                    </div>
                    <div>
                        <ButtonV3 Icon={<IoMdArrowBack className='iconButtonV3'></IoMdArrowBack>} title='Voltar' onClick={closeVisibleContent} />
                    </div>

                </div> */}
                <Preview>
                    <HeaderPreview themeColor={blockPickerColor.header.backgroundColor}>
                        <section>
                            <IconContainer>

                                {logo || actualTheme && actualTheme.img_logo ?
                                    <img src={
                                        logo ?
                                            URL.createObjectURL(logo) :
                                            actualTheme && actualTheme.img_logo ? actualTheme.img_logo : ''
                                    } alt="" />
                                    : null}

                            </IconContainer>
                            <TextHeader themeColor={blockPickerColor.header.textColor}>Texto do cabeçalho</TextHeader>

                        </section>
                        <section>
                            <Icon themeColor={blockPickerColor.header.iconActiveColor}>
                                <IoMdHome></IoMdHome>

                            </Icon>
                            <Icon themeColor={blockPickerColor.header.iconColor}>
                                <IoMdDocument></IoMdDocument>

                            </Icon>
                            <Icon themeColor={blockPickerColor.header.iconColor}>
                                <RiFileUserFill></RiFileUserFill>

                            </Icon>
                            <Icon themeColor={blockPickerColor.header.iconColor}>
                                <GiHamburgerMenu></GiHamburgerMenu>

                            </Icon>
                        </section>
                    </HeaderPreview>
                    <BodyPreview theme={blockPickerColor}>
                        <TextsContainer theme={blockPickerColor}>
                            <h1>Cor Primária</h1>
                            <h4>Texto Primário</h4>
                            <h5>Texto Secundário</h5>
                            <h6>Texto terciário</h6>
                        </TextsContainer>
                        <SecondRowPreview>

                            <Collumn>
                                <CartIconComponent theme={blockPickerColor}>
                                    <CartItemEmoji > &#x1F354;</CartItemEmoji>
                                    <MdOutlineShoppingCart id={"cartIcon"} />

                                    <CartCounter theme={blockPickerColor}>
                                        3
                                    </CartCounter>

                                </CartIconComponent>
                                <div>
                                    <LabelText theme={blockPickerColor}>Inputs</LabelText>
                                    <Input theme={blockPickerColor}></Input>

                                </div>
                                <PrimaryButton theme={blockPickerColor}>teste</PrimaryButton>
                            </Collumn>

                            <ProductCard theme={blockPickerColor}></ProductCard>
                        </SecondRowPreview>


                    </BodyPreview>
                </Preview>
            </PreviewContainer>
        </Container >
    )
}
