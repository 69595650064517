import React from "react";


import{
    Container,
    Title,
    Value,
} from './styles'

interface props{
    title:string,
    value:string
}


export function DescriptionRowOrderDetail({title, value}:props){


return(
    <Container>
        <Title>{title}</Title>
        <Value>{value}</Value>
    </Container>
)


}
