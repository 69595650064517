import styled from 'styled-components';

interface props {
    isActive
}

export const Container = styled.div<props>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: ${({isActive}:props) =>  isActive ? 'rgba(255, 113, 16, 0.15)' : '#fff' };
    height: 45px;
    padding: 0 30px;
    cursor: pointer;

    font-size: 14px;
    font-family: 'Rubik';
    font-weight: ${({isActive}:props) =>  isActive ? 600 : 400 };
    color: ${({isActive}:props) =>  isActive ? '#404040' : '#605F5F' };

    svg{
        color: #ff7110;
        font-size: 20px;
        margin-right: 8px;
    }

`;

