import { PageHeaderV2 } from 'components/pageHeaderV2'
import { UserContext } from 'contexts/user'
import { is_editable } from 'pages/request/components/requested-products/requested-products.helper'
import { useCallback, useContext, useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { MdPersonAddAlt } from 'react-icons/md'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import RequestService from 'services/RequestService'
import StorageService from 'services/StorageService'
import ClientServiceV2 from 'services/v2/ClientService'
import ClientService from 'services/v3/clients.service'
import { set_requests_filters } from 'store/filters'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FeedbackError } from '../../components/feedback/error'
import { FeedbackSuccess } from '../../components/feedback/success'
import { Loader } from '../../components/loader'
import { Pagination } from '../../components/pagination'
import { ProjectContainer } from '../../components/project-container/index'
import { Status } from '../../components/request-status'
import Constants from '../../constants'
import { Request } from '../../interfaces/request'
import DateService from '../../services/DateService'
import OrderService from '../../services/OrderService'
import { load_requests } from '../../store/requests'
import { AddSellerToRequestModal } from './components/add-seller-to-request-modal'
import { ClientDetailsModal } from './components/client-details-modal'
import { RequestsFilterComponent } from './components/filter'
import { NewClientsModal } from './components/new-clients-modal'
import { ProductsPreviewModal } from './components/products-preview-modal'
import { SellerParticipationDetailsModal } from './components/seller-participation-details-modal'
import './index.scss'

import { BaseModal } from 'components/BaseModal'
import ButtonV3 from 'components/Buttonv3'
import IconReact from 'components/icons'
import { RoadMap } from 'components/roadmap'
import { mask_cnpj, phoneMask } from 'helpers/mask.helper'
import { IoStorefrontOutline } from "react-icons/io5";
import { CompanyDetailsModal } from 'pages/buying-companies/components/companyDetailsModal'
import { BsBoxes } from 'react-icons/bs'
const RequestsPage = ({ requests, load_requests, status_list, states, filters, set_filters }: any) => {

  const [successMessage, setSuccessMessage] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [showFilters, setShowFilters] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingModalData, setLoadingModalData] = useState<boolean>(false)
  const [is_add_seller_modal_open, open_add_seller_modal] = useState<boolean>(false)
  const [current_request, setCurrentRequest] = useState<any>(null)
  const [show_client_details, setShowClientDetails] = useState<boolean>(false)
  const [show_seller_participation_details, setShowSellerParticipationDetails] = useState<boolean>(false)
  const [show_products_preview, setProductsPreview] = useState<boolean>(false)
  const [new_clients, setNewClients] = useState<any>()
  const [newClientsByState, setNewClientsByState] = useState<any>()
  const [availableLength, setAvailableLength] = useState<any>()
  const [filteredStateId, setFilteredStateId] = useState<any>()
  const [newClientsModalOpen, setNewClientsModalOpen] = useState<boolean>(false)

  const [companyDataModalVisible, setCompanyDataModalVisible] = useState(false)
  const [modalData, setModalData] = useState<any>()
  const [ramos, setRamos] = useState<any>([])


  const MySwal = withReactContent(Swal)
  const { user, can, setting } = useContext(UserContext)
  const navigate = useNavigate()
  const hasOrders = (orders: any) => orders && orders.total > 0
  const toogleFilters = () => setShowFilters(!showFilters)
  const goToPage = (nextPage: number) => reload({ ...filters, page: nextPage })

  const analitical_path = (item: Request) =>
    `/cotacoes/${item.id}/vendedor/${item.seller_id || `${user.id}?o=1`}/gerencial`

  const confirm = (order: Request) => {
    if (window.confirm(Constants.MSG_ORDER_CONFIRM_QUESTION)) {
      OrderService.confirm(order.id).then(() => {
        setSuccessMessage(Constants.MSG_ORDER_CONFIRM_SUCCESS)
        setTimeout(() => { setSuccessMessage('') }, 5000)
      }).catch(() => {
        setError(Constants.MSG_ORDER_CONFIRM_FAIL)
        setTimeout(() => { setError('') }, 5000)
      })
    }
  }

  const reload = useCallback((queryParams) => {

    let newParams = { ...queryParams }
    if (newParams.numerocotacao != '' && newParams.numerocotacao != null) {

      newParams.dataIni = null
    }

    let params = can('READ_ALL', 'REQUEST') ? newParams : { ...newParams, vendedor: user.id }
    params = setting('CURRENT_USER_FILTER_DEFAULT') && !('vendedor' in params) ? { ...newParams, vendedor: user.id } : params
    // let params = can('READ_ALL', 'REQUEST') ? queryParams : { ...queryParams, vendedor: user.id }
    // params = setting('CURRENT_USER_FILTER_DEFAULT') && !('vendedor' in params) ? { ...queryParams, vendedor: user.id } : params
    setLoading(true)
    load_requests(params).then(() => setLoading(false)).then(() =>
     set_filters(params)

    )
  }, [can, load_requests, user, set_filters, setting])

  const open_client_details = (request: Request) => {
    setCurrentRequest(request)
    setShowClientDetails(true)
  }

  const open_products_preview = (request: Request) => {
    setCurrentRequest(request)
    setProductsPreview(true)
  }

  useEffect(() => {

    function resize() {
      if (window.innerWidth < 901) {
        setShowFilters(false)
      } else {
        setShowFilters(true)
      }
    }

    resize()
    window.addEventListener("resize", resize)
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])
  const remove_seller = (item: any) => {
    MySwal.fire({
      title: 'Deseja remover esse vendedor?',
      text: 'O vendedor será removido da cotação e também não receberá as próximas cotações deste cliente',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        RequestService.dissociate(item.id, item.seller_id).then(() => {
          Swal.fire('Sucesso!', 'Remoção realizada com sucesso', 'success')
          reload(filters)
        })
      }
    })
  }

  const get_parcicipation_class = (item) => {
    if (item.participation <= 0.3) {
      return 'low'
    } else if (item.participation > 0.7) {
      return 'high'
    } else {
      return 'medium'
    }
  }


  async function handleGetRamos() {

     OrderService.getRamos().then((res:any) => { setRamos(res.ramos) })

  }


  useEffect(() => {
    handleGetRamos()

    if (user && can('READ_OTHERS', 'CLIENT_COMPANY')) {
      // ClientService.available_by_provider_id({ for_id: user.providerId, vend_id: user.id }).then(data => {
      //? aparecer somente estado do usuarios
      let userStateId = user.store && user.store.est_id ? user.store.est_id : null
      ClientServiceV2.available_by_provider_id({ for_id: user.providerId, vend_id: user.id, est_id: userStateId }).then(data => {
        if (data && data.length > 0) {
          setAvailableLength(data.length)
          setFilteredStateId(0)
          if (!StorageService.exists('NEW_CLIENTS_WARNING_SEEN')) {
            StorageService.set('NEW_CLIENTS_WARNING_SEEN', true)
            MySwal.fire({
              title: 'Novos clientes estão disponíveis para você, deseja verificar?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não'
            }).then((result) => {
              if (result.isConfirmed) {
                setNewClientsModalOpen(true)
              }
            })
          }
          setNewClients(data)
          ClientServiceV2.available_by_provider_id({ for_id: user.providerId, vend_id: user.id, group_est: 1 }).then(data => {
            if (data.length > 0) {

              setNewClientsByState(data)

            }
          }

          )
        }
      })
    }
  }, [user])

  const handleFilterStateClients = (stateId = user.store.est_id) => {

    if (user && can('READ_OTHERS', 'CLIENT_COMPANY')) {

      ClientServiceV2.available_by_provider_id({ for_id: user.providerId, vend_id: user.id, est_id: stateId }).then(data => {
        if (data && data.length > 0) {
          setFilteredStateId(stateId)
          setNewClients(data)
          ClientServiceV2.available_by_provider_id({ for_id: user.providerId, vend_id: user.id, group_est: 1 }).then(data => {
            if (data.length > 0) {
              setNewClientsByState(data)
            }
          }

          )
        }
      })
    }
  }

  const blocked_template = (obs) => {
    return <div>
      <span>Você foi bloqueado!</span><br /><br />
      <span><b>Motivo: </b>{obs}</span><br /><br />
      <span>Entre em contato com o cliente para maiores esclarecimentos</span>
    </div>
  }

  const show_obs = (obs) => {
    if (obs) {
      MySwal.fire({
        html: blocked_template(obs),
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Voltar',
      })
    }
  }

  const open_participation_details = (item) => {
    setCurrentRequest(item)
    setShowSellerParticipationDetails(true)
  }

  useEffect(() => {
    if (user) {
      reload(filters)
      if (!can('READ', 'REQUEST')) {
        navigate('/pedidosdireto')
      }
    }
  }, [user, reload])

  async function handleOpenModalCompanyData(data: any) {
    setLoadingModalData(true)
    let newData

    await ClientService.load_stores({ cli_id: data.client_id }).then(res => {
      if (res.data.length == 1) {
        newData = res.data[0]

      } else {

        newData = {
          head_office: {
            cnpj: data.client_cnpj ? mask_cnpj(data.client_cnpj) : '',
            social_reazon: data.client_name,
            email: data.client_email,
            trading_name: data.client_fantasy_name,
            district: data.client_store_neighborhood,
            street: data.client_store_street,
            phone: data.client_phone ? phoneMask(data.client_phone) : '',
            zip_code: data.client_store_cep ? JSON.stringify(data.client_store_cep).substring(0, 5) + "-" + JSON.stringify(data.client_store_cep).substring(5) : '',
            city: {
              name: data.client_city
            },
          },
          contact: data.client_contact,
          group_name: data.client,
          name: data.client,
          ...data
        };
      }

    })

    setLoadingModalData(false)
    setModalData(newData)

    setCompanyDataModalVisible(true)
  }

  return (
    <ProjectContainer>
      <div className="requests color-gray-2">
        <RoadMap pageLinks={[
          {
            title: 'Minhas Cotações',
            onclick: () => { }
          },
        ]}></RoadMap>
        <header className="header flex justify-space-between mb-32 ">
          <PageHeaderV2 title="Minhas Cotações" />
          {
            //    <div className="header-actions">
            //        <Button onClick={() => toogleFilters()}>
            //            {showFilters ? (
            //                <span className="flex items-center justify-center">
            //                    <i className="material-icons">close</i>
            //                    Filtros
            //                </span>
            //            ) : (
            //                <span className="flex items-center justify-center">
            //                    <i className="material-icons">filter_alt</i>
            //                    Filtros
            //                </span>
            //            )}
            //        </Button>
            //    </div>
          }
          <div className='togleFiltersContainer'>
            <ButtonV3
              style={{ justifyContent: 'space-between' }}
              title={showFilters ? 'Ocultar filtros' : 'Exibir filtros'}

              onClick={toogleFilters}
              Icon={showFilters ? <FaChevronUp style={{ marginRight: 10 }} color='#ff7110' /> : <FaChevronDown style={{ marginRight: 10 }} color='#ff7110' />}>

            </ButtonV3>

          </div>
        </header>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>

        </div>
        {
          //showFilters && <RequestsFilterComponent
        }
        {showFilters && <RequestsFilterComponent
          ramosList={ramos}
          status_list={status_list}
          set_filters={set_filters}
          states={states}
          registerAmount={new_clients?.length}
          extraActionButtonText="Novos Clientes"
          onOpenRegisterModal={() => setNewClientsModalOpen(true)}
          initialParams={filters}
          onChange={reload} />}

        {error && <FeedbackError message={error} />}

        {successMessage && <FeedbackSuccess message={successMessage} />}

        <div className="flex justify-content-center">
          {loading && <Loader type="inline" />}
        </div>
        {loadingModalData && <Loader />}

        {!loading && (hasOrders(requests) ? (
          <>
            <div className='requestTableDesktop'>
              <div className="listRequests">
                <table className="newTableRequests">
                  <thead>
                    <tr>
                      <th className="text-center">Ações</th>
                      <th className="text-center">Data Final</th>
                      <th className="text-center">Núm. Cotação</th>
                      <th className="text-center">Estado</th>
                      <th>Cliente/Ramo</th>
                      <th className="text-center">Produtos</th>
                      <th className="text-center">Entrega</th>
                      <th className="text-center">Status</th>
                      <th>Vendedor</th>
                      <th className='text-center'>Participação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requests.data.map((item: Request) => (
                      <tr key={`${item.id}-${item.seller_id}`}>
                        <td data-label="Ações"  >
                          <div className='divTdAcoes' >
                            {
                              (!item.blocked && (
                                (item.is_provider_included && !item.is_public) || item.is_public)) ?
                                <button title="Acessar cotação" className="action info"
                                  onClick={() => open_products_preview(item)}>
                                  {
                                    is_editable(item) ?
                                      //false ?
                                      <IconReact type={'edit'} color={'#ff7110'} />
                                      :
                                      <IconReact type={'infoCircle'} color={'#17A2B8'} />
                                  }</button>
                                :
                                <IconReact
                                  type={'infoCircle'}
                                  color={'#CD2A2A'}

                                  onClick={() => show_obs(item.blacklist_obs)}
                                  title="O fornecedor não possui acesso as cotações desse cliente"
                                />
                              //  <BiBlock title="O fornecedor não possui acesso as cotações desse cliente"
                              //      onClick={() => show_obs(item.blacklist_obs)}
                              //      color="red" />
                              //
                            }


                            {
                              (item.status === 'ORDERED' || item.status === 'NOT_ORDERED')
                              && can('READ', 'REPORT') &&
                              <Link to={analitical_path(item)}>
                                <button title="Relatório" className="action info">
                                  <IconReact type={'trendingUp'} color={'#2ACD72'} size={20} />
                                </button>
                              </Link>}
                            {
                              item.status === 'P' && (

                                <button title="Confirmar"
                                  className="action confirm"
                                  onClick={() => confirm(item)}>
                                  <IconReact type={'checkCircle'} color={'#2ACD72'} size={20} />
                                  {
                                    //    <i className="material-icons">check</i>
                                  }
                                </button>
                              )
                            }
                            {
                              item.status === 'ORDERED' &&
                              <Link to={`${Constants.ROUTE_ORDERS}?request_id=${item.id}`}>
                                <IconReact type={'handMoney'} title="Visualizar Pedidos" color={'#FF7110'} size={20} />
                                {
                                  //   <FaHandHoldingUsd className="action info" title="Visualizar Pedidos" />
                                }
                              </Link>
                            }
                          </div>
                        </td>
                        <td data-label="Data Finall" className="text-center">
                          {DateService.formatToDisplayWithTime(new Date(item.date))}
                        </td>
                        <td data-label="Núm. Cotação" className="text-center">
                          <div>
                            <span className='numCotacaoBox' >
                              <p onClick={() => open_client_details(item)} style={{ cursor: 'pointer' }}>
                                #{item.id}
                              </p>
                              {!item.blocked && can('ADD_SELLER', 'REQUEST') && (
                                (item.is_provider_included && !item.is_public) || item.is_public) ?
                                <div className="numCotacaoBoxIconContainer">

                                  <MdPersonAddAlt className="numCotacaoBoxIcon"
                                    onClick={() => {
                                      open_add_seller_modal(true)
                                      setCurrentRequest(item)
                                    }}
                                    title="Adicionar Vendedor" />
                                </div> : ''
                              }
                            </span>
                          </div>
                        </td>


                        <td data-label="Estado" className="tdStateName text-center">{item.state}</td>
                        <td data-label="Cliente" >
                          <div>
                            <span className="client-name"
                              onClick={() => user.for_plus == 1 ? handleOpenModalCompanyData(item) : {}}

                            >

                              {/* <span className="client-name" onClick={() => open_client_details(item)}> */}
                              {item.client}
                              {item.ramo && item.ramo.ramo_descricao ?
                                <span style={{fontWeight:300}}>{item.ramo.ramo_descricao}</span>
                                : null
                              }
                            </span>
                          </div>
                        </td>
                        <td data-label="Produtos">
                          <div>
                            <span className='productCountBox' onClick={() => open_products_preview(item)}
                              title={`${item.products_count} produtos`}>
                              <BsBoxes className='productCountIcon' />
                              {item.products_count} /
                              {item.offered_products_count}
                            </span>
                          </div>
                        </td>
                        <td data-label="Entrega" className="text-center">{item.delivery_method}</td>
                        <td data-label="Status" className="text-center">
                          <Status status={item.status} />
                        </td>
                        <td data-label="Vendedor">
                          <div style={{
                            'display': 'flex',
                            'flexDirection': 'row',
                            'alignItems': 'center',
                            'gap': 3

                          }}>

                            {item.is_provider_included && item.status === 'NOT_TYPED' ?
                              <div style={{ 'height': 19, 'width': 19 }}>
                                <IconReact type='trash'
                                  size={16}
                                  style={{ 'cursor': 'pointer' }}
                                  color={'#CD2A2A'} onClick={() => remove_seller(item)} title="Remover Vendedor" />
                              </div>
                              //  <BiTrash className="removeSellerIcon" title="Remover Vendedor"
                              //      onClick={() => remove_seller(item)} />
                              : ''}
                            {item.is_provider_included ? item.seller : ''}

                          </div>
                        </td>
                        <td data-label="Participação" className='text-center tdParticipacao'>
                          <div  >
                            <span className={['participation', get_parcicipation_class(item)].join(' ')}
                              onClick={() => open_participation_details(item)}>
                              {(item.participation * 100).toFixed(2)}%
                            </span>
                          </div>
                        </td>

                      </tr>
                    ))
                    }
                  </tbody>
                </table>

              </div>
              <div>
                <Pagination
                  page={requests.current_page}
                  totalPages={requests.last_page}
                  from={1}
                  to={1}
                  total={requests.size}
                  goToPage={goToPage}
                />
              </div>

            </div>
            <div className='requestTableMobile'>
              <div className="listRequests">
                <table className="newTableRequests">
                  <thead>
                    <tr>
                      <th>Cliente</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Data Final</th>
                      <th className="text-center">Núm. Cotação</th>
                      <th className="text-center">Estado</th>
                      <th className="text-center">Produtos</th>
                      <th className="text-center">Entrega</th>
                      <th>Vendedor</th>
                      <th className='text-center'>Participação</th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requests.data.map((item: Request) => (
                      <tr className='trMobile' style={{}} key={`${item.id}-${item.seller_id}`}>
                        <td style={{ padding: 0 }} data-label="" >
                          <section className='storeNameMobile'>
                            <section
                              onClick={() => handleOpenModalCompanyData(item)}
                              className='buttonStoreName'>
                              <IoStorefrontOutline />

                            </section>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'flex-start' }}>

                              <p
                                style={{ marginLeft: 0, fontSize: '0.9rem' }}
                                className="client-name-mobile"
                              >
                                {/* <span className="client-name" onClick={() => open_client_details(item)}> */}
                                {item.client}
                              </p>
                              {item.ramo && item.ramo.ramo_descricao ?
                                <span style={{ fontWeight: 300 }}>{item.ramo.ramo_descricao}</span>
                                : null
                              }
                            </div>
                          </section>
                        </td>
                        <td data-label="" className="text-center">
                          <section className='centralizedRow'>
                            <span className='numCotacaoBox-mobile' >
                              <div className='cotacaoButton-mobile'>
                                <p
                                  onClick={() => open_client_details(item)}
                                  style={{ cursor: 'pointer' }}>
                                  #{item.id}
                                </p>
                              </div>
                              {!item.blocked && can('ADD_SELLER', 'REQUEST') && (
                                (item.is_provider_included && !item.is_public) || item.is_public) ?
                                <div
                                  className='cotacaoButton-mobile'
                                //   className="numCotacaoBoxIconContainer"
                                >

                                  <MdPersonAddAlt className="numCotacaoBoxIcon"
                                    onClick={() => {
                                      open_add_seller_modal(true)
                                      setCurrentRequest(item)
                                    }}
                                    title="Adicionar Vendedor" />
                                </div> : ''
                              }
                            </span>
                            <div style={{}} className='columnInfo columData'>
                              <p>Data final:</p>
                              <span>
                                {DateService.formatToDisplayWithTime(new Date(item.date))}
                              </span>
                            </div>
                            <Status status={item.status} />
                          </section>
                        </td>
                        {/* <td data-label="Data Final" className="text-center">
                          {DateService.formatToDisplayWithTime(new Date(item.date))}
                        </td> */}


                        {/* <td data-label="Núm. Cotação" className="text-center">
                          <div>
                            <span className='numCotacaoBox' >
                              <p onClick={() => open_client_details(item)} style={{ cursor: 'pointer' }}>
                                #{item.id}
                              </p>
                              {!item.blocked && can('ADD_SELLER', 'REQUEST') && (
                                (item.is_provider_included && !item.is_public) || item.is_public) ?
                                <div className="numCotacaoBoxIconContainer">

                                  <MdPersonAddAlt className="numCotacaoBoxIcon"
                                    onClick={() => {
                                      open_add_seller_modal(true)
                                      setCurrentRequest(item)
                                    }}
                                    title="Adicionar Vendedor" />
                                </div> : ''
                              }
                            </span>
                          </div>

                        </td> */}


                        <td data-label="" className="">
                          <section className='estadoEntregadiv'>
                            <section className='titleAndInfoRow'>
                              <p>
                                Estado:
                              </p>
                              <span>
                                {item.state}
                              </span>
                            </section>
                            <div className='titleAndInfoRow'>
                              <p>
                                Entrega:
                              </p>
                              <span>
                                {item.delivery_method}
                              </span>
                            </div>
                            <section>
                              <span
                                className='productCountBoxMobile'
                                onClick={() => open_products_preview(item)}
                                title={`${item.products_count} produtos`}>
                                <BsBoxes className='productCountIcon' />
                                {item.products_count} /
                                {item.offered_products_count}
                              </span>
                            </section>
                          </section>
                        </td>

                        {/* <td data-label="Produtos">
                          <div>
                            <span className='productCountBox' onClick={() => open_products_preview(item)}
                              title={`${item.products_count} produtos`}>
                              <Boxes className='productCountIcon' />
                              {item.products_count} /
                              {item.offered_products_count}
                            </span>
                          </div>
                        </td> */}
                        {/* <td data-label="Entrega" className="text-center">{item.delivery_method}</td> */}

                        <td data-label="">
                          <section className='centralizedRow'>
                            <section style={{ display: 'flex', alignItems: 'center', gap: 8 }}>

                              <section style={{}} className='columnInfoVendedor'>
                                <p>
                                  Vendedor:
                                </p>

                                <div>
                                  <span style={{ textAlign: 'start' }}>{item.is_provider_included ? item.seller : ''}</span>
                                </div>
                              </section>
                              {item.is_provider_included && item.status === 'NOT_TYPED' ?
                                <div className='trashButtonSeller' >
                                  <IconReact type='trash'
                                    size={16}
                                    style={{ 'cursor': 'pointer' }}
                                    onClick={() => remove_seller(item)} title="Remover Vendedor" />
                                </div>
                                //  <BiTrash className="removeSellerIcon" title="Remover Vendedor"
                                //      onClick={() => remove_seller(item)} />
                                : ''}
                            </section>
                            <section style={{ marginLeft: 4 }} className='titleAndInfoRow'>
                              <p>
                                Participação:
                              </p>
                              <div style={{ padding: 0 }} className='buttonAction'>
                                <span className={['participation', get_parcicipation_class(item)].join(' ')}
                                  onClick={() => open_participation_details(item)}>
                                  {(item.participation * 100).toFixed(2)}%
                                </span>
                              </div>

                            </section>
                          </section>
                        </td>
                        {/* <td data-label="Participação" className='text-center tdParticipacao'>
                          <div >
                            <span className={['participation', get_parcicipation_class(item)].join(' ')}
                              onClick={() => open_participation_details(item)}>
                              {(item.participation * 100).toFixed(2)}%
                            </span>
                          </div>
                        </td> */}
                        <td data-label=""  >
                          <div className='divTdAcoes' >
                            {
                              (!item.blocked && (
                                (item.is_provider_included && !item.is_public) || item.is_public)) ?
                                <div className='buttonAction'>
                                  <button title="Acessar cotação" className="action info"
                                    onClick={() => open_products_preview(item)}>

                                    {
                                      is_editable(item) ?
                                        //false ?
                                        <IconReact type={'edit'} color={'#ff7110'} />
                                        :
                                        <IconReact type={'infoCircle'} color={'#17A2B8'} />
                                    }</button>

                                </div>
                                :
                                <div className='buttonAction'>
                                  <IconReact
                                    type={'infoCircle'}
                                    color={'#CD2A2A'}

                                    onClick={() => show_obs(item.blacklist_obs)}
                                    title="O fornecedor não possui acesso as cotações desse cliente"
                                  />
                                </div>
                              //  <BiBlock title="O fornecedor não possui acesso as cotações desse cliente"
                              //      onClick={() => show_obs(item.blacklist_obs)}
                              //      color="red" />
                              //
                            }


                            {
                              (item.status === 'ORDERED' || item.status === 'NOT_ORDERED')
                              && can('READ', 'REPORT') &&
                              <div className='buttonAction'>
                                <Link to={analitical_path(item)}>
                                  <button title="Relatório" className="action info">
                                    <IconReact type={'trendingUp'} color={'#2ACD72'} />
                                  </button>
                                </Link>
                              </div>
                            }
                            {
                              item.status === 'P' && (
                                <div className="buttonAction">
                                  <button title="Confirmar"
                                    className="action confirm"
                                    onClick={() => confirm(item)}>
                                    <IconReact type={'checkCircle'} color={'#2ACD72'} />
                                    {
                                      //    <i className="material-icons">check</i>
                                    }
                                  </button>
                                </div>
                              )
                            }
                            {
                              item.status === 'ORDERED' &&
                              <div className='buttonAction'>
                                <Link to={`${Constants.ROUTE_ORDERS}?request_id=${item.id}`}>
                                  <IconReact type={'handMoney'} title="Visualizar Pedidos" color={'#FF7110'} />
                                  {
                                    //   <FaHandHoldingUsd className="action info" title="Visualizar Pedidos" />
                                  }
                                </Link>
                              </div>
                            }
                          </div>
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </table>

              </div>
              <div>
                <Pagination
                  page={requests.current_page}
                  totalPages={requests.last_page}
                  from={1}
                  to={1}
                  total={requests.size}
                  goToPage={goToPage}
                />
              </div>

            </div>
          </>
        ) : <FeedbackError message={Constants.MSG_DATA_NOT_FOUND} />)}

        {
          //current_request && show_products_preview &&
          //    <ProductsPreviewModal
          //        reload={goToPage}
          //        request={current_request} setProductsPreview={setProductsPreview} />
        }
        {current_request &&
          <BaseModal
            modalBodyStyle={{ 'maxHeight': '90vh' }}
            title={`Produtos da cotação #${current_request.id}`}
            isVisible={show_products_preview}
            onCloseCallback={() => setProductsPreview(false)}
            children={<ProductsPreviewModal
              reload={goToPage}
              request={current_request} setProductsPreview={setProductsPreview} />
            }
          />
        }
        {newClientsModalOpen && new_clients && new_clients.length > 0 &&
          //  <NewClientsModal
          //      clients={new_clients}
          //      user={user}
          //      request={current_request} setNewClients={setNewClientsModalOpen} />

          <BaseModal
            modalBodyStyle={{ 'height': 'unset', 'maxHeight': '80vh' }}
            title={'Novos Clientes'}
            isVisible={newClientsModalOpen}
            onCloseCallback={() => {
              setNewClientsModalOpen(false)
              //?comentado para nao carregar todos estados
              // handleFilterStateClients(null)
            }}
            children={<NewClientsModal
              clients={new_clients}
              user={user}
              request={current_request}
              setNewClients={setNewClientsModalOpen}
              clientsByState={newClientsByState}
              handleFilterState={(stateId) => handleFilterStateClients(stateId)}
              registerAmount={availableLength}
              filterdStateID={filteredStateId}
            />}
          />


        }

        {current_request && show_client_details &&
          //   <ClientDetailsModal
          //       request={current_request}
          //       setShowClientDetails={setShowClientDetails} />


          <BaseModal

            modalBodyStyle={{ 'height': 'unset' }}
            title={`Cliente #${current_request.client}`}
            isVisible={show_client_details}
            onCloseCallback={() => setShowClientDetails(false)}
            children={<ClientDetailsModal
              request={current_request}
              setShowClientDetails={setShowClientDetails} />
            }
          />
        }




        {current_request && show_seller_participation_details &&
          //  <SellerParticipationDetailsModal
          //      request={current_request}
          //      setShowSellerParticipationDetails={setShowSellerParticipationDetails} />
          //
          <BaseModal

            modalBodyStyle={{ 'height': 'unset' }}
            title={`#${current_request.seller} - ${current_request.client}`}
            isVisible={show_seller_participation_details}
            onCloseCallback={() => setShowSellerParticipationDetails(false)}
            children={<SellerParticipationDetailsModal
              request={current_request}
              setShowSellerParticipationDetails={setShowSellerParticipationDetails} />
            }
          />


        }

        {current_request && is_add_seller_modal_open &&


          <BaseModal

            modalBodyStyle={{ 'height': 'unset' }}
            title={'Adicionar Vendedor'}
            isVisible={is_add_seller_modal_open}
            onCloseCallback={() => open_add_seller_modal(false)}
            children={<AddSellerToRequestModal
              request={current_request}
              is_add_seller_modal_open={is_add_seller_modal_open}
              open_add_seller_modal={open_add_seller_modal}
              reload={goToPage} />
            }
          />
        }
        {modalData ?
          <BaseModal
            data={null}
            title={`Cliente: ${modalData && modalData.name}`}
            isVisible={companyDataModalVisible}
            onCloseCallback={() => setCompanyDataModalVisible(false)}
            children={
              <CompanyDetailsModal
                cliId={modalData.client_id}
                data={modalData}
              ></CompanyDetailsModal>}
            modalBodyStyle={{ overflow: 'none', maxHeight: '80vh' }}
          />
          : null}
      </div>
    </ProjectContainer >
  )
}


const mapStateToProps = (state: any) => ({
  requests: state.requests.requests,
  salesmen: state.salesmen,
  status_list: state.requests.status_list,
  states: state.requests.states,
  filters: state.filters.requests
})

const mapDispatchToProps = (dispatch: any) => ({
  load_requests: (params: any) => (dispatch(load_requests(params))),
  set_filters: (params: any) => (dispatch(set_requests_filters(params))),

})

export default connect(mapStateToProps, mapDispatchToProps)(RequestsPage)
