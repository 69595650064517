import HttpClient from './http.client'

export default class CampaignService {

    static update_product_price = async (params: any, id: any, prod_id: number) : Promise<any> => {
        return HttpClient.patch(`/providers/campaigns/${id}/products/${prod_id}/price`, params)
    }

    static update_product_status = async (params: any, id: any, prod_id: number) : Promise<any> => {
        return HttpClient.patch(`/providers/campaigns/${id}/products/${prod_id}/status`, params)
    }

}
