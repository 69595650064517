/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import './index.scss'
import { useForm } from 'react-hook-form'
import Constants from '../../constants'
import { FeedbackError } from '../../components/feedback/error'
import { FeedbackSuccess } from '../../components/feedback/success'
import { AuthProvider } from 'contexts/user'
import InputMask from 'react-input-mask'
import CustomerService from 'services/CustomerService'

export const VrSystem = () => {

    const [error, setError] = useState<string>('')
    const [sucess, setSucess] = useState<string>('')
    const [submited, setSubmited] = useState<boolean>(false)
    const { register, handleSubmit, errors } = useForm()

    const onSubmit = (data: any) => {
        setError('')
        // contato: user.name,
        // senha: user.password,
        // telefone: mask.clear(user.phone),
        // email: user.email,
        // usuario: user.email,
        // cnpj
        const request = {
            cnpj: data.cnpj ? data.cnpj.replace(/\D/g, '') : data.cnpj,
            telefone: data.phone,
            email: data.mail,
            contato: data.name,
            usuario: data.mail,
            senha: 123123,
            revenda_id: 61
        }
        //revenda 59
        // CustomerService.request(request)
        CustomerService.register_client_club(request)
            .then(response => {
                if (response) {
                    setSucess('Solicitação efetuada com sucesso, aguarde. Nosso time entrará em contato em breve.')
                    setSubmited(true)
                }
            })
            .catch((er) => {
                if (er.response?.data?.mensagem) {
                    setError(er.response.data.mensagem)
                } else {

                    setError(Constants.MSG_REQUEST_FAIL)
                }
            }
            )
    }

    return (
        <AuthProvider>
            <div className="request" style={
                { backgroundImage: `url(${require('../../assets/images/vrsystem.png')}` }
            }>
                <div className="row request-box-container">
                    <div className="request-box" style={{ borderRadius: 12 }}>

                        <div className="request-box-header">
                            <div className="request-box-logo-vrsystem">

                                <img src={require('../../assets/images/logo-sign-in.jpg')} alt="Logo" className="logo" />
                                <img src={require('../../assets/images/vrsystemlogo.jpeg')} alt="Logo" className="logoVrsystem" />
                            </div>
                            {!sucess ?
                                <h1 className="title" style={{ marginTop: 10 }}>{error}</h1>
                                : null}
                        </div>

                        <div className="request-box-body">
                            {!sucess ?

                                <form className="form" style={{ gap: 15 }} onSubmit={handleSubmit(onSubmit)}>
                                    <InputMask mask="99.999.999/9999-99" >
                                        {(inputProps) =>
                                            <input
                                                className="c-input"
                                                // className="field"
                                                placeholder="Cnpj"
                                                name="cnpj"
                                                {...inputProps}
                                                ref={register({
                                                    required: true,
                                                    minLength: 18,
                                                    pattern: /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/

                                                })}
                                                autoComplete="off" />}
                                    </InputMask>
                                    {errors.cnpj?.type === "required" && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                                    {errors.cnpj?.type === "minLength" && <FeedbackError message={Constants.MSG_LENGTH_FIELD} />}
                                    {errors.cnpj?.type === "pattern" && <FeedbackError message={Constants.MSG_INVALID_FIELD} />}

                                    <input
                                        // className="field"
                                        className="c-input"
                                        placeholder="Nome"
                                        name="name"
                                        ref={register({ required: true })}
                                        autoComplete="off"
                                    />
                                    {errors.name && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}

                                    <InputMask mask="(99) 99999-9999" >
                                        {(inputProps) =>
                                            <input
                                                // className="field"
                                                className="c-input"
                                                placeholder="Telefone"
                                                name="phone"
                                                {...inputProps}
                                                ref={register({
                                                    required: true,
                                                    pattern: /^(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/

                                                })}
                                                autoComplete="off" />}
                                    </InputMask>
                                    {errors.phone?.type === "required" && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                                    {errors.phone?.type === "pattern" && <FeedbackError message={Constants.MSG_INVALID_FIELD} />}

                                    <input
                                        // className="field"
                                        className="c-input"
                                        placeholder="Email"
                                        name="mail"
                                        type="email"
                                        defaultValue=''
                                        ref={register({
                                            required: true,
                                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        })}
                                        autoComplete="off"
                                    />
                                    {errors.mail?.type === "required" && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                                    {errors.mail?.type === "pattern" && <FeedbackError message={Constants.MSG_INVALID_FIELD} />}

                                    {/* <input
                                    // className="field"
                                    className="c-input"
                                    placeholder="Senha"
                                    name="password"
                                    type="password"
                                    defaultValue=''

                                    ref={ register({ required: true }) }
                                    autoComplete="off"
                                />
                                { errors.password && <FeedbackError message={ Constants.MSG_REQUIRED_FIELD } /> }
*/}
                                    {error && <FeedbackError message={error} />}
                                    {sucess && <FeedbackSuccess message={sucess} />}

                                    <button className="send" type="submit" disabled={submited}>Solicitar</button>

                                </form> :
                                <> <div className='containerSuccessScreen'>


                                    <div className='containersvg'>
                                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 52 52">
                                            <circle
                                                className="checkmark__circle"
                                                cx="26"
                                                cy="26"
                                                r="25"
                                                fill="none" />
                                            <path
                                                className="checkmark__check"
                                                fill="none"
                                                d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                        </svg>

                                    </div>

                                    <strong className='SuccessScreenTextStrong'>Solicitação efetuada com sucesso !</strong>
                                    <p className='SuccessScreenText'><br></br>Nosso time entrará em contato em breve.</p>






                                </div></>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </AuthProvider>
    )
}
