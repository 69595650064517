import React, { FC } from 'react'
import './index.scss'

export const Pagination: FC<any> = ({ page = 1, totalPages, goToPage, visible_pages = 10, total, from, to } : any) => {

    if (totalPages === 1) {
        return <></>
    }

    const links: any[] = []

    let begin = page
    let count = 0

    for(let i = page; i > 0 && begin > 0 && count < (visible_pages/2);i--) {
        begin--
        count++
    }

    visible_pages = page+(visible_pages/2)
    visible_pages = visible_pages > totalPages ? totalPages : visible_pages

    for (let i = begin; i <= visible_pages; i++) {
        links.push(
            <div key={ i+1 } className={ `page-item ${i+1 === page ? 'active' : ''}` }>
                <div  style={{'cursor':'pointer'}}  className="page-link" onClick={ () => goToPage(i+1) }>
                   <p> { i+1 }</p>
                </div>
            </div>,
        )
    }

    return (
        <div style={{'display':'flex','flexDirection':'column', 'alignItems':'center','marginTop':10}} >
            <div style={{'display':'flex', 'alignItems':'center'}} >
                { visible_pages > 1 && page > 1 && (
                    <div style={{'cursor':'pointer'}} onClick={ () => goToPage(page - 1) }>
                        <p className='nextPrevText'>
                            Anterior
                        </p>
                    </div>
                ) }
                { links }
                { visible_pages+1 !== page && (
                    <div style={{'cursor':'pointer'}} onClick={ () => goToPage(page + 1) }>
                        <p className='nextPrevText'>
                            Próxima
                        </p>
                    </div>
                ) }
            </div>
            { from && to && <div className="desc">
                Exibindo: { from }..{ to } de { total } registros
            </div> }
        </div>
    )
// return (
//     <nav className="pagination-container flex items-center justify-center mt-24 flex-column">
//         <ul className="pagination">
//             { visible_pages > 1 && page > 1 && (
//                 <li className="page-item">
//                     <button className="page-link" onClick={ () => goToPage(page - 1) }>
//                         Anterior
//                     </button>
//                 </li>
//             ) }
//             { links }
//             { visible_pages+1 !== page && (
//                 <li className="page-item">
//                     <button className="page-link" onClick={ () => goToPage(page + 1) }>
//                         Próxima
//                     </button>
//                 </li>
//             ) }
//         </ul>
//         { from && to && <div className="desc">
//             Exibindo: { from }..{ to } de { total } registros
//         </div> }
//     </nav>
// )
}
