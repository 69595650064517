import ButtonV3 from "components/Buttonv3";
import styles from './index.module.scss'
import { IoMdTrash } from 'react-icons/io'
import { MdOutlineEdit, MdPersonOutline } from 'react-icons/md'
import { BsTruck } from 'react-icons/bs'
import { BaseModal } from "components/BaseModal";
import { useEffect, useLayoutEffect, useState } from "react";
import { Input } from "components/input";
import FieldControlRoutesService from "services/v3/fieldControlRoutes.service";
import PaginationCustomV2 from "components/PaginationCustomV2";
import { ToastContainer, toast } from "react-toastify";
import ProviderService from 'services/ProviderService'
import { CheckBoxCustom } from "components/checkBoxCustom";
import { TbMapPinPlus } from "react-icons/tb";
import HttpClient from "services/HttpClient";
import UserService from "services/UserService";
import DeliveryRoutesService from "services/v3/deliveryRoutesService";

export const DeliveryRoutesComponent = ({ }: any) => {

    const dafaultValuesModal = {
        descricao: '',
        fornecedor_id: '',
        rua: '',
        numero: '',
        complemento: '',
        cidade: '',
        estado: '',
        cep: '',
        referencia: '',
    }
    const [modalData, setModalData] = useState<any>({})
    const [routes, setRoutes] = useState<any>()
    const [modalVisible, setModalVisible] = useState(false)
    const [modalEditData, setModalEditData] = useState(dafaultValuesModal)
    const [reload, setReload] = useState(false)
    const [provider, setProvider] = useState()

    const [stateId, setStateId] = useState<any>();
    const [cityId, setCityId] = useState();
    const [regions, setRegions] = useState<any>(null);

    const defaultCep = {
        cep: '',
        cepResponse: {
            cep: '',
            logradouro: '',
            complemento: '',
            bairro: '',
            localidade: '',
            uf: ''
        }
    }

    const [cep, setCep] = useState(defaultCep
    )



    useEffect(() => {
        if (cep && cep.cep.length >= 8 && cep.cepResponse && regions && cep.cepResponse.uf) {


            let estado = regions.estados.filter(item => item.est_sigla.toLowerCase() == cep.cepResponse.uf.toLowerCase())[0]
            if (estado) {
                estado = estado.est_nome
            }
            let cidade = regions.cidades.filter(item => item.cid_nome.toLowerCase() == cep.cepResponse.localidade.toLowerCase())[0]
            if (cidade) {
                cidade = cidade.cid_nome
            }

            setModalEditData(
                {
                    ...modalEditData,
                    rua: cep.cepResponse.logradouro,
                    numero: '',
                    complemento: cep.cepResponse.complemento,
                    cidade: cidade ? cidade : cep.cepResponse.localidade,
                    estado: estado ? estado : cep.cepResponse.uf
                }
            )
        }
    }, [cep])
    useEffect(() => {
        getRoutes()
        getProviderID()
        loadRegions()
    }, [])



    async function getRoutes() {
        DeliveryRoutesService.getDeliveryRoutes().then(res =>
            setRoutes(res),
        )

    }
    async function loadRegions() {
        const { estados, cidades } = await UserService.regions();
        setRegions({ estados, cidades })

    }

    async function handleDelete(itemId) {
        await DeliveryRoutesService.deleteDeliveryRoute(itemId).then(res => {
            toast.info('Endereço deletado !', {
                position: "top-right",
                autoClose: 2000,
            })
            setModalEditData(dafaultValuesModal)
            setModalVisible(false)
            setReload(!reload)
            getRoutes()
        }
        ).catch(e => {
            toast.error('Erro ao deletar rota!', {
                position: "top-right",
                autoClose: 2000,
            })
            setModalEditData(dafaultValuesModal)
            setModalVisible(false)
        }
        )
    }
    async function getProviderID() {
        const { provider } = await ProviderService.getLoggedProvider()

        setProvider(provider.id)

    }

    function handleOpenModal() {
        setModalData(modalEditData)
        setModalVisible(true)
    }

    function handleOpenModalEdit(item) {

        setModalData(item)

        let data: any = {
            ...modalEditData,
            descricao: item.descricao ? item.descricao : '',
            fornecedor_id: item.fornecedor_id ? item.fornecedor_id : '',
            rua: item.rua ? item.rua : '',
            numero: item.numero ? item.numero : '',
            complemento: item.complemento ? item.complemento : '',
            cidade: handleCityName(item.cidade_id),
            estado: handlStateName(item.estado_id),
            // cep:'',
            cep: item.cep ? item.cep : '',
            referencia: item.referencia ? item.referencia : '',
        }
        let dataCep = { ...cep, cep: item.cep }
        setCep(dataCep)

        setModalEditData(data)
        setModalVisible(true)
        // setModalEditData(item)
    }
    function handleCloseMOdal() {

        setModalVisible(false)
        setModalData({})
        setModalEditData(dafaultValuesModal)
        setCep(defaultCep)

    }
    function handleCityName(id) {
        let cidade
        if (regions && regions.cidades) {
            cidade = regions.cidades.filter(item => Number(item.cid_id) == Number(id))[0]
        }
        return cidade && cidade.cid_nome ? cidade.cid_nome : '-'
    }
    function handlStateName(id) {
        let estado
        if (regions && regions.estados) {
            estado = regions.estados.filter(item => Number(item.est_id) == Number(id))[0]
        }
        return estado && estado.est_nome ? estado.est_nome : '-'
    }


    async function handleSave() {
        const data = {
            ...modalEditData,
            fornecedor_id: provider,
            cidade_id: cityId,
            estado_id: stateId,
            cep: cep.cep
        }


        if (!modalData.id) {

            await DeliveryRoutesService.createDeliveryRoute(data).then(res => {
                toast.success('Endereço salvo com sucesso', {
                    position: "top-right",
                    autoClose: 2000,
                })
                setModalEditData(dafaultValuesModal)
                setModalVisible(false)
                setReload(!reload)
                getRoutes()
            }
            ).catch(e => {
                toast.error('Erro ao salvar', {
                    position: "top-right",
                    autoClose: 2000,
                })
                setModalEditData(dafaultValuesModal)
                setModalVisible(false)
            }
            )

        } else {
            let newData = { ...data, id: modalData.id }
            await DeliveryRoutesService.editDeliveryRoute(newData).then(res => {
                toast.success('Rota salva com sucesso', {
                    position: "top-right",
                    autoClose: 2000,


                })
                setModalEditData(dafaultValuesModal)
                setModalVisible(false)
                setReload(!reload)
                getRoutes()
            }
            ).catch(e => {
                toast.error('Erro ao salvar', {
                    position: "top-right",
                    autoClose: 2000,
                })
                setModalEditData(dafaultValuesModal)
                setModalVisible(false)
            }
            )
        }
    }
    useEffect(() => {
        if (cep.cep.length >= 8) {
            let newcep = cep.cep.replace('-', '')
            fetch(`https://viacep.com.br/ws/${newcep}/json`, {
                headers: { 'content-type': 'application/json' }
            },).then(response => response.json())
                .then(res => {
                    setCep({ ...cep, cepResponse: res })

                    getLocationsIds(res)
                    // onChangeUF(res)
                })
        }
    }, [cep.cep])

    function getLocationsIds(loc: any) {

        if (regions && loc.uf && loc.localidade) {
            let estado = regions.estados.filter(item => item.est_sigla.toLowerCase() == loc.uf.toLowerCase())[0]
            if (estado) {
                setStateId(estado.est_id)
            }
            let cidade = regions.cidades.filter(item => item.cid_nome.toLowerCase() == loc.localidade.toLowerCase())[0]
            if (cidade) {
                setCityId(cidade.cid_id)
            }

        }
        // setStateId(estadoId)
        // setCityId(cidadeID)
        // HttpClient.get(`/external/estate/${loc.uf}`).then((res) => {
        //     let estadoId = res.data.est_id
        //     setStateId(estadoId)
        //     HttpClient.get(`/external/city/${loc.localidade}/${estadoId}`).then((res) => {

        //         let cidadeID = res.data.cid_id

        //         setCityId(cidadeID)
        //     }
        //     )
        // }
        // )
    }


    return (
        <div className={`${styles.profiles} text-center col col-12 rounded`}>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <div >
                <div style={{ 'marginBottom': 15 }} className="d-flex flex-start">
                    {
                        //   <Button className="d-flex flex-start" onClick={add}>
                        //       Novo Perfil
                        //   </Button>
                    }
                    <ButtonV3
                        title='Novo local de retirada'
                        Icon={<TbMapPinPlus className='iconButtonV3' />}
                        onClick={() => handleOpenModal()}
                    />

                </div>

                {
                    routes && routes.length > 0 ?
                        <div className={`${styles.listRepresentatives}`}>
                            <table className={`${styles.newTableRepresentatives}`}>
                                <thead>
                                    <tr>
                                        <th className="text-left">Descrição</th>
                                        <th className="text-left">Estado/Cidade</th>
                                        <th className="text-left">Rua/Número</th>
                                        <th className="text-left">Complemento/Referência</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {routes.map(
                                        (item: any) => (
                                            <tr key={item.id}>
                                                <td data-label='Descrição'>{item.descricao}</td>
                                                <td data-label='Estado/Cidade'>
                                                    <div className={`${styles.columTable}`} >
                                                        <section >
                                                            {handlStateName(item.estado_id)}
                                                        </section>
                                                        <section>
                                                            {handleCityName(item.cidade_id)}
                                                        </section>

                                                    </div>
                                                </td>
                                                <td data-label='Rua/Número'>
                                                    <div className={`${styles.columTable}`} >
                                                        <section >
                                                            {item.rua}
                                                        </section>
                                                        <section>
                                                            {item.numero ? item.numero : '-'}
                                                        </section>

                                                    </div>
                                                </td>
                                                <td data-label='Complemento/Referência'>
                                                    <div className={`${styles.columTable}`} >
                                                        <section >
                                                            {item.complemento ? item.complemento : '-'}
                                                        </section>
                                                        <section>
                                                            {item.referencia ? item.referencia : '-'}
                                                        </section>

                                                    </div>
                                                </td>
                                                <td data-label='Ações' className="text-center">
                                                    <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'center', 'gap': '20px' }}>

                                                        <div >
                                                            <IoMdTrash onClick={() => handleDelete(item.id)}
                                                                title="Excluir rota"
                                                                style={{ cursor: 'pointer' }}
                                                                color='#CD2A2A' size={22} className={`${styles.svgactionsProfiles}`} />
                                                        </div>
                                                        <div className={styles.tooltip}>
                                                            <MdOutlineEdit title="Editar" onClick={() => handleOpenModalEdit(item)}
                                                                color='#606060'
                                                                style={{ cursor: 'pointer' }} size={22} className={`${styles.svgactionsProfiles}`} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                            {routes.last_page > 1 ?
                                <PaginationCustomV2
                                    handleSelectPage={(page) => { }}
                                    paginationData={routes}
                                    paginationDataDescription={'de representantes.'}

                                />
                                : null}
                        </div> : <div>Nenhum Endereço encontrado!</div>
                }
            </div>

            <BaseModal
                title={modalData.descricao ? modalData.descricao : 'Novo endereço'}
                modalBodyStyle={{ minHeight: 230, height: 'unset' }}
                isVisible={modalVisible}
                onCloseCallback={() => handleCloseMOdal()}
                children={
                    <div className={`${styles.ContainerModalRoutes}`}>
                        <div className={`${styles.ContainerInputsModalRoutes}`}>
                            <div className={`${styles.rowInputs}`}>

                                <div style={{ width: '100%' }}>

                                    <Input
                                        type="text"
                                        name="description"
                                        description="Descrição"
                                        placeholder=""
                                        setWidth='100%'
                                        onChange={e => setModalEditData({ ...modalEditData, descricao: e.target.value })}
                                        value={modalEditData.descricao}
                                    ></Input>
                                </div>

                            </div>
                            <div className={`${styles.rowInputs}`}>

                                <div style={{ width: '100%' }}>
                                    <Input
                                        description="Cep"
                                        name='cep'
                                        type={'text'}
                                        placeholder={'CEP'}
                                        setWidth='100%'
                                        value={cep.cep}
                                        onChange={(e) => setCep({ ...cep, cep: e.target.value })}
                                        // label='Usuário'
                                        errorVisible={false}
                                    ></Input>

                                </div>


                                <div style={{ width: '100%' }}>
                                    <Input
                                        type="text"
                                        className={`${styles.removeDropdown}`}
                                        // list="languages"

                                        name="estado"
                                        description="Estado"
                                        placeholder=""
                                        onChange={e => setModalEditData({ ...modalEditData, estado: e.target.value })}
                                        value={modalEditData.estado}
                                    ></Input>

                                    {/* <datalist id="languages">
                                        <option value="PHP" />
                                        <option value="C++" />
                                        <option value="Java" />
                                        <option value="Ruby" />
                                        <option value="Python" />
                                        <option value="Go" />
                                        <option value="Perl" />
                                        <option value="Erlang" />
                                    </datalist> */}

                                </div>


                            </div>
                            <div className={`${styles.rowInputs}`}>
                                <div style={{ width: '100%' }}>
                                    <Input
                                        type="text"
                                        name="cidade"
                                        description="Cidade"
                                        placeholder=""
                                        setWidth='100%'
                                        onChange={e => setModalEditData({ ...modalEditData, cidade: e.target.value })}
                                        value={modalEditData.cidade}
                                    ></Input>
                                </div>
                                <div style={{ width: '100%' }}>

                                    <Input
                                        type="text"
                                        name="rua"
                                        description="Rua"
                                        placeholder=""
                                        setWidth='100%'
                                        onChange={e => setModalEditData({ ...modalEditData, rua: e.target.value })}
                                        value={modalEditData.rua}
                                    ></Input>
                                </div>


                            </div>
                            <div className={`${styles.rowInputs}`}>
                                <div style={{ width: '50%' }}>

                                    <Input
                                        type="text"
                                        name="numero"
                                        description="Número"
                                        placeholder=""
                                        setWidth='100%'
                                        onChange={e => setModalEditData({ ...modalEditData, numero: e.target.value })}
                                        value={modalEditData.numero}
                                    ></Input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <Input
                                        type="text"
                                        name="complemento"
                                        description="Complemento"
                                        placeholder=""
                                        setWidth='100%'
                                        onChange={e => setModalEditData({ ...modalEditData, complemento: e.target.value })}
                                        value={modalEditData.complemento}
                                    ></Input>
                                </div>


                            </div>
                            <div className={`${styles.rowInputs}`}>
                                <div style={{ width: '100%' }}>

                                    <Input
                                        type="text"
                                        name="referencia"
                                        description="Referência"
                                        placeholder=""
                                        setWidth='100%'
                                        onChange={e => setModalEditData({ ...modalEditData, referencia: e.target.value })}
                                        value={modalEditData.referencia}
                                    ></Input>
                                </div>



                            </div>

                            <div className={`${styles.ContainerModalButtonsRoutes}`}>

                                <div >
                                    <ButtonV3
                                        style={{ height: 45 }}
                                        title="Cancelar"
                                        onClick={() => handleCloseMOdal()}
                                    ></ButtonV3>

                                </div>
                                <div style={{}}>
                                    <ButtonV3
                                        filled
                                        style={{ height: 45, paddingLeft: 32, paddingRight: 32 }}
                                        textStyle={{ textAlign: 'center' }}
                                        onClick={() => handleSave()}

                                        title="Salvar"
                                    ></ButtonV3>

                                </div>
                            </div>
                        </div>

                    </div>
                }
            />
        </div>
    );
}
