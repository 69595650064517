import InputV2 from 'components/InputV2';
import ModalImage from 'react-modal-image'
import React, { useEffect, useState } from 'react';
import ProductService from 'services/ProductService';
import styles from './index.module.scss'
import { MdEdit } from 'react-icons/md'
import { AiOutlineCloseCircle } from 'react-icons/ai'

import ButtonV3 from 'components/Buttonv3';
import { CheckBoxCustom } from 'components/checkBoxCustom';

interface props {
    item: any
}

export function BrandsModal({ item }: props) {

    const [groups, setGroups] = useState<any>()
    const [showOnlyWithReq, SetshowOnlyWithReq] = useState(false)
    const [filter, setFilter] = useState('')
    const [filteredList, setFilteredList] = useState<any>()
    //https://hml.api.clubdacotacao.com.br/v3/api/admin/2355151/group

    useEffect(() => {
        getGroups(item.id)
    }, [item])


    async function getGroups(id) {
        ProductService.getgroups(id).then(resp => {
            for (let index = 0; index < resp.data.length; index++) {
                const respBrand = resp.data[index];
                for (let index2 = 0; index2 < item.derivatives.length; index2++) {
                    const brand = item.derivatives[index2];
                    if (respBrand.pro_observacao == brand.brand) { brand.usage_quantity = respBrand.qtd }
                }
            }

            setGroups(resp)


        })
    }

    // this.svc.getBrands(this.product.id).then(resp => {
    //     this.total = resp.data.total
    //     this.objective = resp.data.totalObjetivo
    //     this.generic = resp.data.totalGenerico
    //     for (let index = 0; index < resp.data.data.length; index++) {
    //       const respBrand = resp.data.data[index];
    //       for (let index2 = 0; index2 < this.brands.length; index2++) {
    //         const brand = this.brands[index2];
    //         if (respBrand.pro_observacao == brand.brand){brand.resq = respBrand.qtd}
    //       }
    //     }
    //     this.isLoading = false
    //   })



    function cleanFilters() {
        setFilter('')
        SetshowOnlyWithReq(false)
    }
    function checkFilters() {

    }
    useEffect(() => {
        if (filter != '') {
            let filtered = showOnlyWithReq ?
                item.derivatives.filter(prod => prod.brand.includes(filter.toUpperCase()) && prod.usage_quantity > 0) :
                item.derivatives.filter(prod => prod.brand.includes(filter.toUpperCase()))
            setFilteredList(filtered)
        } else if (filter == '' && showOnlyWithReq) {
            let filtered = item.derivatives.filter(prod => prod.usage_quantity > 0)
            setFilteredList(filtered)
        } else {
            setFilteredList(null)
        }
    }, [filter, showOnlyWithReq])

    // useEffect(() => {
    //     if (showOnlyWithReq) {
    //         let filtered = item.derivatives.filter(prod => prod.usage_quantity > 0)
    //         setFilteredList(filtered)
    //     } else {
    //         setFilteredList(null)
    //     }
    // }, [showOnlyWithReq])

    return (
        <div style={{ padding: '30px 40px' }}>

            <div style={{ display: 'flex', alignItems: 'flex-end', gap: 20, flexWrap: 'wrap' }}>
                <div style={{marginRight:15}}>
                    <ModalImage
                        small={item.pro_imagem || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                        large={item.pro_imagem || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                        className={styles.imageProduct}
                        alt={`Produto: ${item.name}`}
                        hideZoom={true}
                        showRotate={true}
                    />
                </div>
                <div>
                    <InputV2
                        infoIconNull
                        icon={<MdEdit />}
                        mask={undefined}

                        id={'Filtrar por marca'}
                        label={'Buscar marca'}
                        value={filter}
                        type={'text'}
                        placeholder={'Marca'}
                        onChange={(e) => { setFilter(e.target.value) }}

                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height: 40 }}>
                    <CheckBoxCustom
                        value={showOnlyWithReq}
                        change={(e, stats) => SetshowOnlyWithReq(stats)}
                        checked={showOnlyWithReq}
                        description={'Apenas com requisições'}
                    ></CheckBoxCustom>
                </div>

                <div style={{
                    //'marginRight': 20
                }}>
                    <ButtonV3
                        title='Limpar Filtro'
                        Icon={<AiOutlineCloseCircle className='iconButtonV3' />}
                        onClick={() => { cleanFilters() }}
                    />
                </div>

            </div>
            <div style={{ margin: '10px 0px' }}>

            </div>
            {groups ?
                <div className={styles.infosContainerReq}>
                    <div style={{ backgroundColor: '#F0CCCC', color: '#ff0000' }} className={styles.infosContainerReqItem} >
                        <p>Requisições Genéricas: {groups.totalGenerico}</p>
                    </div>
                    <div style={{ backgroundColor: '#Add8e6', color: '#2e2ef8' }} className={styles.infosContainerReqItem} >
                        <p>Requisições Objetivas: {groups.totalObjetivo}</p>
                    </div>
                    <div style={{ backgroundColor: '#fafad2', color: '#c58102' }} className={styles.infosContainerReqItem} >
                        <p>Total de Requisições: {groups.total}</p>
                    </div>
                </div>
                : null
            }


            <div>
                <div className={`${styles.listProductsBrands}`}>
                    <table className={`${styles.tableProductsBrands}`}>
                        <thead>
                            <tr>
                                <th >id</th>
                                <th className='text-left'>marca</th>
                                <th className='text-right'>requisições</th>

                            </tr>
                        </thead>
                        <tbody>

                            {filteredList ?
                                <>
                                    {filteredList && filteredList.length > 0 ?
                                        filteredList.map((prod) => (

                                            <tr>
                                                <td data-label='id'>{prod.id}</td>
                                                <td className='text-left' data-label='marca'>{prod.brand}</td>
                                                <td className='text-right' data-label='requisições'>{prod.usage_quantity ? prod.usage_quantity : '- -'}</td>
                                            </tr>
                                        ))
                                        : null
                                    }
                                </>
                                :
                                <>
                                    {item.derivatives && item.derivatives.length > 0 ?
                                        item.derivatives.map((prod) => (

                                            <tr>
                                                <td data-label='id'>{prod.id}</td>
                                                <td className='text-left' data-label='marca'>{prod.brand}</td>
                                                <td className='text-right' data-label='requisições'>{prod.usage_quantity ? prod.usage_quantity : '- -'}</td>
                                            </tr>
                                        ))
                                        : null
                                    }
                                </>
                            }
                            {/* {item.derivatives && item.derivatives.length > 0 ?
                                item.derivatives.map((prod) => (

                                    <tr>
                                        <td data-label='id'>{prod.id}</td>
                                        <td className='text-left' data-label='marca'>{prod.brand}</td>
                                        <td className='text-right' data-label='requisições'>{prod.usage_quantity ? prod.usage_quantity : '- -'}</td>
                                    </tr>
                                ))
                                : null
                            } */}

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}
