/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable react/prop-types */
import './index.scss'

import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import withReactContent from 'sweetalert2-react-content'

import { Input } from 'components/input'
import { Button } from 'components/button'
import { Loader } from 'components/loader'
import UserService from 'services/v3/UserService'
import { FeedbackError } from 'components/feedback/error'

export const SettingsForm = ({ user }) => {
    const MySwal = withReactContent(Swal)

    const BEFORE_KEY = 'DAYS_TO_DELIVER_BEFORE_HOUR_LIMIT'
    const AFTER_KEY = 'DAYS_TO_DELIVER_AFTER_HOUR_LIMIT'

    const [settings, setSettings] = useState<any>({ before: '2', after: '3' })
    const [isLoading, setLoading] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        loadRegisters()
    }, [])

    const loadRegisters = async () => {
        try {
            setLoading(true)

            const data = await UserService.settings(user.id)
            const before =
                data.find((d: any) => d.key === BEFORE_KEY)?.value || '2'
            const after =
                data.find((d: any) => d.key === AFTER_KEY)?.value || '3'

            setSettings({ before, after })
        } catch (e) {
            console.log('Error to load register', e)
        } finally {
            setLoading(false)
        }
    }

    const onSubmit = async (data: any) => {
        try {
            setLoading(true)

            const payload = Object.entries(data).map((d) => ({
                key: d[0],
                value: d[1],
            }))

            await UserService.updateSettings(user.id, payload)

            MySwal.fire({
                title: <p>Configurações salvas com sucesso!</p>,
            })
        } catch (e) {
            MySwal.fire({
                title: (
                    <p>
                        Algo inesperado aconteceu, tente novamente em alguns
                        instantes!
                    </p>
                ),
                confirmButtonColor: 'red',
            })
            console.log('Error to submit settings', e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p className={`title-weekday`}>
                Quantidade mínima de dias para realizar entrega
            </p>

            {isLoading && <Loader type="inline" size={50} />}

            {!isLoading && (
                <table className={`table`}>
                    <tbody>
                        <tr>
                            <td className={`td-weekday`}>
                                Antes do horário limite
                                <Input
                                    type="number"
                                    name={BEFORE_KEY}
                                    defaultValue={settings.before}
                                    onChange={(e) =>
                                        setSettings({
                                            ...settings,
                                            before: e.target.value,
                                        })
                                    }
                                    register={register({
                                        required: true,
                                        pattern: /^([0-9]+)$/,
                                    })}
                                />
                                {errors[BEFORE_KEY] && (
                                    <FeedbackError
                                        message={'Quantidade inválida'}
                                    />
                                )}
                            </td>
                            <td className={`td-weekday`}>
                                Depois do horário limite
                                <Input
                                    type="number"
                                    name={AFTER_KEY}
                                    defaultValue={settings.after}
                                    onChange={(e) =>
                                        setSettings({
                                            ...settings,
                                            after: e.target.value,
                                        })
                                    }
                                    register={register({
                                        required: true,
                                        pattern: /^([0-9]+)$/,
                                    })}
                                />
                                {errors[AFTER_KEY] && (
                                    <FeedbackError
                                        message={'Quantidade inválida'}
                                    />
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}

            <Button variant="primary" type="submit">
                Salvar
            </Button>
        </form>
    )
}
