import DirectSaleProductService from '../../services/DirectSaleProductService'
import { DirectSaleProduct } from '../../interfaces/directSaleProduct'

export const LOAD_PRODUCTS_SUCCESS   = 'LOAD_PRODUCTS_SUCCESS'

const initialState = null

export default function products(state = initialState, action: any) {
    if (action.type === LOAD_PRODUCTS_SUCCESS) {
        return action.products
    }
    return state
}

export const loadProducts = (providerId: string, filters) =>
    (dispatch: any) => DirectSaleProductService.getAll(providerId, filters)
        .then((response: any) => dispatch(setProducts(response)))
        .catch(() => dispatch(setProducts(null)))

const setProducts = (products: DirectSaleProduct[] | null) => ({
    type: LOAD_PRODUCTS_SUCCESS,
    products,
})
