import React, { FC } from 'react'
import './index.scss'
import ToggleButton from 'react-toggle-button'

const init = {
    inactiveLabel: '',
    activeLabel: null,
    colors: {
        activeThumb: {
            base: 'white',
        },
        inactiveThumb: {
            base: 'white',
        },
        active: {
            base: '#05b121fa',
            hover: '#075c04ea',
        },
        inactive: {
            base: '#e2e2e2',
            hover: '#d9d9d9',
        }
    }
}

export const Toggle: FC<any> = (props) => (
    <ToggleButton
        { ...init }
        { ...props } />
)
