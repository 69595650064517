import React from "react";
import './styles.scss'
interface props {
    title: string
    textStyle?:any
}


export function PageHeaderV2({ title ,textStyle}: props) {




    return (

        <div style={textStyle} className="pageHeaderContainer">
            <span className="pageHeaderStyle">{title}</span>
        </div>
    )
}