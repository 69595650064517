import React, { FC, useEffect, useState } from 'react'
import DateService from '../../../../services/DateService'
import ClientServiceV2 from '../../../../services/v2/ClientService'
import { ModalComponent } from '../../../../components/modals'
import { useForm } from 'react-hook-form'
import { Weekday } from '../../../../interfaces/weekday'
import './index.scss'

export const DirectOrderDeliveryModal: FC<any> = ({ storeId, onCloseCallback } : any ) => {

    const { handleSubmit } = useForm()

    const [ days, setDays ] = useState<Array<any>>([])

    const onSubmit = () =>
    ClientServiceV2.saveDeliveries(storeId, days.map(day => ({ 'tec_dia_semana': day })))
            .then( () => onCloseCallback())

    const check = (event: any) => {
        const value = parseInt(event.target.value)
        if( !days?.includes(value) ) {
            const newVal = days?.slice()
      newVal?.push(value)
      setDays(newVal)
        } else {
            setDays(days?.filter(d => d !== value))
        }
    }

    useEffect(() => {
        ClientServiceV2.deliveries(storeId)
            .then(response => setDays(response.map((day: any) => day.tec_dia_semana)))
    }, [ storeId ])

    return (
        <ModalComponent modal={ { title: 'Dias de Entrega' } } onCloseCallback={ onCloseCallback }>
            <div className="boxes">
                <div className="box">
                    <div className="box-body">
                        <form onSubmit={ handleSubmit(onSubmit) }>
                            <div className="row">
                                <div className="col d-flex justify-content-around p-5 days">
                                    { DateService.weekDays.map((day : Weekday) =>
                                        <div key={ day.key } className="form-check form-check-inline">
                                            <input
                                                id={ `checkbox` + day.key }
                                                className="form-check-input"
                                                type="checkbox"
                                                value={ day.value }
                                                checked={ days != null && days.includes(day.value) }
                                                onChange={ check }
                                            />
                                            <label className="form-check-label"
                                                htmlFor={ `checkbox` + day.key }>
                                                { day.key }
                                            </label>
                                        </div>
                                    ) }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col px-5 d-flex justify-content-end">
                                    <button type="submit" className="btn btn-success">
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ModalComponent>
    )
}
