import React, { FC } from 'react'
import styles from './index.module.scss'

export const PageHeader: FC<any> = ({ title, ...props }: any) =>
    <div className={ styles['header-info'] } { ...props }>
        <p className={ styles.title }>
            <span className={ styles.icon }>{ props.icon }</span>
            <span>{ title }</span>
        </p>
        { props.children }
    </div>
