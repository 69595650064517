import { ResponseApi } from '../interfaces/responseApi'
import Constants from '../constants'
import HttpClient from './HttpClient'
import { Customer } from '../interfaces/customer'
import StoreService from './StoreService'
import { Store } from '../interfaces/store'
import { Salesman } from '../interfaces/salesman'
import SalesmanService from './SalesmanService'
import StateService from './StateService'
import CityService from './CityService'

export default class CustomerService {

    static parse = (customerFromApi: any): Customer => {

        const stores: Store[] = []
        if (customerFromApi.lojas) {
            customerFromApi.lojas.forEach((store: any) => stores.push(StoreService.parse(store)))
        }

        const salesmen: Salesman[] = []
        if (customerFromApi.vendedores) {
            customerFromApi
                .vendedores.forEach((salesman: any) => salesmen.push(SalesmanService.parse(salesman)))
        }
        return ({
            id: customerFromApi.loj_id || customerFromApi.cli_id,
            clientId: customerFromApi.cli_id,
            associado: customerFromApi.associado,
            name: customerFromApi.cli_nome,
            email: customerFromApi.cli_email ? customerFromApi.cli_email : '',
            socialReason: customerFromApi.loj_razao ? customerFromApi.loj_razao : '',
            cnpj: customerFromApi.loj_cnpj ? customerFromApi.loj_cnpj : '',
            phone: customerFromApi.cli_telefone ? customerFromApi.cli_telefone : '',
            contactName: customerFromApi.cli_contato ? customerFromApi.cli_contato : '',
            segment: customerFromApi.ramo_descricao ? customerFromApi.ramo_descricao : '',
            fantasyName: customerFromApi.cli_nomeFantasia ? customerFromApi.cli_nomeFantasia : '',
            group: customerFromApi.cli_nome_grupo?.toUpperCase(),
            state: StateService.parse(customerFromApi),
            city: CityService.parse(customerFromApi),
            stores,
            salesmen,
            totalCategories:
                (customerFromApi.count_categorias_cliente)
                    ? customerFromApi.count_categorias_cliente
                    : 0,
            totalConditions:
                (customerFromApi.count_condicao_cliente)
                    ? customerFromApi.count_condicao_cliente
                    : 0,
            address: customerFromApi.endereco ? customerFromApi.endereco : '',
            neighborhood: customerFromApi.cli_bairro ? customerFromApi.cli_bairro : '',
            cep: customerFromApi.cep ? customerFromApi.cep : '',
        })
    }

    static getAll = (page: number): Promise<ResponseApi> =>
        HttpClient.get(`${Constants.URL_API_CUSTOMER}?page=${page}&page_size=1000`)
            .then((response: ResponseApi) => {
                const customers: Customer[] = []
                response.data.forEach((customer: any) => customers.push(CustomerService.parse(customer)))
                customers.sort((a, b) => {
                    const pos1 = a.name || ''
                    const pos2 = b.name || ''
                    if (pos1 > pos2) return 1
                    if (pos2 > pos1) return -1
                    return 0
                })
                response.data = customers
                return response
            })

    static getAssociateds = (page: number, seller_id: number): Promise<ResponseApi> =>
        HttpClient.get(`${Constants.URL_API_ASSOCIATED_CUSTOMER}?page=${page}&vendedor_id=${seller_id}`)
            .then((response: ResponseApi) => {
                const customers: Customer[] = []
                response.clientes.forEach((customer: any) => customers.push(CustomerService.parse(customer)))
                response.data = customers
                return response
            })

    static associate = (vendedor_id: number, cliente_id: string, associar: boolean): Promise<ResponseApi> =>
        HttpClient.post(Constants.URL_API_ASSOCIATE_CUSTOMER, {
            vendedor_id,
            cliente_id,
            associar
        })

    static ignore = (data: any) => HttpClient.post(`/v2/clients/ignore`, data)

    static request = (data: any) => HttpClient.post(`/v2/external/clients`, data)

    static register_client_club= (data: any) => HttpClient.post(`/v2/external/clients/club`, data)

    // static register_lead: (data: any) => HttpClient.post(`/v2/sellers/leads`, data)
        // const url = '/v2/sellers/leads';
        // return api.post(url, body);


    static getBuyingCompanies =
        (page: number, customerId: string, status: string[],
            stateId: string, cnpj: string, provider_id: number): Promise<any> => {
            let queryStringStatus = ''
            if (status.length > 0) {
                status.forEach(
                    (selectedStatus: string) => queryStringStatus += `&status[]=${selectedStatus}`
                )
            }
            const customerIdQueryString = customerId ? `&cliente_id=${customerId}` : ''
            const statesIdQueryString = stateId ? `&estado_sigla=${stateId}` : ''
            const cnpjQueryString = cnpj ? `&cnpj=${cnpj}` : ''
            const providerQueryString = provider_id ? `&provider_id=${provider_id}` : ''

            return HttpClient.get(
                `${Constants.URL_API_BUING_COMPANIES}` +
                `?page=${page}${customerIdQueryString}${queryStringStatus}` +
                `${statesIdQueryString}${cnpjQueryString}${providerQueryString}`
            )
                .then(response => {
                    const customers: Customer[] = []
                    response.clientes.data.forEach(
                        (customer: any) => customers.push(CustomerService.parse(customer))
                    )
                    response.clientes.data = customers
                    response.clientes.totalActive = response.countAtivos.count_ativos
                    response.clientes.totalPending = response.countPendentes.count_pendentes
                    response.clientes.totalInactive = response.countInativos.count_inativos
                    return response.clientes
                })
        }

}
