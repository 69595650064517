import OfferModel from 'models/offer.model'
import ProductModel from 'models/product.model'
import { createRef } from 'react'

export const filter_product_description = (term: string, product: ProductModel) => {
    const regex = new RegExp(term, 'ig')
    return product.name.match(regex) ? product : null
}

export const filter_offered_only = (flag: boolean, product: ProductModel) => {
    return (!flag ||
        (flag && product.derivatives.some((p: ProductModel) => p.offer.price && p.offer.price > 0)))
        ? product : null
}

export const filter_just_mine = (flag: boolean, product: ProductModel) => {
    return (
        filter_offered_only(flag, product) ||
        (!flag || (flag &&
            product.derivatives.some((p: ProductModel) => p.last_offer_price && p.last_offer_price > 0)))
    ) ? product : null
}

export const filter_category = (category: string, product: ProductModel) => {

    return (
        !category ||
        (product.category && product.category === category)
    ) ? product : null
}

export const link_products = (list: any[]) => {
    list.forEach((p, k) => {
        if (p) {
            p.ref = createRef()
            p.obs_ref = createRef()
            p.new_offer_price = p.offer.price
            p.new_offer_quantity = p.offer.quantity
            p.next = list.length === k + 1 ? list[0] : list[k + 1]
            p.prev = k === 0 ? list[list.length - 1] : list[k - 1]
        }
    })
}

export const is_visible = (deriv: ProductModel | undefined) => {
    const result = deriv && !deriv.is_discontinued &&
        (
            (deriv.offer.price !== undefined && deriv.offer.price > 0) ||
            (deriv.main_product && deriv.main_product.id === deriv.id)

        )
    return result
}

export const offer_confirmation = (offer: OfferModel) => (
    <div className="typing-confirmation-alert">
        <p>O preço digitado está muito acima ou abaixo do mercado.</p>
        <p>
            Ultimo preço informado:
            <strong style={{ color: "red" }}>
                R$ {parseFloat(`${offer.last_offer_price}`).toFixed(2)}
            </strong>
        </p>
        <p>
            Preço Digitado:
            <strong style={{ color: "green" }}>
                R$ {parseFloat(`${offer.price}`).toFixed(2)}
            </strong>
        </p>
        <p>
            Informe o preço do(a): <strong>{offer.purchase_unity}</strong>
        </p>
    </div>
)

export const is_editable = (request: any) => {
    return request &&
        !is_expired(request) &&
         !(request.cot_status == 4 || request.cot_status ==10)
    }
    // !['CONGELADA', 'PEDIDO GERADO', 'COTACAO GERADA', 'SEM PEDIDO'].includes(request.status) &&

export const is_expired = (request: any) => {
    return new Date(request.date) <= new Date()
}

// export const navigate = (e: any, prod: ProductModel, callbacks, index?) => {
//     const key = getKey(e)
//     console.log(key)
//     console.log(e.target)
//     const field = e.target.name
//     console.log(field)
//     const mark_saved = () => { prod.saved = true }
//     const mark_obs_saved = () => { prod.obs_saved = true }
//     const mark_offer_quantity_saved = () => { prod.qtd_saved = true }
//     let callback = field === 'offer-obs' ? () => callbacks.save_obs(mark_obs_saved) : null
//     callback = () => callbacks.save && callback == null ? callbacks.save(mark_saved) : null

//     // if(field === 'newOfferPrice' && (key === "Enter" || key === "Tab" )) {
//     //     console.log('entrou')
//     //     console.log(prod)
//     //     callbacks.save()
//     //     console.log('>>',prod?.ref.current)

//     //     document.getElementById('offer-obs'+index)?.focus()

//     //     if(prod?.ref.current)prod?.ref.current.theInput.focus()
//     //     return
//     // }


//     if (field === 'offer-quantity' && (key === "Enter" || key === "Tab" || key === "ArrowLeft")) {
//         console.log('ofer quantity')
//         mark_offer_quantity_saved()
//         if (prod?.ref.current) {

//             console.log('.......', prod?.ref.current.theInput)
//             prod?.ref.current.focus()
//         }
//         return
//     }

//     if (key === "ArrowUp") {
//         e.preventDefault()
//         if (prod?.prev) {
//             console.log('>>>', prod?.prev.ref.current)
//             prod?.prev?.ref.current.theInput.focus()
//         }
//         callback()
//     } else if (key === "ArrowLeft") {
//         e.preventDefault()
//         prod?.ref.current.theInput.focus()
//         callback()
//     } else if (key === "ArrowDown") {
//         e.preventDefault()
//         if (prod?.next) prod?.next?.ref.current.theInput.focus()
//         callback()
//     } else if (key === "ArrowRight" || key === "Tab") {
//         e.preventDefault()
//         if (field === 'offer-obs') {
//             if (prod?.next && prod?.next?.ref.current) prod?.next?.ref.current.theInput.focus()
//         } else {
//             if (prod?.obs_ref) prod?.obs_ref.current.focus()
//         }
//         callback()
//     } else if (key === "Enter") {
//         e.preventDefault()
//         if (field === 'offer-obs') {
//             if (prod?.next && prod?.next?.ref.current) prod?.next?.ref.current.theInput.focus()
//         } else {
//             if (prod?.obs_ref) prod?.obs_ref.current.focus()
//         }
//         callback()
//     }
// }

function searchNextRow(length, el_name, elIndex) {
    let IndexElemento = elIndex + 1
    for (let index = IndexElemento; index < length; index++) {
        let element = document.getElementById(index + el_name + (0))
        if (element !== null) {
            element.focus()
            return
        }
    }
}
function searchPrevRow(length, el_name, elIndex) {
    let IndexElemento = elIndex - 1
    for (let index = IndexElemento; index >= 0; index--) {
        let element = document.getElementById(index + el_name + (0))
        if (element !== null) {
            for (let idx = 20; index >= 0; idx--) {
               let foundElement= document.getElementById(index + el_name + (idx))
                if (foundElement !== null) {
                    foundElement.focus()
                    return
                }
            }
            element.focus()
            return
        }
    }
}

export const navigate = (e: any, prod: ProductModel, callbacks, el_index?, row_index?, length?) => {
    const key = getKey(e)
    const field = e.target.name


    const mark_saved = () => { prod.saved = true }
    const mark_obs_saved = () => { prod.obs_saved = true }
    const mark_offer_quantity_saved = () => { prod.qtd_saved = true }
    let callback = field === 'offer-obs' ? () => callbacks.save_obs(mark_obs_saved) : null
    callback = () => callbacks.save && callback == null ? callbacks.save(mark_saved) : null

    // if (field === 'offer-quantity' && (key === "Enter" || key === "Tab" || key === "ArrowLeft")) {
    //     mark_offer_quantity_saved()
    //     if (prod?.ref.current) prod?.ref.current.theInput.focus()
    //     return
    // }

    if (key === "ArrowUp") {

        e.preventDefault()
        if (field == 'offer-quantity') {
            if (document.getElementById(row_index + 'offer-quantity' + (el_index - 1)) == null) {
                searchPrevRow(length, 'offer-quantity', row_index)
            } else {
                document.getElementById(row_index + 'offer-quantity' + (el_index - 1))?.focus()
            }
        } else if (field == 'set-currency') {
            if (document.getElementById(row_index + 'set-currency' + (el_index - 1)) == null) {
                searchPrevRow(length, 'set-currency', row_index)
            } else {
                document.getElementById(row_index + 'set-currency' + (el_index - 1))?.focus()
            }
        } else if (field == 'offer-obs') {
            if (document.getElementById(row_index + 'offer-obs' + (el_index - 1)) == null) {
                searchPrevRow(length, 'offer-obs', row_index)
            } else {
                document.getElementById(row_index + 'offer-obs' + (el_index - 1))?.focus()
            }
        } else if (field == 'offer-quantityModal') {

            document.getElementById('offer-quantityModal' + (el_index - 1))?.focus()

        }else if (field == 'setCurrencyModal') {
            document.getElementById('setCurrencyModal' + (el_index-1 ))?.focus()

        }else if (field == 'offer-obsModal') {
            document.getElementById('offer-obsModal' + (el_index-1 ))?.focus()

        }
        callback()
    } else if (key === "ArrowDown") {

        e.preventDefault()
        if (field == 'offer-quantity') {
            if (document.getElementById(row_index + 'offer-quantity' + (el_index + 1)) == null) {
                searchNextRow(length, 'offer-quantity', row_index)
            } else {

                document.getElementById(row_index + 'offer-quantity' + (el_index + 1))?.focus()
            }
        } else if (field == 'set-currency') {
            if (document.getElementById(row_index + 'set-currency' + (el_index + 1)) == null) {
                searchNextRow(length, 'set-currency', row_index)
            } else {

                document.getElementById(row_index + 'set-currency' + (el_index + 1))?.focus()
            }
        } else if (field == 'offer-obs') {
            if (document.getElementById(row_index + 'offer-obs' + (el_index + 1)) == null) {
                searchNextRow(length, 'offer-obs', row_index)
            } else {
            document.getElementById(row_index + 'offer-obs' + (el_index + 1))?.focus()
            }
        }
         else if (field == 'offer-quantityModal') {

            document.getElementById('offer-quantityModal' + (el_index + 1))?.focus()

        }else if (field == 'setCurrencyModal') {
            document.getElementById('setCurrencyModal' + (el_index+1 ))?.focus()

        }
        else if (field == 'offer-obsModal') {
            document.getElementById('offer-obsModal' + (el_index+1 ))?.focus()

        }
        callback()
    } else if (key === "ArrowLeft") {

        e.preventDefault()

        if (field == 'offer-obs') {
            document.getElementById(row_index + 'set-currency' + (el_index))?.focus()
        }
        else if (field == 'set-currency') {
            document.getElementById(row_index + 'offer-quantity' + (el_index))?.focus()
        }else if (field == 'offer-obsModal') {
            document.getElementById('setCurrencyModal' + (el_index ))?.focus()
        }else if (field == 'setCurrencyModal') {

            document.getElementById('offer-quantityModal' + (el_index ))?.focus()

        }


        callback()
    } else if (key === "ArrowRight" || key === "Tab") {

        e.preventDefault()

        if (field == 'offer-quantity') {
            document.getElementById(row_index + 'set-currency' + (el_index))?.focus()
        } else if (field == 'set-currency') {
            document.getElementById(row_index + 'offer-obs' + (el_index))?.focus()
        }else if (field == 'offer-quantityModal') {
            document.getElementById('setCurrencyModal' + (el_index ))?.focus()
        }else if (field == 'setCurrencyModal') {
            document.getElementById('offer-obsModal' + (el_index ))?.focus()
        }else if (field == 'offer-obsModal') {

            document.getElementById('offer-quantityModal' + (el_index + 1))?.focus()
        }

        // if (field === 'offer-obs') {
        //     if (prod?.next && prod?.next?.ref.current) prod?.next?.ref.current.theInput.focus()
        // } else {
        //     if (prod?.obs_ref) prod?.obs_ref.current.focus()
        // }
        callback()
    } else if (key === "Enter") {

        e.preventDefault()
        if (field === 'offer-obs') {
            if (document.getElementById(row_index + 'offer-quantity' + (el_index + 1)) == null) {
                searchNextRow(length, 'offer-quantity', row_index)
            } else {

                document.getElementById(row_index + 'offer-quantity' + (el_index + 1))?.focus()
            }
        } else if(field == 'set-currency'){
            if (document.getElementById(row_index + 'set-currency' + (el_index + 1)) == null) {
                searchNextRow(length, 'set-currency', row_index)
            } else {

                document.getElementById(row_index + 'set-currency' + (el_index + 1))?.focus()
            }
        }else if (field == 'offer-quantity') {
            document.getElementById(row_index + 'set-currency' + (el_index))?.focus()
        }
        else if (field == 'offer-obsModal') {

            document.getElementById('offer-quantityModal' + (el_index + 1))?.focus()
        }
        else if (field == 'offer-quantityModal') {

            document.getElementById('setCurrencyModal' + (el_index ))?.focus()
        }
        else if (field == 'setCurrencyModal') {

            document.getElementById('offer-obsModal' + (el_index ))?.focus()
        }
        callback()
    }
}


export const getKey = (e: { key: any; keyCode: number }) => {

    if (e == null) return null

    if (e.key) return e.key

    const keyFromCode = String.fromCharCode(e.keyCode)

    if (keyFromCode) return keyFromCode
    // add here the tricky keys that you use in your app
    if (e.keyCode === 13) return "Enter"
    if (e.keyCode === 16) return "Shift"
    if (e.keyCode === 8) return "Tab"
    // etc
}
