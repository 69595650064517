import styled from 'styled-components';

export const Container = styled.div`
cursor: default;
position: relative;
display: flex;
flex-direction: column;

`;

export const TextLabel = styled.p`
   color:#898989 ;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Rubik';
    margin-bottom: 4px;
`;
export const TextSelect = styled.p`
color:#898989 ;
font-family: $font-familyV2;

font-size: 16px;
`;
export const Select = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 10px 12px ;
    border-color: #e5e5e5;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;

    outline-width: 0px;
`;
export const OptionsContainer = styled.div`
   display :${props => props.isOpen ? 'flex' : 'none'};
    padding: 10px 0;
    cursor: pointer;
   border: 1px solid #e5e5e5 ;
   border-top: 0px;
   background-color: #fff;
   flex-direction: column;
   gap: 6px;
   border-radius: 0px 0px 8px 8px;
   top: 94%;
   width: 100%;
   position: absolute;
   animation: 0.3s ease fadedown;

   @keyframes fadedown {
   from {
       opacity: 0;
       transform:translateY(-2px) ;
   }
   to {
       opacity: 1;
       transform: translateY(0);
   }
   }
   `;
export const Option = styled.div`
display: flex;
animation: 0.3s ease  fadedown;
padding: 5px 15px;


:hover{
    background-color:#e5e5e5;
}
`;
