import React, { FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import ReactDatepicker, { Day, DayValue } from 'react-modern-calendar-datepicker'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import DateService from '../../services/DateService'
import BRLocale from './datepicker.locale'
import './index.scss'
import { MdToday } from 'react-icons/md'

export const Datepicker: FC<any> = ({
    control, name, value, description,
    onChange, mode, pos, min, inputClass, callendarIcon, max, disabledFormControlClass
}: any) => {

    const [date, setDate] = useState<DayValue>(null)

    const formatDate = () => {
        return date ? DateService.formatToDisplay(DateService.newDateFromDayValue(date)) : ''
    }

    useEffect(() => {
        const dt = value ? new Date(value) : new Date()
        setDate(DateService.newDayValueFromDate(dt))
    }, [value])

    const updateDate = (dt: any) => {
        const val = DateService.newDateFromDayValue(dt)
        onChange && onChange(`${DateService.formatToRequest(val)}T00:00:00`)
        return dt
    }

    return (
        <div style={{ 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'space-between', 'width': '100%', 'height': '100%' }}>
            {description && <label style={{ 'marginBottom': 0 }} className="form-label c-label">{description}</label>}
            {control ? (
                <div style={{ position: 'relative', zIndex: 10, 'height': '100%' }}>
                    <Controller
                        control={control}
                        name={name}
                        value={date}
                        defaultValue={date}
                        render={(props) => (
                            <ReactDatepicker
                                value={props.value}
                                locale={BRLocale}
                                minimumDate={DateService.newDayValueFromDate(min) as Day}
                                maximumDate={DateService.newDayValueFromDate(max) as Day}
                                wrapperClassName="dat-picker"
                                calendarPopperPosition={pos || "bottom"}
                                //inputClassName={ inputClass? inputClass : 'form-control date-edit' }
                                //!disabledFormControlClass para evitar o erro de readOnly
                                inputClassName={disabledFormControlClass ? 'date-edit' : 'form-control date-edit'}
                                onChange={(e) => { updateDate(e) && props.onChange(e) }}
                                formatInputText={formatDate}
                                shouldHighlightWeekends
                            />
                        )}
                    />
                    {callendarIcon && <MdToday style={{ pointerEvents: 'none' }} className='callendarIconStyle'></MdToday>}
                </div>) :
                (<div className="flex" style={{ 'position': 'relative', zIndex: 10, 'height': '100%' }}>
                    <ReactDatepicker

                        value={date}
                        locale={BRLocale}
                        minimumDate={DateService.newDayValueFromDate(min) as Day}
                        maximumDate={DateService.newDayValueFromDate(max) as Day}
                        wrapperClassName="dat-picker"
                        calendarPopperPosition="bottom"
                        inputClassName={inputClass ? inputClass : ` date-edit ${mode}`}
                        onChange={(e) => { updateDate(e) }}
                        formatInputText={formatDate}
                        shouldHighlightWeekends
                    />
                    {mode && <span className="material-icons c-icon-dp">edit</span>}
                    {callendarIcon && <MdToday style={{ pointerEvents: 'none' }} className='callendarIconStyle'></MdToday>}
                </div>)
            }
        </div>)
}
