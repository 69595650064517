import Constants from '../constants'
import HttpClient from './HttpClient'
import DirectSaleProductService from './DirectSaleProductService'
import ProviderService from './ProviderService'
import StoreService from './StoreService'
import { ResponseApi } from '../interfaces/responseApi'
import { DirectSaleOrder } from '../interfaces/directSaleOrder'
import { DirectSaleOrderItem } from '../interfaces/directSaleOrderItem'

export default class SalesReportService {

    //   static parse = (orderFromApi: any) : DirectSaleOrder => {
    //       const items:DirectSaleOrderItem[] = []
    //       orderFromApi.itens.forEach((item: any) => items.push(DirectSaleOrderService.parseItem(item)))
    //       return {
    //           id: orderFromApi.pedido_id,
    //           date: orderFromApi.data_criacao,
    //           deliveryDate: orderFromApi.data_entrega,
    //           value: orderFromApi.total,
    //           storeName: orderFromApi.loja.loj_razao,
    //           paymentStatus: orderFromApi.payment_status,
    //           campaign_id: orderFromApi.campaign_id,
    //           campaign: orderFromApi.campaign,
    //           seller: DirectSaleOrderService.parse_seller(orderFromApi.seller),
    //           route: DirectSaleOrderService.parse_route(orderFromApi.route),
    //           status: orderFromApi.status,
    //           receiving_method: orderFromApi.tipo_recebimento,
    //           provider: ProviderService.parse(orderFromApi.fornecedor),
    //           store: StoreService.parse(orderFromApi.loja),
    //           items: items,
    //           integration: orderFromApi.integration
    //       }
    //   }

    // static parse_seller = (seller: any) : any => {
    //     if(!seller)return { }

    //     return {
    //         id: seller.usu_id,
    //         name: seller.usu_nome
    //     }
    // }

    //   static parse_route = (route: any) : any => {
    //       if(!route)return []
    //
    //       return {
    //           id: route.id,
    //           description: route.descricao
    //       }
    //   }

    //  static parseItem = (item: any) : DirectSaleOrderItem => ({
    //      id: item.pro_id,
    //      quantity: item.quantidade,
    //      unitPrice: item.preco_unitario,
    //      campaign_item_id: item.campaign_item_id,
    //      packing_price: item.preco_embalagem,
    //      totalPrice: (item.quantidade * item.preco_embalagem),
    //      product: DirectSaleProductService.parse(item.product),
    //  })

    static getSalesReport = (params: any,page?): Promise<any> =>

        HttpClient.get(page ?page:Constants.URL_API_PROVIDER_SALES_REPORT, params).then(
            (response) => response
        )


    // static getSalesReport = async (params: any) : Promise<ResponseApi> =>
    //      HttpClient.get('/orders', params).then((response: ResponseApi) => {
    //          const orders: DirectSaleOrder[] = []
    //          response.data.forEach((order: any) => orders.push(DirectSaleOrderService.parse(order)))
    //          response.data = orders
    //          return response
    //      })
    //
    //  static confirm = (orderId: string) : Promise<any> =>
    //      HttpClient.post(Constants.URL_API_DIRECT_SALE_ORDER_CONFIRM, { pedido_id: orderId })
    //
    //  static save = (data: any) : Promise<any> =>
    //      HttpClient.put('/orders', data)

}
