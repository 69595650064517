import RepresentativesService from '../../services/RepresentativesService'
// import { Representative } from '../../interfaces/representative';

export const LOAD_REPRESENTATIVES_SUCCESS = 'LOAD_REPRESENTATIVES_SUCCESS'

const initialState = null

export default function representatives(state = initialState, action: any) {
    if (action.type === LOAD_REPRESENTATIVES_SUCCESS) {
        return action.representatives
    }
    return state
}

//export const loadRepresentatives = (params: any) => (dispatch: any) =>
//    
//    RepresentativesService.getAll(params)
//        .then((response: any[]) => {
//            console.log('paramssss-------', params),
//            console.log('response ---  ', response)
//            dispatch(setRepresentatives(response))
//        })
//        .catch(() => dispatch(setRepresentatives(null)))

export const loadRepresentatives = (params: any) => (dispatch: any) =>

    params.vendedor ?

        RepresentativesService.getById(params.vendedor)
            .then((response: any) => {
               
                dispatch(setRepresentatives(response))
            })
            .catch(() => dispatch(setRepresentatives(null)))
        :
        RepresentativesService.getAll(params)
            .then((response: any[]) => {
              
                dispatch(setRepresentatives(response))
            })
            .catch(() => dispatch(setRepresentatives(null)))


const setRepresentatives = (representatives: any[] | null) => ({
    type: LOAD_REPRESENTATIVES_SUCCESS,
    representatives,
})
