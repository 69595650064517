import { mask_currency } from "helpers/mask.helper"

export default class SalesHighchartModel {

    chart = {
        plotBackgroundColor: null,
        type: "spline"
    }
    colors: string[] = [ "#20ce88", "#ff7110", "#BBBBBB" ]
    title: any
    tooltip = {
        crosshairs: true,
        shared: true,
        points: [],
        x: null,
        formatter: function () {
            return this.points.reduce(function (s, point: any) {
                return s + '<br/>' + point.series.name + ': ' +
                    mask_currency(point.y)
            }, '<b>' + this.x + '</b>')
        }
    }
    series: any
    xAxis = {
        categories: [
            "Jan", "Fev", "Mar", "Abr",
            "Mai", "Jun", "Jul", "Aug",
            "Set", "Out", "Nov", "Dez"
        ]
    }
    yAxis = {
        title: {
            text: 'Valor em venda (R$)'
        },
        value: null,
        labels: {
            value: null,
            formatter: function() {
                return `${(this.value || 0)/1000} mil`
            }
        }
    }

    constructor(data : any) {
        this.title = { text: "Pedidos" }
        this.series = [
            {
                name: new Date().getFullYear(),
                data: this.map_to_months(this.group_by_year(data, new Date().getFullYear())),
            },
            {
                name: new Date().getFullYear()-1,
                data: this.map_to_months(this.group_by_year(data, new Date().getFullYear()-1)),
            }
        ]
    }

    map_to_months(data: any) {
        const result = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ]
        let date : any = null
        data.forEach((sale: any) => {
            date = new Date(sale.data_geracao)
            result[date.getMonth()] += sale.valor_pedido
        })
        return result
    }

    group_by_year(data: any, year: number) {
        return data.filter((sale: any) => {
            return new Date(sale.data_geracao).getFullYear() == year
        })
    }

}
