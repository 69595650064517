import React, { useState, useEffect } from 'react'
import { ProjectContainer } from '../../components/project-container'
import { connect } from 'react-redux'
import { loadEditLoggedProviderData } from '../../store/providers'
import './index.scss'
import { PageHeader } from '../../components/page-header'

import Representatives from '../provider/edit/components/representatives/index'
import Fornecedor from '../provider/edit/components/fornecedor/form/'
import { BsClipboardData } from 'react-icons/bs'
import { IoIosPeople } from 'react-icons/io'

const ProviderEdit = ({ loadEditLoggedProviderData }: any) => {

    const [ loading, setLoading ] = useState<boolean>(true)

    useEffect(() => {
        loadEditLoggedProviderData().then(() => setLoading(false))
    }, [ loadEditLoggedProviderData, setLoading ])

    return (

        <ProjectContainer loading={ loading }>
            <header>
                <PageHeader title="Minhas informações" />
            </header>
            <div className="provider-edit">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link" id="nav-home-tab"
                            data-toggle="tab" href="#nav-home" role="tab"
                            aria-controls="nav-home" aria-selected="true"><BsClipboardData />Dados do fornecedor</a>
                        <a className="nav-item nav-link active" id="nav-profile-tab"
                            data-toggle="tab" href="#nav-profile" role="tab"
                            aria-controls="nav-profile" aria-selected="false"><IoIosPeople />Representantes</a>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <Fornecedor />
                    </div>
                    <div className="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <Representatives />
                    </div>
                    <div className="tab-pane fade" id="nav-contact"
                        role="tabpanel"
                        aria-labelledby="nav-contact-tab">...</div>
                </div>

            </div>
        </ProjectContainer>
    )
}

const mapStateToProps = (state: any) => ({
    loggedUser: state.loggedUser,
    editLoggedProviderData: state.editProvider,
})

const mapDispatchToProps = (dispatch: any) => ({
    loadEditLoggedProviderData: () => dispatch(loadEditLoggedProviderData()),
})


export default connect(mapStateToProps, mapDispatchToProps)(ProviderEdit)
