import HttpClient from './http.client'

export default class ProfileService {

    static load = async () : Promise<any> => {
        return HttpClient.get(`/providers/profiles`)
    }

    static save = (profile) =>  {
        return HttpClient.post(`providers/profiles`, profile)
    }

    static remove = (profile)  => {
        return HttpClient.delete( `providers/profiles/${profile.id}`)
    }

    static remove_permission = (permission) => {
        return HttpClient.delete(`providers/profiles/${permission.profile_id}/permissions/${permission.id}`)
    }

    static update = (profile) =>  {
        return HttpClient.put(`providers/profiles/${profile.id}`, profile)
    }

    static create_permission = (permission) => {
        return HttpClient.post(
            `providers/profiles/${permission.profile_id}/permissions`,
            permission
        )
    }

}
