export default class StorageService {

  static storage = localStorage

  static exists = (key: string) => StorageService.storage.getItem(key) !== null

  static get = (key: string) => (
      (StorageService.exists(key))
          ? JSON.parse(StorageService.storage.getItem(key)  || '{}')
          : ''
  )

  static set = (key: string, value: any) =>
      StorageService.storage.setItem(key, JSON.stringify(value))

  static remove = (key: string) => StorageService.storage.removeItem(key)

  static clear = () => StorageService.storage.clear()

}
