import React, { FC, useEffect, useState } from 'react'
import './index.scss'
import styles from './index.module.scss'
import ModalComponent from 'components/modal-v2'
import RequestServiceV3 from 'services/v3/request.service'
import RequestService from 'services/RequestService'
import { Loader } from 'components/loader'
import { FaBoxes, FaCashRegister } from 'react-icons/fa'
import { Checkbox } from 'components/checkbox'
import { Button } from 'components/button'
import { ImExit } from 'react-icons/im'
import { Link } from 'react-router-dom'
import { filter_product_description, is_editable } from 'pages/request/components/requested-products/requested-products.helper'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Input } from 'components/input'
import { BsCheckAll } from 'react-icons/bs'
import ProductModel from 'models/product.model'
import ButtonV3 from 'components/Buttonv3'
import IconReact from 'components/icons'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import InputV2 from 'components/InputV2'

export const ProductsPreviewModal: FC<any> = ({ request, setProductsPreview, reload }: any) => {

    const MySwal = withReactContent(Swal)
    const [modal, setModal] = useState<any>({})
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState<any>([])
    const [params, setParams] = useState<any>({})
    const item_path = () =>
        `/cotacoes/${request.id}/vendedor/${request.seller_id || `${request.id}?o=1`}`

    useEffect(() => {
        setModal({
            title: <span>Produtos da cotação <b>#{request.id}</b></span>,
            icon: <FaBoxes />
        })
    }, [request])



    useEffect(() => {
        RequestServiceV3.load_request_products_withoutPaginate(request.id, request.seller_id,0)
            .then(async (response: any) => {
                response.forEach((p: any) => {
                    p.derivatives = p.derivatives.map((pp: any) => new ProductModel(pp))
                })
                const prods: any = response.map((p: any) => new ProductModel(p))
                prods.forEach((p: ProductModel) => p.visible = true)
                prods.filter((p: ProductModel) => p && !p.is_generic)
                    .forEach((p: ProductModel) => p.derivatives = [new ProductModel({ ...p, main_product: p })])
                return RequestServiceV3.load_request_last_offers_by_seller(request.id, request.seller_id)
                    .then((result: any) => {
                        prods.forEach((p: ProductModel) => {
                            p.process_last_offer(result)
                        })
                        setProducts(prods)
                    })
            })
            .then(() => setLoading(false))
    }, [request])

    useEffect(() => {
        const prods = products.slice(0)
        products.forEach((p: ProductModel) => p.visible = false)
        products
            .filter((p: ProductModel) => (params.checked && p.last_offer_price > 0) || !params.checked)
            .filter((p: ProductModel) => filter_product_description(params.name, p))
            .forEach((p: ProductModel) => p.visible = true)
        setProducts(prods)
    }, [params])

    const close = () => {
        setProductsPreview(false)
    }

    const mark_no_offer = () => {
        MySwal.fire({
            title: 'Confirma esta operação?',
            text: "Seu status nessa cotação será marcado como não ofertante!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, prosseguir!'
        }).then((result) => {
            if (result.isConfirmed) {
                RequestService.mark_no_offers({
                    for_id: request.provider_id,
                    numerocotacao: request.id,
                    vend_id: request.seller_id
                }).then(() => {
                    reload(1)
                    Swal.fire(
                        'Sucesso!',
                        'Requisição enviada com sucesso',
                        'success'
                    )
                    close()
                }).catch(err => {

                    Swal.fire('Ops!', err.response.data.mensagem, 'error')
                    close()
                })
            }
        })
    }

    return loading ?
        <Loader type="inline" /> :
        <div id="preview-products">
            <div style={{ 'padding': 25 }}>
                <div className='divCompanyName'>
                    Empresa: <b className="company-name">{request.client}</b>
                </div>
                <div style={{ 'marginTop': 15 }} >
                    <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'flex-end', 'justifyContent': 'space-between', 'gap': 10, 'flexWrap': 'wrap' }}>
                        <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'flex-end', 'justifyContent': 'space-between', 'gap': 15, 'flexWrap': 'wrap' }}>
                            {
                                //   <Input placeholder="Filtrar produto"
                                //       onChange={(evt: any) => setParams({ ...params, name: evt.target.value })}
                                //   />
                            }
                            <div style={{ 'minWidth': 300 }} >
                                <InputV2
                                    infoIconNull
                                    icon={<IconReact color={'#898989'} className='iconButtonV3' type='search'></IconReact>}
                                    mask={undefined}
                                    id={'filtrar'}
                                    type={'text'}
                                    placeholder={"Filtrar produto"}
                                    onChange={(evt: any) => setParams({ ...params, name: evt.target.value })}
                                />
                            </div>
                            <div style={{ 'minWidth': 165 }} >
                                {
                                    //    <Checkbox description="Apenas meus produtos"
                                    //        onChange={(evt: any) => setParams({ ...params, checked: evt.target.checked })}
                                    //    />
                                }
                                <CheckBoxCustom
                                    checked={params.checked}
                                    description="Apenas meus produtos"
                                    value={params.checked}
                                    change={(e, stats: any) =>
                                        setParams({ ...params, checked: stats })
                                    }
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                'display': 'flex',
                                'flexDirection': 'row',
                                'alignItems': 'flex-end',
                                'gap': 20
                            }}>
                            {
                                (!request.blocked && is_editable(request) &&
                                ((request.is_provider_included && !request.is_public) || request.is_public)) ?
                                <div>
                                        <ButtonV3
                                            title='Sem oferta'
                                            onClick={mark_no_offer}
                                            Icon={<IconReact className='iconButtonV3' type='closeCircle'></IconReact>}
                                            />
                                    </div>
                                    :
                                    <></>
                                }
                                {
                                    //   <Button className="c-btn-outline c-btn-remove" onClick={mark_no_offer}>
                                    //       <ImExit />
                                    //       Não Possuo Oferta
                                    //   </Button>
                                }
                            {(!request.blocked &&
                                ((request.is_provider_included && !request.is_public) || request.is_public)) ?
                                <Link to={item_path()}>
                                    {
                                        //   <Button className="c-btn-ternary">
                                        //       <FaCashRegister />
                                        //       {is_editable(request) ? 'Lançar Preços' : 'Acessar Cotação'}
                                        //   </Button>
                                    }
                                    <div>

                                        <ButtonV3
                                            title={is_editable(request) ? 'Lançar Preços' : 'Acessar Cotação'}
                                            onClick={() => { }}
                                            Icon={<IconReact className='iconButtonV3' type={'fileOpen'} />}
                                        />
                                    </div>
                                </Link> : ''
                            }
                        </div>

                    </div>
                </div>
                <div className="listProductsPreview">
                    <table className="newTableProductsPreview">
                        <thead>
                            <tr>
                                <th>Produto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products && products.filter((prod: ProductModel) => prod.visible)
                                .map((prod: ProductModel) => <tr key={prod.id}>
                                    <td >
                                        <div>
                                            <BsCheckAll color='#ff7110' size={20} style={{
                                                visibility: prod.last_offer_price > 0 ? 'visible' : 'hidden',
                                                //visibility: true ? 'visible' : 'hidden',
                                                'marginRight': 5

                                            }} title="Meus Produtos" />
                                            {prod.name}
                                        </div>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
}


