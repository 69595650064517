import styled from 'styled-components';



interface categoryProp {
    isActive?: boolean
    listMode?: boolean
}
interface addContainerProps {
    visible?: boolean

}

export const ListCategoriesContainer = styled.div`
display: flex;
width: 100%;


`;



export const CategoriesContainer = styled.div<categoryProp>`
display: grid;

grid-template-columns:${props => props.listMode ? '1fr' : 'repeat(auto-fit, minmax(280px, 1fr))'};;
width: 100%;
gap: 12px;
@media screen and (max-width: 900px) {

    display: flex;
    flex-direction: column;
}
`;

export const CategoryNameButton = styled.div<categoryProp>`
    width: 100%;
 > section{
    padding: 4px 5px;
    /* border: 1px solid transparent; */
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.2);
    display: flex;
    align-items: center;

    gap: 15px;
    border-bottom: 2px solid;
    border-image: linear-gradient(to right, #ff7110 -30%, transparent 95%) 100% 0%;


    p{
    flex: 1;
    color: rgba(0,0,0,0.8);
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;

}
svg{
    font-size: 20px;
    color: #ff711090;
}
.editCategory{

    svg{

        color: rgba(0,0,0,0.4);
    }
}
.removeCategory{
    cursor: pointer;
    svg{

    color: rgba(255,0,0,0.5);
}
}

}



    /* background-color: ${props => props.isActive ? '#ff711020' : 'transparent'}; */

&:hover{

   >  section{

        p{
            transition: 200ms;
            /* translate: 2px; */
        }
    }
}







`;





export const SubcategoriesContainer = styled.div<categoryProp>`
    /* background-color: rgba(0,0,0,0.1); */
    margin: 0 10px;
    border-radius: 0 0 8px 8px;
    transition:${props => props.isActive ? ' all 500ms' : 'all 100ms'};;
    overflow: hidden;
    max-height: ${props => props.listMode && props.isActive ? '700px' : '0'};;
    opacity: ${props => props.listMode && props.isActive ? 1 : 'unset'};
    display: flex;
    flex-direction: column;
    gap: 10px;

    box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.1);
    padding: 5px 10px;

    &:hover{

    box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.1);
    }
    > section{
        /* position: relative; */
        padding: 5px 15px;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        display: flex;
        align-items: center;
        gap: 8px;
        p{
            /* margin-left: 20px; */
            text-transform: capitalize;
        }
        svg{

        color: rgba(255,0,0,0.5);
        }


    }
    &.listmode{
        @media screen and (min-width: 900px) {
            transition:${props => props.isActive ? ' all 500ms' : 'all 100ms'};;
            overflow: hidden;
            max-height: ${props => props.isActive ? '700px' : '0'};;
            opacity: ${props => props.isActive ? 1 : 0};
        }

    }
    &.blockmode{
        @media screen and (min-width: 900px) {
            max-height:unset;
            opacity: 1;
        }

    }
    @media screen and (max-width: 900px) {

    /* display: ${props => props.isActive ? 'flex' : 'none'};; */
    transition:${props => props.isActive ? ' all 500ms' : 'all 100ms'};;
    overflow: hidden;
    max-height: ${props => props.isActive ? '700px' : '0'};;
    opacity: ${props => props.isActive ? 1 : 0};

    gap: 10px;

    section{
        p{

        }

    }


}
`;
export const HeaderListButtonsContainer = styled.div<categoryProp>`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .subLoader{
        animation: spin 1s linear infinite ;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
}
    .listModeContainer{
        display: flex;
        @media screen and (max-width: 900px) {
            display: none;

        }
        gap: 15px;
        section{
            cursor: pointer;
            transition: all 200ms;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            width: 35px;
            padding: 5px;
            border-radius: 6px;
            border: 1px solid transparent;
            box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.2);
            svg{

                font-size: 25px;
            }
        }
        section.selected{
            background-color: #ff7110;
            color: #fff;
            border-color: #ff7110;
        }
        section.unselected{
            background-color:#fff;
            color: #000;
        }

    }
`
export const AddCategoryContainer = styled.div<addContainerProps>`
    display: flex;

    align-items: center;
    gap: 5px;

    .inputsCategory{
        transition: all 300ms;
        /* transition: opacity 400ms; */
        display: flex;
        align-content: center;
        gap: 5px;
        opacity: ${props => props.visible ? '1' : '0'} ;
        /* display: ${props => props.visible ? 'flex' : 'none'};; */
        max-width:${props => props.visible ? '300px' : '0'} ;
    }
    .inputNovaCategoria{
        height: 45px;
        /* outline: #ff7110; */
    }
    .buttonCancelCategory{
        cursor: pointer;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid rgba(0,0,0,0.1);
        border-radius: 8px;
        /* box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.1); */


    }
.iconButtonClose{
    color: rgba(255,0,0,0.7);
    font-size: 30px;

}
    `;
export const AddSubcategoryContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 5px 10px;
    input{

    }


.AddSubcategoryIcon{
    cursor: pointer;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    width: 35px;
    height: 35px;
    border-radius: 5px;

    box-shadow:1px 1px  2px 1px rgba(0,0,0,0.1);
    /* transition: 200ms; */
    position: relative;
    transition: translate 100ms;
    .savesubicon{
        position: absolute;
        transition: 200ms;
        opacity: 1;
    }
    .checksubicon{
        position: absolute;
        transition: 200ms;
        opacity: 0;
    }
    svg{

        font-size: 15px;
        color: #00a65a;
    }

    &:hover{
        box-shadow:1px 1px 3px 1px rgba(0,0,0,0.2);
        transition: box-shadow 200ms;
        translate:0 -1px;
        transition: translate 100ms;

        .savesubicon{
            transition: 100ms;
            opacity: 0;
        }
        .checksubicon{
            transition: 500ms;
            opacity: 1;
            transform: scale(1.1);

        }
        svg{

        /* transform: rotate(180deg); */
        }
    }
    .subLoader{
        animation: spin 1s linear infinite ;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
}

}
`;
