/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable array-bracket-spacing */
import { Button } from 'components/button'
import { Input } from 'components/input'
import { Select } from 'components/select'
import { Textarea } from 'components/textarea'
import { FC, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useForm } from 'react-hook-form'
import ModalImage from 'react-modal-image'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FeedbackError } from '../../../../components/feedback/error'
import Constants from '../../../../constants'
import { DirectSaleProduct } from '../../../../interfaces/directSaleProduct'
import Utils from '../../../../utils'
import './index.scss'

import { CheckBoxCustom } from 'components/checkBoxCustom'
import { BiEditAlt, BiUpload } from 'react-icons/bi'
import { TbCloudUpload } from 'react-icons/tb'
import ProductService from 'services/ProductService'
import ClassificationService from 'services/v3/classification.service'
import ButtonV3 from 'components/Buttonv3'
import { BaseModal } from 'components/BaseModal'
import ClassificationModal from '../classificationsModal'
import { IoFilterSharp } from 'react-icons/io5'
import { ImFolderUpload } from 'react-icons/im'
import { MdFileUpload, MdImageSearch } from 'react-icons/md'

const ProductForm: FC<any> = ({ saveCallback, product }: any) => {
    const [packing, setPacking] = useState('')
    const [imageSrc, setImageSrc] = useState<any>()
    const [packingSize, setPackingSize] = useState('')
    const [prodTerceiro, setProdTerceiro] = useState(0)
    const [storageControl, setStorageControl] = useState(0)
    const [prodFracionado, setProdFracionado] = useState(0)
    const [selectedUnity, setSelectedUnity] = useState('')
    const [prodCategories, setProdCategories] = useState<any>([])
    const [category, setCategory] = useState<any>(null)
    const [infoCategories, setInfoCategories] = useState<any>({
        cat: null,
        subcat: null,
    })
    const [classifications, setClassifications] = useState<any>([])
    const [modalClassificationVisible, setModalClassificationVisible] =
        useState(false)
    const [selectedsClassifications, setSelectedsClassifications] =
        useState<any>([])

    async function getClassification(page = 1) {
        await ClassificationService.getclassification(page).then((res) => {
            setClassifications(res)
        })
    }

    const MySwal = withReactContent(Swal)

    async function handleGetAllProdCategories() {
        await ProductService.getAllProductCategories().then((res) => {
            setProdCategories(
                res.data?.sort((a, b) => a.name.localeCompare(b.name))
            )
        })
    }
    useEffect(() => {
        handleGetAllProdCategories()
        getClassification()
    }, [])
    useEffect(() => {
        if (product) {
            setPackingSize(product.packingQuantity)
            setPacking(product.packing.split(' ')[0])
            setInfoCategories({
                cat: product.categoria,
                subcat: product.subcategoria,
            })

            if (product && product.classificacao) {
                setSelectedsClassifications(product.classificacao)
            }
        }
    }, [product])

    // const loadInfoCategories = (catId:number, subCatId:number){

    // }
    // const uploadImage = (event: any) => {
    //   const reader = new FileReader()

    //   reader.onload = (e: any) => {
    //     const img = new Image()
    //     img.onload = function () {
    //       if (img.height > img.width) {
    //         MySwal.fire({
    //           title: 'Imagem invalida',
    //           text: 'Desculpe, apenas imagens do tipo paisagem(foto de lado) são aceitas.',
    //           icon: 'error',
    //           confirmButtonColor: '#3085d6',
    //           confirmButtonText: 'Ok',
    //         })
    //         return false
    //       } else if (img.height > 360 || img.width > 640) {
    //         MySwal.fire({
    //           title: 'Imagem invalida',
    //           text: 'Desculpe, a imagem deve ter um limite de 360px de altura e 640px de largura.',
    //           icon: 'error',
    //           confirmButtonColor: '#3085d6',
    //           confirmButtonText: 'Ok',
    //         })
    //         return false
    //       } else {

    //         setImageSrc(e.target.result)
    //       }
    //     }
    //     img.src = e.target.result
    //   }
    //   event.target
    //     ? reader.readAsDataURL(event.target.files[0])
    //     : reader.readAsDataURL(event[0])

    //   //
    //   //reader.readAsDataURL(event[0])
    //   //reader.readAsDataURL(event)
    // }

    const { register, handleSubmit, errors } = useForm()

    const save = (data: any) => {
        console.log('??', imageSrc)

        const productToSave: DirectSaleProduct = {
            id: '',
            internalCode: data.code,
            ean: data.code, // this field receive the same value of internal code
            description: data.description,
            packing: `${data.packing} ${data.packingQuantity}`,
            packingQuantity: data.packingQuantity,
            pro_caixa: data.pro_caixa,
            dailyLimit: data.dailyLimit,
            stock: data.stock,
            pro_qtd_min: data.pro_qtd_min,
            additionalInformations: data.additionalInformations,
            // picture: imageSrc ? Utils.formatImageToApi(imageSrc) : null,
            // picture:  null,
            img: imageSrc ? imageSrc : null,
            prod_terceiro: prodTerceiro,
            storage_control: storageControl,
            produto_fracionado: prodFracionado,
            categoria: infoCategories.cat,
            subcategoria: infoCategories.subcat,
            classificacao:
                selectedsClassifications.length > 0
                    ? selectedsClassifications
                    : null,
        }

        saveCallback(productToSave)
    }

    const checkPacking = (unity) => {
        if (product) {
            if ('UN' === unity) {
                product.packingQuantity = '1'
            } else if ('DZ' === unity) {
                product.packingQuantity = '12'
            }
            setPackingSize(product.packingQuantity)
            setPacking(unity)
        } else {
            setSelectedUnity(unity)
        }
    }
    useEffect(() => {
        product && setProdFracionado(product.produto_fracionado)
        product && setProdTerceiro(product.prod_terceiro)
        product && setStorageControl(product.storage_control)
    }, [product])

    function handleSetCategory(idSelecionado) {
        if (idSelecionado === 'categoria') {
            setInfoCategories({ cat: null, subcat: null })
            return
        }
        let selectedCat
        let selectedSubCat

        for (let i = 0; i < prodCategories.length; i++) {
            let actualCategory = prodCategories[i]
            if (actualCategory.id == idSelecionado) {
                selectedCat = idSelecionado
                setInfoCategories({ cat: selectedCat, subcat: selectedSubCat })
                return
            }
            if (actualCategory.subCategories.length > 0) {
                let subcategories = [...actualCategory.subCategories]
                for (let s = 0; s < subcategories.length; s++) {
                    if (subcategories[s].id == idSelecionado) {
                        selectedCat = actualCategory.id
                        selectedSubCat = idSelecionado
                        setInfoCategories({
                            cat: selectedCat,
                            subcat: selectedSubCat,
                        })
                        return
                    }
                }
            }
        }
    }
    function handleChangeImage(event) {
        console.log('event', event.target.files)
        setImageSrc(event.target.files[0])
    }

    return (
        <form onSubmit={handleSubmit(save)}>
            <div>
                <div>
                    <div className="file-input divInputUpImage">
                        <section>
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                className="current-image"
                            >
                                {imageSrc ? (
                                    <ModalImage
                                        small={
                                            URL.createObjectURL(imageSrc) ||
                                            'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'
                                        }
                                        large={
                                            URL.createObjectURL(imageSrc) ||
                                            'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'
                                        }
                                        className="image imageteste"
                                        // alt={`Produto: ${product.description ?? ''}`}
                                    />
                                ) : (
                                    <ModalImage
                                        small={
                                            product?.picture ??
                                            'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'
                                        }
                                        large={
                                            product?.picture ??
                                            'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'
                                        }
                                        className="image imageteste"
                                        // alt={`Produto: ${product.description ?? ''}`}
                                    />
                                )}
                            </div>

                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="fileInput"
                                onChange={(e: any) => handleChangeImage(e)}
                            />
                            <label
                                title="Adicionar imagem"
                                className="editImageContainerIcon"
                                htmlFor="fileInput"
                                style={{ cursor: 'pointer' }}
                            >
                                <MdImageSearch
                                    className="editImageIcon"
                                    size={30}
                                />
                            </label>

                            {/* <IoMdCheckmarkCircleOutline color={getColorCheckmark('desktop')} size={30} /> */}
                        </section>
                    </div>

                    {errors.image && (
                        <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />
                    )}
                </div>
                <div
                    style={{
                        marginTop: 40,
                        display: 'grid',
                        //transition: grid-template-columns 100ms ease-in-out;
                        justifyContent: 'center',
                        gridTemplateColumns:
                            'repeat(auto-fill,minmax(330px, 40%))',
                        columnGap: ' 30px',
                        rowGap: ' 10px',
                    }}
                >
                    <div>
                        <Input
                            id="description"
                            name="description"
                            placeholder="Nome"
                            description="Nome"
                            register={register({ required: true })}
                            defaultValue={product ? product.description : ''}
                        />

                        {errors.description && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                    </div>
                    <div>
                        <Input
                            id="code"
                            name="code"
                            type="number"
                            description="Código"
                            placeholder="Código"
                            register={register({ required: true })}
                            defaultValue={product ? product.internalCode : ''}
                        />
                        {errors.code && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                    </div>
                    <div>
                        <Select
                            onChange={(e: any) => checkPacking(e.target.value)}
                            id="packing"
                            name="packing"
                            description="Embalagem"
                            value_key="value"
                            label_key="name"
                            options={[
                                { name: 'Unidade', value: 'UN' },
                                { name: 'Caixa', value: 'CX' },
                                { name: 'Fardo', value: 'FD' },
                                { name: 'Quilo', value: 'KG' },
                                { name: 'Duzia', value: 'DZ' },
                                { name: 'Pacote', value: 'PT' },
                            ]}
                            register={register({ required: true })}
                            defaultValue={packing}
                        />
                        {errors.packing && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                    </div>
                    <div>
                        <Input
                            readOnly={packing === 'UN' || packing === 'DZ'}
                            description="Quantidade na embalagem"
                            id="packingQuantity"
                            name="packingQuantity"
                            type="number"
                            className="form-control input-field"
                            placeholder="Quantidade de embalagens"
                            register={register({ required: true })}
                            defaultValue={packingSize}
                        />
                        {errors.packingQuantity && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                    </div>
                    <div>
                        <Input
                            id="dailyLimit"
                            name="dailyLimit"
                            description="Limite Diário"
                            placeholder="Limite diário"
                            className="form-control input-field"
                            register={register()}
                            defaultValue={product ? product.dailyLimit : ''}
                        />
                        {errors.dailyLimit && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                    </div>
                    <div>
                        <Input
                            id="stock"
                            name="stock"
                            description="Estoque"
                            placeholder="Estoque"
                            register={register()}
                            defaultValue={product ? product.stock : ''}
                        />
                        {errors.stock && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                    </div>
                    <div>
                        <Input
                            id="pro_qtd_min"
                            name="pro_qtd_min"
                            description="Quantidade Mínima"
                            type="number"
                            placeholder="Quantidade Mínima de compra"
                            defaultValue={
                                product
                                    ? product.pro_qtd_min == 0
                                        ? 1
                                        : product.pro_qtd_min
                                    : null
                            }
                            register={register({ required: true })}
                        />
                        {errors.pro_qtd_min && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                        <div
                            style={{ marginTop: 10, display: 'flex', gap: 15 }}
                        >
                            <CheckBoxCustom
                                change={(e) => setProdTerceiro(1)}
                                description="Produto de terceiro"
                                checked={prodTerceiro == 1}
                            ></CheckBoxCustom>
                            <CheckBoxCustom
                                change={(e) => setStorageControl(1)}
                                description="Controle de estoque"
                                checked={storageControl == 1}
                            ></CheckBoxCustom>
                            {selectedUnity == 'KG' || packing == 'KG' ? (
                                <CheckBoxCustom
                                    change={(e) => setProdFracionado(1)}
                                    description="Produto fracionado (Kg)"
                                    checked={prodFracionado == 1}
                                ></CheckBoxCustom>
                            ) : null}
                        </div>
                    </div>
                    <div>
                        <Input
                            id="pro_caixa"
                            name="pro_caixa"
                            description="Quantidade Caixa"
                            type="number"
                            placeholder="Quantidade caixa(transporte)"
                            onChange={(evt) => {
                                // console.log(evt.target.value, 'CC')
                            }}
                            defaultValue={
                                product
                                    ? product.pro_caixa == 0
                                        ? 1
                                        : product.pro_caixa
                                    : null
                            }
                            register={register({ required: true })}
                        />
                        {errors.pro_caixa && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                    </div>
                    <div>
                        <label
                            className="c-label"
                            htmlFor="additionalInformations"
                        >
                            Informações adicionais
                        </label>
                        <Textarea
                            id="additionalInformations"
                            name="additionalInformations"
                            rows={4}
                            className="form-control textarea-field"
                            placeholder="..."
                            register={register()}
                            defaultValue={
                                product ? product.additionalInformations : ''
                            }
                        />
                    </div>
                    <div>
                        {prodCategories?.length > 0 ? (
                            <div className="selectContainer">
                                <label
                                    className="c-label"
                                    htmlFor="additionalInformations"
                                >
                                    Categoria
                                </label>

                                <select
                                    name="select"
                                    className="inputSelect"
                                    style={{ textTransform: 'capitalize' }}
                                    onChange={(e: any) =>
                                        handleSetCategory(e?.target?.value)
                                    }
                                    defaultValue={
                                        product?.subcategoria
                                            ? product.subcategoria
                                            : product?.categoria
                                    }
                                >
                                    <option
                                        selected={category == undefined}
                                        value={undefined}
                                    >
                                        selecione a categoria
                                    </option>
                                    {prodCategories &&
                                        prodCategories.map((item, index) => {
                                            return (
                                                <>
                                                    <option
                                                        key={'cat' + index}
                                                        className="itemOption"
                                                        selected={
                                                            category == item.id
                                                        }
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                    {item.subCategories
                                                        ?.length > 0
                                                        ? item.subCategories.map(
                                                              (sub, index) => {
                                                                  return (
                                                                      <option
                                                                          key={
                                                                              'subcat' +
                                                                              index
                                                                          }
                                                                          className="subItemOption"
                                                                          selected={
                                                                              category ==
                                                                              sub.id
                                                                          }
                                                                          value={
                                                                              sub.id
                                                                          }
                                                                      >
                                                                          {
                                                                              sub.name
                                                                          }
                                                                      </option>
                                                                  )
                                                              }
                                                          )
                                                        : null}
                                                </>
                                            )
                                        })}
                                </select>
                            </div>
                        ) : null}
                        {classifications.length > 0 && product?.id ? (
                            <div
                                style={{
                                    marginTop: 12,
                                    display: 'flex',
                                    gap: 8,
                                    alignItems: 'center',
                                    paddingTop: 6,
                                    justifyContent: 'space-between',
                                    borderTop: '1px solid rgba(0,0,0,0.1)',
                                }}
                            >
                                <ButtonV3
                                    Icon={
                                        <IoFilterSharp className="iconButtonV3" />
                                    }
                                    type="button"
                                    onClick={() =>
                                        setModalClassificationVisible(true)
                                    }
                                    title="Selecionar Classificação"
                                />
                                {/* <p><strong>{selectedsClassifications.length} </strong>Selecionadas</p> */}
                            </div>
                        ) : null}
                    </div>
                </div>
                {product?.id ? (
                    <BaseModal
                        data={null}
                        title={`Classificações`}
                        isVisible={modalClassificationVisible}
                        onCloseCallback={() =>
                            setModalClassificationVisible(false)
                        }
                        children={
                            <ClassificationModal
                                proId={product.id}
                                closeCallback={() =>
                                    setModalClassificationVisible(false)
                                }
                                selecteds={selectedsClassifications}
                                onChangeValue={(data) =>
                                    setSelectedsClassifications(data)
                                }
                                classifications={classifications}
                            />
                        }
                        modalBodyStyle={{ overflow: 'none' }}
                    />
                ) : null}
            </div>

            <Button
                type="submit"
                className="c-btn-save ml-3"
                id="save-product"
                style={{ display: 'none' }}
            >
                <i className="material-icons">save_alt</i>Salvar.
            </Button>
        </form>
    )
}

export default ProductForm
