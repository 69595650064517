import React, { FC, useState, useEffect } from 'react'

import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai'


import {
    Container,
    ArrowButtonContainer,
    PagesContainer,
    NumberPage,
    Page,
    PaginationContainer,
    PaginationDescriptionContainer,
    Description,
} from './styles'

interface props {
    pages?: any,
    handleSelectPage: (page) => void,
    paginationData: any,
    paginationDataDescription?: string
}



export default function NewPagination({ paginationData, handleSelectPage, paginationDataDescription }: props) {

    const [pages, setPages] = useState([])
    //const [activePage, setActivePage] = useState(Number)
    //return(
    //    <div style={{'width':300, 'height':100, 'backgroundColor':'red'}}></div>
    //)



    function selectPage(index) {
        paginationData.current_page != index && handleSelectPage(index)

        //setActivePage(index)
    }

    // useEffect(() => {
    //     setPages(teste())
    // }, [paginationData])


    // function teste() {
    //     var itens: any = []

    //     for (let index = 1; index <= paginationData.last_page; index++) {
    //         itens.push(
    //             <NumberPage
    //                 key={index}
    //                 onClick={() => selectPage(index)} isActive={paginationData.current_page == index}>
    //                 <Page>{index}</Page>
    //             </NumberPage>)
    //     }
    //     return itens
    // }
    return (
        <Container >
            <PaginationContainer>
                <ArrowButtonContainer
                    title={'Página anterior'}
                    style={paginationData.prev_page_url ? { 'cursor': 'pointer' } : { 'cursor': 'not-allowed' }}
                    onClick={() => { paginationData.prev_page_url ? selectPage(paginationData.prev_page_url) : {} }} >
                    <AiOutlineDoubleLeft
                        color={paginationData.prev_page_url ? '#ff7110' : '#353a40'} size={12} />
                </ArrowButtonContainer>
                <PagesContainer>
                    {paginationData.links &&paginationData.links.map((item, index) =>
                        item.url  && item.label!='Next &raquo;' && item.label!='&laquo; Previous' &&<NumberPage
                            key={index}
                            onClick={() => selectPage(item.url)} isActive={item.active}>
                            <Page>{item.label}</Page>
                        </NumberPage>
                    )}
                </PagesContainer>
                <ArrowButtonContainer
                    title={'Próxima página'}
                    style={paginationData.next_page_url ? { 'cursor': 'pointer' } : { 'cursor': 'not-allowed' }}
                    onClick={() => { paginationData.next_page_url ? selectPage(paginationData.next_page_url) : {} }}
                >
                    <AiOutlineDoubleRight color={paginationData.next_page_url ? '#ff7110' : '#353a40'} size={12} />
                </ArrowButtonContainer>
            </PaginationContainer>
            <PaginationDescriptionContainer>

                <Description> {`Exibindo: ${paginationData.from} até ${paginationData.to} de ${paginationData.total} registros ${paginationDataDescription ? paginationDataDescription : ''}`}</Description>
            </PaginationDescriptionContainer>

        </Container >
    )
}
