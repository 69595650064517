import React, { FC, useEffect, useState } from 'react'
import styles from './index.module.scss'
import ModalComponent from 'components/modal-v2'
import { FaStoreAlt } from 'react-icons/fa'
import DateService from 'services/DateService'
import { DescriptionRow } from 'components/descriptionRow'
import { mask_cnpj, phoneMask } from 'helpers/mask.helper'

export const ClientDetailsModal: FC<any> = ({ request, setShowClientDetails }: any) => {

    const [modal, setModal] = useState<any>({})

    useEffect(() => {
        setModal({
            title: <span>Cliente <b>#{request.client}</b></span>,
            icon: <FaStoreAlt />
        })
    }, [request])

    const close = () => {
        setShowClientDetails(false)
    }

    return (
        <div style={{ 'padding': '30px 40px' }}>
            <div className={styles.tableContainerCLientsDetail} >
                <div style={{ 'display': 'flex', 'flexDirection': 'column', 'width': '100%', 'rowGap': 5 }}>
                    <div className={styles.clientDetailcolumn}><DescriptionRow title='CNPJ:' value={request.client_cnpj ? mask_cnpj(request.client_cnpj) : ''} /></div>
                    <div className={styles.clientDetailcolumn}><DescriptionRow title='Nome Fantasia:' value={request.client_fantasy_name} /></div>
                    <div className={styles.clientDetailcolumn}><DescriptionRow title='Contato:' value={request.client_contact} /></div>
                    <div className={styles.clientDetailcolumn}><DescriptionRow title='Email:' value={request.client_email} /></div>
                    <div className={styles.clientDetailcolumn}><DescriptionRow title='Grupo:' value={request.client} /></div>
                    <div className={styles.clientDetailcolumn}><DescriptionRow title='Cidade:' value={request.client_city} /></div>
                    <div className={styles.clientDetailcolumn}><DescriptionRow title='Endereço:' value={request.client_store_street} /></div>
                    <div className={styles.clientDetailcolumn}><DescriptionRow title='Data Lançamento:' value={DateService.formatToDisplayWithTime(new Date(request.created_at))} /></div>
                    <div className={styles.clientDetailcolumn}><DescriptionRow title='Data Fim:' value={DateService.formatToDisplayWithTime(new Date(request.date))} /></div>
                </div>
                <div style={{ 'display': 'flex', 'flexDirection': 'column', 'width': '100%', 'rowGap': 5 }}>
                    <div className={styles.clientDetailSecondColumn}>  <DescriptionRow title='Razão social:' value={request.client_name} /></div>
                    <div className={styles.clientDetailSecondColumn}>  <DescriptionRow title='Telefone:' value={request.client_phone ? phoneMask(request.client_phone) : ''} /></div>
                    <div className={styles.clientDetailSecondColumn}>  <DescriptionRow title='Bairro:' value={request.client_state} /></div>
                    <div className={styles.clientDetailSecondColumn}>  <DescriptionRow title='CEP:' value={request.client_store_cep ? JSON.stringify(request.client_store_cep).substring(0, 5)
                        + "-"
                        + JSON.stringify(request.client_store_cep).substring(5):'' }/></div>
                    {/* <div className={styles.clientDetailSecondColumn}>  <DescriptionRow title='CEP:' value={request.client_store_cep ? clientCep.substring(0, 5)
                        + "-"
                        + clientCep.substring(5):'' }/></div> */}
                    <div className={styles.clientDetailSecondColumn}>  <DescriptionRow title='Vendedor:' value={request.seller} /></div>



                </div>
            </div>
        </div>
    )
    //return <ModalComponent modal={ modal } show={ true } close={ close } className={ styles['modal-dialog'] }>
    //    <div className="container-fluid">
    //        <div className="row header">
    //
    //            <div className="col m-4 p-3 shadow">
    //                <table className={ `table ${styles.table}` }>
    //                    <tbody>
    //                        <tr>
    //
    //                            <td><b>CNPJ</b> </td>
    //                            <td>{ request.client_cnpj }</td>
    //                            <td><b>Razão social</b></td>
    //                            <td>{ request.client_name }</td>
    //                        </tr>
    //                        <tr>
    //                            <td><b>Nome Fantasia</b></td>
    //                            <td>{ request.client_fantasy_name }</td>
    //                            <td><b>Telefone</b></td>
    //                            <td>{ request.client_phone }</td>
    //                        </tr>
    //                        <tr>
    //                            <td><b>Contato</b></td>
    //                            <td>{ request.client_contact }</td>
    //                            <td><b>Bairro</b></td>
    //                            <td>{ request.client_state }</td>
    //                        </tr>
    //                        <tr>
    //                            <td><b>Email</b></td>
    //                            <td>{ request.client_email }</td>
    //                            <td><b>CEP</b></td>
    //                            <td>{ request.client_store_cep }</td>
    //                        </tr>
    //                        <tr>
    //                            <td><b>Grupo</b></td>
    //                            <td>{ request.client }</td>
    //                            <td><b>Vendedor</b></td>
    //                            <td>{ request.seller }</td>
    //                        </tr>
    //                        <tr>
    //                            <td><b>Cidade</b></td>
    //                            <td>{ request.client_city }</td>
    //                            <td></td>
    //                            <td></td>
    //                        </tr>
    //                        <tr>
    //                            <td><b>Endereço</b></td>
    //                            <td>{ request.client_store_street }</td>
    //                            <td></td>
    //                            <td></td>
    //                        </tr>
    //                        <tr>
    //                            <td><b>Data Lançamento</b></td>
    //                            <td>{ DateService.formatToDisplayWithTime(new Date(request.created_at)) }</td>
    //                            <td></td>
    //                            <td></td>
    //                        </tr>
    //                        <tr>
    //                            <td><b>Data Fim</b></td>
    //                            <td>{ DateService.formatToDisplayWithTime(new Date(request.date)) }</td>
    //                            <td></td>
    //                            <td></td>
    //                        </tr>
    //
    //                    </tbody>
    //                </table>
    //            </div>
    //        </div>
    //    </div>
    //</ModalComponent>
}
