import ProviderService from "services/ProviderService"

const initialState = {
    routes: null
}

export const SET_ROUTES   = 'SET_ROUTES'

export default function route(state = initialState, action: any) {
    if ( action.type === SET_ROUTES ) {
        return { routes: action.routes }
    }
    return state
}

export const load_routes = (provider_id: number) => (dispatch: any) => {
    ProviderService.getProviderRoutes(provider_id)
        .then(response => dispatch(set_routes(response)))
}

const set_routes = (routes: any) => {
    return { type: SET_ROUTES, routes }
}
