import HttpClient from './http.client'
import Constants from '../../constants'
export default class AuthService {

    static auth_token = (token: string) =>
        HttpClient.post(`/auth/token`, { token })

    static removeRepresentative = (user_id) =>
        HttpClient.put(`${Constants.URL_API_AUTH}/excluirusuario/${user_id}`, {})
}
