/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable react/prop-types */
import './index.scss'

import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import withReactContent from 'sweetalert2-react-content'

import { Input } from 'components/input'
import { Button } from 'components/button'
import { Weekday } from 'models/weekday.model'
import ProviderService from 'services/v3/ProviderService'
import { FeedbackError } from 'components/feedback/error'
import { Loader } from 'components/loader'

export const WeekdayForm = ({ user }) => {
    const MySwal = withReactContent(Swal)

    const [ weekdays, setWeekdays ] = useState<Weekday[]>([])
    const [ isLoading, setLoading ] = useState<boolean>(false)

    const days = {
        0: { label: 'Domingo', value: 'sun' },
        1: { label: 'Segunda', value: 'mon' },
        2: { label: 'Terça', value: 'tue' },
        3: { label: 'Quarta', value: 'wed' },
        4: { label: 'Quinta', value: 'thu' },
        5: { label: 'Sexta', value: 'fri' },
        6: { label: 'Sábado', value: 'sat' },
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        loadRegisters()
    }, [])

    const loadRegisters = async () => {
        try {
            setLoading(true)
            const data = await ProviderService.deliveryWeekdays(
                user.providerId
            )
            setWeekdays(data)
        } catch (e) {
            console.log('Error to load register', e)
        } finally {
            setLoading(false)
        }
    }

    const onChangeWeekday = (key: string, value: string) => {
        const id: number = Number(key.replace('weekday_', ''))
        const result: Weekday[] = weekdays.map((w) => {
            if (w.id === id) {
                w.hour = Number(value)
            }
            return w
        })
        setWeekdays(result)
    }

    const onSubmit = async () => {
        try {
            setLoading(true)
            await ProviderService.saveDeliveryWeekdays(
                user.providerId,
                weekdays
            )
            MySwal.fire({
                title: <p>Horários salvos com sucesso!</p>,
            })
        } catch (e) {
            MySwal.fire({
                title: (
                    <p>
                        Algo inesperado aconteceu, tente novamente em alguns
                        instantes!
                    </p>
                ),
                confirmButtonColor: 'red',
            })
            console.log('Error to submit delivery weekdays', e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <p className={ `title-weekday` }>
                Horário máximo para realização do pedido (0h - 23h)
            </p>

            { isLoading && <Loader type="inline" size={ 50 } /> }

            { !isLoading && (
                <table className={ `table` }>
                    <tbody>
                        <tr>
                            { weekdays.map((weekday) => (
                                <td key={ weekday.id } className={ `td-weekday` }>
                                    { days[weekday.day]?.label }
                                    <Input
                                        type="number"
                                        name={ `weekday_${weekday.id}` }
                                        onChange={ (e: any) =>
                                            onChangeWeekday(
                                                e.target.name,
                                                e.target.value
                                            ) }
                                        defaultValue={ weekday.hour }
                                        register={ register({
                                            required: true,
                                            pattern: /^(1?[0-9]|2[0-3])$/,
                                        }) }
                                    />
                                    { errors[`weekday_${weekday.id}`] && (
                                        <FeedbackError
                                            message={ 'Horário inválido' }
                                        />
                                    ) }
                                </td>
                            )) }
                        </tr>
                    </tbody>
                </table>
            ) }

            <Button variant="primary" type="submit">
                Salvar
            </Button>
        </form>
    )
}
