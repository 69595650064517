/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable react-hooks/exhaustive-deps */
import ModalComponent from 'components/modal-v2'
import * as $ from 'jquery'
import React, { useEffect, useState } from 'react'
import './index.scss'
import DirectSaleOrderService from 'services/DirectSaleOrderService'
import { HiOutlineTruck } from 'react-icons/hi'
import { BiError } from 'react-icons/bi'
import { FiCheckCircle } from 'react-icons/fi'
import DateService from 'services/DateService'
import { Status } from 'components/status'
import Constants from '../../../../constants'
import { DirectSaleOrder } from 'interfaces/directSaleOrder'
import directSaleOrders from 'store/directSaleOrder'
import { ReactComponent as PixLogo } from '../../../../assets/pix.svg'
import { MdPix } from 'react-icons/md'
import { Loader } from 'components/loader'

export const OrdersModal = ({ route_delivery_id, onClose }: any) => {
    const [orders, setOrders] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)
    const [modal, setModal] = useState({
        styles: { maxWidth: '80vw' },
        id: 'weekday-modal-mine',
        title: `Pedidos da rota`,
    })

    useEffect(() => {
        setIsLoading(true)
        route_delivery_id &&
            DirectSaleOrderService.getAll({ route_delivery_id }).then(
                (response) => {
                    console.log(response)
                    setOrders(response.data)
                    setIsLoading(false)
                }
            )
    }, [route_delivery_id])

    const get_integration_classname = (integration) => {
        return integration
            ? 'text-center integrated'
            : 'text-center not-integrated'
    }

    function handleClose() {
        setOrders([])
        onClose()
    }
    return (
        <ModalComponent
            modal={modal}
            show={!!route_delivery_id}
            close={() => handleClose()}
            // close={onClose}
            className={'modal-dialog'}
            footerNull
        >
            {isLoading ? <Loader /> : null}

            <div className="direct-sale-order color-gray-2">
                <div className="listDirectSalesOrder">
                    <table className="NewTableDirectSalesOrder">
                        <thead>
                            <tr>
                                <th scope="col">Pedido</th>
                                <th scope="col">Empresa</th>
                                <th scope="col">Data</th>
                                <th scope="col">Valor do Pedido</th>
                                <th scope="col">Entrega</th>
                                <th scope="col">Tipo Recebimento</th>
                                <th scope="col">Rota</th>
                                <th scope="col">Status</th>
                                <th scope="col">Vendedor</th>
                                <th scope="col" className="text-center">
                                    Integração
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((item: DirectSaleOrder) => (
                                <tr key={item.id}>
                                    <td data-label="Pedido">
                                        <div className="orderIdContainer">
                                            <div className="pixPaymentContainer">
                                                {item.paymentStatus ==
                                                    'PAGO' && (
                                                    <div
                                                        style={{
                                                            width: 14,
                                                            marginRight: 3,
                                                        }}
                                                    >
                                                        <PixLogo
                                                            title="PIX"
                                                            style={{
                                                                color: '#28BCAC',
                                                                filter: 'invert(61%) sepia(48%) saturate(600%) hue-rotate(124deg) brightness(92%) contrast(91%)',
                                                            }}
                                                            fontSize={2}
                                                        />
                                                    </div>
                                                )}
                                                {item.paymentStatus ==
                                                    'CANCELADO' && (
                                                    <div
                                                        style={{
                                                            width: 14,
                                                            marginRight: 3,
                                                        }}
                                                    >
                                                        <PixLogo
                                                            title="PIX"
                                                            style={{
                                                                color: '#ff1c1c',
                                                                filter: 'invert(27%) sepia(91%) saturate(4597%) hue-rotate(350deg) brightness(98%) contrast(107%)',
                                                            }}
                                                            fontSize={2}
                                                        />
                                                    </div>
                                                )}
                                                {item.is_accepting_pix == 1 &&
                                                item.pix_qr_code_texto ? (
                                                    <MdPix
                                                        title="Pix não recebido !"
                                                        color="yellow"
                                                        size={16}
                                                    ></MdPix>
                                                ) : null}
                                                <p>
                                                    {!item.paymentStatus && '#'}
                                                    {item.id}
                                                </p>
                                            </div>
                                            <p>
                                                {item.integration && (
                                                    <p
                                                        title="Número do pedido externo"
                                                        className="externalOrder"
                                                    >
                                                        {
                                                            item.integration
                                                                .external_reference
                                                        }
                                                    </p>
                                                )}
                                            </p>
                                        </div>
                                    </td>
                                    <td data-label="Empresa">
                                        {item.storeName || '-'}
                                    </td>
                                    <td data-label="Data" className="date">
                                        {DateService.formatToDisplayWithTime(
                                            new Date(
                                                item.date.replace(/\s/, 'T')
                                            )
                                        )}
                                    </td>
                                    <td data-label="Valor do Pedido">{`${
                                        Constants.CURRENCY_SIGNAL
                                    } ${item.value.toFixed(2)}`}</td>
                                    <td data-label="Entrega" className="date">
                                        {item.deliveryDate
                                            ? DateService.formatToDisplay(
                                                  new Date(
                                                      item.deliveryDate.replace(
                                                          /\s/,
                                                          'T'
                                                      )
                                                  )
                                              )
                                            : '-'}
                                    </td>
                                    <td data-label="Tipo Recebimento">
                                        {item.receiving_method || '-'}
                                    </td>
                                    <td data-label="Rota">
                                        {item.route?.description || '-'}
                                    </td>
                                    <td data-label="Status">
                                        <Status status={item.status} />
                                    </td>
                                    <td data-label="Vendedor">
                                        {item.seller?.name || '-'}
                                    </td>
                                    <td
                                        data-label="Integração"
                                        className={get_integration_classname(
                                            item.integration
                                        )}
                                    >
                                        <div className="divIntegrationDSO">
                                            {item.integration ? (
                                                //false
                                                <FiCheckCircle
                                                    color="#30AA4C"
                                                    style={{ fontSize: 23 }}
                                                    title="Integrado"
                                                />
                                            ) : (
                                                <BiError
                                                    color="#CD2A2A"
                                                    style={{ fontSize: 23 }}
                                                    title="Não Integrado"
                                                />
                                            )}
                                            {item.field_control ? (
                                                <HiOutlineTruck
                                                    title={
                                                        item.field_control
                                                            .status ==
                                                        'INTEGRATED'
                                                            ? 'Field Control integrado !'
                                                            : 'Field Control não integrado !'
                                                    }
                                                    color={
                                                        item.field_control
                                                            .status ==
                                                        'INTEGRATED'
                                                            ? '#30AA4C'
                                                            : 'rgb(205, 42, 42);'
                                                    }
                                                    className={
                                                        item.field_control
                                                            .status ==
                                                        'INTEGRATED'
                                                            ? ''
                                                            : 'callFieldControlButton'
                                                    }
                                                />
                                            ) : (
                                                <HiOutlineTruck
                                                    title="Field Control"
                                                    color="#c7c7c7"
                                                />
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </ModalComponent>
    )
}
