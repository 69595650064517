import React from 'react'
import { OrderDetails } from '../components/order-details/order-details'
import styles from '../index.module.scss'

type Props = {
    order: any
}

export class OrderPrint extends React.PureComponent<Props> {
    render() {
        return (
            <div className={ `container-fluid ${styles['print-only']}` }>
                <div className="order-print">
                    { this.props.order && <OrderDetails order={ this.props.order } /> }
                </div>
            </div>)
    }
}
