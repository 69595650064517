import { PaymentType } from '../../interfaces/payment-type.model'
import PaymentTypeService from '../../services/payment-type.service'

// TYPES
export const LOAD_PAYMENT_TYPES   = 'LOAD_PAYMENT_TYPES'

// Reducer
const initialState = { types : [] }

export default function payments(state = initialState, action: any) {
    if (action.type === LOAD_PAYMENT_TYPES) {
        return { types: action.types }
    } else {
        return state
    }
}


// ACTIONS
export const load_types = () => {
    return (dispatch: any) => PaymentTypeService.getAll()
        .then((response) => {
            dispatch(set_types(response))
        })
        .catch(() => {
            dispatch(set_types(null))
        })
}

const set_types = (types: PaymentType[] | null) => {
    return { type: LOAD_PAYMENT_TYPES, types }
}
