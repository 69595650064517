import { Currency } from 'components/currency-input'
import IconReact from 'components/icons'
import { UserContext } from 'contexts/user'
import ProductModel from 'models/product.model'
import { useContext, useEffect, useState } from 'react'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { useSnackbar } from 'react-simple-snackbar'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { Input } from '../../../../../../components/input'
import { is_editable, is_visible } from '../../requested-products.helper'
import styles from './index.module.scss'

export const OfferItemRowComponent = ({ length, ref, data, index }: any) => {

  const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)

  const {
    request, setup_offer, products, setCurrentOffer, seller_id,
    setCurrentProduct, save, save_obs, remove_offer, setShowPreviousProductModalVisible, save_quantity, open_previous_offers_modal, setDerivativeProductsModalVisible
  } = data

  //const [prod, setProd] = useState<ProductModel>(products[index])
  const [prod, setProd] = useState<ProductModel>(products)
  const { can } = useContext(UserContext)

  const offerFlagColor = {
    ORANGE: '#ff7110',
    BLUE: '#0325e7',
    GREEN: '#13a700',
    BLACK: '#0a0a0aaa',
  }

  //  useEffect(() => {
  //      setProd(products[index])
  //  }, [data, products, index])
  useEffect(() => {
    setProd(products)
  }, [data, products])

  const get_last_price_text_class = (prod: ProductModel) => {
    if (prod.is_previous_offer_winner == null || !prod.last_offer_price) {
      return ''
    } else if (!prod.is_previous_offer_winner) {
      return 'lower'
    } else {
      return 'higher'
    }
  }

  const get_color = (prod: ProductModel) => {
    if (!prod.is_winner && prod.flagged) {
      return 'blue'
    } else if (!prod.winner_offer_ratio) {
      return 'gray'
    } else if (prod.winner_offer_ratio >= 0 && prod.winner_offer_ratio <= 5) {
      return 'green'
    } else if (prod.winner_offer_ratio > 5 && prod.winner_offer_ratio <= 15) {
      return 'orange'
    } else if (prod.winner_offer_ratio > 15) {
      return 'black'
    }
  }

  const get_last_price_text = (prod: ProductModel) => {
    if (prod?.winner_offer_ratio != undefined && prod?.winner_offer_ratio > 0) {
      return prod.winner_offer_ratio.toFixed(2) + '%'
    }
  }

  const wrap_inputs = (deriv: ProductModel, prod: ProductModel, idx, index) => {
    let el_index = idx
    let row_index = index

    return prod.is_generic ? (<>
      <div style={{ width: 60 }}>

        <Input value={deriv.new_offer_quantity}
          name="offer-quantity"
          id={row_index + 'offer-quantity' + el_index}
          autocomplete="off"
          className={`wrap-amount thin ${styles.itemUnity} ${deriv.qtd_saved && styles.saved}`}
          register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
          disabled={'disabled'} />
      </div>
      <div style={{}} >
        <Input defaultValue={prod.purchase_unity} tabIndex="-1" readOnly="readonly"
          register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
          className={`wrap-description thin ${styles.itemUnity} `} />
      </div>
    </>) :
      <>
        <div style={{ width: 60 }}></div>
        <div style={{}}>
          <Input defaultValue={prod.packing} tabIndex="-1" readOnly="readonly"
            register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
            className={`wrap-description thin ${styles.itemUnity}`} />
        </div>

      </>
  }

  function handleOpenModalDerivated() {
    setCurrentProduct(prod)
    setDerivativeProductsModalVisible(true)
  }
  function handleOpenModalPrevious(deriv) {
    open_previous_offers_modal(deriv)
    setShowPreviousProductModalVisible(true)
  }

  const get_status = (deriv: ProductModel) => {
    if (deriv.new_offer_price == 0) {
      return 'Não Digitado'
    }
    return deriv.is_winner ? 'Vencedor' : 'Não Vencedor'
  }
  return (
    <>
      {prod ? (
        <div key={index} className={styles.wrapper}>
          <div key={prod.id} className={`row product-header bg-light-gray ${styles.main}`}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '85%' }}>
              <div data-label='Código' style={{ display: 'flex', flexDirection: 'row' }}>
                <div className={`${styles.codigoOffer} `}><p>{prod.ean}</p></div>
                <div className={`${styles.circle} `}>
                  {/* <p className={prod.is_generic ? styles.showbrands : styles.showbrandsDisabled}
                                        data-toggle="modal"
                                        //disabled={!prod.is_generic}
                                        data-target="#derivatives-modal"
                                        onClick={() => { prod.is_generic ? handleOpenModalDerivated() : {} }
                                        }>
                                        Ver Marcas
                                    </p> */}
                  {
                    //  <BsCircleFill />
                  }

                </div>

              </div>
              <div className='TdtabelaDigitacaoPreco' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <span className={`${styles.prodNameOffer} `} style={{}}>{prod.name}</span>

                </div>


                <div className={`${styles.infOfferItem}  text-lowercase`}>
                  {prod.is_generic ? <p>Preço do(a): {prod.purchase_unity}</p> : ''}
                  {!prod.is_generic ? <p>Preço</p> : ''}
                </div>
              </div>
            </div>

            {/* <div style={{ width: '15%' }} className=" requested-quantity"><p className="w-100">Qtd {prod.selected_amount}</p></div> */}
          </div>
          {
            prod.derivatives && prod.derivatives.filter(is_visible).map((deriv: any, idx) =>
              <div key={`${deriv.id}-deriv`} className={`row product-deriv ${styles.brand}`}>
                {
                  <div style={{ width: 60 }}>
                  </div>
                }
                <div className="col">
                  {prod.is_generic ? <div className={`brand ${styles.hearth}`}>
                    {deriv.starred ? <AiFillHeart title="Marca preferencial" /> : ''}
                    {!deriv.starred ? <div className="on flex"><AiOutlineHeart /></div> : ''}
                    {deriv.brand}
                  </div> : ''}
                </div>
                <div style={{ width: 400 }}>

                  <div style={{}}>

                    <div style={{ display: 'flex' }} className="wrap">
                      {wrap_inputs(deriv, prod, idx, index)}
                    </div>
                  </div>
                  <div style={{ marginLeft: 10, display: 'flex', borderColor: deriv.offer_flag }}>
                    <Currency
                      id={index + 'set-currency' + idx}
                      name={'set-currency'}
                      value={deriv.new_offer_price}
                      disabled={'disabled'}
                      style={{
                        color: offerFlagColor[deriv.offer_flag],
                        backgroundColor: offerFlagColor[deriv.offer_flag ] + '10',
                        // borderRadius: 6,
                        padding: 4,
                        boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)'
                      }}
                      register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
                      onFocus={() => setCurrentOffer(undefined)}
                      className={`${styles.currencyOffer} ${deriv.saved && styles.saved}`} />
                    <div className={styles['price-actions']}>
                      {can('READ_HISTORY', 'REQUEST_PRODUCT') && deriv.last_offer_price > 0 &&
                        <IconReact
                          type='graphVertical'
                          title="Histórico de ofertas"
                          className={styles.previous} />
                      }
                      {
                        (is_editable(request) && deriv.last_offer_price > 0) ? <IconReact type='fileCopy' className={styles.copy}
                          title="Copiar ultimo preço ofertado" />
                          :
                          <></>

                      }
                    </div>
                  </div>

                </div>
                {
                  //! Total removido grap

                }
                {/* <div style={{ width: 85 }} className=" text-center last-price">
                                    <span title={get_last_price_text(deriv)}
                                        className={get_last_price_text_class(deriv)} style={{ color: get_color(deriv) }}>
                                        {format_currency(deriv.new_offer_price*deriv.new_offer_quantity)}
                                    </span>
                                </div> */}
                <div style={{ width: 140 }} className=" text-right">
                  <Input value={get_status(deriv)}
                    placeholder="..."
                    autocomplete="off"
                    name='offer-obs'
                    id={index + 'offer-obs' + idx}
                    className={` ${styles.offerObs}${deriv.obs_saved && styles.saved}`}
                    disabled={'disabled'}
                    register={(el: any) => deriv.obs_ref ? deriv.obs_ref.current = el : el} />
                </div>
              </div>
            )

          }
        </div>) : ''
      }
    </>
  )

  //return prod ? (
  //    <div style={style} className={styles.wrapper}>
  //        <div key={prod.id} className={`row product-header bg-light-gray ${styles.main}`}>
  //            <div className="col">{prod.ean}</div>
  //            <div className={`${styles.circle} col`}>
  //                <p className={styles.showbrands}
  //                    data-toggle="modal"
  //                    //disabled={!prod.is_generic}
  //                    data-target="#derivatives-modal"
  //                    onClick={() => {prod.is_generic ? setCurrentProduct(prod): {}}
  //                }>
  //                    Ver Marcas
  //                </p>
  //                <BsCircleFill />
  //                <span style={{ width: "50px" }}>{prod.name}</span>
  //            </div>
  //            <div className="col">
  //            </div>
  //            <div className="col text-lowercase">
  //                {!prod.is_generic ? 'Informe apenas seu preço' : ''}
  //                {prod.is_generic ? `Informe o preço do(a): ${prod.purchase_unity}` : ''}
  //            </div>
  //            <div className="col requested-quantity"><p className="w-100">Qtd {prod.requested_quantity}</p></div>
  //        </div>
  //        {prod.derivatives && prod.derivatives.filter(is_visible).map((deriv: any) =>
  //            <div key={`${deriv.id}-deriv`} className={`row product-deriv ${styles.brand}`}>
  //                <div className="col">

  //                </div>
  //                <div className="col">
  //                    {prod.is_generic ? <FiTrash title="Remover digitação"
  //                        onClick={() => { is_editable(request) && remove_offer(deriv) }} /> : ''}
  //                    {prod.is_generic ? <div className={`brand ${styles.hearth}`}>
  //                        {deriv.starred ? <AiFillHeart title="Marca preferencial" /> : ''}
  //                        {!deriv.starred ? <div className="on flex"><AiOutlineHeart /></div> : ''}
  //                        {deriv.brand}
  //                    </div> : ''}
  //                </div>
  //                <div className="col">
  //                    <div className="wrap">
  //                        {wrap_inputs(deriv, prod)}
  //                    </div>
  //                </div>
  //                <div className="col">
  //                    <Currency value={deriv.new_offer_price}
  //                        onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
  //                        disabled={(prod.is_generic && deriv.new_offer_quantity < 1) || (!is_editable(request) || (request.owner_type && request.owner_type !== 'PROVIDER')) ?
  //                            'disabled ' : false}
  //                        register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
  //                        onFocus={() => setCurrentOffer(undefined)}
  //                        className={`offer-amount thin ${deriv.saved && styles.saved}`}
  //                        onChange={(maskedvalue: string, floatValue: number) => {
  //                            deriv.offer.price = floatValue
  //                            deriv.new_offer_price = floatValue
  //                            setup_offer({ maskedvalue, floatValue, deriv, main: prod })
  //                        }}
  //                        onBlur={() => save(() => deriv.saved = true)} />
  //                    <div className={styles['price-actions']}>
  //                        {can('READ_HISTORY', 'REQUEST_PRODUCT') && deriv.last_offer_price > 0 && <RiNumbersLine
  //                            title="Histórico de ofertas"
  //                            className={styles.previous} onClick={() => open_previous_offers_modal(deriv)} />}
  //                        {is_editable(request) && deriv.last_offer_price > 0 &&
  //                            (deriv.new_offer_quantity > 0 || !prod.is_generic) &&
  //                            <FaRegCopy className={styles.copy}
  //                                onClick={() => copy_price(deriv, prod)}
  //                                title="Copiar ultimo preço ofertado" />}
  //                    </div>
  //                </div>
  //                <div className="col text-center last-price">
  //                    <span title={get_last_price_text(deriv)}
  //                        className={get_last_price_text_class(deriv)}>
  //                        {format_currency(deriv.last_offer_price)}
  //                    </span>
  //                </div>
  //                <div className="col text-right">
  //                    <Input value={deriv.observations || ''}
  //                        placeholder="..."
  //                        name="offer-obs"
  //                        className={`thin ${deriv.obs_saved && styles.saved}`}
  //                        disabled={(!is_editable(request) || deriv.new_offer_price == 0) ? 'disabled ' : false}
  //                        register={(el: any) => deriv.obs_ref ? deriv.obs_ref.current = el : el}
  //                        onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs })}
  //                        onChange={(evt: any) => {
  //                            deriv.observations = evt.target.value
  //                            setup_offer({ deriv, obs: evt.target.value })
  //                        }}
  //                        onBlur={() => save_obs(() => deriv.obs_saved = true)} />
  //                </div>
  //            </div>
  //        )}
  //    </div>) : ''
}
