
import styled from 'styled-components';


interface colorProps {
    theme?: {}
}

export const Container = styled.div<colorProps>`

    /* position: relative; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 180px;
    min-height: 295px;
    max-height: 310px;
    border-radius: 12px;
    border: 1px solid ${props => props.theme.colors['gray-300']};;
    box-shadow: 1px 1px 3px -1px rgba(0,0,0,0.4);
    background-color: ${props => props.theme.colors.backgroundWhite};;
    :hover{
        box-shadow: 1.5px 1.5px 5px  rgba(0,0,0,0.3);
        transition: 200ms ease;
    }

    section{
        margin-top: -5px;
        display: flex;
        justify-content: center;
        /* width: 220px; *///!-------fix image
        width: calc(100% + 12px);
    }
    @media  only screen and (max-width: 500px) {

        width: 95%;
        height: unset;
        margin-top: 5px;
        section{
        /* margin-top: -5px; */
        display: flex;
        justify-content: center;
        width:calc(100% + 6px)
    }
    }
`;

export const Image = styled.img<colorProps>`
    /* width: 195px; */
    /* width: calc(100% + 1px); */
    /* width: 180px; */
    width: 100%;
    height: 130px;

    border-radius: 20px;
    border: 1px solid ${props => props.theme.colors['gray-300']};;
    border-radius: 12px;
    cursor: pointer;
    /* margin: 0 auto; */

    /* object-fit: resize; */
    object-fit: cover;
    @media only screen and (max-width: 800px) {
        /* width: 180px; */
    }
`;
export const ProdInfosContainer = styled.div<colorProps>`
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.textSecondary};;
    text-align: center;
    justify-content: space-evenly;
    height: 100%;
    padding: 2px 4px;
    flex: 1;
    h4{
        font-family: 'Ubuntu';
        display: block;
        font-weight: 400;
        font-size: 1rem;
        /* margin-bottom: 2px; */
        color: ${props => props.theme.colors.textPrimary};;
    }
    span{
        font-family: 'Open Sans' sans-serif;
        font-weight: 300;
        font-size: 0.8rem;
        display: block;
        color: ${props => props.theme.colors.textTertiary};;
    }
    h3{
        font-family: 'Open Sans' sans-serif;
        font-weight: 400;
        font-size: 0.8rem;
        /* margin-bottom: 3px; */
        color: ${props => props.theme.colors.textSecondary};;
    }
    h2{
        font-family: 'Open Sans' sans-serif;
        font-weight: 600;
        font-size: 0.9rem;
        color: ${props => props.theme.colors.primary};;
        line-height: 1rem;
        /* margin-bottom: 2px; */
        /* height: 1rem; */
    }


`;
export const FooterContainer = styled.div<colorProps>`

    height: 40px;
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;


`;
export const AddButton = styled.div<colorProps>`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${props => props.theme.colors.primary};
    cursor: pointer;
    /* pointer-events: all; */
    :hover{
        opacity: 0.9;
    }
    :active{
        opacity: 0.7;
    }
    span{
        font-family: 'Ubuntu';
        font-weight: 400;
        font-size: 0.9rem;
        color: ${props => props.theme.colors.textWhite};;
    }

    user-select: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;


    /* background-color: ${props => props.theme.colors.primary}; */

`;




