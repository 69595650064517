import React from "react";
import './index.scss'

interface props {
    onClick: () => void,
    title: string,
    variant: "save" | "cancel",
    type?: any,
    form?:string

}

export default function ButtonV2({ form, onClick, title, type, variant }: props) {

    return (

        <button
            type={type? type:undefined}
            className={variant == 'cancel' ? 'buttonCancel' : 'buttonSave'}
            onClick={onClick}
            form={form? form : undefined}
            
            >{title}
        </button>

    )
}