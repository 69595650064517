import React, { FC, useEffect, useState } from 'react'
import { Select } from 'components/select'
import SalesmanService from 'services/SalesmanService'
import styles from './index.module.scss'
import { FilterComponent } from 'components/filter'
import ButtonV3 from 'components/Buttonv3'
import { AiOutlineCloseCircle } from 'react-icons/ai'

export const RepresentativesFilterComponent: FC<any> = ({ onChange }: any) => {

    const [params, setParams] = useState<any>({})
    const [salesmen, setSalesmen] = useState<any>([])

    const update = (params: any) => onChange(params)

    const clearFilters = () => onChange({})

    useEffect(() => {
        SalesmanService.getAll().then(data => setSalesmen(data))

    }, [])
    // useEffect(() => {
    //     console.log('salesman',salesmen )
    //
    // }, [salesmen ])


    return (
        <div style={{
            'display': 'flex',
            'flexDirection': 'row',
            'alignItems': 'flex-end',
            'gap':10 ,
            'flexWrap':'wrap'

        }}>

            {
                // <FilterComponent title="Filtrar Representantes" filter={ () => update(params) } clear={ clearFilters }>
            }
            <div style={{}} >
                <Select
                    value_key="id"
                    description="Vendedor"
                    label_key="name"
                    blank_label="Selecione um vendedor"
                    options={salesmen}
                    onChange={(e: any) => setParams({ ...params, vendedor: e.target.value })}>
                </Select>
            </div>

            <div style={{ 'height': 40 }}>
                <ButtonV3
                    style={{ 'height': 40 }}
                    filled
                    title='Filtrar'
                    onClick={() => update(params)}
                />
            </div>
            <div style={{ 'height': 40 }}>
                <ButtonV3
                    style={{ 'height': 40 }}
                    Icon={<AiOutlineCloseCircle className='iconButtonV3' />}
                    title='Limpar Filtros'
                    onClick={clearFilters}
                />
            </div>
            {
                // </FilterComponent>
            }
        </div>
    )
}
