import React from "react";
import './index.scss';
import { IoMdInformationCircle } from 'react-icons/io';
import InputMask from 'react-input-mask'




interface props {
    id: any,
    onChange?: any,
    type: any,
    value?: any,
    placeholder?: any,
    label?: any,
    icon: any,
    mask?: any,
    infoIconNull: any,
    disabled?: any,
    register?: any,
    defaultValue?: any,
    onFocus?: any,
    onBlur?: any
    list?:any
}




export default function InputV2({ id, onChange, type,list, value, placeholder, label, icon, mask, infoIconNull, disabled, register, defaultValue, onFocus, onBlur }: props) {
    function handleKeyDown(event) {
        if (event.key === "Enter" &&  onBlur) {

            onBlur()
        }
    }

    return (
        <div className="containerInputV2">
            <div className={'labelContainer'}>
                {label && <label id={id} className="labelInputV2" >{label}</label>}

                {infoIconNull == false &&
                    <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center', 'justifyContent': 'center' }}>
                        <IoMdInformationCircle className="icon" />
                    </div>
                }

            </div>
            <div className="inputContainer">
                <InputMask
                    list={list}
                    onKeyDown={(event) => handleKeyDown(event)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    defaultValue={defaultValue}
                    ref={register}
                    disabled={disabled}
                    autoComplete='off'
                    mask={mask}
                    onChange={onChange}
                    name={id}
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    className={'input'}
                ></InputMask>
                <section className="iconContainer">
                    {icon}
                </section>
            </div>

        </div>

    )

}
//export default function InputV2({ id, onChange, type, value, placeholder, label, icon,mask }) {
//
//    return (
//        <div className="container">
//            <div className={'labelContainer'}>
//                <label id={id} className="label" >{label}</label>
//                <IoMdInformationCircle className="icon"/>
//            </div>
//            <div className="inputContainer">
//                <input
//                    autoComplete='off'
//                    mask={'99999'}
//                    onChange={onChange}
//                    name={id}
//                    id={id}
//                    type={type}
//                    placeholder={placeholder}
//                    value={value}
//                    className={'input'}
//                ></input>
//                <div className="iconContainer">
//                    {icon}
//                </div>
//            </div>
//
//        </div>
//
//    )
//
//}
