import React from 'react';
import DateService from 'services/DateService';
import { FilterContainer, FilterTitle, FilterValue } from './styles';
import Logo from '../../../assets/Logo-BRANCA.svg'
import { PageHeaderV2 } from 'components/pageHeaderV2';


interface Props {
    contentTable: any,
    params: any,
    salesmen?: any
}

export class PrintSalesReport extends React.PureComponent<Props> {



    render() {
        let vendedor = this.props.params.vendedor ? this.props.salesmen.filter(elem => elem.id == this.props.params.vendedor)[0].name : null
        const formatedParams = {
            ...this.props.params,
            vendedor: vendedor,
            dataIni: DateService.formatToDisplay(new Date(this.props.params.dataIni)),
            dataFim: DateService.formatToDisplay(new Date(this.props.params.dataFim)),
            dataEntrega: this.props.params.dataEntrega ? DateService.formatToDisplay(new Date(this.props.params.dataEntrega)) : null,
        }



        return <div style={{ padding: 20 }}>
            <div style={{ marginBottom: 20 }}>

             <PageHeaderV2 title='Relatório de Vendas de Produto'></PageHeaderV2>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }} >

                {
                    formatedParams &&
                    <div style={{ display: 'flex', gap: 7,flexWrap:'wrap' }}>

                        {formatedParams.dataIni &&
                            <FilterContainer style={{ display: 'flex' }}>
                                <FilterTitle>Data Inicio</FilterTitle>
                                <FilterValue>{formatedParams.dataIni}</FilterValue>
                            </FilterContainer>
                        }
                        {formatedParams.dataFim &&
                            <FilterContainer style={{ display: 'flex' }}>
                                <FilterTitle>Data Fim</FilterTitle>
                                <FilterValue>{formatedParams.dataFim}</FilterValue>
                            </FilterContainer>
                        }
                        {formatedParams.dataEntrega &&
                            <FilterContainer style={{ display: 'flex' }}>
                                <FilterTitle>Data Entrega</FilterTitle>
                                <FilterValue>{formatedParams.dataEntrega}</FilterValue>
                            </FilterContainer>
                        }
                        {formatedParams.status &&
                            <FilterContainer style={{ display: 'flex' }}>
                                <FilterTitle>Status</FilterTitle>
                                <FilterValue>{formatedParams.status}</FilterValue>
                            </FilterContainer>
                        }
                        {formatedParams.vendedor &&
                            <FilterContainer style={{ display: 'flex' }}>
                                <FilterTitle>Vendedor</FilterTitle>
                                <FilterValue>{formatedParams.vendedor}</FilterValue>
                            </FilterContainer>
                        }
                        {formatedParams.cliente &&
                            <FilterContainer style={{ display: 'flex' }}>
                                <FilterTitle>Cliente</FilterTitle>
                                <FilterValue>{formatedParams.cliente}</FilterValue>
                            </FilterContainer>
                        }
                        {formatedParams.tipoEntrega &&
                            <FilterContainer style={{ display: 'flex' }}>
                                <FilterTitle>Tipo de Entrega</FilterTitle>
                                <FilterValue>{formatedParams.tipoEntrega}</FilterValue>
                            </FilterContainer>
                        }
                    </div>
                }
                <div style={{padding:'7px 20px',display:'flex', alignItems:'center', justifyContent:'center', borderRadius:10,backgroundColor:'#f77110'}}>
                    <img src={Logo} color={'red'} alt="logo do club da cotação" className='HeaderLogo' />
                </div>
            </div>
            <div className="NewlistEditCampaign">
                <table className="newTableEditCampaign">
                    <thead>
                        <tr>
                            <th className="">Código</th>
                            <th>Nome</th>
                            <th className="text-center">Quantidade</th>
                            <th className="text-center">Valor Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            this.props.contentTable.length > 0 && this.props.contentTable.map((i, index) => (
                                <tr key={index} >
                                    <td className="">{i.pro_id}</td>
                                    <td className="">{i.pro_descricao}</td>
                                    <td className="text-center">{i.qtd_prod}</td>
                                    <td className="text-center">{`${i.valor_total.toLocaleString('pt-BR', {
                                        currency: 'BRL',
                                        style: 'currency',
                                        minimumFractionDigits: 2
                                    })}`}</td>


                                </tr>
                            ))
                            // ))
                        }

                    </tbody>
                </table>

            </div>
        </div>

    };
}
