import React, { FC } from 'react'
import styles from './index.module.scss'

export const STATUS_CONFIRMED = 'CONFIRMADO'
export const STATUS_NOT_CONFIRMED = 'NÃO CONFIRMADO'
export const STATUS_WAITING_CONFIRMAION = 'AGUARDANDO_CONFIRMACAO'
export const STATUS_CANCELED = 'CANCELADO'
export const STATUS_NOT_TYPED = 'NÃO DIGITADA'
export const STATUS_WITHOUT_ORDER = 'SEM PEDIDO'
export const STATUS_FROZEN = 'CONGELADA'
export const STATUS_ORDERED = 'PEDIDO GERADO'
export const STATUS_TYPED = 'DIGITADA'
export const STATUS_WAITING_PAYMENT = 'AGUARDANDO_PAGAMENTO'
export const STATUS_STARTED = 'INICIADO'

interface Props {
  status: string
}

export const Status: FC<Props> = ({ status }: any) => {
    switch( status ) {
    case STATUS_CONFIRMED:
        return <p className={ `${styles.status} ${styles.confirmed}` }>Confirmados</p>// ??
    case STATUS_ORDERED:
        return <p className={ `${styles.status} ${styles.typed}` }>Pedido Gerado</p>// ??
    case STATUS_TYPED:
        return <p className={ `${styles.status} ${styles.ordered}` }>Digitada</p>
    case STATUS_WAITING_CONFIRMAION:
        return <p className={ `${styles.status} ${styles.waitingConfirmation} ` }>Aguardando Confirmação</p>// ??
    case STATUS_NOT_CONFIRMED:
        return <p className={ `${styles.status} ${styles['not-confirmed']}` }>Não confirmado</p>
    case STATUS_CANCELED:
        return <p className={ `${styles.status} ${styles['canceled']}` }>Cancelado</p>
    case  STATUS_WAITING_PAYMENT:
        return <p className={ `${styles.status} ${styles.waitingPayment}` }>Aguardando Pagamento</p>
    case  STATUS_STARTED:
        return <p className={ `${styles.status} ${styles.ordered}` }>Iniciado</p>
    default:
        return <p className="status canceled">{ status }</p>
    }
}
