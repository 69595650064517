import ButtonV3 from 'components/Buttonv3';
import { Datepicker } from 'components/datepicker';
import IconReact from 'components/icons';
import InputV2 from 'components/InputV2';
import { Loader } from 'components/loader';
import NewPagination from 'components/newPagination';
import { PageHeaderV2 } from 'components/pageHeaderV2';
import { Pagination } from 'components/pagination';
import PaginationCustomV2 from 'components/PaginationCustomV2';
import { ProjectContainer } from 'components/project-container';
import { RoadMap } from 'components/roadmap';
import { Select } from 'components/select';
import { SelectV2 } from 'components/selectV2';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DateService from 'services/DateService';
import SalesmanService from 'services/SalesmanService';
import SalesReportService from 'services/salesReportService';
import { useReactToPrint } from 'react-to-print'
import Constants from "../../constants";
import './style.css'
import {
  Header,
  BodyContainer,
  FiltersContainer,
  DatePickerAndButtonsContainer,
  SelectFiltersContainer,
  TableContainer,
  FilterButtonsContainer,
  DatePickersContainer,
  CustomCheckContainer,
  ChecksContainer,
  DivClienteEndereco
} from './styles';
import { PrintReport } from './print';
import ClientService from 'services/v3/clients.service';
import HttpClient from 'services/HttpClient';
import { CheckBoxCustom } from 'components/checkBoxCustom';
import { stringify } from 'querystring';
import { FaAngleDown } from "react-icons/fa";
import Utils from 'utils';
import { PaginationCustom } from 'components/pagination-custom';
import { useSelector } from 'react-redux';
import ButtonShowHideMobileFilters from 'components/ButtonShowHideMobileFilters';



export function WinnersReport() {




  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const initialQueryParams = {
    dataini: new Date(),
    datafim: new Date(),
    ordenacao: 'descricao',
    tipoorder: 'asc',
    nome: null,
    cliente: null,
    vendedor: null
    // dataini=2023-12-06&datafim=2024-02-21
  }

  const [contentTableToPrint, setContentTableToPrint] = useState(initialQueryParams)
  const [queryParams, setQueryParams] = useState(initialQueryParams)
  const [params, setParams] = useState<any>(initialQueryParams)
  const [contentTable, setContentTable] = useState<any>([])
  const [contentTableEan, setContentTableEan] = useState<any>([])
  const [paginationInfo, setPaginationInfo] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [salesmen, setSalesmen] = useState<any>([])
  const [clientList, setClientList] = useState<any>([])
  const [selectedExpanded, setSelectedExpanded] = useState<any>([])
  const [consolidarEan, setConsolidarEan] = useState<any>(false)
  const [clientsList, setClientsList] = useState<any>()
  const [showEanTable, setShowEanTable] = useState<any>(false)
  const [showFiltersMobile, setShowFiltersMobile] = useState<any>(false)






  const navigate = useNavigate()

  const componentRef = useRef(null)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })


  async function handleLoadCLients() {
    await ClientService.load_stores({}).then(res => {
      setClientsList(res.data)

    }
    )
  }

  const print = async () => {
    let ini = await DateService.formatToRequest(params.dataini).slice(0, 10)
    let fim = await DateService.formatToRequest(params.datafim).slice(0, 10)

    let parametros = {
      ...params,
      status: selectedStatus.map((item) => { return "'" + item + "'" }).join(','),
      dataini: params.dataini ? ini : null,
      datafim: params.datafim ? fim : null,
      impressao: true
    }



    setIsLoading(true)
    if (!consolidarEan) {

      HttpClient.get(`https://api.clubdacotacao.com.br/api/getitensvencedores`,
        parametros
      ).then((data) => {
        setContentTableToPrint(data.itens)

        handlePrint && handlePrint()
        setIsLoading(false)
        // setShowEanTable(false)
      }).catch(er => errorRequest())
    } else {

      HttpClient.get(`https://api.clubdacotacao.com.br/api/getitensvencedoresean`,
        parametros
      ).then((data) => {
        setContentTableToPrint(data.itens)
        handlePrint && handlePrint()
        setIsLoading(false)
        // setShowEanTable(true)
      }
      ).catch(er => errorRequest())
    }
    // SalesReportService.getSalesReport(formatedParams).then(res => {
    //     setContentTableToPrint(res)
    // }).then(res =>
    //     handlePrint && handlePrint()
    // )

  }
  function errorRequest() {
    setIsLoading(false)
    toast.error('Erro ao buscar dados')
  }


  function RenderTable() {
    handleGetvencedores(consolidarEan)

  }

  useLayoutEffect(() => {
    SalesmanService.getAll().then((data) => setSalesmen(data))
    HttpClient.get('/getclientenome').then((data) => { setClientList(data.clientes), console.log('cli-->', data.clientes) })
    handleLoadCLients()
  }, [])

  useEffect(() => {
    // handleGetvencedores()
  }, [])

  function formatToRequest(date: Date) {
    return `${date.getFullYear()}-` +
      `${Utils.strpad(DateService.getDateMonth(date))}-` +
      `${Utils.strpad(date.getDay())}`
  }

  async function handleGetvencedores(isEan = false, page = 1) {
    setIsLoading(true)

    let ini = await DateService.formatToRequest(params.dataini).slice(0, 10)
    let fim = await DateService.formatToRequest(params.datafim).slice(0, 10)

    let parametros = {
      ...params,
      status: selectedStatus.map((item) => { return "'" + item + "'" }).join(','),
      dataini: params.dataini ? ini : null,
      datafim: params.datafim ? fim : null,
      page: page,
    }



    if (!isEan) {

      HttpClient.get(`https://api.clubdacotacao.com.br/api/getitensvencedores`,
        parametros
      ).then((data) => { setContentTable(data.itens), setShowEanTable(false), setIsLoading(false) }).catch(er => errorRequest())
    } else {

      HttpClient.get(`https://api.clubdacotacao.com.br/api/getitensvencedoresean`,
        parametros
      ).then((data) => { setContentTableEan(data.itens), setShowEanTable(true), setIsLoading(false) }).catch(er => errorRequest())
    }
  }

  function handleChangePage(page) {
    handleGetvencedores(consolidarEan, page)
  }


  function formatDate(date) {
    if (!date) return null;

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  function handleSetStatus(status) {
    let actualStatus = [...selectedStatus]
    if (actualStatus.includes(status)) {
      actualStatus = actualStatus.filter(item => item != status)
    } else {
      actualStatus.push(status)
    }
    setSelectedStatus(actualStatus)
  }

  function validarProduto(produto) {
    if (produto.count_solicitacoes > 0) {
      if (
        produto.quantidade_solicitacao != null &&
        produto.quantidade_solicitacao > 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  function handleConsolidarEan() {
    setConsolidarEan(!consolidarEan)
    // handleGetvencedores(true)
  }
  function handleSetExpandedItem(ean) {
    let actualExpanded = [...selectedExpanded]
    if (actualExpanded.includes(ean)) {
      actualExpanded = actualExpanded.filter(item => item != ean)
    } else {
      actualExpanded.push(ean)
    }
    setSelectedExpanded(actualExpanded)

    // ean == expandedItem ?
    //     setExpandedItem('') : setExpandedItem(ean)
  }
  const handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    let clienteSelected = clientList.filter(cli => cli.cli_nome.includes(selectedOption))
    if (clienteSelected.length == 1) {
      clienteSelected = clienteSelected[0].cli_id
    //   console.log(clienteSelected)
      setParams({ ...params, cliente: clienteSelected });
    } else {
      if (params.cliente != null) {
        setParams({ ...params, cliente: null });
      }
    }

  }


  return (

    <ProjectContainer>
      <RoadMap pageLinks={[
        {
          title: 'Relatórios de itens vencedores',
          onclick: () => { }
        },
      ]}></RoadMap>
      <Header>
        <PageHeaderV2 title='Itens vencedores'></PageHeaderV2>

        {contentTable.data?.length > 0 && !consolidarEan || contentTableEan.data?.length > 0 && consolidarEan ?
          <div>
            <ButtonV3
              style={{ height: '47px' }}
              Icon={<IconReact type='printer' className='iconButtonV3' />}
              title="Imprimir"
              onClick={() => print()}
            />

          </div> : null
        }
      </Header>

      <BodyContainer>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable

          pauseOnHover />

        <ButtonShowHideMobileFilters onShowFilters={(e) => setShowFiltersMobile(e)}></ButtonShowHideMobileFilters>
        {showFiltersMobile ? <FiltersContainer>
          <DatePickerAndButtonsContainer>
            <DatePickersContainer>

              <div style={{ width: 150 }}>
                <Datepicker
                  callendarIcon
                  value={params.dataini}
                  description="Data Início"
                  onChange={(date: string) => setParams({ ...params, dataini: new Date(date) })}
                />
              </div>
              <div style={{ width: 150 }}>
                <Datepicker
                  callendarIcon
                  value={params.datafim}
                  description="Data Fim"
                  // onChange={(date: any) => setParams({ ...params, dataFim:DateService.formatToRequest(new Date(date))})}
                  onChange={(date: any) => setParams({ ...params, datafim: new Date(date) })}

                />

              </div>

            </DatePickersContainer>

          </DatePickerAndButtonsContainer>

          <SelectFiltersContainer>

            <div style={{ minWidth: 240 }}>
              <InputV2
                infoIconNull
                icon={<IconReact type='search'></IconReact>}
                mask={undefined}
                id={'Buscar'}
                label={'Nome do produto'}
                value={params.produto}
                type={'text'}
                placeholder={'Digite o nome do produto'}
                onChange={(e: any) => setParams({ ...params, nome: e.target.value })}
              />

            </div>
            <div style={{ minWidth: 180 }}>
              <InputV2
                infoIconNull
                icon={<IconReact type='search'></IconReact>}
                mask={undefined}
                id={'solicitacao'}
                label={'Solicitação'}
                value={params.produto}
                type={'text'}
                placeholder={'Nº da solicitação'}
                onChange={(e: any) => setParams({ ...params, numerocotacao: e.target.value })}
              />

            </div>
            {clientList.length > 0 ?
              <div style={{ minWidth: 250, }}>
                <InputV2
                  list="cliList"
                  mask={undefined}
                  infoIconNull
                  icon={null}
                  id={'clientlist'}
                  label={'Cliente'}
                  type={'text'}
                  placeholder={'Nome do cliente'}
                  // value={params.produto}
                  // className='c-input'
                  // onChange={(e: any) => setParams({ ...params, numerocotacao: e.target.value })}
                  onChange={handleOptionChange}
                />

                <datalist id="cliList">
                  {clientList.map((opt) => (
                    <option id={opt.cli_id}>{opt.cli_nome}</option>))
                  }
                </datalist>

                {/* <Select
                                    value={params.cliente}

                                    description="Cliente"
                                    blank_label='Selecione um cliente'
                                    label_key="cli_nome"
                                    value_key="cli_id"
                                    name="cliente"
                                    //register={}
                                    onChange={(e: any) => setParams({ ...params, cliente: e.target.value })}
                                    options={clientList}
                                /> */}
              </div>

              : null}
            <div style={{ minWidth: 180, }}>
              <Select
                value={params.vendedor}
                description="Vendedor"
                blank_label='Selecione um vendedor'
                label_key="name"
                value_key="id"
                name="vendedor"
                //register={}
                onChange={(e: any) => setParams({ ...params, vendedor: e.target.value })}
                options={salesmen}
              />

            </div >
            <CustomCheckContainer>
              <CheckBoxCustom checked={consolidarEan} change={() => handleConsolidarEan()} description=''></CheckBoxCustom>
              <p>Consolidar EAN</p>
            </CustomCheckContainer>
            <FilterButtonsContainer>
              {contentTable.length > 0 || contentTableEan.length > 0 &&
                <div>
                  <ButtonV3
                    style={{ height: '47px' }}
                    Icon={<IconReact type='printer' className='iconButtonV3' />}
                    title="Imprimir"
                    onClick={() => print()}
                  />

                </div>
              }

              <ButtonV3
                style={{ height: '47px' }}
                Icon={<IconReact type='closeCircle' className='iconButtonV3' />}
                title="Limpar Filtros"
                onClick={() => (setParams(initialQueryParams), setContentTable([]))}
              />


              <ButtonV3
                style={{ height: '47px' }}
                filled
                Icon={<></>}
                title="Visualizar"
                onClick={() => RenderTable()}
              />
            </FilterButtonsContainer>
          </SelectFiltersContainer>
          {/* <section style={{ display: 'flex', gap: 15, flexWrap: 'wrap', justifyContent: 'space-between' }}>


            <ChecksContainer>
              <CustomCheckContainer bg={'rgba(48, 170, 76, 0.1)'} color={'#30AA4C'}>
                <CheckBoxCustom checked={selectedStatus.includes('cc-color-05')} change={() => handleSetStatus('cc-color-05')} description=''></CheckBoxCustom>
                <section>
                  <p>Competitivo</p>
                </section>
              </CustomCheckContainer>
              <CustomCheckContainer bg={'rgba(255, 113, 16, 0.1)'} color={'#FF7110'}>
                <CheckBoxCustom checked={selectedStatus.includes('cc-color-510')} change={() => handleSetStatus('cc-color-510')} description=''></CheckBoxCustom>
                <section>
                  <p>Intermediário</p>
                </section>
              </CustomCheckContainer>
              <CustomCheckContainer bg={'rgba(96, 96, 96, 0.1)'} color={'#505050'}>
                <CheckBoxCustom checked={selectedStatus.includes('cc-color-10')} change={() => handleSetStatus('cc-color-10')} description=''></CheckBoxCustom>
                <section>
                  <p>Não Competitivo</p>
                </section>
              </CustomCheckContainer>
              <CustomCheckContainer bg={'rgba(23, 162, 184, 0.1)'} color={'#17A2B8'}>
                <CheckBoxCustom checked={selectedStatus.includes('cc-color-blue')} change={() => handleSetStatus('cc-color-blue')} description=''></CheckBoxCustom>
                <section>
                  <p>Outros</p>
                </section>
              </CustomCheckContainer>
            </ChecksContainer>


          </section> */}
        </FiltersContainer>
          : null}
        <TableContainer >
          {!showEanTable ?
            <>
              <div className="NewlistEditCampaign">
                <table className="newTableEditCampaign">
                  <thead>
                    <tr>
                      <th className="">Descrição</th>
                      <th>Qtd</th>
                      <th className="text-center">Val. digitado</th>
                      <th className="text-center">Emb.</th>
                      {/* <th className="text-center">Est.</th> */}
                      {/* <th className="text-center">Cidade</th> */}
                      <th className="">Cliente </th>
                      <th className="text-center">Solicitação</th>
                      <th className="text-center">Data</th>
                      <th className="text-center">Vendedor</th>
                    </tr>
                  </thead>
                  {isLoading ? <Loader /> :
                    <tbody>
                      {
                        contentTable.data?.length > 0 ? contentTable.data.map((item, index) => (
                          <>
                            {
                              item.class != 0 ?
                                <tr key={index}>
                                  <td style={{}} data-label="Descrição" className="">{item.pro_descricao}</td>
                                  <td style={{}} data-label="Qtd" className="text-center">{item.quantidade}</td>
                                  <td style={{}} data-label="Val. digitado" className={`text-center `}>
                                    <div className={item.class}>
                                      R$ {item.valor ? Number(item.valor).toFixed(2) : 0}
                                    </div>
                                  </td>
                                  <td style={{}} data-label="Embalagem" className="text-center">{item.pro_embalagem}</td>
                                  {/* <td style={{}} data-label="Estado" className="text-center">{item.est_sigla}</td> */}
                                  {/* <td style={{}} data-label="Cidade" className="">{item.cid_nome}</td> */}
                                  <td style={{}} data-label="Cliente" className="">
                                    <DivClienteEndereco >
                                      <section>
                                        <span>{item.est_sigla} - </span>
                                        <span>{item.cid_nome}</span>
                                      </section>
                                      <p>  {item.loj_razao}</p>
                                    </DivClienteEndereco>
                                  </td>
                                  <td style={{}} data-label="Solicitação" className="text-center">{item.id_cotacao}</td>
                                  <td style={{}} data-label="Data" className="text-center">{formatDate(item.data_cotacao.substring(0, 10))}</td>
                                  <td style={{}} data-label="Vendedor" className="text-center">{item.nome_vendedor}</td>

                                </tr>
                                : null
                            }
                          </>
                        )) :
                          <>
                            <tr>
                              <td colSpan={10}>
                                <div style={{ width: '100%' }}><h6 style={{ textAlign: 'center' }}>Nenhum registro encontrado!</h6></div>
                              </td>
                            </tr>
                          </>
                        // ))
                      }
                    </tbody>}
                </table>
              </div>
              {
                contentTable?.data && contentTable?.data.length > 0 && !isLoading ?
                  <div style={{ marginTop: 15, marginBottom: 10 }}>
                    <PaginationCustom
                      handlePageChange={(page) => { handleChangePage(page) }}
                      total={contentTable.last_page}
                      itemsName={'registros'}
                      ItemsPorPage={contentTable.per_page}
                      currentPage={contentTable.current_page}
                      totalItems={contentTable.total}
                    />

                  </div> : null

              }
            </>
            :
            <>
              {/* //! --------------------------------tabela consolidar ean */}
              <div className="NewlistEditCampaign">
                <table className="newTableEditCampaign">
                  <thead>
                    <tr>
                      <th className="">Descrição</th>
                      <th>EAN</th>
                      <th className="text-center">Total Qtd.</th>
                      <th className="text-center">Total solicitações</th>
                    </tr>
                  </thead>
                  {isLoading ? <Loader /> :
                    <tbody>
                      {
                        contentTableEan.data?.length > 0 ? contentTableEan.data.map((item, index) => (
                          <>
                            {
                              validarProduto(item) ?
                                <tr
                                  onClick={() => item.itensEan.length > 0 ? handleSetExpandedItem(item.pro_ean) : {}}
                                  key={'ean' + index}
                                  style={{ cursor: item.itensEan.length > 0 ? 'pointer' : 'default' }}
                                >
                                  <td style={{}} data-label="Descrição" className="">

                                    <FaAngleDown color={item.itensEan.length > 0 ? '#ff7110' : '#c7c7c7'} className='chevronDownEan'></FaAngleDown>

                                    {item.pro_descricao}</td>
                                  <td style={{}} data-label="EAN" className="">{item.pro_ean}</td>
                                  <td style={{}} data-label="Total Qtd." className="text-center">{item.quantidade_solicitacao}</td>
                                  <td style={{}} data-label="Total solicitações" className="text-center">{item.quantidade_solicitacoes}</td>
                                </tr>
                                : null
                            }
                            {selectedExpanded.includes(item.pro_ean) ?
                              <tr style={{ transition: '200ms' }} className='trAccordion'>
                                <td colSpan={7}>
                                  <table className="newTableEditCampaign">
                                    <thead>
                                      <tr>
                                        <th>Qtd</th>
                                        <th>Vlr Digitado</th>
                                        <th>Embalagem</th>
                                        <th>Estado</th>
                                        <th>Cidade</th>
                                        <th>Cliente</th>
                                        <th>Solicitação</th>
                                        <th>Data</th>
                                        <th>Vendedor</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.itensEan?.length > 0 && item.itensEan.map((itemP, index) => (
                                        <>
                                          <tr key={index + 'ean'} >
                                            <td data-label="Qtd" className='text-center'>{itemP.quantidade_solicitacao ? itemP.quantidade_solicitacao : itemP.quantidade}</td>
                                            <td data-label="Vlr Digitado" >
                                              <div className={itemP.class}>R$ {item.tipoCliente == 1 && item.tipoPedido == 0
                                                ? itemP.valor ? itemP.valor.toFixed(2)
                                                  : 0
                                                : itemP.valor_embalgem.toFixed(2)}</div>
                                            </td>
                                            <td data-label="Embalagem" className='text-center'>
                                              {itemP.pro_embalagem}
                                            </td>


                                            <td data-label="Estado">{itemP.est_sigla}</td>
                                            <td data-label="Cidade">{itemP.cid_nome}</td>
                                            <td data-label="Cliente" >{itemP.loj_razao ? itemP.loj_razao : itemP.nome_cliente}</td>
                                            <td data-label="Solicitação">{itemP.id_cotacao}</td>
                                            <td data-label="Data">{formatDate(itemP.data_cotacao.substring(0, 10))}</td>
                                            <td data-label="Vendedor" >{itemP.nome_vendedor}</td>
                                          </tr>
                                        </>
                                      ))}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              : null}
                          </>
                        )) :
                          <>
                            <tr>
                              <td colSpan={10}>
                                <div style={{ width: '100%' }}><h6 style={{ textAlign: 'center' }}>Nenhum registro encontrado!</h6></div>
                              </td>
                            </tr>
                          </>
                        // ))
                      }
                    </tbody >}
                </table>
              </div>
              {
                contentTableEan?.data && contentTableEan?.data.length > 0 && !isLoading ?
                  <div style={{ marginTop: 15, marginBottom: 10 }}>
                    <PaginationCustom
                      handlePageChange={(page) => { handleChangePage(page) }}
                      total={contentTableEan.last_page}
                      itemsName={'registros'}
                      ItemsPorPage={contentTableEan.per_page}
                      currentPage={contentTableEan.current_page}
                      totalItems={contentTableEan.total}
                    />
                    {/* total, currentPage, handlePageChange, totalItems, itemsName, ItemsPorPage, ...rest */}
                  </div> : null

              }
            </>
          }
        </TableContainer>
      </BodyContainer>
      {
        <div style={{ display: 'none' }} >
          <PrintReport
            salesmen={salesmen}
            params={params}
            contentTable={contentTableToPrint}
            consolidarEan={consolidarEan}
            ref={componentRef} />
        </div>

      }


    </ProjectContainer >
  );
}
