import ButtonV3 from 'components/Buttonv3'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { Snackbar } from 'components/snackbar'
import { UserContext } from 'contexts/user'
import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { Loader } from '../../../components/loader'
import { ProjectContainer } from '../../../components/project-container'
import Constants from '../../../constants'
import { DirectSaleProduct } from '../../../interfaces/directSaleProduct'
import DirectSaleProductService from '../../../services/DirectSaleProductService'
import ProductForm from '../components/form'
import './index.scss'

import { RoadMap } from 'components/roadmap'
import { ImArrowLeft2 } from 'react-icons/im'
import HttpClient from '../../../services/v3/http.client'

export const ProductEdit = () => {

  const { id } = useParams<string>()
  const { user } = useContext(UserContext)
  const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)

  const [loading, setLoading] = useState<boolean>(false)
  const [product, setProduct] = useState<DirectSaleProduct | null>(null)

  useEffect(() => {
    if (user) {
      setLoading(true)
      DirectSaleProductService.getById(user.providerId, id)
        .then((currentProduct: DirectSaleProduct) => {
          setLoading(false)
          setProduct(currentProduct)
        })
    }
  }, [id, user, setProduct])

  const save = (productToSave: DirectSaleProduct) => {
    setLoading(true)

    DirectSaleProductService.edit({ ...productToSave, id: (id as string) }, [], user.providerId)
      .then(() => {
        setLoading(false)
        HttpClient.post(`/providers/classificacao/sub/items`,productToSave.classificacao)
        openSnackbar(
          <Snackbar icon="sticky_note_2">
            Produto salvo com sucesso!
          </Snackbar>)
      })
      .catch(() => {
        openSnackbar(
          <Snackbar icon="sticky_note_2">
            Ocorreu um erro ao salvar o produto!!
          </Snackbar>)
        setLoading(false)
      })
  }

  const submit_form = () => {
    const sub_btn = document.getElementById("save-product")
    if (sub_btn) {
      sub_btn.click()
    }
  }

  const navigate = useNavigate()

  return (
    <ProjectContainer>
      <div className="products-edit" >
        {loading && <Loader />}
        <header>
          <RoadMap
            pageLinks={[
              {
                title: 'Produtos',
                onclick: () => { navigate(Constants.ROUTE_PRODUCTS) }
              },
              {
                title: 'Editar Produto',
                onclick: () => { }
              },
            ]}
          ></RoadMap>
          <PageHeaderV2 title="Editar Produto" />
        </header>
        <div className="HeaderButtonsContainer" >

          <Link to={`${Constants.ROUTE_PRODUCTS}`}>
            <ButtonV3
              Icon={<ImArrowLeft2 className='iconButtonV3' />}
              title={'Voltar'}
            //onClick={() => { }}
            ></ButtonV3>


          </Link>
          <Link to={Constants.ROUTE_PRODUCTS}>
            <div style={{ 'height': 46 }}>

              <ButtonV3
                style={{ 'height': '100%', 'borderColor': '#FF7110', 'padding': '13px 46px' }}
                textStyle={{ 'color': '#FF7110' }}
                Icon={<></>}
                title={'Cancelar'}
              //onClick={() => { }}
              ></ButtonV3>

            </div>

            {
              //    <Button>
              //        <i className="material-icons">arrow_back</i>Voltar
              //    </Button>
            }
          </Link>
          <ButtonV3
            style={{ 'padding': '13px 46px' }}
            Icon={<></>}
            filled
            type={'submit'}
            title={'Salvar'}
            onClick={submit_form}
          ></ButtonV3>
          {
            //   <Button type="submit" className="c-btn-save ml-3" onClick={submit_form}>
            //       <i className="material-icons">save_alt</i>Salvar
            //   </Button>
          }
        </div>
        <div className="form">
          {product && (
            <ProductForm
              product={product}
              saveCallback={(productToSave: DirectSaleProduct) => save(productToSave)}
            />
          )}
        </div>
        {/* <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center', 'justifyContent': 'center', 'marginTop': 20 }} >
                    <Link to={Constants.ROUTE_PRODUCTS}>
                        <div style={{ 'height': 46, 'marginRight': 20 }}>

                            <ButtonV3
                                style={{ 'height': '100%', 'borderColor': '#FF7110', 'padding': '13px 46px' }}
                                textStyle={{ 'color': '#FF7110' }}
                                Icon={<></>}
                                title={'Cancelar'}
                            //onClick={() => { }}
                            ></ButtonV3>

                        </div>

                        {
                            //    <Button>
                            //        <i className="material-icons">arrow_back</i>Voltar
                            //    </Button>
                        }
                    </Link>
                    <ButtonV3
                        style={{ 'padding': '13px 46px' }}
                        Icon={<></>}
                        filled
                        type={'submit'}
                        title={'Salvar'}
                        onClick={submit_form}
                    ></ButtonV3>
                    {
                        //   <Button type="submit" className="c-btn-save ml-3" onClick={submit_form}>
                        //       <i className="material-icons">save_alt</i>Salvar
                        //   </Button>
                    }
                </div> */}
      </div>
    </ProjectContainer>
  )
}
