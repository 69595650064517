import Constants from "../constants"
import HttpClient from "./HttpClient"

export default class OfferService {

    static save = async (data: any) : Promise<any> => HttpClient.post(Constants.URL_SAVE_OFFER, data)

    static save_quantity = async (data: any) : Promise<any> => HttpClient.post(Constants.URL_SAVE_OFFER_QUANTITY, data)

    static save_obs = async (data: any) : Promise<any> =>  HttpClient.post('/digitarcotacaoobservacao', data)

    static copy_prices = async (data: any) : Promise<any> => HttpClient.get('/copiarprecos', data)

}
