import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import './index.scss'
import { useForm } from 'react-hook-form'
import Constants from '../../constants'
import { FeedbackError } from '../../components/feedback/error'
import AuthService from '../../services/AuthService'
import { AuthProvider, UserContext } from 'contexts/user'
import logo from '../../assets/images/logo-sign-in.jpg'
import { FeedbackSuccess } from 'components/feedback/success'

export const SignIn = () => {

    const navigate = useNavigate()
    const [error, setError] = useState<string>('')
    const [success, setSuccess] = useState<string>('')
    const { register, handleSubmit, errors } = useForm()
    const { me, load_profiles } = useContext(UserContext)
    const [recoveryVisible, setRecoveryVisible] = useState(false)
    const useQuery = () => new URLSearchParams(useLocation().search)

    const token = useQuery().get('token')

    const process_token = useCallback((token: string) => {
        AuthService.signIn(token)
        me().then((response: any) => {
            if (response && response.status !== 401) {
                load_profiles()
                navigate('/')
            } else {
                setError('Token de acesso inválido, tente novamente!')
            }
        })
    }, [load_profiles, history, me])

    useEffect(() => { token && process_token(token) }, [token, process_token])

    const onSubmit = (data: any) => {
        setError('')
        AuthService.authorize(data)
            .then(response => {
                if (response && response.token) {
                    process_token(response.token)
                }
            })
            .catch(() => setError(Constants.MSG_AUTH_FAIL))
    }
    const onSubmitRecovery = (data: any) => {
        setError('')

        AuthService.send_password(data.usernamerecovery)
            .then(response => {
                setSuccess("Senha enviada com sucesso! Verifique seu email")
                // if (response && response.token) {
                //     process_token(response.token)
                // }
                setTimeout(() => {
                    setRecoveryVisible(false)
                }, 6000);

            })
            .catch(() => setError('Erro ao recuperar conta!'))
    }

    return (
        <AuthProvider>
            <div className="sign-in">
                <div className="row sign-in-box-container">
                    {/* <div className="col-sm-12 col-md-3 sign-in-box"> */}
                    <div className="sign-in-box">

                        <div className="sign-in-box-header">
                            <img src={logo} alt="Logo" className="logo" />
                            {!recoveryVisible ?
                                <h1 className="title">Entre com sua conta</h1>
                                : null}
                        </div>

                        <div className="sign-in-box-body">

                            {!recoveryVisible ?
                                <form className="form" onSubmit={handleSubmit(onSubmit)}>

                                    <input
                                        className="field"
                                        placeholder="Nome de usuário"
                                        name="username"
                                        ref={register({ required: true })}
                                        autoComplete="off"
                                    />
                                    {errors.username && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}

                                    <input
                                        className="field"
                                        placeholder="Senha"
                                        name="password"
                                        type="password"
                                        ref={register({ required: true })}
                                        autoComplete="off"
                                    />
                                    {errors.password && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}

                                    <div style={{ fontSize: 12, marginTop: 3, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <p onClick={() => setRecoveryVisible(true)} style={{ cursor: 'pointer', }}>Esqueceu a senha?</p>
                                    </div>
                                    <button className="send" type="submit">Entrar</button>

                                    {error && <FeedbackError message={error} />}
                                </form>
                                :
                                <form className="form" onSubmit={handleSubmit(onSubmitRecovery)}>
                                    <div
                                        style={
                                            {
                                                fontSize: 14,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'flex-start'
                                            }}>
                                        <p>Email ou Login</p>
                                    </div>
                                    <input
                                        style={{ marginTop: 4 }}
                                        className="field"
                                        placeholder="Email usuário"
                                        name="usernamerecovery"
                                        ref={register({ required: true })}
                                        autoComplete="off"
                                    />
                                    {errors.usernamerecovery && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                                    <div style={{ fontSize: 12, marginTop: 3, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <p onClick={() => setRecoveryVisible(false)} style={{ cursor: 'pointer', }}>Voltar</p>
                                    </div>
                                    <button className="send" type="submit">Enviar</button>

                                    {error && <FeedbackError message={error} />}
                                    {success && <FeedbackSuccess message={success} />}
                                </form>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </AuthProvider>
    )
}
