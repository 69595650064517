import { Profile } from 'models/profile.model'
import React, { FC, useCallback, useEffect, useState } from 'react'
import ProfileService from 'services/v3/profile.service'
import { EditProfileModal } from '../edit-profile-modal'
import styles from './index.module.scss'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Button } from 'components/button'
import { BsShieldLock } from 'react-icons/bs'
import { IoMdTrash } from 'react-icons/io'
import { MdOutlineEdit, MdPersonOutline } from 'react-icons/md'
import ButtonV3 from 'components/Buttonv3'


export const ProfileListComponent: FC<any> = () => {

    const [profiles, setProfiles] = useState<any>(null)
    const [currentProfile, setCurrentProfile] = useState<Profile | null>()
    const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false)
    const MySwal = withReactContent(Swal)

    const load = useCallback(() => {
        ProfileService.load()
            .then(data => setProfiles(data))
    }, [])

    const edit = (profile: Profile) => {
        setCurrentProfile(profile)
        setProfileModalOpen(true)
    }

    const add = () => {
        setCurrentProfile({} as Profile)
        setProfileModalOpen(true)
    }

    const close = () => {
        setProfileModalOpen(false)
        setCurrentProfile(null)
        load()
    }

    const remove = (profile: any) => {
        MySwal.fire({
            title: `Confirma remoção do perfil ${profile.name}?`,
            text: `O perfil ${profile.name} será REMOVIDO!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!'
        }).then((result) => {
            if (result.isConfirmed) {
                ProfileService.remove(profile).then(() => {
                    Swal.fire(
                        'Removido!',
                        'Perfil removido com sucesso.',
                        'success'
                    )
                    load()
                }).catch(() => {
                    Swal.fire(
                        'Ops!',
                        'Erro ao remover perfil! Tente novamente mais tarde.',
                        'error'
                    )
                })
            }
        })
    }

    useEffect(() => load(), [load])

    return (
        <div className={`${styles.profiles} text-center col col-12 rounded`}>
            <div >
                <div style={{ 'marginBottom': 15 }} className="d-flex flex-start">
                    {
                        //   <Button className="d-flex flex-start" onClick={add}>
                        //       Novo Perfil
                        //   </Button>
                    }
                    <ButtonV3
                        title='Novo Perfil'
                        Icon={<MdPersonOutline className='iconButtonV3' />}
                        onClick={add}
                    />

                </div>
                {profiles && profiles.meta.total > 0 ?
                    <div className={styles.newListPerfilMI}>
                        <table >
                            <thead>
                                <tr>
                                    <th colSpan={6} className='text-left'>Perfil</th>
                                    <th className="text-right">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {profiles.data.map((item: any) => (
                                    <tr key={item.id}>
                                        <td data-label='Perfil' colSpan={6} className="pl-2 text-left">
                                            <BsShieldLock className={styles.shield} />
                                            {item.name}
                                        </td>
                                        <td data-label='Ações' >
                                            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'flex-end', 'gap': '20px' }}>

                                                <div >
                                                    {
                                                        //    <span onClick={() => remove(item)} className={`material-icons ${styles.iconsActionsExclude}`}
                                                        //        style={{ color: '#ff1010' }}>
                                                        //        delete_forever
                                                        //    </span>
                                                    }
                                                    <IoMdTrash onClick={() => remove(item)} color='#CD2A2A' className={`${styles.svgactionsProfiles}`} />
                                                </div>
                                                <div className={styles.tooltip}>
                                                    {
                                                        //   <i
                                                        //       className={`material-icons ${styles.iconsActionsEdit}`}
                                                        //       data-toggle="tooltip"
                                                        //       data-placement="top"
                                                        //       onClick={() => edit(item)}
                                                        //       style={{ color: '#ff7110' }}
                                                        //   >edit</i>
                                                    }
                                                    <MdOutlineEdit onClick={() => edit(item)} color='#606060' className={`${styles.svgactionsProfiles}`} />
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> : <div>Nenhum perfil encontrado!</div>}
            </div>
            <EditProfileModal
                load={load}
                profile={currentProfile}
                close={close}
                show={currentProfile && isProfileModalOpen} />

        </div>
    )
}
