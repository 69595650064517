import React from "react";


import{
    Container,
    Title,
    Description,

} from './styles'


interface props {
    title:string,
    description:string,
    hasBorderBottom?:boolean

}

export function DescriptionRow({title, description,hasBorderBottom}:props){

return(

    <Container hasBorderBottom={hasBorderBottom}>
        <Title >{title}</Title>
        <Description>{description}</Description>
    </Container>
)


}