
import styled from 'styled-components';


interface pageProps{
    isActive?:any
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

`;

export const ArrowButtonContainer = styled.div`
    

 `;
export const PagesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;

 `;

export const NumberPage = styled.div<pageProps>`
    padding:10px 12px;
    background-color:${({ isActive}) =>isActive? '#ff7110': ''}; 
    color: ${({ isActive}) =>isActive? 'white': '#ff7110'};
    border-radius:6px;
    margin: 0px 2px;

    cursor: pointer;
    &:hover {
        background-color:${({ isActive}) =>isActive? '': '#ff7110'};;
        color: ${({ isActive}) =>isActive? '': 'white'};
    }
    &:active{
        box-shadow: 0 0 0.3em #ff7110;
    }
`;
export const Page = styled.p<pageProps>`
    font-size: 16px;
`;


export const PaginationContainer= styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

`;
export const PaginationDescriptionContainer= styled.div`
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const Description= styled.p`
    
`;