import styled from 'styled-components';
import { RiWhatsappFill } from 'react-icons/ri'
export const Container = styled.div`
    display: flex;
    min-width: 130px;
    `;

export const WhatsIcon = styled(RiWhatsappFill)`
    color: #4BC858;
    font-size: 18px;
    cursor: pointer;
`;
export const WhatsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 3px 4px;
    gap: 3px;
    :hover{
        background-color: #4BC858;
        color: #fff !important;
        border-radius: 4px;

        svg{
            color: #fff
        }
    }
`;
export const Phone = styled.p`

`;
