import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`
const blinkAnimation = keyframes`
  0%,  40%, 100% {
    background-color: rgba(255,0,0,0.1); /* Cor desejada */
  }
  20%, 60% {
    background-color: rgba(255,0,0,0.3);
  }
`;


export const Container = styled.div`

    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px 20px;
`;
export const ContentContainer = styled.div`
    h4{
        font-size: 24px;
        font-family: $font-familyV2;
        font-weight: 600;
        color: #605F5F;
    }
    section{
        display: flex;
        gap: 15px;
        margin-top: 25px;

        @media screen and (max-width: 900px) {
            flex-direction: column;
        }

    }

`;
export const ButtonsContainer = styled.div`
    margin-top: 25px;
    display: flex;
    gap: 15px;
    justify-content: flex-end;

    @media screen and (max-width: 900px) {
            flex-direction: column;


        }

`;
export const ButtonDownload = styled.div`
 display: flex;
  flex-direction: row;
  align-items: center;

  transition: margin 100ms ease-in-out;
  animation: 0.5s ease 0s 1 slideInFromRight;

  //@at-root: #FAFAFC;
  background-color: #ffff;
  border: 1px solid #E5E5E5;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;


  transition: margin 100ms ease-in-out;
  animation: 0.5s ease 0s 1 slideInFromRight;
  background-color: #FF7110;
  border: none;
  color: #fff;
  &:active{
    background-color:rgba(255, 113, 16,0.9);
    box-shadow: 0 0 2px $orange;
  }
  position: relative;
  svg{
    color: #fff;

    animation: ${rotate} 1s linear infinite;
    position: absolute;
    right: 14px;


  }


`;

export const EmptyResponseContainer=styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 10px 15px;
border-radius: 8px;
animation: ${blinkAnimation} 1s ease;
margin: 10px 0;
background-color: rgba(255,0,0,0.2);
    p{
        color: red;
        font-weight: 400;
        font-family: 'Rubik';
    }
`;
