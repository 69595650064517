import { Loader } from 'components/loader'
import { Toggle } from 'components/toggle'
import { UserContext } from 'contexts/user'
import { Representative } from 'interfaces/representative'
import Pagination from 'pagination-front-end'
import React, { useContext, useEffect, useState } from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import RepresentativeService from 'services/RepresentativesService'
import { loadRepresentatives } from 'store/representatives'
import salesmen, { loadSalesmen } from 'store/salesman'
import { Button } from '../../../../../components/button'
import { PaginationCustom } from '../../../../../components/pagination-custom'
import Constants from '../../../../../constants'
import { WeekdayModalV2 } from '../weekday-modal-v2'
import { RepresentativesFilterComponent } from './components/filter'
import styles from './index.module.scss'
import { AiFillFileAdd } from 'react-icons/ai'

import ToggleButton from 'react-toggle-button'


import { MdOutlineAssignmentLate, MdOutlineGroup, MdOutlineEdit } from 'react-icons/md'
import { IoMdTrash } from 'react-icons/io'
import ButtonV3 from 'components/Buttonv3'
import PaginationCustomV2 from 'components/PaginationCustomV2'
import { PhoneWithWhats } from 'components/phoneWithWhats'

export const Representatives = ({
    representatives,
    findRepresentatives,
    findSalesmen,
}: any) => {
    const page = 1
    const { user } = useContext(UserContext)

    const [allPages, setAllPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState<boolean>(true)
    const [showFilters, setShowFilters] = useState<boolean>(false)
    const [representative, setRepresentative] = useState<any>(null)
    const [weekdayModal, setWeekdayModal] = useState<boolean>(false)
    const [representativesItems, setRepresentativesItems] = useState([])
    const [paginationInfo, setPaginationInfo] = useState<any>()
    const [filterinSeller, setFilteringSeller] = useState(false)
    const [queryParams, setQueryParams] = useState<any>({
        page,
        activeFilter: true,
        profile: null,
    })




    function filterSeller(e) {
        //setFilteringSeller(true)
        setQueryParams(e)
    }

    const pageSize = 15


    const toogleFilters = () => setShowFilters(!showFilters)

    function handleSelectPage(page) {
        RepresentativeService.get(`getvendedores?page=${page}`)
            .then(res => {

                setPaginationInfo({ ...res.list })
                setRepresentativesItems(res.list.data)
                //setLoading(!representatives.list.data)
            }).then(res => scrollToTop())

    }

    useEffect(() => {
        if (findRepresentatives) {

            findRepresentatives(queryParams)
        }
    }, [findRepresentatives, queryParams])


    useEffect(() => {
        if (findSalesmen) {

            findSalesmen()
        }
    }, [findSalesmen])



    useEffect(() => {
        if (representatives && representatives.id) {
            setFilteringSeller(true)
            let teste: any = [{ ...representatives }]
            setRepresentativesItems(teste)

        }
        else if (representatives) {

            setPaginationInfo({ ...representatives.list })
            setRepresentativesItems(representatives.list.data)
            setLoading(!representatives.list.data)
            //scrollToTop()
        }
    }, [representatives])

    function scrollToTop() {
        window.scrollTo({ top: 150, behavior: 'smooth' });
    }



    //comentado para testar nova paginação
    // useEffect(() => {
    //     if (representatives) {
    //         const pagination = Pagination.pagination(
    //             representatives.list.data || [],
    //             1,
    //             pageSize
    //         )
    //         setCurrentPage(pagination.currentPage)
    //         setCurrentPage(pagination.currentPage)
    //         if (pagination.allPages == 0) {
    //             setAllPages(1)
    //         } else {
    //             setAllPages(pagination.allPages)
    //         }
    //         setRepresentativesItems(pagination.items)
    //         window.sessionStorage.setItem(
    //             'representativesItemsCount',
    //             `${representatives.list.data.length}`
    //         )

    //     }
    // }, [])

    //  useEffect(() => {
    //      if (representatives) {
    //          console.log('representatives...', representatives)
    //          const pagination = Pagination.pagination(
    //              representatives.list.data || [],
    //              page,
    //              pageSize
    //          )
    //          setLoading(!representatives.list.data)
    //          setCurrentPage(pagination.currentPage)
    //          if (pagination.allPages == 0) {
    //              setAllPages(1)
    //          } else {
    //              setAllPages(pagination.allPages)
    //          }
    //          setRepresentativesItems(pagination.items)
    //          window.sessionStorage.setItem(
    //              'representativesItemsCount',
    //              `${representatives.list.data.length}`
    //          )
    //      }
    //  }, [representatives])

    const remove = (representative: Representative) => {
        if (
            window.confirm(
                `Você tem certeza que deseja excluir ${representative.name}?`
            )
        ) {
            RepresentativeService.remove(representative.id).then(() =>
                findRepresentatives(queryParams)
            )
        }
    }

    const toggle_integration = (representative: Representative) => {

        RepresentativeService.toggle_integration(representative.id).then(() =>
            findRepresentatives(queryParams)
        )
    }

    function handlePageChange(page) {
        const pagination = Pagination.pagination(
            representatives.list.data,
            page,
            pageSize
        )
        setCurrentPage(page)
        setCurrentPage(pagination.currentPage)
        setCurrentPage(pagination.currentPage)
        if (pagination.allPages == 0) {
            setAllPages(1)
        } else {
            setAllPages(pagination.allPages)
        }
        setRepresentativesItems(pagination.items)
    }

    const profile_description = (rep: any) => {


        // if (rep.isMaster) {
        //     return 'Gerente'
        // } else if (rep.isSalesman) {
        //     return 'Vendedor'
        // } else if (rep.isSupervisor) {
        //     return 'Supervisor'
        // } else {
        //     return 'Vendedor'
        // }
    }

    return (
        <div className={`${styles.representatives} col`}>
            <div className={styles['infos-and-actions']}>
                {
                    //  <div className="actions">
                    //      <Button
                    //          className={'c-btn-secondary'}
                    //          onClick={() => toogleFilters()}
                    //      >
                    //          {showFilters ? (
                    //              <span className="flex items-center justify-center">
                    //                  <i className="material-icons">close</i>
                    //                  Filtros
                    //              </span>
                    //          ) : (
                    //              <span className="flex items-center justify-center">
                    //                  <i className="material-icons">filter_alt</i>
                    //                  Filtros
                    //              </span>
                    //          )}
                    //      </Button>
                    //  </div>
                }
            </div>
            {
                // showFilters && (
                true && (
                    <div style={{
                        'display': 'flex',
                        'flexDirection': 'row',
                        'flexWrap': 'wrap',
                        'gap': 10,
                        'alignItems': 'flex-end'
                    }}>
                        {
                            //<RepresentativesFilterComponent onChange={setQueryParams} />
                        }
                        <RepresentativesFilterComponent onChange={(e) => filterSeller(e)} />
                        <Link
                            to={Constants.ROUTE_REPRESENTATIVES_ADD}
                            className="btn__primary btn-icon add-button"
                        >
                            {
                                //   <Button className={'c-btn-ternary'}>
                                //       <i className="material-icons">add</i>Novo Representante
                                //   </Button>
                            }

                            <ButtonV3
                                style={{ 'height': 40 }}
                                //style={{'padding':'10px 13px'}}
                                Icon={<AiFillFileAdd className='iconButtonV3'></AiFillFileAdd>}
                                title='Novo Representante'
                            // onClick={() => { }}
                            />
                        </Link>

                    </div>

                )
            }
            {!loading ? (
                <div>
                    <div className={`${styles.listRepresentatives}`}>
                        <table className={`${styles.newTableRepresentatives}`}>
                            <thead>
                                <tr>
                                    <th>Perfil</th>
                                    <th>Nome</th>
                                    <th>Login</th>
                                    {/* <th>E-mail</th> */}
                                    <th>Telefone</th>
                                    <th>Retaguarda</th>
                                    <th className="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {representativesItems &&
                                    representativesItems.length > 0 &&
                                    // representatives.list.data.map(
                                    representativesItems.map(
                                        (item: Representative) => (
                                            <tr key={item.id}>
                                                <td data-label='Perfil' >
                                                    <div title={!item.isSalesman ? 'Usuário Master' : ""} className={styles.tdPerfilDiv}>

                                                        <div className={
                                                            styles.iconPerfil
                                                        }>
                                                            <FaUserAlt color={!item.isSalesman ? '#ff7110' : "#cecece"}

                                                            />

                                                        </div>
                                                        <p className={
                                                            styles.perfildesc
                                                        } >

                                                            {item.pfl_descricao}
                                                        </p>

                                                    </div>

                                                </td>
                                                <td data-label='Nome'>{item.name}</td>
                                                <td data-label='Login'>{item.login}</td>
                                                {/* <td data-label='E-mail'>{item.email}</td> */}
                                                {/* <td data-label='Telefone'>{item.phone ? item.phone:'- -'}</td> */}
                                                <td data-label='Telefone'><PhoneWithWhats phone={item.ddd ? item.ddd + item.phone : ''} /></td>
                                                <td data-label='Retaguarda'>
                                                    <div className={styles.toggleTdDiv} >
                                                        <ToggleButton
                                                            trackStyle={{}}
                                                            inactiveLabel={<p className='switchButtonLabel'>Inativo</p>}
                                                            activeLabel={<p className='switchButtonLabel'>Ativo</p>}
                                                            colors={{
                                                                activeThumb: {
                                                                    base: 'white',
                                                                },
                                                                inactiveThumb: {
                                                                    base: 'white',
                                                                },
                                                                active: {
                                                                    base: '#FF7110',
                                                                    hover: '#FF7110',
                                                                },
                                                                inactive: {
                                                                    base: '#e2e2e2',
                                                                    hover: '#d9d9d9',
                                                                }
                                                            }}
                                                            value={item.accept_integration}
                                                            onToggle={() => {
                                                                toggle_integration(item)
                                                            }} />
                                                        {
                                                            //  <Toggle
                                                            //      onToggle={() =>
                                                            //          toggle_integration(item)
                                                            //      }
                                                            //      value={
                                                            //          item.accept_integration
                                                            //      }
                                                            //  />
                                                        }
                                                    </div>
                                                </td>
                                                <td data-label='Ações'>
                                                    <div className={styles.tdDivActionsRepresentatives} >
                                                        <div
                                                            style={{ 'cursor': 'pointer' }}
                                                            onClick={() =>
                                                                remove(item)
                                                            }
                                                            title='Excluir'>
                                                            <IoMdTrash color='#CD2A2A' className={`${styles.svgactionsRepresentatives}`} />
                                                            {
                                                                //   <span
                                                                //       onClick={() =>
                                                                //           remove(item)
                                                                //       }
                                                                //       className={`material-icons ${styles.iconsActionsExclude}`}
                                                                //       style={{
                                                                //           color: '#ff1010',
                                                                //       }}
                                                                //   >
                                                                //       delete_forever
                                                                //   </span>
                                                            }
                                                            {
                                                                //    <span
                                                                //        className={
                                                                //            styles.tooltiptext
                                                                //        }
                                                                //    >
                                                                //        Excluir
                                                                //    </span>
                                                            }
                                                        </div>
                                                        <div title='Editar'>
                                                            <Link
                                                                to={`${Constants.ROUTE_REPRESENTATIVES_EDIT}/${item.id}`}
                                                            >
                                                                <MdOutlineEdit color='#606060' className={`${styles.svgactionsRepresentatives}`} />
                                                                {
                                                                    //    <i
                                                                    //        className={`material-icons ${styles.iconsActionsEdit}`}
                                                                    //        data-toggle="tooltip"
                                                                    //        data-placement="top"
                                                                    //        style={{
                                                                    //            color: '#ff7110',
                                                                    //        }}
                                                                    //    >
                                                                    //        edit
                                                                    //    </i>
                                                                }
                                                            </Link>
                                                            {
                                                                //    <span
                                                                //        className={
                                                                //            styles.tooltiptext
                                                                //        }
                                                                //    >
                                                                //        Editar
                                                                //    </span>
                                                            }
                                                        </div>

                                                        <div title='Clientes da cotação' >
                                                            <Link
                                                                to={`${Constants.ROUTE_REPRESENTATIVES_CLIENTS.replace(
                                                                    ':id',
                                                                    item.id
                                                                )}`}
                                                            >
                                                                <MdOutlineGroup color='#17A2B8' className={`${styles.svgactionsRepresentatives}`} />
                                                                {

                                                                    //    <i
                                                                    //        className={`material-icons ${styles.iconsActionsEdit}`}
                                                                    //        data-toggle="tooltip"
                                                                    //        data-placement="top"
                                                                    //        style={{
                                                                    //            color: '#00a54a',
                                                                    //        }}
                                                                    //    >
                                                                    //        groups
                                                                    //    </i>
                                                                }

                                                            </Link>
                                                            {
                                                                //    <span
                                                                //        className={
                                                                //            styles.tooltiptext
                                                                //        }
                                                                //    >
                                                                //        Clientes da cotação
                                                                //    </span>
                                                            }
                                                        </div>
                                                        <div
                                                            title='Parâmetros da venda direta'
                                                            className={styles.tooltip}
                                                            onClick={() => {
                                                                setWeekdayModal(true)
                                                                setRepresentative(item)
                                                            }}
                                                        >
                                                            <MdOutlineAssignmentLate color='#FF7110' className={`${styles.svgactionsRepresentatives}`} />
                                                            {
                                                                //  <i
                                                                //      className={`material-icons ${styles.iconsActionsEdit}`}
                                                                //      data-toggle="tooltip"
                                                                //      data-placement="top"
                                                                //      style={{
                                                                //          color: '#ff7110',
                                                                //      }}
                                                                //  >
                                                                //      info
                                                                //  </i>
                                                            }
                                                            {
                                                                //   <span
                                                                //       className={
                                                                //           styles.tooltiptext
                                                                //       }
                                                                //   >
                                                                //       Parâmetros da venda
                                                                //       direta
                                                                //   </span>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )}
                            </tbody>
                        </table>

                        <WeekdayModalV2
                            user={{
                                providerId: user.providerId,
                                ...representative,
                            }}
                            isVisible={weekdayModal}
                            onCloseCallback={() => setWeekdayModal(false)}
                        />

                        {
                            //    <PaginationCustom
                            //        total={allPages}
                            //        currentPage={currentPage}
                            //        onPageChange={handlePageChange}
                            //        totalItems={
                            //            representatives
                            //                ? representatives.list.data.length
                            //                : 0
                            //        }
                            //        ItemsPorPage={pageSize}
                            //        itemsName={'representantes'}
                            //    />
                        }
                    </div>
                    <PaginationCustomV2
                        handleSelectPage={(page) => handleSelectPage(page)}
                        paginationData={paginationInfo}
                        paginationDataDescription={'de representantes.'}

                    />
                </div>
            ) : (
                <Loader />
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    representatives: state.representatives,
    loggedUser: state.loggedUser,
    salesmen: state.salesmen,
})

const mapDispatchToProps = (dispatch: any) => ({
    findRepresentatives: (params: any) => dispatch(loadRepresentatives(params)),
    findSalesmen: () => dispatch(loadSalesmen()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Representatives)
