import { phoneMask } from 'helpers/mask.helper';
import React from 'react';
import { RiWhatsappFill } from 'react-icons/ri'

import {
    Container,
    WhatsContainer,
    Phone,
    WhatsIcon
} from './styles';
interface props {
    phone: string
}

export function PhoneWithWhats({ phone }: props) {
    return (
        <>
            {phone ?
                <Container title={phone}>
                    <WhatsContainer>
                        <a href={`https://api.whatsapp.com/send?phone=55${phone}`} target="_blank">
                            <WhatsIcon></WhatsIcon>
                        </a>
                        <Phone>{phoneMask(phone)}</Phone>
                        {/* <Phone>{phone}</Phone> */}
                    </WhatsContainer>
                </Container>
                : null}
        </>
    );
}
