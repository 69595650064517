import styled from 'styled-components';

export const Container = styled.div`
display:flex;

flex-direction:column;
align-items: center;
/* overflow-x: scroll; */
/* width: min-content; */
/* flex-wrap:wrap; */
width: 100%;
/* flex: 1; */
/* background-color: lightblue;/ */
/* min-height: 200px; */
position: relative;
section{
    display: flex;
    width:100%;
    gap: 0.4rem;
    overflow-x: hidden;
}
`;
export const Static = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.4rem;
    width: 100%;

`;
export const ImageContainerCarouselLarge = styled.img`
/* overflow: hidden; */
/* max-width: 350px; */
/* width: 50px; */
/* height: 50px; */
/* height: 350px; */
/* display: inline !important; */
/* overflow: scroll; */
/* display: flex; */
/* background-color: red; */
/* object-fit: cover; */
border-radius: 8px;
width: 350px;
height: 350px;
img{
    }

`;

export const IconContainer = styled.div`

`;
export const IconContainerPrev = styled(IconContainer)`
position: absolute;
    left: 0;

`;
export const IconContainerNext = styled(IconContainer)`
    position: absolute;
    right: 10px;
`;
export const Selectors = styled.div`
position: absolute;
    right:'50%';
bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
`;
interface Idot {
    isActive: boolean
}
export const Dot = styled.div`
    width: 12px;
    height: 12px;
    background-color: ${props => props.isActive ? '#ff7110' : '#ff711040'};;
    border-radius: 50%;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    border: 1px solid ${props => props.isActive ? '#ff7110' : '#cecece'};
    cursor: pointer;
`;
export const ImageContainerCarouselBanner = styled.img`
    /* width: max-content; */

    width: 50vw;
    width: 50dvw;
    max-height: 420px;
    object-fit: contain;
    /* overflow-x: auto; */
    @media only screen and (max-width: 740px) {
        object-fit: cover;

}
`;
export const ImageContainerStatic = styled.img`

    width: 350px;
    height: 380px;
    object-fit: contain;

`;
export const VideoContainer = styled.div`

    iframe{

        width: 446px;
        height: 262px;
        border-radius: 8px;
    }
`;

interface dragProps {
    isOver: boolean
    fromSide: 'left' | 'right'
    isdragging: boolean
}
export const CarrouselItemContaner = styled.div<dragProps>`
    padding: 3px;
    background-color: #cecece80;
    border-radius: 4px;
    opacity: ${props => props.isdragging ? 0.4 : 'unset'};;
    /* border-left: 4px solid ${props => props.isOver ? '#ff7110' : 'transparent'} ; */
    border-left: 4px solid ${props => props.isOver && props.fromSide == 'right' ? '#ff7110' : 'transparent'} ;
    border-right: 4px solid ${props => props.isOver && props.fromSide == 'left' ? '#ff7110' : 'transparent'} ;
    padding-left: ${props => props.isOver && props.fromSide == 'right' ? '80px' : '3px'};
    padding-right: ${props => props.isOver && props.fromSide == 'left' ? '80px' : '3px'};
    transition: 250ms ease-in-out;
    /* border-right: 4px solid  transparent ; */
`;
export const GrabIconContainer = styled.div`
    background-color: #fff;
    border-radius: 5px;
    padding: 1px 5px;
    svg{
        font-size: 18px;
    }
    box-shadow: 1px 1px 3px  rgba(0,0,0,0.4);
    cursor: grab;
`;

export const OptionsContainer = styled.div`
    padding: 5px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* position: absolute; */
    /* top: 5px;
    right: 5px; */
    /* background-color: red; */
    div{
        /* display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        border-radius: 50%;
        background-color: #cecece50; */
        /* cursor: pointer; */
        /* :hover{
            background-color: #cecece;
        } */
    }
    /* svg{
        font-size: 18px;


    } */
`;
