import React, { useEffect, useRef, useState } from 'react'
import { CarrouselItemContaner, Container, Dot, GrabIconContainer, IconContainer, IconContainerNext, IconContainerPrev, ImageContainerCarouselBanner, ImageContainerCarouselLarge, ImageContainerStatic, OptionsContainer, Selectors, Static, VideoContainer } from './styles'
import { MdArrowLeft, MdArrowRight, MdDragHandle, MdDragIndicator, MdOutlineDragHandle, MdOutlineDragIndicator } from 'react-icons/md'
import { BiMoveHorizontal, BiTrash } from 'react-icons/bi'
import highlightsService from 'services/v3/HighlightsConfig.service copy'
import { GrDrag } from 'react-icons/gr'
import { IoMdReorder } from 'react-icons/io'

import { ToastContainer, toast } from 'react-toastify';
interface props {
    items: []
    type: 'carousel-banner' | 'carousel-large' | 'carousel-static',
    autoplay: boolean
    reloadHighlights: () => void
}


export default function Carousel({ items, type, autoplay, reloadHighlights }: props) {


    const carouselLargeRef = useRef<any>(null);
    const carouselBannerRef = useRef<any>(null);

    const [currentIndexLarge, setCurrentIndexLarge] = useState(0);
    const [currentIndexBanner, setCurrentIndexBanner] = useState(0);
    const [itemsCarrousel, setItemsCarrousel] = useState(items)
    useEffect(() => {
        setItemsCarrousel(items)
    }, [items])
    async function handleRemoveCard(id) {

        await highlightsService.removeCardFromBLock(id).then(res =>
            reloadHighlights()
        )
    }


    function scrollTo(idx) {

        if (type == 'carousel-large') {
            setCurrentIndexLarge(idx)
        } else {

            setCurrentIndexBanner(idx)
        }
        // let elemento
        // if (type == 'carousel-large') {
        //     elemento = carouselLargeRef.current.children[idx];
        // } else if (type == 'carousel-banner') {
        //     elemento = carouselBannerRef.current.children[idx];
        // }

        // // Verifique se o elemento foi encontrado
        // if (elemento) {
        //     // Execute o scroll suave até o elemento
        //     elemento.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        // }
    }
    const scrollLargeToIndex = (index) => {

        const elemento = carouselLargeRef.current.children[index];
        if (elemento) {

            const rect = elemento.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;

            if (rect.top >= 0 && rect.bottom <= windowHeight) {


                elemento.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                return;
            }


        }

    };
    const scrollBannerToIndex = (index) => {

        const elemento = carouselBannerRef.current.children[index];
        if (elemento) {
            const rect = elemento.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;

            if (rect.top >= 0 && rect.bottom <= windowHeight) {


                setTimeout(() => {

                    elemento.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });

                }, 500);
                return;
            }
        }

    };
    useEffect(() => {


        const interval = setInterval(() => {

            setCurrentIndexLarge((prevIndex) => (prevIndex + 1) % itemsCarrousel.length)
            setCurrentIndexBanner((prevIndex) => (prevIndex + 1) % itemsCarrousel.length)

            // setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 6000);

        return () => clearInterval(interval);
    }, [itemsCarrousel]);

    useEffect(() => {
        if (autoplay && type == 'carousel-banner') {

            scrollBannerToIndex(currentIndexBanner);
        }
    }, [currentIndexBanner]);
    useEffect(() => {
        if (autoplay && type == 'carousel-large') {
            scrollLargeToIndex(currentIndexLarge);
        }
    }, [currentIndexLarge]);

    //? --------------------------------drag and drop ------------------------

    const [isOver, setIsOver] = useState(null)


    const dragItem = useRef<any>(null)
    const draggedOverItem = useRef<any>(null)


    async function handleSort() {



        const itemsClone: any = [...itemsCarrousel]

        let destinationIndex = draggedOverItem.current
        let fromIndex = dragItem.current



        const element = itemsClone.splice(fromIndex, 1)[0];

        itemsClone.splice(destinationIndex, 0, element);

        setItemsCarrousel(itemsClone)
        setIsOver(null)
        dragItem.current = null
        draggedOverItem.current = null


        for (let index = 0; index < itemsClone.length; index++) {
            const card = itemsClone[index];


            highlightsService.editCard(card.id, { ...card, position: index }).then(res => {

            }).catch((resp) => {
                toast.error('Erro ao reordenar destaque', {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,

                })
                return
            })

        }
    }


    function dragOver(e: any, idx: any) {
        e.preventDefault()
        setIsOver(idx)

    }
    function dragStart(index) {
        dragItem.current = index
    }
    function dragEnd(index) {
        draggedOverItem.current = index
        // setTimeout(() => {
        //     dragItem.current = null
        // }, 1000);
    }

    //? --------------------------------drag and drop ------------------------




    return (itemsCarrousel &&
        <Container>


            {type == 'carousel-large' ?
                <section
                    ref={carouselLargeRef}>
                    {itemsCarrousel.map((image: any, index) =>
                        <CarrouselItemContaner
                            // isOver={isOver == index}
                            isdragging={dragItem.current == index}
                            isOver={draggedOverItem.current == index && dragItem.current != index}
                            fromSide={dragItem.current > draggedOverItem.current ? 'right' : 'left'}
                            style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}

                            draggable
                            onDragStart={() => dragStart(index)}
                            onDragEnter={() => dragEnd(index)}
                            onDragEnd={handleSort}

                            onDragOver={(e) => dragOver(e, index)}
                            key={index}
                        // style={{ display: 'flex', position: 'relative' }}
                        >
                            <OptionsContainer  >
                                <div>
                                </div>
                                <GrabIconContainer>
                                    <BiMoveHorizontal size={22}></BiMoveHorizontal>
                                </GrabIconContainer>
                                <div onClick={() => handleRemoveCard(image.id)}>
                                    <BiTrash size={22} color='red' title='Remover item do Bloco' style={{ cursor: 'pointer' }}></BiTrash>
                                </div>
                            </OptionsContainer>
                            {image.tipo == 'img' ?
                                <ImageContainerCarouselLarge
                                    draggable={false}
                                    src={image.img}
                                />
                                :
                                <VideoContainer
                                    // size={getSizeProps(item.modelo)}
                                    onClick={() => { }}
                                    dangerouslySetInnerHTML={{ __html: image.img }}
                                >

                                </VideoContainer>
                            }

                        </CarrouselItemContaner>

                    )}
                </section >
                : null
            }
            {type == 'carousel-banner' ?
                <section ref={carouselBannerRef}>
                    {itemsCarrousel.map((image: any, index) =>
                        <CarrouselItemContaner
                            isOver={isOver == index}
                            draggable
                            onDragStart={() => (dragItem.current = index)}
                            onDragEnter={() => (draggedOverItem.current = index)}
                            onDragEnd={handleSort}
                            onDragOver={(e) => dragOver(e, index)}
                            key={index}
                            style={{ display: 'flex', flexDirection: 'column' }}>
                            <OptionsContainer>
                                <div>
                                </div>
                                <GrabIconContainer>
                                    <BiMoveHorizontal size={22}></BiMoveHorizontal>
                                </GrabIconContainer>
                                <div onClick={() => handleRemoveCard(image.id)}>
                                    <BiTrash size={22} color='red' title='Remover item do Bloco' style={{ cursor: 'pointer' }} ></BiTrash>
                                </div>
                            </OptionsContainer>
                            <ImageContainerCarouselBanner
                                draggable={false}
                                src={image.img ? image.img : image.img_mobile}
                            />

                        </CarrouselItemContaner>

                    )}
                </section>
                : null
            }
            {type == 'carousel-static' ?
                <Static >

                    {itemsCarrousel.map((image: any, index) =>
                        <CarrouselItemContaner
                            draggable
                            onDragStart={() => (dragItem.current = index)}
                            onDragEnter={() => (draggedOverItem.current = index)}
                            onDragEnd={handleSort}
                            onDragOver={(e) => dragOver(e, index)}
                            isOver={isOver == index}
                            key={index} style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                            <OptionsContainer>
                                <div></div>
                                <GrabIconContainer>
                                    <BiMoveHorizontal size={22}></BiMoveHorizontal>
                                </GrabIconContainer>
                                <div onClick={() => handleRemoveCard(image.id)}>
                                    <BiTrash size={22} color='red' title='Remover item do Bloco' style={{ cursor: 'pointer' }} ></BiTrash>
                                </div>
                            </OptionsContainer>
                            {image.tipo == 'img' ?
                                <ImageContainerCarouselLarge
                                    draggable={false}
                                    src={image.img}
                                />
                                :
                                <VideoContainer
                                    // size={getSizeProps(item.modelo)}

                                    dangerouslySetInnerHTML={{ __html: image.img }}
                                >

                                </VideoContainer>
                            }
                        </CarrouselItemContaner>
                    )}

                </Static>
                : null
            }
            {type !== 'carousel-static' ?
                <Selectors>
                    {itemsCarrousel.length > 1 && itemsCarrousel.map((dot, index) => <Dot
                        isActive={type == 'carousel-banner' ? currentIndexBanner == index : currentIndexLarge == index}
                        onClick={() => scrollTo(index)}></Dot>)}

                </Selectors> : null}
        </Container >
    )
}
