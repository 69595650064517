import React, { FC, useEffect } from 'react'
import { useState } from 'react'
import { Input } from 'components/input'
import ModalComponent from 'components/modal-v2'
import styles from './index.module.scss'
import { Currency } from 'components/currency-input'
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import { is_visible, link_products } from '../../requested-products.helper'
import { filter_number } from 'helpers/string.helper'
import { FaBoxes } from 'react-icons/fa'
import ProductModel from 'models/product.model'

export const DerivativeProductsModalComponent: FC<any> = ({
    setup_offer, is_editable, setCurrentOffer, save_obs, setCurrentProduct,
    navigate, save, product, request, reprocess, save_quantity, products, onChange
}: any) => {

    const [modal, setModal] = useState<any>({})

    useEffect(() => {
        if (product) {
            load_links(product)
        }
        setModal({
            title: `${product?.name}`,
            icon: <FaBoxes />,
            styles: {
                maxWidth: '910px'
            },
            onClose: reprocess
        })
    }, [product, reprocess])

    const load_links = (data: any) => {
        data.derivatives.sort((a, b) => {
            const pos1 = a.brand
            const pos2 = b.brand
            if (pos1 > pos2) return 1
            if (pos2 > pos1) return -1
            return 0
        })
        link_products(data.derivatives.filter((p: ProductModel) => is_visible(p)))
        reprocess()
        return true
    }

    const close = () => {
        setCurrentProduct(null)
        onChange(products)
    }

    return (
        <>
            <div className={styles.modalContentDesktop} style={{ padding: 25 }}>
                <p className={styles.info}>
                    Informe a quantidade na embalagem e o preço do(a) <strong>{product?.purchase_unity}</strong>
                </p>
                <div className={styles.newListDerivatedProducts} >
                    <table className={styles.newTableDerivatedProducts}>
                        <thead>
                            <tr>
                                <th colSpan={2} className="">DESCRIÇÃO/MARCA</th>
                                <th className="text-left pl-4">QTD. NA EMBALAGEM</th>
                                <th className="pl-3">PREÇO</th>
                                <th>OBSERVAÇÃO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {product && product.derivatives && product.derivatives.map((deriv: any, index) =>
                                <tr key={`${deriv.id}-deriv`} >
                                    <td data-label="DESCRIÇÃO/MARCA" colSpan={2}>
                                        <div className={styles.brandDerivated}>
                                            {product.is_generic ?
                                                <div className={styles.hearth}>
                                                    {deriv.starred ? <AiFillHeart className={styles.on} /> : ''}
                                                    {!deriv.starred ? <AiOutlineHeart className={styles.off} /> : ''}
                                                    {deriv.brand}
                                                </div> : ''}
                                        </div>
                                    </td>
                                    <td data-label="QTD. NA EMBALAGEM">
                                        <div className={styles.wrap}>
                                            <div style={{ width: 60 }}>
                                                <Input value={deriv.new_offer_quantity || ''}
                                                    name="offer-quantityModal"
                                                    id={'offer-quantityModal' + index}
                                                    disabled={(request.owner_type && request.owner_type !== 'PROVIDER') || !is_editable(request) ? 'disabled ' : false}
                                                    onChange={(evt: any) => {
                                                        const parsed = parseInt(filter_number(evt.target.value))
                                                        deriv.new_offer_quantity = isNaN(parsed) ? 0 : parsed
                                                        deriv.offer.quantity = deriv.new_offer_quantity
                                                        setup_offer({
                                                            floatValue: deriv.new_offer_price,
                                                            deriv, main: product
                                                        })
                                                    }}
                                                    onKeyDown={(e: any) => navigate(e, deriv, { save: null, save_obs: null }, index)}
                                                    onBlur={() => save_quantity(() => deriv.qtd_saved = true)}
                                                    onFocus={() => setCurrentOffer(undefined)}
                                                    className={`${styles.wrapAmount} ${deriv.qtd_saved && styles.saved}`} />
                                            </div>
                                            <div>
                                                <Input defaultValue={product.purchase_unity}
                                                    className={styles.wrapDescription}
                                                    disabled={true} />

                                            </div>
                                        </div>
                                    </td>
                                    {/* id={index + 'set-currency' + idx} */}
                                    <td data-label="PREÇO">
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Currency
                                                name={'setCurrencyModal'}
                                                id={'setCurrencyModal' + index}
                                                value={deriv.new_offer_price}
                                                onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs }, index)}
                                                disabled={(request.owner_type && request.owner_type !== 'PROVIDER') ||
                                                    (!is_editable(request) || !deriv.new_offer_quantity) ? 'disabled ' : false}
                                                register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
                                                onFocus={() => setCurrentOffer(undefined)}
                                                //className={`${styles['offer-amount']} ${deriv.saved && styles.saved}`}
                                                className={`${styles['offer-amount']} ${deriv.saved && styles.saved}`}
                                                onChange={(maskedvalue: string, floatValue: number) => {
                                                    deriv.offer.price = floatValue
                                                    deriv.new_offer_price = floatValue
                                                    load_links(product) &&
                                                        setup_offer({ maskedvalue, floatValue, deriv, main: product })
                                                }}
                                                onBlur={() => save(() => deriv.saved = true)} />
                                        </div>
                                    </td>
                                    <td data-label="OBSERVAÇÃO" className="text-right">
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Input value={deriv.observations || ''}
                                                name="offer-obsModal"
                                                id={'offer-obsModal' + index}
                                                placeholder="..."
                                                disabled={(!is_editable(request) || !deriv.new_offer_quantity) ?
                                                    'disabled ' : false}
                                                register={(el: any) => deriv.obs_ref ? deriv.obs_ref.current = el : el}
                                                onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs }, index)}
                                                className={` ${styles.derivatedObs} ${deriv.obs_saved && styles.saved}`}
                                                onChange={(evt: any) => {
                                                    deriv.observations = evt.target.value
                                                    load_links(product) && setup_offer({ deriv, obs: evt.target.value })
                                                }}
                                                onBlur={() => save_obs(() => deriv.obs_saved = true)} />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={styles.modalContentMobile} style={{ padding: 25 }}>
                <p className={styles.info}>
                    Informe a quantidade na embalagem e o preço do(a) <strong>{product?.purchase_unity}</strong>
                </p>
                <div className={styles.newListDerivatedProducts} >
                    <table className={styles.newTableDerivatedProducts}>
                        <thead>
                            <tr>
                                <th colSpan={2} className="">DESCRIÇÃO/MARCA</th>
                                <th className="text-left pl-4">QTD. NA EMBALAGEM</th>
                                <th className="pl-3">PREÇO</th>
                                <th>OBSERVAÇÃO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {product && product.derivatives && product.derivatives.map((deriv: any, index) =>
                                <tr key={`${deriv.id}-deriv`} >
                                    <td >
                                        <div className={styles.brandDerivatedMobilde}>
                                            {product.is_generic ?
                                                <div className={styles.hearth}>
                                                    {deriv.starred ? <AiFillHeart className={styles.on} /> : ''}
                                                    {!deriv.starred ? <AiOutlineHeart className={styles.off} /> : ''}
                                                    {deriv.brand}
                                                </div> : ''}
                                        </div>
                                    </td>
                                    <td >
                                        <div className={styles.qtdpreçoTitleContainer}>
                                                <p>Qtd. Emb.</p>
                                                <p>Preço</p>
                                        </div>
                                    </td>
                                    {/* id={index + 'set-currency' + idx} */}
                                    <td >
                                        <div className={styles.wrapMobile}>
                                            <div style={{  }}>
                                                <Input
                                                    value={deriv.new_offer_quantity || ''}
                                                    name="offer-quantityModal"
                                                    id={'offer-quantityModal' + index}
                                                    disabled={(request.owner_type && request.owner_type !== 'PROVIDER') || !is_editable(request) ? 'disabled ' : false}
                                                    onChange={(evt: any) => {
                                                        const parsed = parseInt(filter_number(evt.target.value))
                                                        deriv.new_offer_quantity = isNaN(parsed) ? 0 : parsed
                                                        deriv.offer.quantity = deriv.new_offer_quantity
                                                        setup_offer({
                                                            floatValue: deriv.new_offer_price,
                                                            deriv, main: product
                                                        })
                                                    }}
                                                    onKeyDown={(e: any) => navigate(e, deriv, { save: null, save_obs: null }, index)}
                                                    onBlur={() => save_quantity(() => deriv.qtd_saved = true)}
                                                    onFocus={() => setCurrentOffer(undefined)}
                                                    className={`${styles.wrapAmountMobile} ${deriv.qtd_saved && styles.saved}`} />
                                            </div>
                                            <div>
                                                <Input defaultValue={product.purchase_unity}
                                                    className={styles.wrapDescriptionMObile}
                                                    disabled={true} />

                                            </div>
                                            <Currency
                                                name={'setCurrencyModal'}
                                                id={'setCurrencyModal' + index}
                                                value={deriv.new_offer_price}
                                                onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs }, index)}
                                                disabled={(request.owner_type && request.owner_type !== 'PROVIDER') ||
                                                    (!is_editable(request) || !deriv.new_offer_quantity) ? 'disabled ' : false}
                                                register={(el: any) => deriv.ref ? deriv.ref.current = el : el}
                                                onFocus={() => setCurrentOffer(undefined)}
                                                //className={`${styles['offer-amount']} ${deriv.saved && styles.saved}`}
                                                className={`${styles['offer-amount']} ${deriv.saved && styles.saved}`}
                                                onChange={(maskedvalue: string, floatValue: number) => {
                                                    deriv.offer.price = floatValue
                                                    deriv.new_offer_price = floatValue
                                                    load_links(product) &&
                                                        setup_offer({ maskedvalue, floatValue, deriv, main: product })
                                                }}
                                                onBlur={() => save(() => deriv.saved = true)} />
                                        </div>


                                    </td>
                                    <td data-label="OBSERVAÇÃO" className="text-right">
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Input value={deriv.observations || ''}
                                                name="offer-obsModal"
                                                id={'offer-obsModal' + index}
                                                placeholder="..."
                                                disabled={(!is_editable(request) || !deriv.new_offer_quantity) ?
                                                    'disabled ' : false}
                                                register={(el: any) => deriv.obs_ref ? deriv.obs_ref.current = el : el}
                                                onKeyDown={(e: any) => navigate(e, deriv, { save, save_obs }, index)}
                                                className={` ${styles.derivatedObs} ${deriv.obs_saved && styles.saved}`}
                                                onChange={(evt: any) => {
                                                    deriv.observations = evt.target.value
                                                    load_links(product) && setup_offer({ deriv, obs: evt.target.value })
                                                }}
                                                onBlur={() => save_obs(() => deriv.obs_saved = true)} />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>




    )
}
